import { Button } from '@mui/material'
import styled from 'styled-components'

export const CardInformation = styled.div`
  position: relative;
  background: #e7f3ff;
  border-radius: 8px;
  padding: 16px;
  height: fit-content;
  &.closed {
    display: none;
  }
`
export const TitleInformation = styled.h4`
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #3c3938;
  margin-top: 0px;
  margin-bottom: 8px;
`
export const DescriptionInformation = styled.div`
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #3c3938;
  margin: 0px;
  width: 85%;
`
export const ButtonClose = styled(Button)`
  position: absolute !important;
  width: 16px !important;
  min-width: 16px !important;
  height: 20px !important;
  right: 16px !important;
  top: 16px !important;

  font-family: 'Linearicons' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: #3c3938 !important;
  margin: 0 !important;
  border: none !important;
  background: none !important;
  padding: 0 !important;
  &:hover {
    cursor: pointer;
  }
  & span {
    margin: 0 !important;
  }
  & svg {
    width: 16px;
    height: 16px;
  }
`
