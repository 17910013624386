import styled from "styled-components";

export const Shimmer = styled.div`
    width: 100%;
    border-radius: 8px;
    background: #e2e3e4;
    background-image: linear-gradient(to right, #e2e3e4 0%, #F7F9FB 20%, #e2e3e4 40%, #e2e3e4 100%);
    background-repeat: no-repeat;
    animation : shim infinite 1.2s linear;

    @keyframes shim {
        0% { background-position: -800px 0;}
        100% { background-position: 800px 0;}
    }
`