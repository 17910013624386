import axios from "axios";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { store } from "store";
import { logoff, refreshTokenSucess } from "store/auth";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const getAuthAndRefreshToken = () => {
  const authData = JSON.parse(localStorage.getItem("persist:auth"));
  let token = null;
  let refreshToken = null;
  if (!authData) {
    return {token, refreshToken};
  }
  if (!!authData?.token) {
    token = authData.token.replace('"', "").replace('"', "");
    token = (token !== '') ? token : null;
  }
  if (!!authData?.refreshToken) {
    refreshToken = authData.refreshToken.replace('"', "").replace('"', "");
    refreshToken = (refreshToken !== '') ? refreshToken : null;
  }

  return {token, refreshToken};
}

const backend = axios.create({
  baseURL: process.env.it2s_backend_url ?? "https://apibackend.vofficer.io",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  }
});

export default backend;

const backendPrivate = axios.create({
  // baseURL: "http://localhost:5000",
  baseURL: process.env.it2s_backend_url ?? "https://apibackend.vofficer.io",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  }
});

backendPrivate.interceptors.request.use(
  async (config) => {
    const {token, refreshToken} = getAuthAndRefreshToken();
    if (!config.headers?.Authorization) {
      config.headers.Authorization = `Bearer ${token}`
    }

    const user = jwtDecode(token)
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    if (!isExpired) return config;

    const response = await backend.post(`/auth/refresh-token`, {
      refreshToken
    })
    if (response.status === 200 && !!response.data) {
      config.headers.Authorization = `Bearer ${response.data.token}`
      store.dispatch(refreshTokenSucess(response.data))
    }
    return config
  },
  (error) => Promise.reject(error)
);

backendPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const swal = withReactContent(Swal);
      const result = await swal.fire({
        title: 'Aviso!',
        text: 'Sua sessão expirou. gostaria de ser redirecionado para a página de login?',
        icon: 'warning',
        backdrop: true,
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      });

      if (result.isConfirmed) {
        store.dispatch(logoff());
        window.location.replace("/login");
      }

      return Promise.reject("Unauthorized");
    } else {
      return Promise.reject(error);
    }
  }
);



const backendService = axios.create({
  // baseURL: "http://localhost:5000",
  baseURL: "https://services.virtualofficer.io",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  }
});

backendService.interceptors.request.use(
  async (config) => {
    const {token, refreshToken} = getAuthAndRefreshToken();
    if (!config.headers?.Authorization) {
      config.headers.Authorization = `Bearer ${token}`
    }

    const user = jwtDecode(token)
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    if (!isExpired) return config;

    const response = await backend.post(`/auth/refresh-token`, {
      refreshToken
    })
    if (response.status === 200 && !!response.data) {
      config.headers.Authorization = `Bearer ${response.data.token}`
      store.dispatch(refreshTokenSucess(response.data))
    }
    return config
  },
  (error) => Promise.reject(error)
);

backendService.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const swal = withReactContent(Swal);
      const result = await swal.fire({
        title: 'Aviso!',
        text: 'Sua sessão expirou. gostaria de ser redirecionado para a página de login?',
        icon: 'warning',
        backdrop: true,
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Sim",
        cancelButtonText: "Não"
      });

      if (result.isConfirmed) {
        store.dispatch(logoff());
        window.location.replace("/login");
      }

      return Promise.reject("Unauthorized");
    } else {
      return Promise.reject(error);
    }
  }
);

const backendServicePublic = axios.create({
  // baseURL: "http://localhost:5000",
  baseURL: "https://services.virtualofficer.io",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  }
});

export { backendPrivate, backendService, backendServicePublic }