import { Container } from "Components/Container";
import { useCallback, useEffect, useState } from "react";
import { getControlById } from "services/controls-service";
import { Box, Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";
import React from "react";
import {
  AreaOptions,
  CyberSecConceptOptions,
  DomainValue,
  FunctionOptions,
  ISPropertiesOptions,
  TypeOptions,
} from "../options";

import { ControlDetailsItem } from "./ControlDetailsItem";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const ControlDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchControls = useCallback(async () => {
    if (id) {
      setIsLoading(true);
      const result = await getControlById(id);
      if (result?.data) {
        setData(result?.data);
      }
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchControls();
  }, [fetchControls]);

  return (
    <Container
      title="Controles"
      breadcrumb="Configurações gerais / Controles"
      linkPage="/controls"
    >
      {!isLoading && (
        <div className="container">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={0}>
              <Tab
                label="Detalhes"
                id="tab-details"
                aria-controls="tabpanel-details"
                style={{ marginLeft: 0, marginTop: 0, width: "fit-content" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={0} index={0}>
            <ControlDetailsItem name="O que" value={data?.what} />
            <ControlDetailsItem name="Onde" value={data?.where} />
            <ControlDetailsItem name="Porque" value={data?.why} />
            <ControlDetailsItem name="Como" value={data?.how} isArray={false} />
            <ControlDetailsItem
              name="IS Properties"
              value={data?.isProperties}
              isArray={true}
              objKeys={ISPropertiesOptions}
            />
            <ControlDetailsItem
              name="Função"
              value={data?.function}
              isArray={true}
              objKeys={FunctionOptions}
            />
            <ControlDetailsItem
              name="CyberSec"
              value={data?.cyberSecConcept}
              isArray={true}
              objKeys={CyberSecConceptOptions}
            />
            <ControlDetailsItem
              name="Tipo"
              value={data?.type ? TypeOptions[data.type] : ""}
            />
            <ControlDetailsItem
              name="Pré requisito"
              value={data?.prerequisite?.what}
            />
            <ControlDetailsItem name="Plano de ação" value={data?.actionPlan} />
            <ControlDetailsItem
              name="Área"
              value={data?.area ? AreaOptions[data.area] : ""}
            />
            <ControlDetailsItem
              name="Domínio"
              value={data?.domain ? DomainValue[data.domain] : ""}
            />
          </TabPanel>
        </div>
      )}
    </Container>
  );
};
