import { Row } from 'Components/Grid';
import { BgLoginColumn, ColumnContent, ContainerLogin } from './styles';

export const LayoutUnauthenticated = ({children}) => {
    return (
        <ContainerLogin>
            <Row>
                <BgLoginColumn justifyContent="center" flex>
                    {/* <img
                        id="bgLogin"
                        src="/img/bg-unauthenticated.svg"
                        alt="Plano de fundo de"
                    /> */}
                    <img id="logo" src="/img/icons/logo.svg" alt="" />
                </BgLoginColumn>
                <ColumnContent xs={12} sm={6} >
                    {children}
                </ColumnContent>
            </Row>
        </ContainerLogin>
    )
}