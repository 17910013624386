import { Box, Chip, Tab, Tabs } from '@mui/material'
import { Container } from 'Components/Container'
import { Column } from 'Components/Grid'
import { useQuery } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom'
import { ColumnEdit, LinkEdit, RowExhibition } from './styles'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { getDataById } from 'services/datas-service'
import { PageLoaderShow } from 'Components/PageLoader/Show'


const TabPanel = (props) => {
    const { children, value, index, id, ariaLabel, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={id}
            aria-labelledby={ariaLabel}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

export const DataShow = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { isLoading, error, data } = useQuery('getDataById', async () => {
        const resData = await getDataById(id)

        if (resData.status === 200) return resData.data

        return null
    })

    const onClickEdit = () => {
        navigate('/data/edit/'+id)
    }

    return (
        <>
            {!isLoading && !error && (
                <Container
                    title={`${data?.name}`}
                    breadcrumb={`Mapeamento / Tipo de dado / ${data?.name}`}
                    linkPage="/data"
                >
                    <Box sx={{ width: '100%' }} className="container">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={0}
                                aria-label="basic tabs example"
                            >
                                <Tab
                                    label="Detalhes"
                                    id="tab-details"
                                    aria-controls="tabpanel-details"
                                    style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel
                            value={0}
                            index={0}
                            id="tabpanel-details"
                            ariaLabel="tab-details"
                        >
                            <RowExhibition>
                                <Column xs={3}>Nome</Column>
                                <Column>{data.name}</Column>
                                <ColumnEdit xs={2}>
                                    <LinkEdit onClick={onClickEdit}>
                                        <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                        Editar
                                    </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Descrição</Column>
                                <Column>{data.description}</Column>
                                <ColumnEdit xs={2}>
                                    <LinkEdit onClick={onClickEdit}>
                                        <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                        Editar
                                    </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Tags</Column>
                                <Column>
                                    {data.tags.map((tag) => (
                                        <Chip
                                        key={tag}
                                        label={tag}
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                            width: 'fit-content',
                                            marginRight: '6px'
                                        }}
                                        />
                                    ))}
                                </Column>
                                <ColumnEdit xs={2}>
                                    <LinkEdit onClick={onClickEdit}>
                                        <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                        Editar
                                    </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                        </TabPanel>
                    </Box>
                </Container>
            )}
            {isLoading && <PageLoaderShow />}
            {error && <div>Erro</div>}
        </>
    )
}
