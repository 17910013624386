import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import { useSelector } from 'react-redux';
import { useAuthUser } from 'store/auth';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Container } from 'Components/Container';
import Paper from '@mui/material/Paper';

import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import {
  editPolicyVersionStatus,
  getPolicyById,
  getTotalPoliciesAcceptsByPolicyIdAndPolicyVersionId,
  sendPolicyUserAcceptRequest,
} from 'services/policies-service';
import { formatDateTimeToDateTimeBR, formatDateToDateBRPolish } from 'utils/date_utils';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { ButtonForm } from 'Components/Form/styles';
import 'react-quill/dist/quill.snow.css';
import { Column, Row } from 'Components/Grid';
import { useReactToPrint } from 'react-to-print';

export const PolicyVersionShow = () => {
  const { id, idVersion } = useParams();
  const user = useSelector(useAuthUser);
  const componentRef = useRef();
  const navigate = useNavigate();

  const SwalReact = withReactContent(Swal);
  const [refetch, setRefetch] = useState(0);

  const {
    isLoading,
    error,
    data: policy,
  } = useQuery(['getPolicyById', refetch], async () => {
    const resPolicy = await getPolicyById(id);

    if (resPolicy.status === 200) return resPolicy.data;

    return null;
  });

  const { data: policyAccepts } = useQuery(['getTotalPolicyAccepts', refetch, id, idVersion], async () => {
    const resTotalPolicyAccepts = await getTotalPoliciesAcceptsByPolicyIdAndPolicyVersionId(id, idVersion);

    if (resTotalPolicyAccepts.status === 200) return resTotalPolicyAccepts.data;

    return { total: 0 };
  });

  const mutation = useMutation(
    () => {
      return sendPolicyUserAcceptRequest(id, idVersion);
    },
    {
      onError: error => {
        // An error happened!
        SwalReact.fire({
          title: 'Erro!',
          text: error.response.data.message ?? error.message,
          icon: 'error',
        });
      },
      onSuccess: data => {
        if (data.status === 200) {
          setRefetch(!refetch);
        }
      },
    },
  );

  const getClassificationPolicy = policy => {
    return policy ? policy?.classification : '';
  };

  const getVersionPolicy = (policy, idVersion) => {
    return policy ? policy?.policyVersions[idVersion]?.version : '';
  };

  const updateStatus = async status => {
    try {
      SwalReact.showLoading();
      await editPolicyVersionStatus(id, idVersion, status);
      SwalReact.hideLoading();
      SwalReact.fire({
        title: 'Sucesso!',
        text: 'Status alterado com sucesso!',
        icon: 'success',
        willClose: () => {
          setRefetch(refetch + 1);
        },
      });
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message,
        icon: 'error',
      });
    }
  };

  const getStatusReview = policyVersion => {
    if (policyVersion.reviewedDate) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <TaskAltOutlinedIcon sx={{ fill: 'green' }} />
          &nbsp;Revisado
        </div>
      );
    } else if (!policyVersion.reviewedDate && policyVersion.reviewer._id !== user.userId) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <IndeterminateCheckBoxOutlinedIcon sx={{ '&:hover': { cursor: 'not-allowed' }, fill: 'red' }} />
          &nbsp;Você não é o revisor
        </div>
      );
    }

    return (
      <div style={{ verticalAlign: 'middle', display: 'flex' }}>
        <CheckBoxOutlineBlankOutlinedIcon
          sx={{ '&:hover': { cursor: 'pointer' }, fill: '#0039C5' }}
          onClick={() => updateStatus('Revisado')}
        />
        &nbsp;Registrar Revisão
      </div>
    );
  };

  const getStatusApprove = policyVersion => {
    if (policyVersion.approvedDate) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <TaskAltOutlinedIcon sx={{ fill: 'green' }} />
          &nbsp;Aprovado
        </div>
      );
    } else if (!policyVersion.reviewedDate) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <IndeterminateCheckBoxOutlinedIcon sx={{ '&:hover': { cursor: 'not-allowed' }, fill: 'red' }} />
          &nbsp;Aguardando Revisão
        </div>
      );
    } else if (policyVersion.reviewedDate && user.userId !== policyVersion.approver._id) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <IndeterminateCheckBoxOutlinedIcon sx={{ '&:hover': { cursor: 'not-allowed' }, fill: 'red' }} />
          &nbsp;Você não é o aprovador
        </div>
      );
    }

    return (
      <div style={{ verticalAlign: 'middle', display: 'flex' }}>
        <CheckBoxOutlineBlankOutlinedIcon
          sx={{ '&:hover': { cursor: 'pointer' }, fill: '#0039C5' }}
          onClick={() => updateStatus('Aprovado')}
        />
        Aprovar
      </div>
    );
  };

  const getStatusPublish = policyVersion => {
    if (policyVersion.publishedDate) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <TaskAltOutlinedIcon sx={{ fill: 'green' }} />
          &nbsp;Publicado
        </div>
      );
    } else if (!policyVersion.approvedDate) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <IndeterminateCheckBoxOutlinedIcon sx={{ '&:hover': { cursor: 'not-allowed' }, fill: 'red' }} />
          &nbsp;Aguardando Aprovação
        </div>
      );
    } else if (policyVersion.publishedDate && user.userId !== policyVersion.author._id) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <IndeterminateCheckBoxOutlinedIcon sx={{ '&:hover': { cursor: 'not-allowed' }, fill: 'red' }} />
          &nbsp;Você não é o publicador
        </div>
      );
    }

    return (
      <div style={{ verticalAlign: 'middle', display: 'flex' }}>
        <CheckBoxOutlineBlankOutlinedIcon
          sx={{ '&:hover': { cursor: 'pointer' }, fill: '#0039C5' }}
          onClick={() => updateStatus('Publicado')}
        />
        Publicar
      </div>
    );
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { size: 10in 12in }',
  });

  return (
    <>
      {!isLoading && !error && (
        <Container
          title={`${policy?.name} - Versão (${policy?.policyVersions[idVersion].version})`}
          breadcrumb={`Adequações / Políticas / ${policy?.name} / Versões`}
          linkPage={`/policies/show/${id}`}
          actionButton={
            <ButtonForm secondary id="printPdf" label="Exportar PDF" onClick={() => handlePrint()}></ButtonForm>
          }
        >
          <Box
            ref={componentRef}
            sx={{
              width: '100%',
              fontFamily:
                '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            }}
            className="container"
          >
            <Row className="mb-1 pl-3">
              <Column xs={12}>
                <h3 className="m-0">{policy.name}</h3>
              </Column>
              <Column xs={12}>
                Versão: {getVersionPolicy(policy, idVersion)} | Classificação: {getClassificationPolicy(policy)}
              </Column>
              <Column xs={12}>
                Publicação: {formatDateToDateBRPolish(policy?.policyVersions[idVersion]?.publishedDate)}
              </Column>
              <Column xs={12}>Autor: {policy?.policyVersions[idVersion].author.name}</Column>
            </Row>
            <div>
              <h3
                style={{
                  textAlign: 'center',
                  fontSize: '1.75rem',
                  fontWeight: '500',
                  marginTop: '1.5rem',
                  marginBottom: '0.5rem',
                }}
              >
                {policy.name}
              </h3>
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
              className="ql-snow ql-editor"
              dangerouslySetInnerHTML={{ __html: policy?.policyVersions[idVersion].policyText }}
            ></div>
            <TableContainer
              component={Paper}
              style={{
                marginBottom: '1rem',
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow key={'table-row-header'}>
                    <TableCell key={`thrc-stage`}>Estágio</TableCell>
                    <TableCell key={`thrc-status`}>Status</TableCell>
                    <TableCell key={`thrc-date`}>Data</TableCell>
                    <TableCell key={`thrc-sponsor`}>Responsável</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={'table-row-header-review'}>
                    <TableCell key={`thrc-stage-1`}>Revisão</TableCell>
                    <TableCell key={`thrc-status-1`}>{getStatusReview(policy?.policyVersions[idVersion])}</TableCell>
                    <TableCell key={`thrc-date-1`}>
                      {formatDateTimeToDateTimeBR(policy?.policyVersions[idVersion].reviewedDate)}
                    </TableCell>
                    <TableCell key={`thrc-sponsor-1`}>{policy?.policyVersions[idVersion].reviewer?.name}</TableCell>
                  </TableRow>
                  <TableRow key={'table-row-header-approve'}>
                    <TableCell key={`thrc-stage-2`}>Aprovação</TableCell>
                    <TableCell key={`thrc-status-2`}>{getStatusApprove(policy?.policyVersions[idVersion])}</TableCell>
                    <TableCell key={`thrc-date-2`}>
                      {formatDateTimeToDateTimeBR(policy?.policyVersions[idVersion].approvedDate)}
                    </TableCell>
                    <TableCell key={`thrc-sponsor-2`}>{policy?.policyVersions[idVersion].approver?.name}</TableCell>
                  </TableRow>
                  <TableRow key={'table-row-header-publish'}>
                    <TableCell key={`thrc-stage-3`}>Publicação</TableCell>
                    <TableCell key={`thrc-status-3`}>{getStatusPublish(policy?.policyVersions[idVersion])}</TableCell>
                    <TableCell key={`thrc-date-3`}>
                      {formatDateTimeToDateTimeBR(policy?.policyVersions[idVersion].publishedDate)}
                    </TableCell>
                    <TableCell key={`thrc-sponsor-3`}>{policy?.policyVersions[idVersion].author?.name}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {policy?.policyVersions[idVersion]?.publishedDate && policyAccepts?.total > 0 && (
              <div>
                <ButtonForm
                  label="Ver Aceites"
                  onClick={() => navigate(`/policies/show/${id}/version/${idVersion}/accepts`)}
                />
              </div>
            )}
            {policy?.policyVersions[idVersion]?.publishedDate && policyAccepts?.total === 0 && (
              <div>
                <ButtonForm label="Enviar Aceites" onClick={() => mutation.mutate()} />
              </div>
            )}
          </Box>
        </Container>
      )}
      {isLoading && <div>Carregando</div>}
      {error && <div>Erro</div>}
    </>
  );
};
