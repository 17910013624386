import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormSuccess } from 'Components/Form/Success';
import { Container } from '../../../Components/Container';
import { Row } from 'Components/Grid';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid'; // Import uuidv4 function to generate UUIDs

import { ButtonForm, TitleForm } from 'Components/Form/styles';
import { FormControl, InputLabel, MenuItem, Select, Box, TextField, Typography, Divider } from '@mui/material';
import { getControls } from 'services/controls-service';

import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { StepperForm } from 'Components/StepperForm';
import {
  addSecurityStandard,
  editSecurityStandard,
  getSecurityStandardById,
  getSecurityStandardControls,
} from 'services/security-standard-service';

const schema = z.object({
  name: z.string().nonempty(),
  version: z.string(),
  description: z.string().optional(),
});
const steps = ['Detalhes', 'Controles'];

export const SecurityStandardForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [idStandard, setIdStandard] = useState(null);
  const [finished, setFinished] = useState(false);
  const [controls, setControls] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
  });
  const [currentRequisite, setCurrentRequisite] = useState('');
  const [selectedRequisites, setSelectedRequisites] = useState([]);
  const [openedRequisite, setOpenedRequisite] = useState(null);

  const handleReset = () => {
    setFinished(false);
    setActiveStep(0);
    setValue('name', '');
    setValue('version', '');
    setValue('description', '');
    setIdStandard(null);
  };

  const fetchSecurityStandard = useCallback(async () => {
    if (id) {
      const result = await getSecurityStandardById(id);
      if (result?.data) {
        setValue('name', result?.data.name);
        setValue('version', result?.data.version);
        setValue('description', result?.data.description);
      }
      return result?.data;
    }
    return null;
  }, [id, setValue]);

  useEffect(() => {
    fetchSecurityStandard();
  }, [fetchSecurityStandard]);

  const fetchControls = useCallback(async () => {
    const result = await getControls();
    if (result?.data) {
      setControls(result?.data);
    }
    return result?.data;
  }, []);

  useEffect(() => {
    fetchControls();
  }, [fetchControls]);

  const fetchSecurityStandardControls = useCallback(async () => {
    console.log('teste');
    if (id) {
      const result = await getSecurityStandardControls(id);
      if (result?.data) {
        const distinctRequisites = [];
        result?.data
          .filter(item => item.requisite)
          .forEach(element => {
            if (distinctRequisites.map(item => item._id).includes(element.requisite?._id)) return;
            else distinctRequisites.push(element.requisite);
          });

        const requisites = distinctRequisites.map(req => ({
          ...req,
          controls: result?.data.filter(item => item.requisite?._id === req._id).map(item => item.control),
        }));
        setSelectedRequisites(requisites);
      }
      return result?.data;
    }
    return null;
  }, [id]);

  useEffect(() => {
    fetchSecurityStandardControls();
  }, [fetchSecurityStandardControls]);

  const onSubmit = async data => {
    const request = {
      ...data,
      requisites: selectedRequisites.map(requisite => {
        return {
          _id: requisite._id,
          name: requisite.name,
        };
      }),
      controls: selectedRequisites
        .map(requisite => {
          return requisite.controls.map(control => ({
            controlId: control._id,
            requisiteId: requisite._id,
          }));
        })
        .reduce((accumulator, controls) => {
          return [...accumulator, ...controls];
        }, []),
    };

    try {
      const res = id ? await editSecurityStandard(id, request) : await addSecurityStandard(request);
      if (res.status === 200) {
        setIdStandard(res.data._id ?? id);
        setFinished(true);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() ? activeStep : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handlePrevStep = () => {
    if (activeStep === 0) {
      navigate('/security-standards');
    } else {
      handleBack();
    }
  };

  const handleNextStep = data => {
    if (activeStep === 0) {
      handleNext();
    } else {
      onSubmit(data);
    }
  };

  const addRequisiteControl = (requisiteId, control) => {
    const newSelected = selectedRequisites.map(r => {
      if (r._id === requisiteId) return { ...r, controls: [...r.controls, control] };
      else return r;
    });
    setSelectedRequisites(newSelected);
  };

  const handleAddRequisite = () => {
    const uuid = uuidv4();
    setSelectedRequisites([...selectedRequisites, { _id: uuid, name: currentRequisite, controls: [] }]);
    setCurrentRequisite('');
  };

  const handleRemoveRequisite = requisite => {
    setSelectedRequisites(selectedRequisites.filter(r => r._id !== requisite._id));
  };

  const handleRemoveControl = (requisite, control) => {
    setSelectedRequisites(
      selectedRequisites.map(r =>
        r._id === requisite.id
          ? {
              ...requisite,
              controls: requisite.controls.filter(c => c._id !== control._id),
            }
          : requisite,
      ),
    );
  };

  return (
    <Container
      title={`${id ? 'Editar' : 'Adicionar'} Padrão`}
      breadcrumb="Configurações globais / Padrões"
      linkPage="/security-standards"
    >
      {!finished && <StepperForm activeStep={activeStep} steps={steps} />}

      <div className="card-content">
        <Row>
          <TitleForm>
            {!finished && activeStep === 0
              ? 'Detalhes sobre o padrão'
              : !finished && activeStep === 1
              ? 'Vincular Controles'
              : ''}
          </TitleForm>
        </Row>

        <form onSubmit={handleSubmit(handleNextStep)}>
          <Stack spacing={4}>
            {!finished && activeStep === 0 && (
              <>
                <Box display={'flex'} gap={4} justifyContent={'space-between'}>
                  <TextField
                    label="Nome"
                    variant="outlined"
                    error={errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                    {...register('name')}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Versão"
                    variant="outlined"
                    fullWidth
                    multiline
                    {...register('version')}
                    InputLabelProps={{ shrink: true }}
                    error={errors.version}
                    helperText={errors.version?.message}
                  />
                </Box>
                <TextField
                  label="Descrição"
                  multiline
                  rows={4}
                  fullWidth
                  {...register('description')}
                  error={errors.description}
                  helperText={errors.description?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </>
            )}

            {!finished && activeStep === 1 && (
              <>
                <TextField
                  label="Requisito"
                  variant="outlined"
                  fullWidth
                  onChange={e => setCurrentRequisite(e.target.value)}
                  value={currentRequisite}
                  rows={2}
                  multiline
                />

                <Box display={'flex'} justifyContent={'center'}>
                  <ButtonForm
                    secondary
                    label={'Adicionar'}
                    onClick={handleAddRequisite}
                    style={{ minWidth: '100% !important' }}
                    className="full"
                  ></ButtonForm>
                </Box>

                <Stack spacing={2}>
                  {selectedRequisites.map((requisite, index) => {
                    const filtered = controls.filter(
                      control => !requisite.controls.find(selectedControl => selectedControl._id === control._id),
                    );
                    return (
                      <Box key={index} borderRadius={'4px'} border={'1px solid #BDB9B7 '}>
                        <Box
                          display={'flex'}
                          borderBottom={openedRequisite === requisite._id ? ' 1px solid #BDB9B7 ' : 'none'}
                          alignItems={'center'}
                          p={2}
                        >
                          <Typography width={'30%'} color={'#3C3938'} fontWeight={700} flex={1}>
                            {requisite.name}
                          </Typography>

                          <Box style={{ cursor: 'pointer' }} mr={2}>
                            <img
                              src="/img/icons/cross.svg"
                              alt="Icone excluir"
                              onClick={() => handleRemoveRequisite(requisite)}
                            />
                          </Box>
                          <Box
                            bgcolor={'white'}
                            borderRadius={'8px'}
                            boxShadow={'0px 6px 20px 0px #EFF1F7'}
                            p={'8px'}
                            width={'32px'}
                            height={'32px'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (openedRequisite === requisite._id) {
                                setOpenedRequisite(null);
                              } else {
                                setOpenedRequisite(requisite._id);
                              }
                            }}
                          >
                            <img
                              src="/img/icons/arrowDown.svg"
                              alt=""
                              style={{
                                transform: openedRequisite === requisite._id ? 'rotate(180deg)' : 'unset',
                              }}
                            />
                          </Box>
                        </Box>
                        {openedRequisite === requisite._id &&
                          requisite.controls.map((item, index) => {
                            return (
                              <>
                                <Box key={index} display={'flex'} p={'8px 16px'}>
                                  <Typography flex={1}>{item.what}</Typography>
                                  <Box style={{ cursor: 'pointer' }}>
                                    <img
                                      src="/img/icons/cross.svg"
                                      alt="Icone excluir"
                                      onClick={() => handleRemoveControl(requisite, item)}
                                    />
                                  </Box>
                                </Box>
                                <Divider />
                              </>
                            );
                          })}
                        {openedRequisite === requisite._id && <Divider />}
                        {openedRequisite === requisite._id && (
                          <Box padding={'8px 16px 16px 16px'}>
                            <FormControl fullWidth variant="standard">
                              <InputLabel id="control">Adicionar controle </InputLabel>
                              <Select
                                labelId="control"
                                id="control"
                                label="Controle"
                                onChange={e => {
                                  addRequisiteControl(requisite._id, e.target.value);
                                }}
                                placeholder="Selecione um controle"
                                value={''}
                              >
                                {filtered.length === 0 && <MenuItem value={''}>Nenhum controle disponível</MenuItem>}

                                {filtered.map(control => (
                                  <MenuItem key={control._id} value={control}>
                                    {control.what}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </Stack>
              </>
            )}
            {!finished && (
              <Box display={'flex'} gap={2} justifyContent={'space-between'}>
                <ButtonForm
                  secondary
                  id="cancel"
                  label={activeStep === 0 ? 'Cancelar' : 'Voltar'}
                  onClick={handlePrevStep}
                ></ButtonForm>
                <ButtonForm
                  label={activeStep === 1 ? 'Salvar' : 'Próximo'}
                  variant="submit"
                  style={{ width: '80px !important' }}
                />
              </Box>
            )}
          </Stack>
        </form>

        {finished && (
          <FormSuccess
            title={`Padrão ${idStandard ? 'editado' : 'cadastrado'} com sucesso!`}
            labelStartAgain="Cadastrar novo padrão"
            handleStartAgain={() => handleReset()}
            labelShow="Ver detalhes"
            handleShow={() => navigate('/security-standards/show/' + idStandard)}
          />
        )}
      </div>
    </Container>
  );
};
