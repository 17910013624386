import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
/** Components */
import { Vinculos } from './Bonds';
import { Container } from '../../../Components/Container';
import { TitleForm } from 'Components/Form/styles';
import { FormSuccess } from 'Components/Form/Success';
import { Row } from 'Components/Grid';
import { StepperForm } from 'Components/StepperForm';
import { Detalhes } from './Details';
/** Services */
import { addAsset, editAsset, getAssetById } from 'services/assets-service';
/** State Managers */
import { useAuthTenant } from 'store/auth';
import { useSelector } from 'react-redux';
/** Third Lib Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useQuery } from 'react-query';
import { PageLoaderForm } from 'Components/PageLoader/Form';

const steps = ['Detalhes', 'Vínculos'];

export const AssetsForm = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const { id } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [idAsset, setIdAsset] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [finished, setFinished] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      criticity: '',
      type: '',
      dataProcessing: '',
      location: '',
      pii: false,
      user: '',
      description: '',
      tags: [],
      areas: [],
      processes: [],
      systems: [],
      companyKey: null,
    },
  });

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() ? activeStep : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleComplete = async () => {
    try {
      setIsLoadingForm(true);
      const assetFormmated = formatForm(getValues());
      const res = id ? await editAsset(id, assetFormmated) : await addAsset(assetFormmated);
      if (res.status === 200 || res.status === 201) {
        setIdAsset(res.data._id ?? id);
        setFinished(true);
        setIsLoadingForm(false);
      } else {
        throw new Error('Ocorreu um erro ao salvar/editar um Ativo!');
      }
    } catch (error) {
      setIsLoadingForm(false);
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const formatProcess = process => {
    return {
      _id: process._id,
      name: process.name,
      description: process.description,
      user: process.user,
      criticity: process.criticity,
    };
  };

  const formatArea = area => {
    return {
      _id: area._id,
      name: area.name,
      description: area.description,
      user: area.user,
      criticity: area.criticity,
    };
  };

  const formatSystem = system => {
    return {
      _id: system._id,
      name: system.name,
      description: system.description,
      criticity: system.criticity,
      user: system.user,
    };
  };

  const formatForm = dados => {
    return {
      name: dados.name,
      criticity: dados.criticity,
      type: dados.type,
      user: dados.user,
      tags: dados.tags.length > 0 ? dados.tags : [],
      pii: dados.pii,
      location: dados.location,
      description: dados.description,
      companyId: dados.user.companyId,
      areas: dados.areas.length > 0 ? dados.areas.map(area => formatArea(area)) : [],
      processes: dados.processes.length > 0 ? dados.processes.map(process => formatProcess(process)) : [],
      systems: dados.systems.length > 0 ? dados.systems.map(system => formatSystem(system)) : [],
      companyKey: dados.companyKey ?? selectedTenant.slug,
    };
  };

  const handleReset = () => {
    reset();
    setActiveStep(0);
    setFinished(false);
    setIdAsset(null);
    navigate('/assets/add');
  };

  const getTitleStep = () => {
    if (finished) return <></>;

    if (activeStep === 0)
      return (
        <Row>
          <TitleForm>Detalhes do ativo</TitleForm>
        </Row>
      );

    return (
      <Row>
        <TitleForm>Vinculos do ativo</TitleForm>
      </Row>
    );
  };

  const {
    isLoading,
    error,
    data: asset,
  } = useQuery(['getAsset', id], async () => {
    if (!id) return null;
    const resAsset = await getAssetById(id);

    if (!resAsset.data) return null;

    return resAsset.data;
  });

  useEffect(() => {
    if (!id) return;
    if (!asset) return;

    Object.keys(asset).forEach(key => {
      setValue(key, asset[key]);
    });

    return () => {};
  }, [id, asset, setValue]);

  const showForm = () => {
    if (id) return !isLoading && !error;
    return true;
  };

  return (
    <Container title={`${id ? 'Editar' : 'Adicionar'} Ativo`} breadcrumb="Mapeamento / Ativo" linkPage="/assets">
      {isLoading && <PageLoaderForm />}
      {showForm() && (
        <>
          <StepperForm activeStep={activeStep} steps={steps} />
          <div className="card-content">
            {getTitleStep()}
            {activeStep === 0 && (
              <Detalhes
                control={control}
                register={register}
                handleNext={handleNext}
                handleSubmit={handleSubmit}
                errors={errors}
                getValues={getValues}
                id={id}
              />
            )}
            {activeStep === 1 && !finished && (
              <Vinculos
                control={control}
                register={register}
                handleBack={handleBack}
                handleComplete={handleComplete}
                handleSubmit={handleSubmit}
                isLoading={isLoadingForm}
              />
            )}
            {finished && (
              <FormSuccess
                title="Ativo cadastrado com sucesso!"
                labelStartAgain="Cadastrar novo ativo"
                handleStartAgain={() => handleReset()}
                labelShow="Ver detalhes"
                handleShow={() => navigate('/assets/show/' + idAsset)}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
};
