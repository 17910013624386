import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { Container } from 'Components/Container';
import { editPolicyVersionStatus, getPolicyById } from 'services/policies-service';
import { useState } from 'react';
import { formatDateTimeToDateTimeBR } from 'utils/date_utils';
import { useSelector } from 'react-redux';
import { useAuthUser } from 'store/auth';
import { PageLoaderShow } from 'Components/PageLoader/Show';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

export const PoliciesChecklistVersion = () => {
  const { id, idVersion } = useParams();
  const user = useSelector(useAuthUser);
  const SwalReact = withReactContent(Swal);
  const [refetch, serRefetch] = useState(0);
  const {
    isLoading,
    error,
    data: policy,
  } = useQuery(['getPolicyById', refetch], async () => {
    const resPolicy = await getPolicyById(id);

    if (resPolicy.status === 200) return resPolicy.data;

    return null;
  });

  const updateStatus = async status => {
    try {
      SwalReact.showLoading();
      await editPolicyVersionStatus(id, idVersion, status);
      SwalReact.hideLoading();
      SwalReact.fire({
        title: 'Sucesso!',
        text: 'Status alterado com sucesso!',
        icon: 'success',
        willClose: () => {
          serRefetch(refetch + 1);
        },
      });
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message,
        icon: 'error',
      });
    }
  };

  const getStatusReview = policyVersion => {
    if (!policyVersion.reviewedDate && policyVersion.reviewer._id !== user.userId) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <IndeterminateCheckBoxOutlinedIcon sx={{ '&:hover': { cursor: 'not-allowed' }, fill: 'red' }} />
          &nbsp;Você não é o revisor
        </div>
      );
    } else if (policyVersion.reviewedDate) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <TaskAltOutlinedIcon sx={{ fill: 'green' }} />
          &nbsp;Revisado
        </div>
      );
    }
    return (
      <div style={{ verticalAlign: 'middle', display: 'flex' }}>
        <CheckBoxOutlineBlankOutlinedIcon
          sx={{ '&:hover': { cursor: 'pointer' }, fill: '#0039C5' }}
          onClick={() => updateStatus('Revisado')}
        />
        &nbsp;Registrar Revisão
      </div>
    );
  };

  const getStatusApprove = policyVersion => {
    if (!policyVersion.reviewedDate) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <IndeterminateCheckBoxOutlinedIcon sx={{ '&:hover': { cursor: 'not-allowed' }, fill: 'red' }} />
          &nbsp;Aguardando Revisão
        </div>
      );
    } else if (policyVersion.reviewedDate && user.userId !== policyVersion.approver._id) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <IndeterminateCheckBoxOutlinedIcon sx={{ '&:hover': { cursor: 'not-allowed' }, fill: 'red' }} />
          &nbsp;Você não é o aprovador
        </div>
      );
    } else if (policyVersion.approvedDate) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <TaskAltOutlinedIcon sx={{ fill: 'green' }} />
          &nbsp;Aprovado
        </div>
      );
    }
    return (
      <div style={{ verticalAlign: 'middle', display: 'flex' }}>
        <CheckBoxOutlineBlankOutlinedIcon
          sx={{ '&:hover': { cursor: 'pointer' }, fill: '#0039C5' }}
          onClick={() => updateStatus('Aprovado')}
        />
        Aprovar
      </div>
    );
  };

  const getStatusPublish = policyVersion => {
    if (!policyVersion.approvedDate) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <IndeterminateCheckBoxOutlinedIcon sx={{ '&:hover': { cursor: 'not-allowed' }, fill: 'red' }} />
          &nbsp;Aguardando Aprovação
        </div>
      );
    } else if (policyVersion.publishedDate && user.userId !== policyVersion.author._id) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <IndeterminateCheckBoxOutlinedIcon sx={{ '&:hover': { cursor: 'not-allowed' }, fill: 'red' }} />
          &nbsp;Você não é o publicador
        </div>
      );
    } else if (policyVersion.publishedDate) {
      return (
        <div style={{ verticalAlign: 'middle', display: 'flex' }}>
          <TaskAltOutlinedIcon sx={{ fill: 'green' }} />
          &nbsp;Publicado
        </div>
      );
    }
    return (
      <div style={{ verticalAlign: 'middle', display: 'flex' }}>
        <CheckBoxOutlineBlankOutlinedIcon
          sx={{ '&:hover': { cursor: 'pointer' }, fill: '#0039C5' }}
          onClick={() => updateStatus('Publicado')}
        />
        Publicar
      </div>
    );
  };
  return (
    <>
      {!isLoading && !error && (
        <Container
          title={`${policy?.name} - Versão (${policy?.policyVersions[idVersion].version})`}
          breadcrumb={`Adequações / Políticas / ${policy?.name} / Versões`}
          linkPage="/policies"
        >
          <Box
            sx={{
              width: '100%',
              fontFamily:
                '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            }}
            className="container"
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow key={'table-row-header'}>
                    <TableCell key={`thrc-stage`}>Estágio</TableCell>
                    <TableCell key={`thrc-status`}>Status</TableCell>
                    <TableCell key={`thrc-date`}>Data</TableCell>
                    <TableCell key={`thrc-sponsor`}>Responsável</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={'table-row-header-review'}>
                    <TableCell key={`thrc-stage-1`}>Revisão</TableCell>
                    <TableCell key={`thrc-status-1`}>{getStatusReview(policy?.policyVersions[idVersion])}</TableCell>
                    <TableCell key={`thrc-date-1`}>
                      {formatDateTimeToDateTimeBR(policy?.policyVersions[idVersion].reviewedDate)}
                    </TableCell>
                    <TableCell key={`thrc-sponsor-1`}>{policy?.policyVersions[idVersion].reviewer?.name}</TableCell>
                  </TableRow>
                  <TableRow key={'table-row-header-approve'}>
                    <TableCell key={`thrc-stage-2`}>Aprovação</TableCell>
                    <TableCell key={`thrc-status-2`}>{getStatusApprove(policy?.policyVersions[idVersion])}</TableCell>
                    <TableCell key={`thrc-date-2`}>
                      {formatDateTimeToDateTimeBR(policy?.policyVersions[idVersion].approvedDate)}
                    </TableCell>
                    <TableCell key={`thrc-sponsor-2`}>{policy?.policyVersions[idVersion].approver?.name}</TableCell>
                  </TableRow>
                  <TableRow key={'table-row-header-publish'}>
                    <TableCell key={`thrc-stage-3`}>Publicação</TableCell>
                    <TableCell key={`thrc-status-3`}>{getStatusPublish(policy?.policyVersions[idVersion])}</TableCell>
                    <TableCell key={`thrc-date-3`}>
                      {formatDateTimeToDateTimeBR(policy?.policyVersions[idVersion].publishedDate)}
                    </TableCell>
                    <TableCell key={`thrc-sponsor-3`}>{policy?.policyVersions[idVersion].author?.name}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  );
};
