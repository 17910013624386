import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Column, Row } from 'Components/Grid';
import { ButtonAdd, ContainerContent, Subtitle } from './styles';
import { useNavigate } from 'react-router-dom';
import { Main } from 'Components/Main';

export const Container = props => {
  const { children, title, breadcrumb, showButtonAdd, linkPage, actionButton } = props;
  const navigate = useNavigate();

  return (
    <Main>
      {title && (
        <div className="headerMenu container">
          <div>
            <Row>
              <Column>
                <h1 className="clickable title" onClick={() => navigate(linkPage)}>
                  {title}
                </h1>
              </Column>
            </Row>
            <Row alignItems="center">
              <Column xs={6}>{breadcrumb && <Subtitle className="subtitle">{breadcrumb}</Subtitle>}</Column>
              <Column xs={6} justifyContent="end" className="d-flex">
                {showButtonAdd && (
                  <ButtonAdd label="+ Adicionar novo" className="no-margin" onClick={() => navigate('add')} />
                )}
                {actionButton}
              </Column>
            </Row>
          </div>
        </div>
      )}
      <ContainerContent>{children}</ContainerContent>
      <ToastContainer />
    </Main>
  );
};
