import { TextField } from "@mui/material";
import styled from "styled-components";

export const TitleLogin = styled.span`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    color: #0039C5;
    &.bold {
        font-weight: 700;
    }
`
export const SubtitleLogin = styled.span`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #0039C5;
`
export const Observation = styled.p`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6B6664;
    margin-bottom: 24px;
    span {
        font-weight: 700;
    }
`
export const HasAccount = styled.p`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6B6664;
    button {
        border: none;
        background: transparent;
        width: fit-content;
        font-weight: 700;
        color: #0039C5;
    }
`
export const InputCode = styled(TextField)`
    height: 50px;
    width: 50px;
    border: 1px solid #333;
    margin-inline: 6px!important;
    margin-block-end: 8px!important;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
` 