import { backendPrivate } from "services";

export const addGapAnalysis = async (data) => {
  return await backendPrivate.post("/gap-analysis", data);
};

export const getGapAnalysisById = async (id) => {
  return await backendPrivate.get(`/gap-analysis/${id}`);
};

export const getGapAnalysisByCompany = async (tenantId) => {
  return await backendPrivate.get(`/gap-analysis?companyKey=${tenantId}`);
};

export const getReportByCompany = async (tenantId) => {
  return await backendPrivate.get(
    "/gap-analysis-report/report-by-company?company-key=" + tenantId
  );
};

export const getReportByStandard = async (tenantId, gapAnalysisId) => {
  return await backendPrivate.get(
    `/gap-analysis-report/report-by-standard?company-key=${tenantId}&gap-analysis-id=${gapAnalysisId}`
  );
};

export const getReportByControls = async (tenantId, gapAnalysisId) => {
  return await backendPrivate.get(
    `/gap-analysis-report/report-by-controls?company-key=${tenantId}&gap-analysis-id=${gapAnalysisId}`
  );
};

export const getControlDeploymentById = async (id) => {
  return await backendPrivate.get(`/control-deployment-logs/${id}`);
};

export const addControlDeployment = async (data) => {
  return await backendPrivate.post(`/control-deployment-logs`, data);
};
export const editControlDeployment = async (data) => {
  return await backendPrivate.put(`/control-deployment-logs/${data._id}`, data);
};

export const getChartEvolution = async (
  companyKey,
  startDate,
  endDate,
  gapAnalysisId
) => {
  if (gapAnalysisId === undefined) {
    return await backendPrivate.get(
      `/control-deployment-evolution?companyKey=${companyKey}&startDate=${startDate}&endDate=${endDate}`
    );
  }
  return await backendPrivate.get(
    `/control-deployment-evolution?companyKey=${companyKey}&startDate=${startDate}&endDate=${endDate}&gapAnalysisId=${gapAnalysisId}`
  );
};

export const getChartEvolutionByRequisites = async (
  companyKey,
  startDate,
  endDate,
  gapAnalysisId
) => {
  if (gapAnalysisId === undefined) {
    return await backendPrivate.get(
      `/control-deployment-evolution/by-requisites-2?companyKey=${companyKey}&startDate=${startDate}&endDate=${endDate}`
    );
  }
  return await backendPrivate.get(
    `/control-deployment-evolution/by-requisites-2?companyKey=${companyKey}&startDate=${startDate}&endDate=${endDate}&gapAnalysisId=${gapAnalysisId}`
  );
};
