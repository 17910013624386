import { Link, TextField } from '@mui/material'
import { DefaultButton } from 'Components/DefaultButton'
import { Column } from 'Components/Grid'
import styled from 'styled-components'

export const Error = styled.p`
  background-color: #ffc;
`

export const ButtonForm = styled(DefaultButton)`
  margin-top: 0 !important;
  height: 38px;
  width: fit-content;
  padding: 10px 22px;
  margin: 0 !important;
  &.full {
    width: 100%;
  }
  &.max-content {
    width: max-content;
  }
  &.center {
    justify-content: center;
  }
`

export const TitleForm = styled(Column)`
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #3c3938;
  margin-bottom: 16px;
`

export const CustomTextField = styled(TextField)`
  max-height: 48px;
  > div {
    min-height: 48px;
  }
`

export const LinkButton = styled(Link)`
  &:hover {
    cursor: pointer;
  }
`
