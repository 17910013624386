import { Button } from '@mui/material';
import { useEffect, useRef } from 'react';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

export default function FileUploader(props) {
  const {
    buttonActionName,
    buttonSentActionName,
    sentAction,
    resetStatus,
    loading = false,
    changeStatus = () => {},
  } = props;
  const SwalReact = withReactContent(Swal);
  const inputRef = useRef(null);

  const handleUpload = file => {
    if (sentAction && sentAction instanceof Function) {
      sentAction(file);
    }
  };

  const handleFileChange = event => {
    changeStatus();
    const selectedFile = event.target.files[0];

    try {
      // Verifica se um arquivo foi selecionado e se a extensão é CSV
      if (selectedFile && selectedFile.name.endsWith('.csv')) {
        // Leitura do arquivo como Base64
        const reader = new FileReader();
        reader.onload = () => {
          const base64Data = reader.result;
          handleUpload(base64Data);
        };

        reader.readAsDataURL(selectedFile);
      } else {
        SwalReact.fire({
          title: 'Aviso!',
          text: 'Por favor, selecione um arquivo CSV.',
          icon: 'error',
        });
        changeStatus();
      }
    } catch (err) {
      SwalReact.fire({
        title: 'Erro!',
        text: err.response.data.message ?? err.message,
        icon: 'error',
      });
      changeStatus();
    }
  };

  const handleActionUpload = () => {
    if (loading) return;
    inputRef.current.click();
  };

  useEffect(() => {
    if (resetStatus && loading) {
      changeStatus();
    }
  }, [resetStatus, loading, changeStatus]);

  return (
    <div>
      {
        <>
          <Button
            sx={{ borderRadius: '12px' }}
            endIcon={
              loading ? (
                <CircularProgress color="primary" sx={{ padding: '0px' }} size={20} />
              ) : (
                <UploadFileOutlinedIcon />
              )
            }
            variant="outlined"
            color="primary"
            onClick={handleActionUpload}
          >
            {loading ? buttonSentActionName : buttonActionName}
          </Button>
          <input ref={inputRef} style={{ display: 'none' }} type="file" accept=".csv" onChange={handleFileChange} />
        </>
      }
    </div>
  );
}
