import { Card } from "Components/Card"
import { ReportsRopaArea } from "../Area"
import { Row } from "Components/Grid"
import { TitleCompany, TitleFooterCompany, TitleMainContentCompany } from "./styles"

export const ReportsRopaCompany = ({company, filter, area, process, data, category, legalBase, processing, position}) => {
    return (
        <>
            <Card className="mt-4 p-report mtp-0 ptp-0 pbp-8">
                <Row>
                    <TitleCompany xs={12} sm={12} md={12}>
                        Dados da Empresa
                    </TitleCompany>
                    <TitleMainContentCompany xs={12} sm={12} md={12}>
                        <Row className="pl-3 font-weight-bold mb-2">Nome da empresa</Row>
                        <Row className="pl-3">{company.name}</Row>
                    </TitleMainContentCompany>
                    <TitleFooterCompany xs={12} sm={12} md={12}>
                        <Row className="pl-3 font-weight-bold mb-2">Resumo da empresa</Row>
                        <Row className="pl-3 mb-2">{company.description}</Row>
                    </TitleFooterCompany>
                </Row>
            </Card>
            {!!company.areas && company.areas
            .filter((areaFilter) => {
                if (filter === 'Área' && !!area) {
                    return areaFilter.name === area
                }
                if (filter === 'Processo' && !!process) {
                    return areaFilter.processes.some((itemProcess) => itemProcess.name === process)
                }
                if (filter === 'Dados' && !!data) {
                    return areaFilter.processes.some((itemProcess) => {
                        return itemProcess.dataMapping.some((dataMap) => dataMap.name === data)
                    })
                }
                if (filter === 'Categoria' && !!category) {
                    return areaFilter.processes.some((itemProcess) => {
                        return itemProcess.dataMapping.some((dataMap) => dataMap.category === category)
                    })
                }
                if (filter === 'Tratamento' && !!processing) {
                    return areaFilter.processes.some((itemProcess) => {
                        return itemProcess.dataMapping.some((dataMap) => {
                            return dataMap.dataProcessing.some((dataProcess) => {
                                return (dataProcess.processing) === processing
                            })
                        })
                    })
                }
                if (filter === 'Legal Base' && !!legalBase) {
                    return areaFilter.processes.some((itemProcess) => {
                        return itemProcess.dataMapping.some((dataMap) => {
                            return dataMap.dataProcessing.some((dataProcess) => {
                                return (dataProcess.legalBase) === legalBase
                            })
                        })
                    })
                }
                if (filter === 'Posição' && !!position) {
                    return areaFilter.processes.some((itemProcess) => {
                        return itemProcess.dataMapping.some((dataMap) => {
                            return dataMap.dataProcessing.some((dataProcess) => {
                                return (dataProcess.position) === position
                            })
                        })
                    })
                }
                return true
            })
            .map((itemArea) => (
                <ReportsRopaArea
                    area={itemArea}
                    key={itemArea.name}
                    filter={filter}
                    process={process}
                    data={data}
                    category={category}
                    legalBase={legalBase}
                    processing={processing}
                    position={position} />
            ))}
        </>
    )
}