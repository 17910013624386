import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';

import {
  acceptPolicyVersion,
  getPolicyPublicById,
  getPolicyVersionUserById,
  markPolicyVersionAsRead,
} from 'services/policies-service';
import { formatDateTimeToDateTimeBR } from 'utils/date_utils';

import { PageLoaderAcceptPolicy } from 'Components/PageLoader/AcceptPolicy';
import { ButtonForm } from 'Components/Form/styles';

import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

import 'react-quill/dist/quill.snow.css';

export const AcceptPolicyVersion = () => {
  const { idPolicyVersionUser } = useParams();
  const SwalReact = withReactContent(Swal);
  const [refetch, setRefetch] = useState(false);

  const {
    isLoading,
    error,
    isSuccess,
    data: policyVersionUser,
  } = useQuery(['getPolicyVersionUserById', refetch], async () => {
    const resPolicyVersionUser = await getPolicyVersionUserById(idPolicyVersionUser);

    if (resPolicyVersionUser.status === 200) return resPolicyVersionUser.data;

    return null;
  });

  const { data: policyVersion } = useQuery(['getPolicyById', policyVersionUser], async () => {
    if (!policyVersionUser) return null;
    const resPolicy = await getPolicyPublicById(policyVersionUser.policyId);

    if (resPolicy.status === 200) return resPolicy.data.policyVersions[policyVersionUser.policyVersionId];

    return null;
  });

  const markAsReadPolicyVersion = useCallback(async () => {
    try {
      if (!idPolicyVersionUser) return;
      if (!policyVersionUser) return;
      await markPolicyVersionAsRead(idPolicyVersionUser);
    } catch (err) {
      SwalReact.fire({
        title: 'Erro!',
        text: err.response.data.message ?? err.message,
        icon: 'error',
      });
    }
  }, [idPolicyVersionUser, policyVersionUser, SwalReact]);

  const acceptPolicyVersionAction = useCallback(async () => {
    try {
      await acceptPolicyVersion(idPolicyVersionUser);
      setRefetch(true);
      SwalReact.fire({
        title: 'Sucesso!',
        text: 'Aceite da política realizado com sucesso!',
        icon: 'success',
      });
    } catch (err) {
      SwalReact.fire({
        title: 'Erro!',
        text: err.response.data.message ?? err.message,
        icon: 'error',
      });
    }
  }, [idPolicyVersionUser, SwalReact]);

  useEffect(() => {
    if (!idPolicyVersionUser) return;
    if (!policyVersionUser) return;
    if (error) return;
    if (!isSuccess) return;
    if (policyVersionUser.readed === true) return;

    markAsReadPolicyVersion();
  }, [idPolicyVersionUser, policyVersionUser, error, isSuccess, markAsReadPolicyVersion]);

  const getStatusReview = () => {
    return (
      <div style={{ verticalAlign: 'middle', display: 'flex' }}>
        <TaskAltOutlinedIcon sx={{ fill: 'green' }} />
        &nbsp;Revisado
      </div>
    );
  };

  const getStatusApprove = () => {
    return (
      <div style={{ verticalAlign: 'middle', display: 'flex' }}>
        <TaskAltOutlinedIcon sx={{ fill: 'green' }} />
        &nbsp;Aprovado
      </div>
    );
  };

  const getStatusPublish = () => {
    return (
      <div style={{ verticalAlign: 'middle', display: 'flex' }}>
        <TaskAltOutlinedIcon sx={{ fill: 'green' }} />
        &nbsp;Publicado
      </div>
    );
  };

  const getFormAcceptPolicyVersion = () => {
    return (
      <>
        <Box
          sx={{
            width: '100%',
            paddingInline: '16px',
            fontFamily:
              '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          }}
          className="container"
        >
          <div>
            <h3
              style={{
                textAlign: 'center',
                fontSize: '1.75rem',
                fontWeight: '500',
                marginTop: '1.5rem',
                marginBottom: '0.5rem',
              }}
            >
              {policyVersionUser?.policyName} ({policyVersionUser?.policyVersion})
            </h3>
          </div>
          <div
            style={{
              textAlign: 'right',
              color: '#6c757d',
              fontSize: '1rem',
              fontWeight: '400',
              marginTop: '0rem',
              marginBottom: '1rem',
            }}
          >
            {policyVersionUser?.policyPublishedDate
              ? formatDateTimeToDateTimeBR(policyVersionUser.policyPublishedDate)
              : ''}
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}
            className="ql-snow ql-editor"
            dangerouslySetInnerHTML={{ __html: policyVersionUser?.policyVersionText }}
          ></div>
          {policyVersion && (
            <TableContainer
              component={Paper}
              style={{
                marginBottom: '1rem',
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow key={'table-row-header'}>
                    <TableCell key={`thrc-stage`}>Estágio</TableCell>
                    <TableCell key={`thrc-status`}>Status</TableCell>
                    <TableCell key={`thrc-date`}>Data</TableCell>
                    <TableCell key={`thrc-sponsor`}>Responsável</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={'table-row-header-review'}>
                    <TableCell key={`thrc-stage-1`}>Revisão</TableCell>
                    <TableCell key={`thrc-status-1`}>{getStatusReview()}</TableCell>
                    <TableCell key={`thrc-date-1`}>{formatDateTimeToDateTimeBR(policyVersion?.reviewedDate)}</TableCell>
                    <TableCell key={`thrc-sponsor-1`}>{policyVersion?.reviewer?.name}</TableCell>
                  </TableRow>
                  <TableRow key={'table-row-header-approve'}>
                    <TableCell key={`thrc-stage-2`}>Aprovação</TableCell>
                    <TableCell key={`thrc-status-2`}>{getStatusApprove()}</TableCell>
                    <TableCell key={`thrc-date-2`}>{formatDateTimeToDateTimeBR(policyVersion?.approvedDate)}</TableCell>
                    <TableCell key={`thrc-sponsor-2`}>{policyVersion?.approver?.name}</TableCell>
                  </TableRow>
                  <TableRow key={'table-row-header-publish'}>
                    <TableCell key={`thrc-stage-3`}>Publicação</TableCell>
                    <TableCell key={`thrc-status-3`}>{getStatusPublish()}</TableCell>
                    <TableCell key={`thrc-date-3`}>
                      {formatDateTimeToDateTimeBR(policyVersion?.publishedDate)}
                    </TableCell>
                    <TableCell key={`thrc-sponsor-3`}>{policyVersion?.author?.name}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {!policyVersionUser?.accepted && <ButtonForm label="Aceitar" onClick={() => acceptPolicyVersionAction()} />}
          {policyVersionUser?.accepted && (
            <div>
              Política já foi aceita em {formatDateTimeToDateTimeBR(policyVersionUser?.acceptedDate)}, feche a aba e
              volte para o sistema!
            </div>
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      {!isLoading && !error && getFormAcceptPolicyVersion()}
      {isLoading && <PageLoaderAcceptPolicy />}
      {error && <div>Ocorreu um erro</div>}
    </>
  );
};
