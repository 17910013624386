import { useState } from 'react';
import { Button, Chip, Divider, FormControl, InputLabel, ListItemIcon, Menu, MenuItem, Select } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Logout from '@mui/icons-material/Logout';
import { Main, UserContainerMenu } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { logoff, updateTenant, useAuthTenant, useAuthUser } from 'store/auth';
import { getSelectedObject } from 'utils/select_utils';
import { getTenant } from 'utils/tenant_utils';

export const Header = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseLogoff = () => {
    setAnchorEl(null);
    onLogoff();
  };
  const onLogoff = () => {
    dispatch(logoff());
  };
  const user = useSelector(useAuthUser);
  const selectedTenant = useSelector(useAuthTenant);
  return (
    <Main>
      <div id="wrapper">
        <div>
          <MenuOutlinedIcon id="menu" style={{ fill: '#0039C5', height: '32px', width: '32px' }} />
          <p>Menu</p>
        </div>
        {selectedTenant?.slug !== getTenant() && (
          <div>
            <Chip label={`Você esta acessando a Empresa ${selectedTenant?.name}`} variant="outlined" color="primary" />
          </div>
        )}
        <div>
          <UserContainerMenu
            onClick={handleClick}
            aria-controls={anchorEl ? 'user-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
          >
            <AccountCircleIcon
              id="photo"
              style={{ fill: '#878787', height: '32px', width: '32px', marginRight: '8px' }}
            />
            <p>{user?.name ?? 'Usuário'}</p>
            <ExpandMoreOutlinedIcon
              id="icon"
              style={{
                fill: '#0039C5',
                height: '28px',
                width: '28px',
                marginLeft: '8px',
                marginRight: '0px',
              }}
            />
          </UserContainerMenu>

          <Button
            sx={{
              minWidth: '32px',
              marginLeft: '15px',
              '& .MuiButton-startIcon': {
                margin: '0',
              },
            }}
            startIcon={
              <NotificationsNoneIcon id="notification" style={{ fill: '#FFFFFF', height: '22px', width: '22px' }} />
            }
          ></Button>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={anchorEl != null}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            minWidth: 210,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        // PaperProps={}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {user.companiesWithAccess.length > 0 && (
          <MenuItem
            onClick={handleClose}
            sx={{
              '&:hover': {
                backgroundColor: 'white!important',
              },
              '&.Mui-focusVisible': {
                backgroundColor: 'white!important',
              },
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="tenant-label" shrink={true}>
                Empresa
              </InputLabel>
              <Select
                labelId="tenant-label"
                id="tenant"
                value={getSelectedObject(user.companiesWithAccess, selectedTenant)}
                label="Empresa"
                onChange={ev => {
                  dispatch(updateTenant({ tenant: ev.target.value }));
                }}
                notched
              >
                {user.companiesWithAccess?.map((item, index) => (
                  <MenuItem value={item} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MenuItem>
        )}
        {user.companiesWithAccess.length > 0 && <Divider />}
        <MenuItem onClick={handleCloseLogoff}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>
    </Main>
  );
};
