export const authenticatedFinishAction = (state) => {
    return {
        token: state.token,
        refreshToken: state.refreshToken,
        user: state.user,
        isLoggedIn: true,
        loading: false,
        error: false,
        selectedTenant: state.selectedTenant
    }
}
export const authenticatedSuccessAction = (state, content) => {
    return {
        token: content.payload.token,
        refreshToken: content.payload.refreshToken,
        user: content.payload.user,
        isLoggedIn: true,
        loading: true,
        error: false,
        selectedTenant: state.selectedTenant
    }
}
export const authenticatedFailureAction = (state) => {
    return {
        token: null,
        refreshToken: null,
        user: null,
        isLoggedIn: false,
        loading: false,
        error: true,
        selectedTenant: state.selectedTenant
    }
}
export const loginAction = (state) => {
    return {
        token: null,
        refreshToken: null,
        user: null,
        isLoggedIn: false,
        loading: true,
        error: false,
        selectedTenant: state.selectedTenant
    }
}
export const logoffAction = () => {
    return {
        token: null,
        refreshToken: null,
        user: null,
        isLoggedIn: false,
        loading: false,
        error: false,
        selectedTenant: null
    }
}
export const refreshTokenSucessAction = (state, content) => {
    return {
        token: content.payload.token,
        refreshToken: content.payload.refreshToken,
        user: state.user,
        isLoggedIn: true,
        loading: false,
        error: false,
        selectedTenant: state.selectedTenant
    }
}

export const updateTenantAction = (state, content) => {
    return {
        token: state.token,
        refreshToken: state.refreshToken,
        user: state.user,
        isLoggedIn: state.isLoggedIn,
        loading: state.loading,
        error: state.error,
        selectedTenant: content.payload.tenant
    }
}