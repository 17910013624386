import { Card } from 'Components/Card';
import { Row } from 'Components/Grid';
import { Title, TitleHighMainContent, TitleFooter } from './styles';
import { IndicatorResume } from './IndicatorResume';
import { IndicatorAreaDatas } from './IndicatorAreaDatas';
import { GraphicAreaData } from './GraphicAreaData';
import { GraphicAreaVolumeData } from './GraphicAreaVolumeData';
import { GraphicLegalBaseVolumeData } from './GraphicLegalBaseVolumeData';
import { GeneralView } from './GeneralView';

export const ReportsRopaExecutiveSummary = ({ dados }) => {
  return (
    <>
      <Card className="mt-4 p-report mtp-0 ptp-0 pbp-8">
        <Row>
          <Title xs={12} sm={12} md={12}>
            Dados da Empresa
          </Title>
          <TitleHighMainContent xs={12} sm={12} md={12}>
            <Row className="pl-3 font-weight-bold mb-2">Nome da empresa</Row>
            <Row className="pl-3">{dados?.companyName}</Row>
          </TitleHighMainContent>
          <TitleFooter xs={12} sm={12} md={12}>
            <Row className="pl-3 font-weight-bold mb-2">Resumo da empresa</Row>
            <Row className="pl-3 mb-2">{dados?.companyDescriptionResume}</Row>
          </TitleFooter>
        </Row>
      </Card>
      <Card className="mt-4 p-report mtp-0 ptp-0 pbp-8">
        <Row>
          <Title xs={12} sm={12} md={12}>
            Sumário Executivo
          </Title>
        </Row>
        <Row>
          <Title xs={12} sm={12} md={12} className="mt-4">
            Indicadores
          </Title>
        </Row>
        <IndicatorResume indicatorResume={dados.indicatorResume} />
        <IndicatorAreaDatas indicatorAreaDatas={dados.indicatorAreaDatas} />
      </Card>
      <Card className="mt-4 p-report mtp-0 ptp-0 pbp-8">
        <Row>
          <Title xs={12} sm={12} md={12}>
            Uso de Dados Pessoais e Dados Pessoais Sensíveis por Área
          </Title>
        </Row>
        <GraphicAreaData indicatorDataGraphicByArea={dados.indicatorDataGraphicByArea} />
      </Card>
      <Card className="mt-4 p-report mtp-0 ptp-0 pbp-8 page-break-auto">
        <Row>
          <Title xs={12} sm={12} md={12}>
            Volume de Processo e Base Legal por Área
          </Title>
        </Row>
        <GraphicAreaVolumeData indicatorVolumeGraphicByArea={dados.indicatorVolumeGraphicByArea} />
      </Card>
      <Card className="mt-4 p-report mtp-0 ptp-0 pbp-8">
        <Row>
          <Title xs={12} sm={12} md={12}>
            Volume de Dados e Processos por Base Legal
          </Title>
        </Row>
        <GraphicLegalBaseVolumeData indicatorVolumeGraphicByLegalBase={dados.indicatorVolumeGraphicByLegalBase} />
      </Card>
      <Card className="mt-4 p-report mtp-0 ptp-0 pbp-8 page-break-auto">
        <Row>
          <Title xs={12} sm={12} md={12}>
            Visão Geral
          </Title>
        </Row>
        <GeneralView generalView={dados.generalView} />
      </Card>
    </>
  );
};
