export const tableHeader = [
  {
    id: 'date',
    type: 'datebr',
    value: 'Data',
  },
  {
    id: 'time',
    type: 'text',
    value: 'Hora',
  },
  {
    id: 'user',
    type: 'text',
    value: 'Usuário',
  },
  {
    id: 'action',
    type: 'text',
    value: 'Ação',
  },
  {
    id: 'return',
    type: 'text',
    value: 'Resposta',
  },
]
