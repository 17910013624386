import { ButtonForm, CustomTextField } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { sendVerifyCode } from 'services/auth-service';
import { getCompanyShortBySlug } from 'services/company-service';
import { HasAccount, Observation, SubtitleLogin, TitleLogin } from './styles';
import DotsLoader from 'Components/DotsLoader';
import { getTenant } from 'utils/tenant_utils';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateTenant } from 'store/auth';

export const Login = () => {
  const dispatch = useDispatch();
  const SwalReact = withReactContent(Swal);
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const navigate = useNavigate();
  const { data: company, error } = useQuery(['getCompanyShortBySlug'], async () => {
    const response = await getCompanyShortBySlug(getTenant());
    return response.data;
  });
  const [oldErrorContent, setOldErrorContent] = useState(error);

  const mutation = useMutation(
    email => {
      // envia email do usuario para validação no backend
      if (company == null)
        throw new Error('Empresa não existente, favor contatar o suporte para requisitar o cadastro da mesma!');
      return sendVerifyCode(email, company.slug);
    },
    {
      onError: (error, variables, context) => {
        const errorMessage = error.response.data.message || error.message;
        // An error happened!
        setError('email', { type: 'custom', message: errorMessage });
      },
      onSuccess: (data, variables, context) => {
        // redireciona para tela de verificação do codigo enviado por email
        dispatch(updateTenant({ tenant: company }));
        navigate(`/verify-code/${data.data.email}`);
      },
    },
  );

  const onSubmit = () => {
    const values = getValues();
    mutation.mutate(values.email);
  };
  useEffect(() => {
    if (error !== null && error !== oldErrorContent) {
      setOldErrorContent(error);
      SwalReact.fire({
        title: 'Erro!',
        text: `${error.message}`,
        icon: 'error',
      });
    }
  }, [error, oldErrorContent, SwalReact]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-5">
      <Row justifyContent="center" className="mt-5">
        <Column xs={12} className="text-center mb-3">
          <TitleLogin>Virtual</TitleLogin>
          <TitleLogin className="bold"> Officer</TitleLogin>
        </Column>
        <Column xs={12} className="text-center mb-2">
          <SubtitleLogin>Acessar painel Virtual Office</SubtitleLogin>
        </Column>
        <Column xs={12} className="text-center">
          <Observation>
            Faça o login com seu email corporativo.
            <br />
            <span>nome@email-empresa.com.br</span>
          </Observation>
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={8} sm={6} className="mx-auto mb-3" justifyContent="center" flex>
          <CustomTextField
            {...register('email', { required: 'Email é obrigatório' })}
            label="Email"
            placeholder="nome@email-empresa.com.br"
            InputLabelProps={{ shrink: true }}
            error={errors?.email ? true : false}
            helperText={errors?.email ? errors.email.message : null}
            sx={{
              input: {
                padding: '16.5px 10px',
                width: '100%',
              },
              '.MuiOutlinedInput-root': {
                padding: 0,
              },
              width: '100%',
            }}
          />
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={8} sm={6} className="mx-auto mb-3 mt-1" justifyContent="center" flex>
          <ButtonForm className="full center" label={mutation.isLoading ? <DotsLoader /> : 'Entrar'} variant="submit" />
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="text-center">
          <HasAccount>
            Não possui conta?{' '}
            <button type="button" onClick={() => navigate(`/create-account`)}>
              Cadastre-se
            </button>
            <br />
            <br />
            {/* <button type="button" onClick={() => {}} >Não recebi instruções de confirmação</button> */}
          </HasAccount>
        </Column>
        <Column xs={12} className="text-center"></Column>
      </Row>
    </form>
  );
};
