import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ButtonForm, CustomTextField } from "Components/Form/styles";
import { Column, Row } from "Components/Grid";
import { MuiColorInput } from "mui-color-input";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const Configuration = ({ id, register, control, handleSubmit, errors, getValues, handleComplete }) => {
  const navigate = useNavigate();
  const onSubmit = () => {
    handleComplete();
  };

  const languages = [
    {
      value: "en",
      description: "Inglês",
    },
    {
      value: "pt",
      description: "Português",
    },
    {
      value: "es",
      description: "Espanhol",
    },
    {
      value: "gr",
      description: "Alemão",
    },
    {
      value: "fr",
      description: "Francês",
    },
  ];

  const positions = [
    {
      value: "top",
      description: "Topo",
    },
    {
      value: "bottom",
      description: "Rodapé",
    },
    {
      value: "squaredLeft",
      description: "Inferior Esquerdo",
    },
    {
      value: "squaredRight",
      description: "Inferior Direito",
    },
    {
      value: "squaredCenter",
      description: "Centralizado",
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Column>
          <FormControl component="fieldset">
            <FormLabel component="legend" sx={{ fontWeight: "bold", marginBottom: "24px" }}>
              Configurações
            </FormLabel>
          </FormControl>
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} md={6} className="d-flex">
          <CustomTextField
            {...register("url", { required: "URL é obrigatório" })}
            label="Site"
            autoFocus
            error={errors?.url ? true : false}
            sx={{ minHeight: "48px", margin: "0 auto 16px", width: "100%" }}
            helperText={errors?.url ? errors.name.message : null}
            InputLabelProps={{ shrink: true }}
            disabled={!!id}
          />
        </Column>
        <Column xs={12} md={6} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues("fixedLang") !== "";
              },
              required: "Língua padrão é obrigatório",
            }}
            name="fixedLang"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ marginBottom: "16px" }} error={errors?.fixedLang ? true : false}>
                <InputLabel id="fixed-lang-label" shrink={true}>
                  Idioma Padrão
                </InputLabel>
                <Select
                  labelId="fixed-lang-label"
                  id="fixedLang"
                  value={value ?? ""}
                  label="Idioma Padrão"
                  onChange={onChange}
                  notched
                  sx={{ "> #fixedLang": { padding: "11.5px 14px" } }}
                >
                  <MenuItem value={""} key={-1}>
                    Selecione um idioma
                  </MenuItem>
                  {languages?.map(item => (
                    <MenuItem value={`${item.value}`} key={`language-${item.value}`}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.fixedLang ? errors.fixedLang.message : ""}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="activeMultilanguage"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                value={value}
                control={<Checkbox checked={value} onChange={onChange} name="activeMultilanguage" />}
                label="Ativar Multilingua"
                sx={{ marginLeft: 0, marginBottom: "16px", whiteSpace: "nowrap" }}
              />
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12} md={4} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues("buttonBackground") !== "";
              },
              required: "Cor Principal é obrigatório",
            }}
            name="buttonBackground"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth error={errors?.buttonBackground ? true : false}>
                <InputLabel id="button-background-label" shrink={true}>
                  Cor Principal
                </InputLabel>
                <MuiColorInput label="Cor Principal" format="hex" value={value} onChange={onChange} />
                <FormHelperText>{errors?.buttonBackground ? errors.buttonBackground.message : ""}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
        <Column xs={12} md={4} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues("background") !== "";
              },
              required: "Cor Fundo é obrigatório",
            }}
            name="background"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth error={errors?.background ? true : false}>
                <InputLabel id="background-label" shrink={true}>
                  Cor Fundo
                </InputLabel>
                <MuiColorInput label="Cor Fundo" format="hex" value={value} onChange={onChange} />
                <FormHelperText>{errors?.background ? errors.background.message : ""}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
        <Column xs={12} md={4} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues("font") !== "";
              },
              required: "Cor Texto é obrigatório",
            }}
            name="font"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth error={errors?.font ? true : false}>
                <InputLabel id="font-label" shrink={true}>
                  Cor Texto
                </InputLabel>
                <MuiColorInput label="Cor Texto" format="hex" value={value} onChange={onChange} />
                <FormHelperText>{errors?.font ? errors.fontColor.message : ""}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} md={6} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues("positioning") !== "";
              },
              required: "Posição é obrigatório",
            }}
            name="positioning"
            render={({ field: { onChange, value } }) => (
              <FormControl
                fullWidth
                sx={{ marginBottom: "24px", marginTop: "24px" }}
                error={errors?.positioning ? true : false}
              >
                <InputLabel id="positioning-label" shrink={true}>
                  Posição do Banner
                </InputLabel>
                <Select
                  labelId="positioning-label"
                  id="positioning"
                  value={value ?? ""}
                  label="Posição do Banner"
                  onChange={onChange}
                  notched
                  sx={{ "> #positioning": { padding: "11.5px 14px" } }}
                >
                  <MenuItem value={""} key={-1}>
                    Selecione uma posição
                  </MenuItem>
                  {positions?.map(item => (
                    <MenuItem value={`${item.value}`} key={`language-${item.value}`}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.positioning ? errors.positioning.message : ""}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
        <Column xs={12} md={6}>
          <Controller
            control={control}
            name="showBlurBackground"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                checked={value}
                control={<Checkbox onChange={onChange} name="showBlurBackground" />}
                label="Desfocar o fundo"
                sx={{ whiteSpace: "nowrap" }}
              />
            )}
          />
        </Column>
      </Row>
      <Divider sx={{ marginBottom: "24px" }} />
      <Row>
        <Column>
          <FormControl component="fieldset">
            <FormLabel component="legend" sx={{ fontWeight: "bold", marginBottom: "24px" }}>
              Configurações Avançadas
            </FormLabel>
          </FormControl>
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <Controller
            control={control}
            name="permissive"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                checked={value}
                control={<Checkbox onChange={onChange} name="permissive" />}
                label="Cookies bloqueados até o consentimento do usuário"
                sx={{ whiteSpace: "nowrap" }}
              />
            )}
          />
        </Column>
        <Column>
          <p style={{ color: "#9CA3AF", fontSize: "14px", marginTop: 0, marginLeft: "30px" }}>
            Ative essa função para que os cookies sejam bloqueados até o consentimento dos usuários (recomendado para
            ter os melhores padrões de privacidade). Caso contrário, os cookies funcionarão até que o usuário selecione
            suas preferências.
          </p>
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <Controller
            control={control}
            name="resourceBlocker"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                checked={value}
                control={<Checkbox onChange={onChange} name="resourceBlocker" />}
                label="Bloquear recursos até o consentimento do usuário"
                sx={{ whiteSpace: "nowrap" }}
              />
            )}
          />
        </Column>
        <Column>
          <p style={{ color: "#9CA3AF", fontSize: "14px", marginTop: 0, marginLeft: "30px" }}>
            Ative isso para bloquear vídeos do YouTube, Vimeo, Google Fonts e Google Maps automaticamente até que os
            usuários dêem seu consentimento (recomendado para os melhores padrões de privacidade)
          </p>
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="cancel" label="cancelar" onClick={() => navigate("/cookies")}></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label="Salvar" variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
