import { backendPrivate } from "services"

export const getOffices = async (tenant) => {
    return await backendPrivate.get(`/office?companyKey=${tenant}`)
}

export const getOfficesPaged = async (tenant, page, perPage) => {
    return await backendPrivate.get(`/office?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getOfficeById = async (id) => {
    return await backendPrivate.get(`/office/${id}`)
}

export const addOffice = async (data) => {
    return await backendPrivate.post('/office', data)
}

export const editOffice = async (id, data) => {
    return await backendPrivate.put(`/office/${id}`, data)
}

export const deleteOffice = async (id) => {
    return await backendPrivate.delete(`/office/${id}`)
}