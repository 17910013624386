import ChartCard from 'Pages/GapAnalisys/ChartCard';
import { GridBox } from 'Pages/GapAnalisys/styles';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getReportByStandard } from 'services/gap-analysis';
import { useAuthTenant } from 'store/auth';

export const ControlTypes = {
  COMPANY: 'Empresas',
  ASSETS: 'Ativos',
  PROCESS: 'Processos',
  SYSTEM: 'Sistemas',
};

function DashboardTab() {
  const [report, setReport] = React.useState(null);
  const selectedTenant = useSelector(useAuthTenant);
  const { id } = useParams();

  const fetchReport = useCallback(async () => {
    const result = await getReportByStandard(selectedTenant.slug, id);
    if (result?.data) {
      setReport(result?.data);
    }
  }, [id, selectedTenant.slug]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  return (
    <GridBox>
      {' '}
      {report?.map(item => (
        <ChartCard
          title={`${ControlTypes[item.type]}`}
          deployedCount={item.deployedCount}
          notDeployedCount={item.notDeployedCount}
          notApplicableCount={item.notApplicableCount}
        />
      ))}
    </GridBox>
  );
}

export default DashboardTab;
