export const legalBases = [
  'Consentimento do titular',
  'Obrigação legal ou regulatório pelo controlador',
  'Tratamento e uso compartilhado de dados necessários à execução de políticas públicas',
  'Realização de estudos por órgão de pesquisa',
  'Execução de contrato ou de procedimentos preliminares',
  'Exercício regular de direitos em processo judicial, administrativo ou arbitral',
  'Proteção da vida ou da incolumidade física do titular ou de terceiro',
  'Tutela da saúde em procedimentos realizados por profissionais da saúde',
  'Legítimo interesse do controlador ou de terceiro',
  'Proteção ao crédito',
  'Garantia da prevenção à fraude e a segurança do titular',
];
export const treatments = ['Criar', 'Armazenar', 'Acessar', 'Compartilhar', 'Arquivar', 'Destruir'];