import { useCallback, useState } from "react"
import { addEdge, applyEdgeChanges, applyNodeChanges, Background, ReactFlow } from "reactflow"
import 'reactflow/dist/style.css';

const Graphic = ({
    nodeTypes,
    tenant,
    result,
    areas,
    processes,
    systems,
    assets
}) => {
    const [nodes, setNodes] = useState([
        {
            id: `${tenant.slug}-0`,
            sourcePosition: 'bottom',
            type: 'company',
            className: 'default_node',
            data: {
                name: tenant.name,
                slug: tenant.slug,
                result: result
            },
            position: { x: 350, y: 5 },
        },
        ...(areas.length > 0 ? areas?.map((itemArea, areaIndex) => {
            return {
                id: `${tenant.slug}-area-${itemArea._id}`,
                type: 'section',
                className: 'default_node',
                data: {
                    area: 'Área de Negócio', 
                    name: itemArea.name,
                    criticity: itemArea.criticity
                },
                position: { x: (areaIndex === 0 ? 30 : 120*(areaIndex+1)+20), y: 220 },
            }
        }) : []),
        ...(processes.length > 0 ? processes
        ?.map((itemProcess, processIndex) => {
            return {
                id: `${tenant.slug}-process-${itemProcess._id}`,
                type: 'section',
                className: 'default_node',
                data: {
                    area: 'Processos', 
                    name: itemProcess.name,
                    criticity: itemProcess.criticity
                },
                position: { x: (processIndex === 0 ? 30 : 120*(processIndex+1)+20), y: 380 },
            }
        }) : []),
        ...(systems.length > 0 ? systems
        ?.map((itemSystem, systemIndex) => {
            return {
                id: `${tenant.slug}-system-${itemSystem._id}`,
                type: 'section',
                className: 'default_node',
                data: {
                    area: 'Sistemas', 
                    name: itemSystem.name,
                    criticity: itemSystem.criticity
                },
                position: { x: (systemIndex === 0 ? 30 : 120*(systemIndex+1)+20), y: 530 },
            }
        }) : []),
        ...(assets.length > 0 ? assets
        ?.map((itemAsset, assetIndex) => {
            return {
                id: `${tenant.slug}-asset-${itemAsset._id}`,
                type: 'section',
                className: 'default_node',
                data: {
                    area: 'Ativos', 
                    name: itemAsset.name,
                    criticity: itemAsset.criticity,
                    last: true
                },
                position: { x: (assetIndex === 0 ? 30 : 120*(assetIndex+1)+20), y: 680 },
            }
        }) : []),
        // ...areas?.filter(itemArea => itemArea.processes.length > 0)
        // .map(itemArea => 
        //     itemArea.processes
        //     .map((itemProcess, processIndex) => {
        //         return {
        //             id: `${tenant.slug}-process-${processIndex}`,
        //             type: 'section',
        //             className: 'default_node',
        //             data: {
        //                 area: 'Processos', 
        //                 name: itemProcess.name,
        //                 criticity: itemProcess.criticity
        //             },
        //             position: { x: (processIndex === 0 ? 30 : 120*(processIndex+1)+20), y: 380 },
        //         }
        //     })
        // ).reduceRight((accumulator, currentValue) => accumulator.concat(currentValue))
        
    ])
    const [edges, setEdges] = useState([
        ...(areas.length > 0 ? areas?.map((itemArea, areaIndex) => {
            return {
                id: `${tenant.slug}-${itemArea._id}-area`,
                source: `${tenant.slug}-0`,
                target: `${tenant.slug}-area-${itemArea._id}`
            }
        }) : []),
        ...(areas.length > 0 ? areas:[])?.filter(itemArea => itemArea.processes.length > 0)
        ?.map((itemArea) => itemArea.processes
            ?.map((itemProcess) =>{
                return {
                    id: `${tenant.slug}-${itemArea._id}-${itemProcess._id}-process`,
                    source: `${tenant.slug}-area-${itemArea._id}`,
                    target: `${tenant.slug}-process-${itemProcess._id}`
                }
            })
        )?.reduceRight((accumulator, currentValue) => accumulator.concat(currentValue), []),
    ])
    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );
    const onConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    );

    return (
        <ReactFlow
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            // fitView
            nodesDraggable={false}>
            <Background />
            {/* <Controls /> */}
        </ReactFlow>
    )
}

export default Graphic