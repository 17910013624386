import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  font-size: 14px;
  border-collapse: collapse; // Added this to handle border spacing issue
  tbody tr:last-child {
    border-bottom: none; /* Removes the border-bottom from the last row */
    border-radius: 8px;
  }
  tbody tr:nth-last-child(2) {
    border-bottom: none; /* Removes the border-bottom from the next-to-last row */
    border-radius: 8px;
  }
  tbody tr:nth-last-child(3) {
    border-bottom: none; /* Removes the border-bottom from the next-to-last row */
    border-radius: 8px;
  }
`;
export const TableRow = styled.tr`
  border-bottom: 1px solid #f1efee;
  color: #6b6664;
`;

export const TableCell = styled.td`
  padding: 16px;
  text-align: ${(props) => props.align || "left"};
`;

export const TableHeader = styled.th`
  padding: 16px;
  color: #3c3938;
  text-align: ${(props) => props.align || "left"};
  font-weight: 700;
`;
