import { useState } from 'react';
import { useQuery } from 'react-query';
import { DefaultTable } from 'Components/DefaultTable';
import { Container } from '../../Components/Container';
import { tableHeader } from './table-header.js';
import { PageLoaderList } from 'Components/PageLoader/List';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import { NoData } from 'Components/NoData';
import { Button, FormControl, InputLabel, MenuItem, Pagination, Select, Stack } from '@mui/material';
import { getAuditLogPaged } from 'services/audit-logs-service';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Controller, useForm } from 'react-hook-form';
import { Card } from 'Components/Card';
import { Column, Row } from 'Components/Grid';
import { CustomTextField } from 'Components/Form/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';

export const AuditLogs = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const [refetch, setRefetch] = useState(false);
  const [page, setPage] = useState(1);

  const { register, control, reset, watch, getValues } = useForm({
    defaultValues: {
      typeFilter: '',
      searchContent: '',
      startDate: null,
      endDate: null,
    },
  });

  const getContentSearch = () => {
    if (watch('typeFilter') === '') return '';
    if (!watch('searchContent')) return '';

    return watch('searchContent');
  };

  const getValueTypeFilter = () => {
    switch (watch('typeFilter')) {
      case 'Usuário':
        return 'user';
      case 'Ação':
        return 'action';
      case 'Resposta':
        return 'return';
      default:
        return '';
    }
  };

  const getValueStartDateFilter = () => {
    if (getValues('startDate') == null) return null;
    return dayjs(getValues('startDate')).format('YYYY-MM-DD');
  };

  const getValueEndDateFilter = () => {
    if (getValues('endDate') == null) return null;
    return dayjs(getValues('endDate')).format('YYYY-MM-DD');
  };

  const {
    isLoading,
    error,
    data: pagedLogs,
  } = useQuery(['getLogs', refetch, page], async () => {
    if (selectedTenant.slug === undefined) return [];
    const search = getContentSearch();
    const filterValue = getValueTypeFilter();
    const filterStartDate = getValueStartDateFilter();
    const filterEndDate = getValueEndDateFilter();
    const result = await getAuditLogPaged(
      selectedTenant.slug,
      page,
      15,
      filterValue,
      search,
      filterStartDate,
      filterEndDate,
    );
    if (result?.data.data.length > 0) {
      return result?.data;
    }
    return {
      data: [],
      page,
      totalPages: 0,
    };
  });

  return (
    <Container title="Logs" breadcrumb="Configurações Gerais / Logs" showButtonAdd={false} linkPage="/audit-logs">
      {/* <Information
        title="Logs"
        description={
          <p className="m-0">
            Cada empresa possui uma organização distinta, seja por área, squad, setores, etc.
            <br />
            Nos demonstre como é a sua organização para que a gente possa identificar quais pontos críticos para o
            negócio.
            <br />
            Essas são compostas por diversos grupos e equipes que permitem o seu funcionamento adequado e que suas
            operações possam ser realizadas com sucesso.
          </p>
        }
        style={{
          marginTop: '24px',
        }}
      /> */}
      {error && <div>Ocorreu um erro ...</div>}
      {!error && (
        <>
          <Card className="mb-2 mt-3">
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="pt-br">
              <Row className="mb-3">
                <Column xs={12} md={'3'}>
                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => {
                      return (
                        <DatePicker
                          label="Data inicial"
                          name="startDate"
                          value={field.value}
                          inputRef={field.ref}
                          format="DD/MM/YYYY"
                          onChange={date => {
                            field.onChange(date);
                          }}
                          sx={{
                            maxHeight: '40px',
                            lineHeight: '.9em',
                            '> label': {
                              lineHeight: '1em',
                              top: '-2px',
                            },
                            input: {
                              fontSize: '.8em',
                            },
                          }}
                        />
                      );
                    }}
                  />
                </Column>
                <Column xs={12} md={'3'}>
                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field }) => {
                      return (
                        <DatePicker
                          label="Data final"
                          name="endDate"
                          value={field.value}
                          inputRef={field.ref}
                          format="DD/MM/YYYY"
                          onChange={date => {
                            field.onChange(date);
                          }}
                          sx={{
                            maxHeight: '40px',
                            lineHeight: '.9em',
                            '> label': {
                              lineHeight: '1em',
                              top: '-2px',
                            },
                            input: {
                              fontSize: '.8em',
                            },
                          }}
                        />
                      );
                    }}
                  />
                </Column>
              </Row>
            </LocalizationProvider>
            <Row>
              <Column xs={12} md={'3'}>
                <Controller
                  control={control}
                  name="typeFilter"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth sx={{ margin: '0 auto' }}>
                      <InputLabel id="type-filter-label" shrink>
                        Filtrar por
                      </InputLabel>
                      <Select
                        displayEmpty
                        labelId="type-filter-label"
                        id="typeFilter"
                        notched
                        label="Filtrar por"
                        value={value}
                        onChange={onChange}
                        size="small"
                        style={{ marginBottom: '16px !important' }}
                        sx={{
                          '& > .MuiSelect-select.MuiSelect-outlined': {
                            paddingLeft: '0 !important',
                          },
                        }}
                      >
                        <MenuItem value={''} key={-1}>
                          Selecione o campo
                        </MenuItem>
                        {['Usuário', 'Ação', 'Resposta']?.map((item, index) => (
                          <MenuItem value={item} key={`type-filter-${index}`}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Column>
              <Column xs={12} md={5}>
                <CustomTextField
                  {...register('searchContent')}
                  label="Buscar"
                  sx={{
                    minHeight: '40px',
                    maxHeight: '40px',
                    margin: '0 auto',
                    width: '100%',
                    ' > div': {
                      minHeight: '40px',
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    reset();
                    setRefetch(!refetch);
                  }}
                >
                  Limpar
                </Button>
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  endIcon={<SearchOutlinedIcon />}
                  disabled={watch('typeFilter') === ''}
                  onClick={() => {
                    setRefetch(!refetch);
                  }}
                >
                  Filtrar
                </Button>
              </Column>
            </Row>
          </Card>
          {!isLoading && pagedLogs.data.length === 0 && <NoData description={'Não há logs para exibir'} />}
          {!isLoading && pagedLogs.data?.length > 0 && (
            <div className="container mt-3">
              <DefaultTable
                rows={tableHeader}
                cells={pagedLogs.data}
                onClickDelete={() => {}}
                hideDelete
                hideEdit
                hideShow
                link="logs"
              />
              <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginTop: '16px' }}>
                <Pagination
                  count={pagedLogs.totalPages}
                  onChange={(_, selectedPage) => {
                    setPage(selectedPage);
                  }}
                  color="primary"
                  page={page}
                  showFirstButton
                  showLastButton
                />
              </Stack>
            </div>
          )}
        </>
      )}
      {isLoading && <PageLoaderList></PageLoaderList>}
    </Container>
  );
};
