import backend from "services"

export const sendVerifyCode = async (email, companyId) => {
  return await backend.post(`/auth/send-verify-code`, {
    email,
    companyId
  })
}

export const userAuth = async (email, code, companyKey) => {
  return await backend.post(`/auth`, {
    code,
    email,
    companyKey
  })
}