import { useState } from 'react';
import { useQuery } from 'react-query';
import { DefaultTable } from 'Components/DefaultTable';
import { Container } from '../../Components/Container';
import { Information } from 'Components/Information';
import { tableHeader } from './table-header.js';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { PageLoaderList } from 'Components/PageLoader/List';
import { deleteCompany, getCompaniesByPartnerPaged } from 'services/company-service';
import { useAuthTenant, useAuthUser } from 'store/auth';
import { useSelector } from 'react-redux';
import { hasModulePermission } from 'utils/permission_utils';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { NoData } from 'Components/NoData';
import { Pagination, Stack } from '@mui/material';

export const CompaniesPartner = () => {
  const user = useSelector(useAuthUser);
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const [open, setOpen] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const {
    isLoading,
    error,
    data: pagedCompanies,
  } = useQuery(['getCompaniesByPartner', reFetch, page], async () => {
    const result = await getCompaniesByPartnerPaged(selectedTenant._id, page, 15);
    if (result?.data.data.length > 0) {
      return result?.data;
    }
    return {
      data: [],
      page,
      totalPages: 0,
    };
  });

  const hideDelete = () => {
    return !hasModulePermission(user.permissions, ['super_admin_global', 'admin_global']);
  };

  const onDelete = item => {
    setItemToDelete(item);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      const result = await deleteCompany(itemToDelete?._id);
      if (result.status === 204) {
        onClose();
        setRefetch(!reFetch);
      } else {
        throw new Error('Ocorreu um erro ao tentar remover a Empresa!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  return (
    <Container
      title="Clientes"
      breadcrumb="Configurações Gerais / Clientes"
      showButtonAdd
      linkPage="/companies-partner"
    >
      <Information
        title={'O que são clientes'}
        description={`Aqui segue explicação prévia.`}
        style={{
          marginTop: '24px',
        }}
      />
      {isLoading && <PageLoaderList></PageLoaderList>}
      {error && <div>Ocorreu um erro ...</div>}
      {!isLoading && !error && pagedCompanies.data?.length === 0 && (
        <NoData description={'Não há Clientes para exibir'} />
      )}
      {!isLoading && !error && pagedCompanies.data?.length > 0 && (
        <div className="container">
          <DefaultTable
            rows={tableHeader}
            cells={pagedCompanies.data}
            onClickDelete={onDelete}
            link="companies-partner"
            hideDelete={hideDelete()}
          />
          <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginTop: '16px' }}>
            <Pagination
              count={pagedCompanies.totalPages}
              onChange={(_, selectedPage) => {
                setPage(selectedPage);
              }}
              color="primary"
              page={page}
              showFirstButton
              showLastButton
            />
          </Stack>
          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja excluir <strong>{` ${itemToDelete?.name} `}</strong> como cliente?
              </>
            }
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
          />
        </div>
      )}
    </Container>
  );
};
