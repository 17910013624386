import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { Container } from 'Components/Container';
import { getProcessById } from 'services/processes-service';
import { PageLoaderShow } from 'Components/PageLoader/Show';
import { ReportsRopaProcess } from 'Pages/Reports/Ropa/Show/Process';

export const ProcessesDatasShow = () => {
  const { id, mappingId } = useParams();
  const {
    isLoading,
    error,
    data: process,
  } = useQuery('getProcessById', async () => {
    const dataProcess = await getProcessById(id);
    return dataProcess.status === 200 ? dataProcess.data : null;
  });

  const filterByMapping = processData => {
    const processFiltered = processData;
    if (!!mappingId) {
      processFiltered.dataMapping = [processData.dataMapping[mappingId]];
    }
    return processFiltered;
  };

  return (
    <>
      {!isLoading && !error && (
        <Container
          title={`${process?.name}`}
          breadcrumb={`Mapeamento / Processos / ${process?.name} / Datas`}
          linkPage="/processes"
        >
          <Box sx={{ width: '100%' }}>
            <ReportsRopaProcess process={process ? filterByMapping(process) : null} filter={''} data={[]} />
          </Box>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  );
};
