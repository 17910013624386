import styled from 'styled-components'

export const StepIcon = styled.div`
  background-color: ${(props) =>
    props.active ? '#1976d2' : 'rgba(0, 0, 0, 0.38)'};
  color: #fff;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-size: 12px;
  border-radius: 8px;
  font-weight: 700;
`
