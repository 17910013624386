import { Container } from 'Components/Container';
import { DefaultTable } from 'Components/DefaultTable';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { PageLoaderList } from 'Components/PageLoader/List';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { deleteControl, getControls } from 'services/controls-service';
import { useAuthTenant } from 'store/auth';

export const tableHeader = [
  {
    id: 'what',
    type: 'text',
    value: 'Nome',
  },
  {
    id: 'type',
    type: 'text',
    value: 'Tipo',
  },
  {
    id: 'prerequisite',
    type: 'prerequisite',
    value: 'Pré requisito',
  },
  {
    id: 'why',
    type: 'text',
    value: 'Justificativa',
  },
  {
    id: 'action',
    type: 'actions',
    value: '',
  },
];

export const Controls = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const [open, setOpen] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const { isLoading, error, data } = useQuery(['getControls', reFetch], async () => {
    const result = await getControls(selectedTenant.slug);
    const data = result?.data.map(item => {
      return {
        ...item,
        prerequisite: item.prerequisite?.name,
      };
    });

    return result?.data.length > 0 ? data : [];
  });

  const onDelete = item => {
    setItemToDelete(item);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      const result = await deleteControl(itemToDelete?._id);
      if (result.status === 200 || result.status === 204) {
        onClose();
        setRefetch(!reFetch);
      } else {
        console.log(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container title="Controles" breadcrumb="Configurações gerais / Controles" showButtonAdd linkPage="/controls">
      {isLoading && (
        <div>
          {' '}
          <PageLoaderList />
        </div>
      )}
      {error && <div>Ocorreu um erro ...</div>}
      {!isLoading && !error && (
        <div className="container">
          <DefaultTable rows={tableHeader} cells={data} onClickDelete={onDelete} link="controls" />
          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja excluir <strong>{` ${itemToDelete?.name} `}</strong>?
              </>
            }
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
          />
        </div>
      )}
    </Container>
  );
};
