import styled from "styled-components";

export const BouncingLoader = styled.div`
    display: flex;
    justify-content: center;
    & > div {
        width: 12px;
        height: 12px;
        margin: 3px 6px;
        border-radius: 50%;
        background-color: ${props => props.blue ? 'blue':'#FFFFFF'};
        opacity: 1;
        animation: bouncing-loader 0.6s infinite alternate;
    }
    @keyframes bouncing-loader {
        to {
            opacity: 0.1;
        }
    }
    
    & > div:nth-child(2) {
        animation-delay: 0.2s;
    }
    
    & > div:nth-child(3) {
        animation-delay: 0.4s;
    }
`