export const tableHeader = [
    {
        id: 'name',
        type: 'text',
        value: 'Nome',
    },
    {
        id: 'description',
        type: 'text',
        value: 'Descrição',
        maxSize: 48
    },
    {
        id: 'cnpj',
        type: 'string',
        value: 'CNPJ',
    },
    {
        id: 'active',
        type: 'boolean',
        value: 'Ativo',
    },
    {
        id: 'partner',
        type: 'boolean',
        value: 'Parceira',
    },
    {
        id: 'action',
        type: 'actions-redirect',
        value: '',
    },
]
