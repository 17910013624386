import { Box } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import Header from './Header';
import { Button, GridBox, OutlinedButton } from './styles';
import { useNavigate } from 'react-router-dom';
import ChartCard from './ChartCard';
import { getReportByCompany } from 'services/gap-analysis';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';

export default function GapAnalisys() {
  const navigate = useNavigate();
  const [report, setReport] = React.useState(null);
  const selectedTenant = useSelector(useAuthTenant);

  const fetchReport = useCallback(async () => {
    const result = await getReportByCompany(selectedTenant.slug);
    if (result?.data) {
      setReport(result?.data);
    }
  }, [selectedTenant.slug]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  return (
    <Box display={'flex'} flexDirection={'column'} p={4} gap={4}>
      <Header
        title={'Gap Analysis'}
        breadcrumb={'Gap Analysis / Padrões'}
        actions={
          <>
            <OutlinedButton variant="outlined" onClick={() => navigate('evolution')}>
              <img src="/img/icons/bar-chart.svg" alt="Icone gráfico" />
              Evolução
            </OutlinedButton>
            <Button onClick={() => navigate('add')}>
              <img src="/img/icons/plus.svg" alt="Icone mais" /> Adicionar
            </Button>
          </>
        }
      />

      <GridBox>
        {report?.map(item => (
          <ChartCard
            key={item.gapAnalysisId}
            title={`${item.securityStandard}`}
            deployedCount={item.deployedCount}
            notDeployedCount={item.notDeployedCount}
            notApplicableCount={item.notApplicableCount}
            onSeeClick={() => navigate(`${item.gapAnalysisId}/dashboards`)}
          />
        ))}
      </GridBox>
    </Box>
  );
}
