import React from 'react';
import { Handle, Position } from 'reactflow';
import PropTypes from 'prop-types';
import { Main } from './styles';

export const CompanyCard = ({data}) => {
  const {name, slug, result} = data
  return (
    <Main style={{
        marginInline: 'auto',
        border: '1px solid #ccc',
        backgroundColor: '#fff',
      }}>
      <h1 style={{textAlign: 'center'}}>{name}</h1>
      <hr/>
      <div>
        <p>Áreas</p>
        <span>{result?.areas}</span>
      </div>
      <div>
        <p>Processos</p>
        <span>{result?.processes}</span>
      </div>
      <div>
        <p>Sistemas</p>
        <span>{result?.systems}</span>
      </div>
      <div>
        <p>Ativos</p>
        <span>{result?.assets}</span>
      </div>
      <Handle type="source" position={Position.Bottom} id={`${slug}-0`} />
    </Main>
  );
};

CompanyCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  result: PropTypes.object
};
