import { FormSuccess } from 'Components/Form/Success';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '../../../Components/Container';
import { useEffect } from 'react';
import { addUsersGroup, editUsersGroup, getUsersGroupById } from 'services/users-groups-service';
import { FormUsersGroup } from './formUsersGroup';
/** Third Library Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import { useQuery } from 'react-query';

export const UsersGroupsForm = () => {
  const { id } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const selectedTenant = useSelector(useAuthTenant);
  const [idUsersGroup, setIdUsersGroup] = useState(null);
  const [finished, setFinished] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      active: false,
    },
  });

  const formatSubmit = values => {
    return {
      ...values,
      companyKey: selectedTenant.slug,
    };
  };

  const handleComplete = async () => {
    try {
      const formattedForm = formatSubmit(getValues());
      const res = id ? await editUsersGroup(id, formattedForm) : await addUsersGroup(formattedForm);
      if (res.status === 200 || res.status === 201) {
        setIdUsersGroup(res.data._id ?? id);
        setFinished(true);
      } else {
        throw new Error('Ocorreu um erro ao tentar salvar/editar o Grupo de Usuário!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const handleReset = () => {
    reset();
    setFinished(false);
    setIdUsersGroup(null);
    navigate('/users-groups/add');
  };

  const { data: userGroup } = useQuery(['getUsersGroups', id], async () => {
    if (!id) return null;
    const resUsersGroup = await getUsersGroupById(id);

    if (!resUsersGroup.data) return null;

    return resUsersGroup.data;
  });

  useEffect(() => {
    if (!id) return;
    if (!userGroup) return;

    Object.keys(userGroup).forEach(key => {
      setValue(key, userGroup[key]);
    });

    return () => {};
  }, [id, userGroup, setValue]);

  return (
    <Container
      title={`${id ? 'Editar' : 'Adicionar'} Tipo de dado`}
      breadcrumb="Gerenciar / Grupos de usuários"
      linkPage="/users-groups"
    >
      {}
      <div className="card-content">
        {!finished && (
          <FormUsersGroup
            control={control}
            register={register}
            handleComplete={handleComplete}
            handleSubmit={handleSubmit}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
          />
        )}
        {finished && (
          <FormSuccess
            title="Grupo de usuário cadastrado com sucesso!"
            labelStartAgain="Cadastrar novo grupo de usuário"
            handleStartAgain={() => handleReset()}
            labelShow="Ver detalhes"
            handleShow={() => navigate('/users-groups/show/' + idUsersGroup)}
          />
        )}
      </div>
    </Container>
  );
};
