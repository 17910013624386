import { Row } from 'Components/Grid';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const GraphicAreaVolumeData = ({ indicatorVolumeGraphicByArea }) => {
  return (
    <Row className="mt-4">
      <ResponsiveContainer width="100%" height="100%" minHeight={300} minWidth={600}>
        <BarChart
          width={600}
          height={300}
          data={indicatorVolumeGraphicByArea}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar name={'Processos'} dataKey="processQuantity" barSize={50} fill="#70ad46" />
          <Bar name={'Bases Legais'} dataKey="legalBaseQuantity" barSize={50} fill="#ffc100" />
        </BarChart>
      </ResponsiveContainer>
    </Row>
  );
};
