import { Column, Row } from 'Components/Grid';
import { ButtonForm } from '../styles';
import { ContainerSuccess, TitleContent } from './styles';
import { ReactComponent as SuccessIcon } from '../../../assets/success.svg';

export const FormSuccess = ({
  title,
  labelStartAgain,
  handleStartAgain,
  labelShow = 'Ver detalhes',
  handleShow,
  showButtonStartAgain = true,
  showButtonSeeDetail = true,
}) => {
  return (
    <ContainerSuccess>
      <Row justifyContent="center">
        <Column xs={6}>
          <SuccessIcon width="80px" height="80px" style={{ margin: '0 auto 24px', display: 'flex' }} />
        </Column>
      </Row>
      <Row justifyContent="center">
        <TitleContent justifyContent="center" xs={12}>
          {title}
        </TitleContent>
      </Row>
      <Row justifyContent="around" alignItems="center" noGutters>
        {showButtonStartAgain && (
          <Column xs={6} justifyContent="center" className="d-flex">
            <ButtonForm
              secondary
              id="cancel"
              label={labelStartAgain}
              onClick={handleStartAgain}
              className="max-content"
            ></ButtonForm>
          </Column>
        )}
        {showButtonSeeDetail && (
          <Column xs={5} justifyContent="center" className="d-flex">
            <ButtonForm label={labelShow} onvariant="button" onClick={handleShow} />
          </Column>
        )}
      </Row>
    </ContainerSuccess>
  );
};
