import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';

import { getAreas } from 'services/areas-service';
import { getMapCompanyBySlug } from 'services/company-service';

import { CompanyCard } from 'Components/CompanyCard';
import { MappingCard } from 'Components/MappingCard';
import { Main } from '../../Components/Main';
import Graphic from './Graphic';
import { getAssets } from 'services/assets-service';
import { getSystems } from 'services/systems-service';
import { getProcesses } from 'services/processes-service';

export const Map = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const nodeTypes = useMemo(() => ({ company: CompanyCard, section: MappingCard }), []);

  const { data: companyMap, isSuccess: companyMapSuccess } = useQuery(['getMapCompanyBySlug'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const response = await getMapCompanyBySlug(selectedTenant.slug);
    return response?.data;
  });
  const { data: companyAreas, isSuccess: areaSuccess } = useQuery(['getAreas'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getAreas(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  const { data: companyProcesses, isSuccess: processSuccess } = useQuery(['getProcesses'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getProcesses(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  const { data: companySystems, isSuccess: systemSuccess } = useQuery(['getSystems'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getSystems(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  const { data: companyAssets, isSuccess: assetSuccess } = useQuery(['getAssets'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getAssets(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  return (
    <Main>
      <div className="headerMenu container">
        <div>
          <h1 className="clickable title">Mapa Corporativo</h1>
          <h2 className="subtitle">Visualização do Mapa</h2>
        </div>
        <div
          className="container-map"
          style={{
            maxWidth: 'max-content',
            height: '372px',
            minWidth: '-webkit-fill-available',
          }}
        >
          {selectedTenant !== undefined &&
            companyMapSuccess &&
            areaSuccess &&
            processSuccess &&
            systemSuccess &&
            assetSuccess && (
              <Graphic
                tenant={selectedTenant}
                nodeTypes={nodeTypes}
                result={companyMap}
                areas={companyAreas}
                processes={companyProcesses}
                systems={companySystems}
                assets={companyAssets}
              />
            )}
        </div>
      </div>
    </Main>
  );
};
