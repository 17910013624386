import { backendPrivate } from "services"

export const getUsersGroups = async (tenant) => {
    return await backendPrivate.get(`/user-group?companyKey=${tenant}`)
}

export const getUsersGroupsPaged = async (tenant, page, perPage) => {
    return await backendPrivate.get(`/user-group/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getUsersGroupById = async (id) => {
    return await backendPrivate.get(`/user-group/${id}`)
}

export const addUsersGroup = async (userGroup) => {
    return await backendPrivate.post('/user-group', userGroup)
}

export const editUsersGroup = async (id, userGroup) => {
    return await backendPrivate.put(`/user-group/${id}`, userGroup)
}

export const deleteUsersGroup = async (id) => {
    return await backendPrivate.delete(`/user-group/${id}`)
}