import { Column } from "Components/Grid";
import styled from "styled-components";

export const TitleLogin = styled.span`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    color: #0039C5;
    &.bold {
        font-weight: 700;
    }
`
export const SubtitleLogin = styled.span`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #0039C5;
`
export const Observation = styled.p`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6B6664;
    margin-bottom: 24px;
    span {
        font-weight: 700;
    }
`
export const HasAccount = styled.p`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6B6664;
    button {
        border: none;
        background: transparent;
        width: fit-content;
        font-weight: 700;
        color: #0039C5;
        &:hover {
            cursor: pointer;
        }
    }
`
export const SucessContent = styled(Column)`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
    color: #3c3938;
    margin: 0 auto 48px;
    text-align: center;
`