// import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { Column, Row } from 'Components/Grid';
// import { editCompany, getCompanyById } from 'services/company-service'
import { editCompany, getCompanyBySlug } from 'services/company-service';
import { Container } from 'Components/Container';
import { Card } from 'Components/Card';
import { ButtonForm, CustomTextField } from 'Components/Form/styles';
// import { LinkUpload, LogoCompany } from './styles'
import { FormControl, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { Shimmer } from 'Components/PageLoader/styles';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import { MuiChipsInput } from 'mui-chips-input';

export const CompanyProfile = () => {
  const SwalReact = withReactContent(Swal);
  const selectedTenant = useSelector(useAuthTenant);
  // const [finished, setFinished] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      active: true,
      cnpj: '',
      corporateName: '',
      description: '',
      domains: [],
      income: '',
      industry: '',
      managers: [],
      name: '',
      partner: false,
      roles: [],
      size: '',
      slug: '',
    },
  });

  const formmatValues = values => {
    return {
      active: values.active,
      cnpj: values.cnpj ?? '',
      corporateName: values.corporateName ?? '',
      description: values.description ?? '',
      domains: values.domains ?? [],
      income: values.income ?? '',
      industry: values.industry ?? '',
      managers: values.managers ?? [],
      name: values.name ?? '',
      partner: values.partner,
      roles: values.roles ?? [],
      size: values.size ?? '',
      slug: values.slug,
    };
  };

  const handleComplete = async () => {
    try {
      const res = await editCompany(selectedTenant._id, formmatValues(getValues()));
      if (res.status === 200) {
        SwalReact.fire({
          title: 'Sucesso!',
          text: 'Empresa atualizada com sucesso!',
          icon: 'success',
        });
      } else {
        throw new Error('Ocorreu um erro ao tentar editar a Empresa!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };
  const { isLoading, isSuccess, isError } = useQuery(['getCompanyBySlug'], async () => {
    if (selectedTenant.slug === undefined) return null;
    const result = await getCompanyBySlug(selectedTenant.slug);
    if (result?.data) {
      Object.keys(result?.data).forEach(key => {
        setValue(key, result?.data[key]);
      });
    }
    return result?.data;
  });

  return (
    <Container title="Perfil da empresa" breadcrumb="Gerenciar / Perfil da empresa" linkPage="#">
      <form onSubmit={handleSubmit(handleComplete)} className="mt-3">
        <Row alignItems="start" justifyContent="center">
          <Column xs={10}>
            <Card>
              {isLoading && !isError && (
                <>
                  <Row>
                    <Column xs={12}>
                      <Shimmer style={{ height: '48px' }}></Shimmer>
                    </Column>
                  </Row>
                  {[1, 2, 3, 4, 5].map((_, index) => (
                    <Row key={`shimer-${index}`}>
                      <Column xs={12}>
                        <Shimmer style={{ marginTop: '16px', height: '48px' }}></Shimmer>
                      </Column>
                    </Row>
                  ))}
                </>
              )}
              {isSuccess && (
                <>
                  <Row>
                    <Column xs={12}>
                      <CustomTextField
                        {...register('name', { required: 'Nome é obrigatório' })}
                        label="Nome da empresa"
                        autoFocus
                        error={errors?.name ? true : false}
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        helperText={errors?.name ? errors.name.message : null}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={12}>
                      <CustomTextField
                        {...register('corporateName', { required: 'Razão Social é obrigatório' })}
                        label="Razão social"
                        autoFocus
                        error={errors?.corporateName ? true : false}
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        helperText={errors?.corporateName ? errors.corporateName.message : null}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={12}>
                      <CustomTextField
                        {...register('cnpj', { required: 'CNPJ é obrigatório' })}
                        label="CNPJ"
                        error={errors?.cnpj ? true : false}
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        helperText={errors?.cnpj ? errors.cnpj.message : null}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={12}>
                      <CustomTextField
                        {...register('size')}
                        label="Quantidade de funcionários"
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={12}>
                      <CustomTextField
                        {...register('income')}
                        label="Receita"
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={12}>
                      <CustomTextField
                        {...register('industry')}
                        label="Indústria"
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Column>
                  </Row>
                  <Row justifyContent="center">
                    <Column xs={12} className="d-flex">
                      <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                        <Controller
                          control={control}
                          name="domains"
                          render={({ field: { onChange, value } }) => (
                            <MuiChipsInput
                              label="Domínios"
                              value={value}
                              onChange={onChange}
                              InputLabelProps={{ shrink: true }}
                              placeholder="Digite e aperte enter"
                              renderChip={(Component, props) => {
                                return (
                                  <Component
                                    {...props}
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      width: 'fit-content',
                                    }}
                                  />
                                );
                              }}
                              sx={{
                                '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled': {
                                  width: 'fit-content',
                                },
                                '& .MuiButtonBase-root.MuiIconButton-root': {
                                  width: 'fit-content',
                                  marginTop: '0px',
                                },
                              }}
                            />
                          )}
                        ></Controller>
                      </FormControl>
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={12}>
                      <TextField
                        {...register('description')}
                        label="Resumo"
                        multiline
                        rows={3}
                        sx={{ margin: '0 auto 4px', width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={12} justifyContent="end" flex className="mt-3">
                      <ButtonForm label="Salvar alterações" variant="submit" />
                    </Column>
                  </Row>
                </>
              )}
            </Card>
          </Column>
        </Row>
      </form>
    </Container>
  );
};
