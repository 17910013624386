import { backendPrivate } from "services"

export const getDatas = async (tenant) => {
    return await backendPrivate.get(`/data?companyKey=${tenant}`)
}

export const getDatasPaged = async (tenant, page, perPage) => {
    return await backendPrivate.get(`/data/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getDatasNames = async (tenant) => {
    return await backendPrivate.get(`/data/names?companyKey=${tenant}`)
}

export const getDataById = async (id) => {
    return await backendPrivate.get(`/data/${id}`)
}

export const addData = async (data) => {
    return await backendPrivate.post('/data', data)
}

export const editData = async (id, data) => {
    return await backendPrivate.put(`/data/${id}`, data)
}

export const deleteData = async (id) => {
    return await backendPrivate.delete(`/data/${id}`)
}