import styled from 'styled-components'

export const Main = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 30px 30px 30px;

  .headerMenu {
    display: flex;
    align: center;
  }

  .clickable {
    cursor: pointer;
  }

  .container {
    box-shadow: 0px 6px 20px #eff1f7;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin-top: 32px;

    .subcontainer {
      width: 100%;
      margin-top: 24px;
    }

    h1.title {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: #0039c5;
      margin: 0px;
      margin-bottom: 9px;
    }

    h2.subtitle {
      color: #6b6664;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin: 0px;
    }
  }

  #help {
    width: 48px !important;
    height: 48px !important;
    margin-top: 124px;

    img {
      margin: 0px;
    }
  }

  button {
    display: flex;
    justify-content: center;
  }
`
