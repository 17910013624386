import styled from 'styled-components'

export const Main = styled.aside`
  * {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ececec;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #3a86ff;
    border-radius: 10px;
  }
  background-color: #0039c5;
  color: #f7f9fb;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 100vh;
  max-height: 100vh;
  width: 294px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;

  #logo {
    align-self: center;
    margin-top: 17px;
    margin-bottom: 30px;
  }

  div,
  .link {
    align-items: center;
    color: #f7f9fb;
    cursor: pointer;
    display: flex;
    height: 44px;
    min-height: 44px;
    margin-left: 14px;
    padding-left: 16px;
    text-decoration: none;
    width: 280px;

    img {
      height: 20px;
      width: 16px;
    }

    p {
      margin: 0px;
      margin-left: 16px;
    }

    #dropdown {
      margin-left: auto;
      margin-right: 22px;
      transform: rotate(-90deg);
    }

    &:hover {
      background-color: #0133ae;
      box-shadow: 0px 6px 20px #0c3aad;
      border-radius: 8px 0px 0px 8px;
    }
  }

  .wrapper {
    margin-left: 61px;
    display: flex;
    flex-direction: column;

    .menuLink:link,
    .menuLink:visited {
      cursor: pointer;
      margin: 0px;
      margin-bottom: 16px;
      text-decoration: none;
      outline: none;
      color: white;

      &:first-child {
        margin-top: 16px;
      }
    }
  }

  hr {
    width: 234px;
    border: none;
    border-top: 1px solid #537de4;
    margin-bottom: 40px;
    margin-top: 50px;
  }

  > p {
    cursor: pointer;
    margin-left: 30px;
    margin-top: 64px;
  }
`
