import { Container } from 'Components/Container';
import { DefaultTable } from 'Components/DefaultTable';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { PageLoaderList } from 'Components/PageLoader/List';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { deleteSecurityStandard, getSecurityStandards } from 'services/security-standard-service';

export const tableHeader = [
  {
    id: 'name',
    type: 'text',
    value: 'Nome',
  },
  {
    id: 'version',
    type: 'text',
    value: 'Versão',
  },
  {
    id: 'createdAt',
    type: 'date',
    value: 'Criado em',
  },
  {
    id: 'updatedAt',
    type: 'date',
    value: 'Atualizado em',
  },
  {
    id: 'action',
    type: 'actions',
    value: '',
  },
];

export const SecurityStandards = () => {
  const [open, setOpen] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const { isLoading, error, data } = useQuery(['getSecurityStandards', reFetch], async () => {
    const result = await getSecurityStandards();
    return result?.data.length > 0 ? result?.data : [];
  });

  const onDelete = item => {
    setItemToDelete(item);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      const result = await deleteSecurityStandard(itemToDelete?._id);
      if (result.status === 200) {
        onClose();
        setRefetch(!reFetch);
      } else {
        console.log(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container title="Padrões" breadcrumb="Configurações gerais / Padrões" showButtonAdd linkPage="/security-standards">
      {isLoading && (
        <div>
          <PageLoaderList />
        </div>
      )}
      {error && <div>Ocorreu um erro ...</div>}
      {!isLoading && !error && (
        <div className="container">
          <DefaultTable rows={tableHeader} cells={data} onClickDelete={onDelete} link="security-standards" />
          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja excluir <strong>{` ${itemToDelete?.name} `}</strong>?
              </>
            }
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
          />
        </div>
      )}
    </Container>
  );
};
