export const tableHeader = [
  {
    id: 'name',
    type: 'text',
    value: 'Nome',
  },
  {
    id: 'criticity',
    type: 'select',
    value: 'Criticidade',
  },
  {
    id: 'description',
    type: 'text',
    value: 'Descrição',
  },
  {
    id: 'user',
    type: 'text',
    value: 'Responsável',
  },
  {
    id: 'dpia',
    type: 'boolean',
    value: 'DPIA',
  },
  {
    id: 'lia',
    type: 'boolean',
    value: 'LIA',
  },
  {
    id: 'action',
    type: 'actions',
    value: '',
  },
]
