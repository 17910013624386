import { Box, Step, StepLabel, Stepper } from '@mui/material'
import { StepIcon } from './styles'

export const StepperForm = ({ activeStep, steps }) => {
  return (
    <Box sx={{ width: '50%', margin: '24px auto 0' }}>
      <Stepper nonLinear alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              sx={{
                '& .MuiStepLabel-label': { marginTop: '8px' },
                '& .MuiStepConnector-root': {
                  top: '15px',
                  left: 'calc(-50% + 17px)',
                  right: 'calc(50% + 17px)',
                },
              }}
              icon={
                <StepIcon active={activeStep === index}>{index + 1}</StepIcon>
              }
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
