import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from '@mui/material';
import { Column, Row } from 'Components/Grid';
import { Controller } from 'react-hook-form';
import { ButtonForm } from 'Components/Form/styles';
import { useQueries } from 'react-query';
import { getAssetsShort } from 'services/assets-service';
import { getSystemsShort } from 'services/systems-service';
import { getProcessesShort } from 'services/processes-service';
import { getSelectedContent } from 'utils/select_utils';
import { useAuthTenant } from 'store/auth';
import { useSelector } from 'react-redux';
import DotsLoader from 'Components/DotsLoader';
import { getStyles, MenuProps } from './helpers';
import { InputLoader } from 'Components/PageLoader/Input';

export const Vinculos = ({ handleBack, handleComplete, control, handleSubmit, loading }) => {
  const selectedTenant = useSelector(useAuthTenant);
  const theme = useTheme();

  const onSubmit = () => {
    handleComplete();
  };

  const queries = useQueries([
    {
      queryKey: ['getProcesses'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getProcessesShort(selectedTenant.slug);
        return result?.data.length > 0 ? result?.data : [];
      },
    },
    {
      queryKey: ['getSystems'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getSystemsShort(selectedTenant.slug);
        return result?.data.length > 0 ? result?.data : [];
      },
    },
    {
      queryKey: ['getAssets'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getAssetsShort(selectedTenant.slug);
        return result?.data.length > 0 ? result?.data : [];
      },
    },
  ]);

  const [
    { data: processes, isLoading: processLoading, isError: processError },
    { data: systems, isLoading: systemLoading, isError: systemError },
    { data: assets, isLoading: assetLoading, isError: assetError },
  ] = queries;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          {!processLoading && !processError && (
            <Controller
              control={control}
              name="processes"
              render={({ field: { onChange, value }, fieldState }) => (
                <FormControl fullWidth sx={{ margin: '0 auto 24px' }} error={fieldState.error ? true : false}>
                  <InputLabel id="processes-label" shrink={true}>
                    Processos vinculados
                  </InputLabel>
                  <Select
                    labelId="processes-label"
                    id="processes"
                    multiple
                    notched
                    value={getSelectedContent(processes, value)}
                    onChange={onChange}
                    input={<OutlinedInput id="processes-chip" label="Processos vinculados" />}
                    renderValue={selectedProcesses => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selectedProcesses?.map(selectedValue => (
                          <Chip
                            key={`process-chip-${selectedValue._id}`}
                            label={selectedValue.name}
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: 'fit-content',
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {processes?.map(item => (
                      <MenuItem key={`process-${item._id}`} value={item} style={getStyles(item._id, value._id, theme)}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
                </FormControl>
              )}
            ></Controller>
          )}
          {processLoading && !processError && (
            <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
              <InputLabel id="processes-label" shrink={true}>
                Processos vinculados
              </InputLabel>
              <InputLoader />
            </FormControl>
          )}
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          {!systemLoading && !systemError && (
            <Controller
              control={control}
              name="systems"
              render={({ field: { onChange, value }, fieldState }) => (
                <FormControl fullWidth sx={{ margin: '0 auto 24px' }} error={fieldState.error ? true : false}>
                  <InputLabel id="systems-label" shrink={true}>
                    Sistemas vinculados
                  </InputLabel>
                  <Select
                    labelId="systems-label"
                    id="systems"
                    multiple
                    notched
                    value={getSelectedContent(systems, value)}
                    onChange={onChange}
                    input={<OutlinedInput id="systems-chip" label="Sistemas vinculados" />}
                    renderValue={selectedSystems => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selectedSystems?.map(value => (
                          <Chip
                            key={value._id}
                            label={value.name}
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: 'fit-content',
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {systems?.map(item => (
                      <MenuItem key={item._id} value={item} style={getStyles(item._id, value._id, theme)}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
                </FormControl>
              )}
            ></Controller>
          )}
          {systemLoading && !systemError && (
            <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
              <InputLabel id="systems-label" shrink={true}>
                Sistemas vinculados
              </InputLabel>
              <InputLoader />
            </FormControl>
          )}
        </Column>
      </Row>
      <Row>
        <Column xs={12} className="d-flex">
          {!assetLoading && !assetError && (
            <Controller
              control={control}
              name="assets"
              render={({ field: { onChange, value }, fieldState }) => (
                <FormControl fullWidth sx={{ margin: '0 auto 24px' }} error={fieldState.error ? true : false}>
                  <InputLabel id="assets-label" shrink={true}>
                    Ativos vinculados
                  </InputLabel>
                  <Select
                    labelId="assets-label"
                    id="assets"
                    multiple
                    notched
                    value={getSelectedContent(assets, value)}
                    onChange={onChange}
                    input={<OutlinedInput id="assets-chip" label="Ativos vinculados" />}
                    renderValue={selectedAssets => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selectedAssets?.map(value => (
                          <Chip
                            key={value._id}
                            label={value.name}
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: 'fit-content',
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {assets?.map(item => (
                      <MenuItem key={item._id} value={item} style={getStyles(item._id, value._id, theme)}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
                </FormControl>
              )}
            ></Controller>
          )}
          {assetLoading && !assetError && (
            <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
              <InputLabel id="assets-label" shrink={true}>
                Ativos vinculados
              </InputLabel>
              <InputLoader />
            </FormControl>
          )}
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="cancel" label="cancelar" onClick={handleBack}></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label={loading ? <DotsLoader /> : 'Salvar'} variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
