import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import { Button, OutlinedButton } from "../styles";
import { useNavigate } from "react-router-dom";

import { getSecurityStandardControls } from "services/security-standard-service";
import {
  getGapAnalysisByCompany,
  getReportByControls,
} from "services/gap-analysis";
import { useSelector } from "react-redux";
import { useAuthTenant, useAuthUser } from "store/auth";
import { addReportGapAnalysis } from "services/gap-analysis-report";

export default function AddGapAnalisysReport() {
  const navigate = useNavigate();
  const [securityStandards, setSecurityStandards] = useState(null);
  const [selectedGapAnalysis, setSelectedGapAnalysis] = useState(null);
  const user = useSelector(useAuthUser);
  const selectedTenant = useSelector(useAuthTenant);
  const [version, setVersion] = useState("");

  const fetchSecurityStandard = useCallback(async () => {
    const result = await getGapAnalysisByCompany(selectedTenant.slug);
    if (result?.data) {
      setSecurityStandards(result?.data);
    }
  }, [selectedTenant.slug]);

  useEffect(() => {
    fetchSecurityStandard();
  }, [fetchSecurityStandard]);

  const handleSave = async () => {
    const report = await fetchSecurityStandardControls(
      selectedGapAnalysis.securityStandard._id
    );

    const meetsRequirementCount = report.filter(
      (req) => req.status === "DEPLOYED"
    ).length;

    const doesNotMeetRequirementCount = report.filter(
      (req) => req.status === "NOT_DEPLOYED"
    ).length;

    const meetsRequirementPartiallyCount = report.filter(
      (req) => req.status === "PARTIALLY_DEPLOYED"
    ).length;

    const notApplicableCount = report.filter(
      (req) => req.status === "NOT_APPLICABLE"
    ).length;

    const percentageOfCompliance =
      (meetsRequirementCount /
        (meetsRequirementCount +
          doesNotMeetRequirementCount +
          meetsRequirementPartiallyCount)) *
      100;

    const requisites = report.map((req) => ({
      _id: req._id,
      name: req.name,
      status: req.status,
      controls: req.controls.map((control) => ({
        _id: control._id,
        name: control.what,
        status: control.status,
      })),
    }));

    const created_by = user.userId;

    const result = await addReportGapAnalysis({
      company: selectedGapAnalysis.company._id,
      companyKey: selectedGapAnalysis.companyKey,
      securityStandard: selectedGapAnalysis.securityStandard._id,
      version,
      meetsRequirementCount,
      doesNotMeetRequirementCount,
      meetsRequirementPartiallyCount,
      notApplicableCount,
      percentageOfCompliance,
      requisites,
      created_by,
    });

    if (result.status === 200) {
      navigate("/reports/gap-analysis");
    } else {
      console.log("Erro ao salvar gap analysis");
    }
  };

  const fetchSecurityStandardControls = async (standardId) => {
    if (standardId) {
      const result = await getSecurityStandardControls(standardId);
      if (result?.data) {
        const distinctRequisites = [];
        result?.data
          .filter((item) => item.requisite)
          .forEach((element) => {
            if (
              distinctRequisites
                .map((item) => item._id)
                .includes(element.requisite?._id)
            )
              return;
            else distinctRequisites.push(element.requisite);
          });

        const requisites = distinctRequisites.map((req) => ({
          ...req,
          controls: result?.data
            .filter((item) => item.requisite?._id === req._id)
            .map((item) => item.control),
        }));
        const report = await fetchReport();
        const finalReport = requisites.map((requisite) => {
          const mappedRequisite = {
            ...requisite,
            controls: requisite.controls.map((control) => {
              const deployments = report.filter(
                (item) => item.controlId === control._id
              );
              const implantation = deployments.filter(
                (d) => d.deploymentStatus === "DEPLOYED"
              ).length;

              const notApplicable = deployments.filter(
                (d) => d.deploymentStatus === "NOT_APPLICABLE"
              ).length;

              const status =
                deployments.length > 0 && notApplicable === deployments.length
                  ? "NOT_APPLICABLE"
                  : deployments.length - notApplicable === implantation
                  ? "DEPLOYED"
                  : implantation > 0
                  ? "PARTIALLY_DEPLOYED"
                  : "NOT_DEPLOYED";
              return {
                ...control,
                deployments: deployments,
                status: status,
                progress: `${implantation}/${deployments.length}`,
              };
            }),
          };

          const deployedCount = mappedRequisite.controls.filter(
            (c) => c.status === "DEPLOYED"
          ).length;

          const notApplicableCount = mappedRequisite.controls.filter(
            (c) => c.status === "NOT_APPLICABLE"
          ).length;

          const status =
            mappedRequisite.controls.length > 0 &&
            notApplicableCount === mappedRequisite.controls.length
              ? "NOT_APPLICABLE"
              : mappedRequisite.controls.length - notApplicableCount ===
                deployedCount
              ? "DEPLOYED"
              : deployedCount > 0
              ? "PARTIALLY_DEPLOYED"
              : "NOT_DEPLOYED";

          return {
            ...mappedRequisite,
            status: status,
          };
        });

        return finalReport;
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const fetchReport = async () => {
    const result = await getReportByControls(
      selectedTenant.slug,
      selectedGapAnalysis._id
    );
    const newData = [];
    if (result?.data) {
      result?.data.forEach((d) => {
        const control = d.control;
        const deployments =
          d.controlDeployments.map((d) => {
            return {
              _id: d._id,
              deploymentStatus: d.status,
              control: control.what,
              controlId: control._id,
              status: d.status,
            };
          }) ?? [];
        const result =
          deployments.length > 0
            ? deployments
            : [{ control: control.what, controlId: control._id }];

        newData.push(...result);
      });

      newData.sort((a, b) => {
        if (a.control < b.control) {
          return -1;
        }
        if (a.control > b.control) {
          return 1;
        }
        return 0;
      });
      return newData;
    }
  };

  return (
    <Stack spacing={3} p={4}>
      <Header
        title={"Adicionar relatório Gap Analysis"}
        breadcrumb={"Relatórios / Gap Analysis / Gerar novo"}
      />

      <Box bgcolor={"#fff"} p={3} borderRadius={2}>
        <Typography color="#3C3938" fontWeight={700} fontSize={"16px"}>
          Gerar novo relatório
        </Typography>

        <Box display={"flex"} gap={4} mt={4}>
          <FormControl fullWidth>
            <InputLabel id="standard-select-label">Padrão</InputLabel>
            <Select
              labelId="standard-select-label"
              id="standard-select"
              label="Padrão"
              value={selectedGapAnalysis}
              onChange={(e) => setSelectedGapAnalysis(e.target.value)}
            >
              {securityStandards?.map((item) => (
                <MenuItem value={item}>{item.securityStandard.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <TextField
              id="company-select"
              label="Versão"
              variant="outlined"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
            />
          </FormControl>
        </Box>

        <Stack spacing={2} mt={4} direction={"row"} justifyContent={"flex-end"}>
          <OutlinedButton onClick={() => window.history.go(-1)}>
            Cancelar
          </OutlinedButton>
          <Button style={{ width: "120px" }} onClick={handleSave}>
            Salvar
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
