import { Box, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OutlinedButton, Title } from '../styles';
import Header from '../Header';
import { Card } from 'Components/Card';
import TabList from 'Components/TabList';
import Tab from 'Components/TabList/tab';
import DashboardTab from './DashboardTab';
import { getGapAnalysisById } from 'services/gap-analysis';
import { formatDateToDateBR } from 'utils/date_utils';
import RequisitesTab from './RequisitesTab';

export default function Dashboards() {
  const navigate = useNavigate();
  const [gapAnalysis, setGapAnalysis] = React.useState(null);
  const { id } = useParams();

  const fetchGapAnalysis = React.useCallback(async () => {
    const result = await getGapAnalysisById(id);
    if (result?.data) {
      setGapAnalysis(result?.data);
    }
  }, [id]);

  React.useEffect(() => {
    fetchGapAnalysis();
  }, [fetchGapAnalysis]);

  return (
    <Box display={'flex'} flexDirection={'column'} p={4} gap={4}>
      <Header
        title={'Gap Analysis'}
        breadcrumb={'Gap Analysis / Padrões / Dashboards'}
        actions={
          <>
            <OutlinedButton variant="outlined" onClick={() => navigate('/gap-analysis/evolution')}>
              <img src="/img/icons/bar-chart.svg" alt="Icone gráfico" />
              Evolução
            </OutlinedButton>
          </>
        }
      />

      {gapAnalysis && (
        <Card>
          <Title>{gapAnalysis.company.name}</Title>
          <Stack mt={2}>
            <InfoLabel label={'Padrão'} value={gapAnalysis.securityStandard?.name} />
            <InfoLabel label={'Criação'} value={formatDateToDateBR(gapAnalysis.created_at)} />
            <InfoLabel label={'Funcionário'} value={gapAnalysis.created_by.name} />
          </Stack>
        </Card>
      )}

      {!gapAnalysis && (
        <Card>
          <Title>
            <Skeleton variant="text" width={220} height={40} />
          </Title>
          <Stack mt={2}>
            <InfoLabel label={'Padrão'} value={<Skeleton variant="text" width={180} />} />
            <InfoLabel label={'Criação'} value={<Skeleton variant="text" width={200} />} />
            <InfoLabel label={'Funcionário'} value={<Skeleton variant="text" width={150} />} />
          </Stack>
        </Card>
      )}

      <Card>
        <TabList>
          <Tab label="Dashboard">
            <DashboardTab />
          </Tab>
          <Tab label="Controles">
            <RequisitesTab standardId={gapAnalysis?.securityStandard._id} />
          </Tab>
        </TabList>
      </Card>
    </Box>
  );
}

const InfoLabel = ({ label, value }) => {
  return (
    <Stack spacing={1} direction={'row'} alignItems={'center'}>
      <Typography color="#0039c5" fontWeight={500} fontSize={'14px'}>
        {label}:
      </Typography>
      <Typography color="#6B6664" fontWeight={500} fontSize={'14px'}>
        {value}
      </Typography>
    </Stack>
  );
};
