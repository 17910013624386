export const tableHeader = [
  {
    id: 'name',
    type: 'text',
    value: 'Nome',
  },
  {
    id: 'scope',
    type: 'text',
    value: 'Escopo',
  },
  {
    id: 'date',
    type: 'datebr',
    value: 'Data',
  },
  {
    id: 'action',
    type: 'actions',
    value: '',
  },
]