import { backendPrivate } from "services";

export const getSecurityStandards = async (tenant) => {
  return await backendPrivate.get(`/security-standard`);
};

export const getSecurityStandardById = async (id) => {
  return await backendPrivate.get(`/security-standard/${id}`);
};

export const addSecurityStandard = async (data) => {
  return await backendPrivate.post("/security-standard", data);
};

export const editSecurityStandard = async (id, data) => {
  return await backendPrivate.put(`/security-standard/${id}`, data);
};

export const deleteSecurityStandard = async (id) => {
  return await backendPrivate.delete(`/security-standard/${id}`);
};

export const getSecurityStandardControls = async (id) => {
  return await backendPrivate.get(`/security-standard/${id}/controls`);
};

export const getSecurityStandardRequisites = async (id) => {
  return await backendPrivate.get(`/security-standard/${id}/requisites`);
};

export const createSecurityStandardRequisite = async (body) => {
  return await backendPrivate.post(`/security-standard/requisites`, body);
};
