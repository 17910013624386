import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Header from '../Header';
import { Button, OutlinedButton } from '../styles';
import { useNavigate } from 'react-router-dom';
import { getCompanyById } from 'services/company-service';
import { getSecurityStandards } from 'services/security-standard-service';
import { addGapAnalysis } from 'services/gap-analysis';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';

export default function AddGapAnalysis() {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [securityStandards, setSecurityStandards] = useState(null);
  const [selectedSecurityStandard, setSelectedSecurityStandard] = useState(null);
  const selectedTenant = useSelector(useAuthTenant);

  const fetchCompanies = useCallback(async () => {
    const result = await getCompanyById(selectedTenant._id);
    if (result.status === 200) {
      setCompanies([result?.data]);
    } else {
      console.log('Erro ao buscar empresas');
    }
  }, [selectedTenant]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const fetchSecurityStandard = useCallback(async () => {
    const result = await getSecurityStandards();
    if (result?.data) {
      setSecurityStandards(result?.data);
    }
  }, []);

  useEffect(() => {
    fetchSecurityStandard();
  }, [fetchSecurityStandard]);

  const handleSave = async () => {
    const result = await addGapAnalysis({
      company: selectedCompany,
      securityStandard: selectedSecurityStandard,
      companyKey: selectedTenant.slug,
    });

    if (result.status === 200) {
      navigate('/gap-analysis');
    } else {
      console.log('Erro ao salvar gap analysis');
    }
  };

  return (
    <Stack spacing={3} p={4}>
      <Header title={'Adicionar Gap Analysis'} breadcrumb={'Gap Analysis / Adicionar'} />

      <Box bgcolor={'#fff'} p={3} borderRadius={2}>
        <Typography color="#3C3938" fontWeight={700} fontSize={'16px'}>
          Adicionar Gap Analysis
        </Typography>

        <Stack spacing={4} mt={4}>
          <FormControl fullWidth>
            <InputLabel id="company-select-label">Empresa</InputLabel>
            <Select
              labelId="company-select-label"
              id="company-select"
              label="Empresa"
              value={selectedCompany}
              onChange={e => setSelectedCompany(e.target.value)}
            >
              {companies.map(company => (
                <MenuItem value={company._id}>{company.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="standard-select-label">Padrão</InputLabel>
            <Select
              labelId="standard-select-label"
              id="standard-select"
              label="Padrão"
              value={selectedSecurityStandard}
              onChange={e => setSelectedSecurityStandard(e.target.value)}
            >
              {securityStandards?.map(standard => (
                <MenuItem value={standard._id}>{standard.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack spacing={2} mt={4} direction={'row'} justifyContent={'flex-end'}>
          <OutlinedButton onClick={() => window.history.go(-1)}>Cancelar</OutlinedButton>
          <Button style={{ width: '120px' }} onClick={handleSave}>
            Salvar
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
