export const tableHeader = [
  {
    id: 'name',
    type: 'text',
    value: 'Nome',
  },
  {
    id: 'address',
    type: 'text',
    value: 'Endereço',
  },
  {
    id: 'phone',
    type: 'text',
    value: 'Telefone',
  },
  {
    id: 'action',
    type: 'actions',
    value: '',
  },
]
