import { BouncingLoader } from "./styles"

const DotsLoader = (props) => {
    return (
        <BouncingLoader blue={props.blue}>
            <div></div>
            <div></div>
            <div></div>
        </BouncingLoader>
    )
}

export default DotsLoader