import { Column, Row } from 'Components/Grid'
import styled from 'styled-components'

export const RowExhibition = styled(Row)`
  border-bottom: 1px solid #ebecf8;
  min-height: 38px;
`
export const ColumnEdit = styled(Column)`
  display: flex;
`
export const LinkEdit = styled.button`
  background: transparent;
  border: none;
  height: fit-content;
  width: fit-content;
  margin: 0px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-feature-settings: 'liga' off;
  color: #0039c5;
  &:hover {
    cursor: pointer;
  }
`
