import { Column } from "Components/Grid";
import styled from "styled-components";

export const ContainerLogin = styled.div`
    width: calc(100vw - 32px);
    height: 100vh;
`

export const BgLoginColumn = styled(Column)`
    background: url('/img/bg-unauthenticated.png') no-repeat;
    height: 100vh;
    background-size: cover;
    align-items: center;
    position: fixed !important;
    min-width: 50vh !important;
    max-width: 50% !important;
    flex: 0 0 50% !important;
    top: 0 !important;
    #logo {
        max-width: 244px;
        height: 96px;
    }
`

export const ColumnContent = styled(Column)`
    margin-left: 50%
`