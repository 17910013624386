import { Box, FormControl, InputLabel, MenuItem, Select, Skeleton, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, OutlinedButton, Title } from '../styles';
import Header from '../Header';
import { Card } from 'Components/Card';
import { StepperForm } from 'Components/StepperForm';
import FileUpload from 'Components/FileUpload';
import { getControlById } from 'services/controls-service';
import { getAssets } from 'services/assets-service';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import { getProcesses } from 'services/processes-service';
import { getSystems } from 'services/systems-service';
import { getUsers } from 'services/users-service';
import {
  addControlDeployment,
  editControlDeployment,
  getControlDeploymentById,
  getGapAnalysisById,
} from 'services/gap-analysis';
import { formatDateToDateBR } from 'utils/date_utils';

const controlTypes = {
  COMPANY: 'Empresas',
  ASSETS: 'Ativos',
  PROCESS: 'Processos',
  SYSTEM: 'Sistemas',
};
export default function EditControl() {
  const navigate = useNavigate();
  const steps = ['Controle', 'Evidências'];
  const { idControl, idDeploymentLog, id: idGapAnalysis } = useParams();
  const [objects, setObjects] = useState([]);
  const [users, setUsers] = useState([]);
  const selectedTenant = useSelector(useAuthTenant);
  const [gapAnalysis, setGapAnalysis] = React.useState(null);

  const [activeStep, setActiveStep] = useState(0);
  const [finished] = useState(false);
  const [control, setControl] = useState(null);
  const [values, setValues] = React.useState({
    type: '',
    object: '',
    status: '',
    implanter: '',
    validator: '',
    control: idControl,
    gapAnalysis: idGapAnalysis,
    companyKey: selectedTenant.slug,
  });

  const fetchGapAnalysis = React.useCallback(async () => {
    const result = await getGapAnalysisById(idGapAnalysis);
    if (result?.data) {
      setGapAnalysis(result?.data);
    }
  }, [idGapAnalysis]);

  React.useEffect(() => {
    fetchGapAnalysis();
  }, [fetchGapAnalysis]);

  const fetchdeploymentControl = useCallback(async () => {
    const result = await getControlDeploymentById(idDeploymentLog);
    if (result?.data) {
      setValues({
        type: result?.data.type,
        object: result?.data.object.id,
        status: result?.data.status,
        implanter: result?.data.implanter._id,
        validator: result?.data.validator._id,
        control: idControl,
        gapAnalysis: idGapAnalysis,
      });
    }
  }, [idControl, idDeploymentLog, idGapAnalysis]);

  useEffect(() => {
    fetchdeploymentControl();
  }, [fetchdeploymentControl]);

  const fetchControl = useCallback(async () => {
    const result = await getControlById(idControl);
    if (result?.data) {
      setControl(result?.data);
    }
  }, [idControl]);

  useEffect(() => {
    fetchControl();
  }, [fetchControl]);

  const fetchUsers = useCallback(async () => {
    const result = await getUsers(selectedTenant.slug);
    if (result?.data.data) {
      setUsers(result?.data.data);
    }
  }, [selectedTenant.slug]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const fetchObjects = useCallback(async () => {
    const newObjects = [];
    const assets = await getAssets(selectedTenant.slug);
    if (assets.data) {
      assets.data.forEach(asset => {
        newObjects.push({ id: asset._id, name: asset.name, type: 'ASSETS' });
      });
    }
    const processes = await getProcesses(selectedTenant.slug);
    if (processes.data) {
      processes.data.forEach(process => {
        newObjects.push({
          id: process._id,
          name: process.name,
          type: 'PROCESS',
        });
      });
    }
    const systems = await getSystems(selectedTenant.slug);
    if (systems.data) {
      systems.data.forEach(system => {
        newObjects.push({
          id: system._id,
          name: system.name,
          type: 'SYSTEM',
        });
      });
    }
    newObjects.push({
      id: selectedTenant._id,
      name: selectedTenant.name,
      type: 'COMPANY',
    });
    setObjects(newObjects);
  }, [selectedTenant._id, selectedTenant.name, selectedTenant.slug]);

  useEffect(() => {
    fetchObjects();
  }, [fetchObjects]);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() ? activeStep : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handlePrevStep = () => {
    if (activeStep === 0) {
      window.history.go(-1);
    } else {
      handleBack();
    }
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      handleNext();
    } else {
      onSubmit();
    }
  };
  const onSubmit = async () => {
    const newValues = removeObjectEmptyProps(values);

    const result = idDeploymentLog
      ? await editControlDeployment({ ...newValues, _id: idDeploymentLog })
      : await addControlDeployment(newValues);
    if (result.status === 200 || result.status === 201) {
      navigate(`/gap-analysis/${idGapAnalysis}/dashboards`);
    }
  };
  const removeObjectEmptyProps = obj => {
    const filteredEntries = Object.entries(obj).filter(([_, value]) => value !== '');
    return Object.fromEntries(filteredEntries);
  };

  const footer = (
    <Stack spacing={2} mt={4} direction={'row'} justifyContent={'flex-end'}>
      <OutlinedButton onClick={handlePrevStep} style={{ width: '110px' }}>
        {activeStep === 0 ? 'Cancelar' : 'Voltar'}
      </OutlinedButton>
      <Button style={{ width: '110px' }} onClick={handleNextStep}>
        {activeStep === 0 ? 'Próximo' : 'Salvar'}
      </Button>
    </Stack>
  );
  const filteredObjects = objects.filter(object => object.type === values?.type);

  return (
    <Box display={'flex'} flexDirection={'column'} p={4} gap={4}>
      <Header title={'Gap Analysis'} breadcrumb={'Gap Analysis / Padrões/ Controles / Editar'} />

      {gapAnalysis && (
        <Card>
          <Title>{gapAnalysis.company.name}</Title>
          <Stack mt={2}>
            <InfoLabel label={'Padrão'} value={gapAnalysis.securityStandard?.name} />
            <InfoLabel label={'Criação'} value={formatDateToDateBR(gapAnalysis.created_at)} />
            <InfoLabel label={'Funcionário'} value={gapAnalysis.created_by.name} />
          </Stack>
        </Card>
      )}

      {!gapAnalysis && (
        <Card>
          <Title>
            <Skeleton variant="text" width={220} height={40} />
          </Title>
          <Stack mt={2}>
            <InfoLabel label={'Padrão'} value={<Skeleton variant="text" width={180} />} />
            <InfoLabel label={'Criação'} value={<Skeleton variant="text" width={200} />} />
            <InfoLabel label={'Funcionário'} value={<Skeleton variant="text" width={150} />} />
          </Stack>
        </Card>
      )}

      {!finished && <StepperForm activeStep={activeStep} steps={steps} />}

      {steps[activeStep] === 'Controle' && (
        <Card>
          <Typography color="#3C3938" fontWeight={700} fontSize={'16px'}>
            {control?.what}
          </Typography>
          <Stack spacing={3} mt={4}>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Tipo</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                label="Type"
                value={values.type}
                onChange={handleChange('type')}
              >
                {Object.keys(controlTypes).map(key => (
                  <MenuItem value={key}>{controlTypes[key]}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="object-select-label">Objeto</InputLabel>
              <Select
                labelId="object-select-label"
                id="object-select"
                label="Object"
                value={values.object || ''}
                onChange={handleChange('object')}
                disabled={!values.type || objects.length === 0}
              >
                {filteredObjects.map(object => (
                  <MenuItem key={object.id} value={object.id}>
                    {object.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                label="Status"
                value={values.status}
                onChange={handleChange('status')}
              >
                <MenuItem value={'DEPLOYED'}>Implantado</MenuItem>
                <MenuItem value={'NOT_DEPLOYED'}>Não implantado</MenuItem>
                <MenuItem value={'NOT_APPLICABLE'}>Não aplicável</MenuItem>
              </Select>
            </FormControl>
            <Stack direction={'row'} spacing={4}>
              <FormControl fullWidth>
                <InputLabel id="implanter-select-label">Implantador</InputLabel>
                <Select
                  labelId="implanter-select-label"
                  id="implanter-select"
                  label="Implanter"
                  value={values.implanter}
                  onChange={handleChange('implanter')}
                >
                  {users?.map(user => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="validator-select-label">Validador</InputLabel>
                <Select
                  labelId="validator-select-label"
                  id="validator-select"
                  label="Validator"
                  value={values.validator}
                  onChange={handleChange('validator')}
                >
                  {users?.map(user => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            {footer}
          </Stack>
        </Card>
      )}
      {steps[activeStep] === 'Evidências' && (
        <Card>
          <Typography color="#3C3938" fontWeight={700} fontSize={'16px'}>
            Anexe um arquivo de evidência
          </Typography>

          <FileUpload mt={4} />
          {footer}
        </Card>
      )}
    </Box>
  );
}

const InfoLabel = ({ label, value }) => {
  return (
    <Stack spacing={1} direction={'row'} alignItems={'center'}>
      <Typography color="#0039c5" fontWeight={500} fontSize={'14px'}>
        {label}:
      </Typography>
      <Typography color="#6B6664" fontWeight={500} fontSize={'14px'}>
        {value}
      </Typography>
    </Stack>
  );
};
