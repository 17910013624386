import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OutlinedButton, OutlinedButtonSmall } from '../styles';
import Header from '../Header';
import { Card } from 'Components/Card';
import TabList from 'Components/TabList';
import Tab from 'Components/TabList/tab';
import Table from 'Components/Table';
// import ImageIcon from "@mui/icons-material/Image";
import { editControlDeployment, getControlDeploymentById } from 'services/gap-analysis';
import { useSelector } from 'react-redux';
import { useAuthUser } from 'store/auth';
import { DomainValue } from 'Pages/Controls/options';
import { ControlTypes } from '../Dashboards/DashboardTab';
import { Check } from '@mui/icons-material';

export default function ControlDeploymentLogDetails() {
  const { idDeploymentLog } = useParams();
  const [controlDeployment, setControlDeployment] = useState(null);
  const user = useSelector(useAuthUser);
  const { id } = useParams();

  const navigate = useNavigate();

  const fetchdeploymentControl = useCallback(async () => {
    const result = await getControlDeploymentById(idDeploymentLog);
    if (result?.data) {
      setControlDeployment(result?.data);
    }
  }, [idDeploymentLog]);

  useEffect(() => {
    fetchdeploymentControl();
  }, [fetchdeploymentControl]);

  const onUpdateStatus = async newValue => {
    await editControlDeployment({
      ...controlDeployment,
      object: controlDeployment.object.id,
      implanter: controlDeployment.implanter._id,
      validator: controlDeployment.validator?._id,
      status: newValue,
    });
    setControlDeployment({
      ...controlDeployment,
      status: newValue,
    });
  };

  const onValidate = async () => {
    await editControlDeployment({
      ...controlDeployment,
      object: controlDeployment.object.id,
      implanter: controlDeployment.implanter?._id,
      validator: controlDeployment.validator?._id,
      validated: true,
    });
    setControlDeployment({
      ...controlDeployment,
      validated: true,
    });
  };

  // const data = [
  //   {
  //     name: (
  //       <Stack direction={"row"} spacing={1}>
  //         {" "}
  //         <ImageIcon sx={{ color: "#537DE4" }} />{" "}
  //         <Typography fontWeight={700} fontSize={14}>
  //           Nome do arquivo.png
  //         </Typography>
  //       </Stack>
  //     ),
  //     size: "15MB",
  //     uploaded_at: "09/04 14:32",
  //   },
  //   {
  //     name: (
  //       <Stack direction={"row"} spacing={1}>
  //         {" "}
  //         <ImageIcon sx={{ color: "#537DE4" }} />{" "}
  //         <Typography fontWeight={700} fontSize={14}>
  //           Nome do arquivo.png
  //         </Typography>
  //       </Stack>
  //     ),
  //     size: "15MB",
  //     uploaded_at: "09/04 14:32",
  //   },
  //   {
  //     name: (
  //       <Stack direction={"row"} spacing={1}>
  //         {" "}
  //         <ImageIcon sx={{ color: "#537DE4" }} />{" "}
  //         <Typography fontWeight={700} fontSize={14}>
  //           Nome do arquivo.png
  //         </Typography>
  //       </Stack>
  //     ),
  //     size: "15MB",
  //     uploaded_at: "09/04 14:32",
  //   },
  // ];

  return (
    <Box display={'flex'} flexDirection={'column'} p={4} gap={4}>
      <Header
        title={'Implantação de Controle'}
        breadcrumb={`Gap Analysis / Padrões / Controles / ${controlDeployment?.control?.what} `}
        actions={
          <>
            <OutlinedButton
              variant="outlined"
              onClick={() =>
                navigate(
                  `../gap-analysis/${id}/dashboards/control/${controlDeployment.control._id}/deployment-log/${idDeploymentLog}`,
                )
              }
            >
              <img src="/img/icons/bar-chart.svg" alt="Icone gráfico" />
              Editar
            </OutlinedButton>
          </>
        }
      />

      <Card>
        <TabList>
          <Tab label="Detalhes">
            {!controlDeployment && (
              <Stack mt={2}>
                <RowItem label={'Domínio'} value={<Skeleton variant="text" width={200} height={20} />} />
                <RowItem label={'Tipo'} value={<Skeleton variant="text" width={200} height={20} />} />
                <RowItem label={'Objeto'} value={<Skeleton variant="text" width={200} height={20} />} />
                <RowItem label={'Status'} value={<Skeleton variant="text" width={200} height={20} />} />

                <RowItem label={'Implantador'} value={<Skeleton variant="text" width={200} height={20} />} />

                <RowItem label={'Validador'} value={<Skeleton variant="text" width={200} height={20} />} />
              </Stack>
            )}
            {controlDeployment && (
              <Stack mt={2}>
                <RowItem label={'Domínio'} value={DomainValue[controlDeployment.control.domain]} />
                <RowItem label={'Tipo'} value={ControlTypes[controlDeployment.type]} />
                <RowItem label={'Objeto'} value={controlDeployment.object.name} />

                <RowItem
                  label={'Status'}
                  value={
                    controlDeployment.status === 'NOT_APPLICABLE'
                      ? 'Não aplicável'
                      : controlDeployment.status === 'DEPLOYED'
                      ? 'Implantado'
                      : 'Não implantado'
                  }
                />

                <RowItem label={'Implantador'} value={controlDeployment.implanter.name} />

                {controlDeployment.status === 'NOT_DEPLOYED' && user.userId === controlDeployment.implanter._id && (
                  <RowDeploy
                    label={'Implantação'}
                    deployed={controlDeployment.deployed}
                    onDeploy={() => onUpdateStatus('DEPLOYED')}
                  />
                )}

                <RowItem label={'Validador'} value={controlDeployment.validator.name} />

                {controlDeployment.status === 'DEPLOYED' && user.userId === controlDeployment.validator._id && (
                  <RowValidate label={'Validação'} validated={controlDeployment.validated} onValidate={onValidate} />
                )}
              </Stack>
            )}
          </Tab>
          <Tab label="Arquivos">
            {[].length > 0 && (
              <Table
                data={[]}
                textAlign={'left'}
                headers={[
                  { label: 'Nome', key: 'name' },
                  { label: 'Tamanho', key: 'size' },
                  { label: 'Uploaded', key: 'uploaded_at' },
                ]}
              />
            )}
          </Tab>
        </TabList>
      </Card>
    </Box>
  );
}

const RowItem = ({ label, value }) => {
  return (
    <Stack spacing={1}>
      <Box display={'flex'} alignItems={'center'} p={1}>
        <Typography color="#3C3938" fontWeight={700} fontSize={'14px'} width={150}>
          {label}:
        </Typography>

        <Stack spacing={2} direction={'row'}>
          <Typography color="#3C3938" fontWeight={500} fontSize={'14px'}>
            {value}
          </Typography>
        </Stack>
      </Box>
      <Divider />
    </Stack>
  );
};

const RowValidate = ({ label, validated, onValidate }) => {
  return (
    <Stack spacing={1}>
      <Box display={'flex'} alignItems={'center'} p={1}>
        <Typography color="#3C3938" fontWeight={700} fontSize={'14px'} width={150}>
          {label}:
        </Typography>

        <Stack spacing={2} direction={'row'}>
          {validated ? (
            <Typography color="#3C3938" fontWeight={500} fontSize={'14px'}>
              Validado
            </Typography>
          ) : (
            <OutlinedButtonSmall variant="outlined" onClick={onValidate}>
              <Check
                sx={{
                  color: '#537DE4',
                  marginLeft: '-4px',
                  marginRight: '4px',
                }}
              />
              Validar implantação
            </OutlinedButtonSmall>
          )}
        </Stack>
      </Box>
      <Divider />
    </Stack>
  );
};

const RowDeploy = ({ label, deployed, onDeploy }) => {
  return (
    <Stack spacing={1}>
      <Box display={'flex'} alignItems={'center'} p={1}>
        <Typography color="#3C3938" fontWeight={700} fontSize={'14px'} width={150}>
          {label}:
        </Typography>

        <Stack spacing={2} direction={'row'}>
          {deployed ? (
            <Typography color="#3C3938" fontWeight={500} fontSize={'14px'}>
              Implantado
            </Typography>
          ) : (
            <OutlinedButtonSmall variant="outlined" onClick={onDeploy}>
              <Check
                sx={{
                  color: '#537DE4',
                  marginLeft: '-4px',
                  marginRight: '4px',
                }}
              />
              Implantar
            </OutlinedButtonSmall>
          )}
        </Stack>
      </Box>
      <Divider />
    </Stack>
  );
};
