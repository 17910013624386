import styled from "styled-components";

export const StyledTable = styled.table`
  border: 1px solid #f1efee;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  border-collapse: collapse; // Added this to handle border spacing issue
`;
export const TableRow = styled.tr`
  border-bottom: 1px solid #f1efee;
  color: #6b6664;
`;

export const TableCell = styled.td`
  padding: 16px;
  text-align: ${(props) => props.align || "left"};
`;

export const TableHeader = styled.th`
  padding: 16px;
  color: #3c3938;
  text-align: ${(props) => props.align || "left"};
`;

export const GroupHeader = styled.th`
  text-align: left;
  font-weight: bold;
  colspan: 3;
  border-bottom: 1px solid #f1efee;
  padding: 16px;
`;
