import React, { useState } from 'react';
import { Main } from './styles';
import { sideBarData, sideBarDataPartners, sideBarDataSuperAdmin } from '../../route-menus';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuthTenant, useAuthUser } from 'store/auth';
import { hasModulePermission, hasPermission } from 'utils/permission_utils';
import { getTenant } from 'utils/tenant_utils';

export const SideBar = () => {
  const user = useSelector(useAuthUser);
  const selectedTenant = useSelector(useAuthTenant);
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState('');

  const handleMenus = title => {
    if (activeMenu === title) {
      setActiveMenu('');
      return;
    }
    setActiveMenu(title);
  };

  return (
    <Main>
      <img id="logo" src="/img/icons/logo.svg" alt="" />
      <span className="link" onClick={() => navigate('/dashboard')}>
        <img src="/img/icons/home.svg" alt="Ícone de casa" />
        <p>Visão geral</p>
      </span>
      {sideBarData.map(
        (item, i) =>
          hasModulePermission(user.permissions, item.permissions) && (
            <section key={`above-menu-${i}`}>
              <div onClick={() => handleMenus(item.title)}>
                <img src={item.icon} alt={item.iconAlt} />
                {item.children ? (
                  <p>{item.title}</p>
                ) : (
                  <Link to={item.link}>
                    <p>{item.title}</p>
                  </Link>
                )}
                {item.children && <img id="dropdown" src="/img/icons/arrowDown.svg" alt="" />}
              </div>
              {activeMenu === item.title && (
                <section className="wrapper" key={`itens-${item.title}`}>
                  {item.children.map(subItem =>
                    hasPermission(user.permissions, subItem.permission) ? (
                      <Link
                        key={`${subItem.permission}-${subItem.link}`}
                        className="menuLink"
                        id="subitem"
                        to={subItem.link}
                      >
                        {subItem.title}
                      </Link>
                    ) : (
                      <></>
                    ),
                  )}
                </section>
              )}
            </section>
          ),
      )}
      <hr />
      {getTenant() === 'vo' &&
        sideBarDataSuperAdmin.map(
          (item, i) =>
            hasModulePermission(user.permissions, item.permissions) && (
              <section key={`under-menu-${i}`}>
                <div onClick={() => handleMenus(item.title)}>
                  {item.icon}
                  {item.children ? (
                    <p>{item.title}</p>
                  ) : (
                    <Link to={item.link}>
                      <p>{item.title}</p>
                    </Link>
                  )}
                  {item.children && <img id="dropdown" src="/img/icons/arrowDown.svg" alt="" />}
                </div>
                {activeMenu === item.title && (
                  <section className="wrapper" key={`itens-${item.title}`}>
                    {item.children.map(subItem =>
                      hasPermission(user.permissions, subItem.permission) ? (
                        <Link
                          key={`${subItem.permission}-${subItem.link}`}
                          className="menuLink"
                          id="subitem"
                          to={subItem.link}
                        >
                          {subItem.title}
                        </Link>
                      ) : (
                        <></>
                      ),
                    )}
                  </section>
                )}
              </section>
            ),
        )}
      {selectedTenant?.partner === true &&
        sideBarDataPartners.map(
          (item, i) =>
            hasModulePermission(user.permissions, item.permissions) && (
              <section key={`under-menu-${i}`}>
                <div onClick={() => handleMenus(item.title)}>
                  {item.icon}
                  {item.children ? (
                    <p>{item.title}</p>
                  ) : (
                    <Link to={item.link}>
                      <p>{item.title}</p>
                    </Link>
                  )}
                  {item.children && <img id="dropdown" src="/img/icons/arrowDown.svg" alt="" />}
                </div>
                {activeMenu === item.title && (
                  <section className="wrapper" key={`itens-${item.title}`}>
                    {item.children.map(subItem =>
                      hasPermission(user.permissions, subItem.permission) ? (
                        <Link
                          key={`${subItem.permission}-${subItem.link}`}
                          className="menuLink"
                          id="subitem"
                          to={subItem.link}
                        >
                          {subItem.title}
                        </Link>
                      ) : (
                        <></>
                      ),
                    )}
                  </section>
                )}
              </section>
            ),
        )}
      <span className="link">
        <img src="/img/icons/bell.svg" alt="" />
        <p>Notificações</p>
      </span>
      <span className="link">
        <img src="/img/icons/help3.svg" alt="" />
        <p>Ajuda</p>
      </span>
    </Main>
  );
};
