import { backendPrivate } from "services"

export const getAreas = async (tenant) => {
    return await backendPrivate.get(`/area?companyKey=${tenant}`)
}

export const getAreasPaged = async (tenant, page, perPage) => {
    return await backendPrivate.get(`/area/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getAreasShort = async (tenant) => {
    return await backendPrivate.get(`/area/short-data?companyKey=${tenant}`)
}

export const getAreasShortPaged = async (tenant, page, perPage, queryContent) => {
    return await backendPrivate.get(`/area/short-data-paged?companyKey=${tenant}&page=${page}&perPage=${perPage}${queryContent}`)
}

export const getAreasNames = async (tenant) => {
    return await backendPrivate.get(`/area/names?companyKey=${tenant}`)
}

export const getAreaById = async (id) => {
    return await backendPrivate.get(`/area/${id}`)
}

export const getAreaBoundsById = async (id) => {
    return await backendPrivate.get(`/area/${id}/bounds`)
}

export const addArea = async (area) => {
    return await backendPrivate.post('/area', area)
}

export const editArea = async (id, area) => {
    return await backendPrivate.put(`/area/${id}`, area)
}

export const deleteArea = async (id) => {
    return await backendPrivate.delete(`/area/${id}`)
}