import styled from 'styled-components'

export const Main = styled.header`
  * {
    box-sizing: border-box;
  }

  background-color: #f7f9fb;
  box-shadow: 6px 6px 20px #eff1f7;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  height: 80px;
  justify-content: center;
  padding: 0px 30px;
  width: calc( 100vw - 311px) !important;
  max-width: calc( 100vw - 311px) !important;

  @media (max-width: 380px) {
    p {
      font-size: 14px;
    }
  }

  #wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    max-width: 1440px;
    width: 100%;

    div {
      align-items: center;
      display: flex;

      p {
        margin: 0px;
      }

      #menu {
        cursor: pointer;
        margin-right: 8px;
      }

      #photo {
        border-radius: 50%;
        height: 32px;
        margin-right: 16px;
        width: 32px;
      }

      #icon {
        cursor: pointer;
        margin-left: 16px;
        margin-right: 40px;
      }

      button {
        background-color: #537de4;
        border-radius: 12px;
        box-shadow: 0px 6px 20px #eff1f7;
        border: none;
        height: 32px;
        width: 32px;

        img {
          height: 16px;
          width: 20px;
        }
      }
    }
  }
`

export const UserContainerMenu = styled.div`
  &:hover {
    cursor: pointer;
  }
`