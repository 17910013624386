import { FormSuccess } from 'Components/Form/Success';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '../../../Components/Container';
import { Configuration } from './Configuration';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useQuery } from 'react-query';
import { PageLoaderForm } from 'Components/PageLoader/Form';
import { addCookie, editCookie, getCookieById } from 'services/cookies-service';

export const CookiesForm = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const { id } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [idCompanySite, setIdCompanySite] = useState(null);
  const [finished, setFinished] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      activeMultilanguage: false,
      url: '',
      background: '',
      buttonBackground: '',
      font: '',
      positioning: '',
      fixedLang: 'pt',
      showBlurBackground: false,
      permissive: false,
      resourceBlocker: true,
    },
  });

  const formatForm = dados => {
    return {
      companySite: formatCompanySiteForm(dados),
      slug: selectedTenant.slug,
    };
  };

  const formatCompanySiteForm = dados => {
    return {
      url: dados.url,
      customization: {
        colors: {
          background: dados.background,
          buttonBackground: dados.buttonBackground,
          font: dados.font,
        },
        settings: {
          positioning: dados.positioning,
          showCircleWidget: dados.showCircleWidget,
          bannerLogo: 'cookie',
          activeMultilanguage: dados.activeMultilanguage,
          fixedLang: dados.fixedLang,
          showBlurBackground: dados.showBlurBackground,
        },
        advancedSettings: {
          general: {
            showPoweredBy: false,
            finesProtectorActive: true,
          },
          fullyCompliantBanner: {
            iabTcfMode: false,
            permissive: false,
          },
          gdprBanner: {
            iabTcfMode: false,
            resourceBlocker: dados.resourceBlocker,
            permissive: !dados.permissive,
          },
        },
      },
    };
  };

  const handleComplete = async () => {
    try {
      const res = id
        ? await editCookie(id, formatCompanySiteForm(getValues()))
        : await addCookie(formatForm(getValues()));
      if (res.status === 200 || res.status === 201) {
        setIdCompanySite(res.data.companySiteId ?? id);
        setFinished(true);
      } else {
        throw new Error('Ocorreu um erro ao tentar salvar/editar a Cookie');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const handleReset = () => {
    reset();
    setFinished(false);
    setIdCompanySite(null);
    navigate('/cookies/add');
  };

  const {
    isLoading,
    error,
    data: cookieData,
  } = useQuery(['getCookie', id], async () => {
    if (!id) return null;

    const resCookie = await getCookieById(id);

    if (!resCookie.data) return null;

    return resCookie.data;
  });

  useEffect(() => {
    if (!id) return;
    if (!cookieData) return;

    setValue('url', cookieData.url);
    setValue('activeMultilanguage', cookieData.customization.settings.activeMultilanguage);
    setValue('background', cookieData.customization.colors.background);
    setValue('buttonBackground', cookieData.customization.colors.buttonBackground);
    setValue('font', cookieData.customization.colors.font);
    setValue('positioning', cookieData.customization.settings.positioning);
    setValue('fixedLang', cookieData.customization.settings.fixedLang);
    setValue('showBlurBackground', cookieData.customization.settings.showBlurBackground);
    setValue('permissive', !cookieData.customization.advancedSettings?.gdprBanner?.permissive);
    setValue('resourceBlocker', cookieData.customization.advancedSettings?.gdprBanner?.resourceBlocker);

    return () => {};
  }, [id, cookieData, setValue]);

  const showForm = () => {
    if (id) return !isLoading && !error;
    return true;
  };

  return (
    <Container
      title={`${id ? 'Editar' : 'Adicionar'} Cookie e Consentimento`}
      breadcrumb="Adequações / Gestão de Cookies e Consentimento"
      linkPage="/cookies"
    >
      {isLoading && <PageLoaderForm />}
      {showForm() && (
        <>
          <div className="card-content">
            {!finished && (
              <Configuration
                control={control}
                id={id}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                getValues={getValues}
                handleComplete={handleComplete}
              />
            )}
            {finished && (
              <FormSuccess
                title="Cookie cadastrado com sucesso!"
                labelStartAgain="Cadastrar novo cookie"
                handleStartAgain={() => handleReset()}
                labelShow="Ver detalhes"
                handleShow={() => navigate('/cookies/show/' + idCompanySite)}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
};
