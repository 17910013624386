import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { ButtonForm, CustomTextField } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';
import { MuiChipsInput } from 'mui-chips-input';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUsersName } from 'services/users-service';
import { useAuthTenant } from 'store/auth';

export const Detalhes = ({ handleNext, register, control, handleSubmit, errors, getValues, id }) => {
  const selectedTenant = useSelector(useAuthTenant);
  const navigate = useNavigate();
  const onSubmit = () => {
    handleNext();
  };

  const { data: users } = useQuery(['getUsers'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getUsersName(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <CustomTextField
            {...register('name', { required: 'Nome é obrigatório' })}
            label="Nome"
            autoFocus
            error={errors?.name ? true : false}
            sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
            helperText={errors?.name ? errors.name.message : null}
            InputLabelProps={{ shrink: true }}
            disabled={!!id}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues('criticity') !== '';
              },
              required: 'Criticidade é obrigatório',
            }}
            name="criticity"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ marginBottom: '24px' }} error={errors?.criticity ? true : false}>
                <InputLabel id="criticity-label" shrink={true}>
                  Criticidade
                </InputLabel>
                <Select
                  labelId="criticity-label"
                  id="criticity"
                  value={value}
                  notched
                  label="Criticidade"
                  onChange={onChange}
                >
                  <MenuItem value="" key={-1}>
                    Selecione uma criticidade
                  </MenuItem>
                  {['1', '2', '3', '4', '5'].map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.criticity ? errors.criticity.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="type"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                <InputLabel id="type-label" shrink={true}>
                  Tipo
                </InputLabel>
                <Select labelId="type-label" id="type" value={value} notched label="Tipo" onChange={onChange}>
                  <MenuItem value="" key={-1}>
                    Selecione uma tipo
                  </MenuItem>
                  {['Pessoa', 'Tecnologia', 'Ambiente'].map(item => (
                    <MenuItem value={item} key={`type-${item}`}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues('user') !== '';
              },
              required: 'Responsável é obrigatório',
            }}
            name="user"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ marginBottom: '24px' }} error={errors?.user ? true : false}>
                <InputLabel id="user-label" shrink={true}>
                  Responsável
                </InputLabel>
                <Select labelId="user-label" id="user" value={value} notched label="Responsável" onChange={onChange}>
                  <MenuItem value="" key={-1}>
                    Selecione um responsável
                  </MenuItem>
                  {users
                    ?.map(user => user.name)
                    .map((item, index) => (
                      <MenuItem value={item} key={`user-${index}`}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{errors?.user ? errors.user.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
            <Controller
              control={control}
              name="tags"
              render={({ field: { onChange, value }, fieldState }) => (
                <MuiChipsInput
                  label="Tags"
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Digite e aperte enter"
                  renderChip={(Component, props) => {
                    return (
                      <Component
                        {...props}
                        variant="outlined"
                        color="primary"
                        sx={{
                          width: 'fit-content',
                        }}
                      />
                    );
                  }}
                  sx={{
                    '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled': {
                      width: 'fit-content',
                    },
                    '& .MuiButtonBase-root.MuiIconButton-root': {
                      width: 'fit-content',
                      marginTop: '0px',
                    },
                  }}
                />
              )}
            ></Controller>
          </FormControl>
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <CustomTextField
            {...register('location')}
            label="Local"
            sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="pii"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                value={value}
                control={<Checkbox checked={value} onChange={onChange} name="pii" sx={{ paddingLeft: 0 }} />}
                label="PII"
                sx={{ marginLeft: 0, marginBottom: '16px' }}
              />
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <TextField
            {...register('description', {
              required: 'Descrição é obrigatório',
            })}
            label="Descrição"
            multiline
            rows={4}
            error={errors?.description ? true : false}
            helperText={errors?.description ? errors.description.message : null}
            sx={{ margin: '0 auto 24px', width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="cancel" label="cancelar" onClick={() => navigate('/assets')}></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label="Avançar" variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
