import { Box, Chip } from "@mui/material";
import { Container } from "Components/Container";
import { Column } from "Components/Grid";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ColumnEdit, LinkEdit, RowExhibition } from "./styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getUserById } from "services/users-service";
import { getCompanyRolesBySlug } from "services/company-service";
import { PageLoaderShow } from "Components/PageLoader/Show";
import { useSelector } from "react-redux";
import { useAuthTenant } from "store/auth";

export const UsersShow = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const selectedTenant = useSelector(useAuthTenant);

  const {
    isLoading,
    error,
    data: user,
  } = useQuery("getUserById", async () => {
    const dataUser = await getUserById(id);

    return dataUser.status === 200 ? dataUser.data : null;
  });
  const {
    isLoading: loadingRoles,
    error: errorRoles,
    data: roles,
  } = useQuery("getRoleCompanyBySlug", async () => {
    if (selectedTenant.slug === undefined) return [];
    const dataRoles = await getCompanyRolesBySlug(selectedTenant.slug);

    return dataRoles.status === 200 ? dataRoles.data : null;
  });

  const onClickEdit = () => {
    navigate("/users/edit/" + id);
  };

  return (
    <>
      {!isLoading && !error && (
        <Container title={`${user?.name}`} breadcrumb={`Gerenciar / Users / ${user?.name}`} linkPage="/users">
          <Box sx={{ width: "100%" }} className="container">
            <Box sx={{ p: 3 }}>
              <RowExhibition>
                <Column xs={3}>Nome</Column>
                <Column>{user.name}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: "#0039C5" }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Email</Column>
                <Column>{user.email}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: "#0039C5" }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Telefone</Column>
                <Column>{user.cellphone}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: "#0039C5" }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Permissão</Column>
                <Column>
                  {!loadingRoles &&
                    !errorRoles &&
                    user.permissions
                      ?.filter(permission => permission.companyKey === selectedTenant.slug)
                      .map(permission => (
                        <Chip
                          key={`${permission.companyKey}`}
                          label={roles.find(role => role.roleKey === permission.roleKey)?.roleName}
                          variant="outlined"
                          color="primary"
                          sx={{
                            width: "fit-content",
                          }}
                        />
                      ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: "#0039C5" }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Ativo</Column>
                <Column>{user.active ? "Sim" : "Não"}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: "#0039C5" }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Grupo</Column>
                <Column>
                  {user.groups
                    ?.filter(group => group.companyKey === selectedTenant.slug)
                    .map(group => (
                      <Chip
                        key={group.name}
                        label={group.name}
                        variant="outlined"
                        color="primary"
                        sx={{
                          width: "fit-content",
                        }}
                      />
                    ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: "#0039C5" }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </Box>
          </Box>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  );
};
