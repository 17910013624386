import { Container } from 'Components/Container';
import { useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import React from 'react';

import { getSecurityStandardById, getSecurityStandardControls } from 'services/security-standard-service';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const SecurityStandardDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(0);
  const [selectedRequisites, setSelectedRequisites] = useState([]);

  const fetchSecurityStandard = useCallback(async () => {
    if (id) {
      setIsLoading(true);
      const result = await getSecurityStandardById(id);
      if (result?.data) {
        setData(result?.data);
      }
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchSecurityStandard();
  }, [fetchSecurityStandard]);

  const fetchSecurityStandardControls = useCallback(async () => {
    if (id) {
      const result = await getSecurityStandardControls(id);
      if (result?.data) {
        const distinctRequisites = [];
        result?.data
          .filter(item => item.requisite)
          .forEach(element => {
            if (distinctRequisites.map(item => item._id).includes(element.requisite?._id)) return;
            else distinctRequisites.push(element.requisite);
          });

        const requisites = distinctRequisites.map(req => ({
          ...req,
          controls: result?.data.filter(item => item.requisite?._id === req._id).map(item => item.control),
        }));
        setSelectedRequisites(requisites);
      }
      return result?.data;
    }
    return null;
  }, [id]);

  useEffect(() => {
    fetchSecurityStandardControls();
  }, [fetchSecurityStandardControls]);

  return (
    <Container title="Padrões" breadcrumb="Configurações gerais / Padrão" linkPage="/security-standards">
      {!isLoading && (
        <div className="container">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={active}>
              <Tab
                label="Detalhes"
                id="tab-details"
                aria-controls="tabpanel-details"
                style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                onClick={() => setActive(0)}
              />
              <Tab
                label="Controles"
                id="tab-details"
                aria-controls="tabpanel-details"
                style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                onClick={() => setActive(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={active} index={0}>
            <DetailItem name="Nome" value={data?.name} />
            <DetailItem name="Versão" value={data?.version} />
            <DetailItem name="Descrição" value={data?.description} />
          </TabPanel>
          <TabPanel value={active} index={1}>
            <Stack spacing={2} p={2}>
              {selectedRequisites.map((requisite, index) => {
                return (
                  <Box key={index} borderRadius={'4px'} border={'1px solid #BDB9B7 '}>
                    <Box display={'flex'} borderBottom={' 1px solid #BDB9B7 '} alignItems={'center'} p={2}>
                      <Typography width={'30%'} color={'#3C3938'} fontWeight={700} flex={1}>
                        {requisite.name}
                      </Typography>
                    </Box>
                    {requisite.controls.map((item, index) => {
                      return (
                        <>
                          <Box key={index} display={'flex'} p={'8px 16px'}>
                            <Typography flex={1}>{item.what}</Typography>
                          </Box>
                          <Divider />
                        </>
                      );
                    })}
                  </Box>
                );
              })}
            </Stack>
          </TabPanel>
        </div>
      )}
    </Container>
  );
};

const DetailItem = ({ name, value, isArray }) => {
  return (
    <Box display={'flex'} borderBottom={'1px solid lightGray'} pt={2} pb={2} width={'100%'}>
      <Box sx={{ width: '30%', fontWeight: 'bold' }}>
        <span>{name}</span>
      </Box>
      {!isArray && (
        <Box maxWidth={'70%'}>
          <span>{value}</span>
        </Box>
      )}
      {value && isArray && (
        <Box display={'flex'} maxWidth={'70%'} gap={1}>
          {value.map(i => (
            <Box
              key={i}
              display={'flex'}
              alignItems={'center'}
              whiteSpace={'nowrap'}
              justifyContent={'start'}
              ml={'-10px'}
            >
              <Checkbox checked disabled />
              <span>{i.what}</span>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
