import React from 'react';
import { Handle, Position } from 'reactflow';
import PropTypes from 'prop-types';
import { Main } from './styles';


export const MappingCard = ({data}) => {
  const { area, name, criticity, last } = data;

  return (
    <Main criticity={criticity} style={{
        marginInline: 'auto',
        border: '1px solid #ccc',
        backgroundColor: '#fff',
      }}>
      <Handle type="target" position={Position.Top} />
      <h1>{area}</h1>
      <h2>{name}</h2>
      <hr/>
      <section>
        <p>Criticidade</p>
        <div>
          <div id='color' className={criticity < 3 ? 'low' : criticity > 3 ? 'high' : 'medium'}/>
          <span>{criticity}</span>
        </div>
      </section>
      {last ===undefined && (<Handle type="source" position={Position.Bottom} id="a" />)}
    </Main>
  );
};

MappingCard.propTypes = {
  area: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  criticity: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
};
