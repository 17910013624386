import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  height: 100vh;
  @media(max-width: 720px){
    aside {
      display: none;
    }
  }

`;

export const SecondColumn = styled.div`
  background-color: #F7F9FB;
  display: flex;
  flex-direction: column;
  width: calc( 100vw - 311px);
  max-width: calc( 100vw - 311px);
  min-height: 100vh;
  margin-left: 294px;
  position: absolute;

  footer{
    margin-top: auto;
  }

  @media(max-width: 720px){
    width: 100vw;
  }
`