import { useState } from 'react';
import { useQuery } from 'react-query';
/** Components */
import { DefaultTable } from 'Components/DefaultTable';
import { Container } from '../../Components/Container';
import { Information } from 'Components/Information';
import { tableHeader } from './table-header.js';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { PageLoaderList } from 'Components/PageLoader/List';
/** State Managers */
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
/** Services */
import { deleteUsersGroup, getUsersGroupsPaged } from 'services/users-groups-service';
/** Third Library Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { NoData } from 'Components/NoData';
import { Pagination, Stack } from '@mui/material';

export const UsersGroups = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const [open, setOpen] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const {
    isLoading,
    error,
    data: pagedUsersGroups,
  } = useQuery(['getUsersGroups', reFetch, page], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getUsersGroupsPaged(selectedTenant.slug, page, 15);
    if (result?.data.data?.length > 0) {
      return result?.data;
    }
    return {
      data: [],
      page,
      totalPages: 0,
    };
  });

  const onDelete = item => {
    setItemToDelete(item);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      const result = await deleteUsersGroup(itemToDelete?._id);
      if (result.status === 204) {
        onClose();
        setRefetch(!reFetch);
        SwalReact.fire({
          title: 'Sucesso!',
          text: 'Grupo de usuário removido com sucesso',
          icon: 'success',
        });
      } else {
        throw new Error('Ocorreu um erro ao tentar remover o Grupo de Usuário!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  return (
    <Container
      title="Grupos de usuários"
      breadcrumb="Gerenciar / Grupos de usuários"
      showButtonAdd
      linkPage="/users-groups"
    >
      <Information
        title="Grupos de Usuários"
        description={`Organize os usuários em grupos, esses grupos serão utilizados para o envio das políticas.`}
        style={{
          marginTop: '24px',
        }}
      />
      {isLoading && <PageLoaderList></PageLoaderList>}
      {error && <div>Ocorreu um erro ...</div>}
      {!isLoading && !error && pagedUsersGroups.data?.length === 0 && (
        <NoData description={'Não há grupo de usuários para exibir'} />
      )}
      {!isLoading && !error && pagedUsersGroups.data?.length > 0 && (
        <div className="container">
          <DefaultTable rows={tableHeader} cells={pagedUsersGroups.data} onClickDelete={onDelete} link="users-groups" />
          <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginTop: '16px' }}>
            <Pagination
              count={pagedUsersGroups.totalPages}
              onChange={(_, selectedPage) => {
                setPage(selectedPage);
              }}
              color="primary"
              page={page}
              showFirstButton
              showLastButton
            />
          </Stack>
          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja excluir <strong>{` ${itemToDelete?.name} `}</strong> como Grupo?
              </>
            }
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
          />
        </div>
      )}
    </Container>
  );
};
