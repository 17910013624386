import { Box, Stack } from "@mui/material";
import React from "react";
import { Subtitle, Title } from "../styles";

export default function Header({ title, breadcrumb, actions }) {
  return (
    <Box bgcolor={"#fff"} p={3} borderRadius={2}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack spacing={1}>
          <Title>{title}</Title>
          <Subtitle>{breadcrumb}</Subtitle>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          {actions && actions}
        </Stack>
      </Box>
    </Box>
  );
}
