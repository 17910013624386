import { Container } from 'Components/Container';
import { TitleForm } from 'Components/Form/styles';
import { Row } from 'Components/Grid';
import { useNavigate } from 'react-router-dom';
import { GenerateNewReportRopa } from './Details';
import { FormSuccess } from 'Components/Form/Success';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { addRopa } from 'services/report-ropa-service';

export const ReportsRopaForm = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [finished, setFinished] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      scope: '',
      areaId: '',
      processId: '',
      companyKey: selectedTenant.slug,
    },
  });

  const handleComplete = async () => {
    try {
      const res = await addRopa(getValues());
      if (res.status === 200 || res.status === 201) {
        setFinished(true);
      } else {
        throw new Error('Ocorreu um erro ao tentar salvar/editar o Escritório!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        html: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const handleReset = () => {
    reset();
    setFinished(false);
  };

  return (
    <Container title={`Gerar Novo Relatório`} breadcrumb="Gerenciar / Escritórios" linkPage="/reports/ropa">
      <div className="card-content">
        <Row>
          <TitleForm>Gerar Novo Relatório</TitleForm>
        </Row>
        {!finished && (
          <GenerateNewReportRopa
            control={control}
            register={register}
            handleComplete={handleComplete}
            handleSubmit={handleSubmit}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
            selectedTenant={selectedTenant}
          />
        )}
        {finished && (
          <FormSuccess
            title="Relatório Ropa cadastrado com sucesso!"
            labelStartAgain="Cadastrar Novo Relatório Ropa"
            handleStartAgain={() => handleReset()}
            labelShow="Ok"
            handleShow={() => navigate('/reports/ropa')}
          />
        )}
      </div>
    </Container>
  );
};
