import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
/** Components */
import { Card } from 'Components/Card';
import { Column, Row } from 'Components/Grid';
import { Container } from 'Components/Container';
import { CustomTextField } from 'Components/Form/styles';
import { DefaultTable } from 'Components/DefaultTable';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { NoData } from 'Components/NoData';
import { PageLoaderList } from 'Components/PageLoader/List';
import { tableHeader } from './table-header';
/** Services */
import { deletePolicy, getPoliciesPaged } from 'services/policies-service';
/** State Managers */
import { useAuthTenant, useAuthUser } from 'store/auth';
import { useSelector } from 'react-redux';
/** Third Lib Modal */
import { Button, Pagination, Stack } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

export const Policies = () => {
  const user = useSelector(useAuthUser);
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const [open, setOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const { register, reset, watch } = useForm({
    defaultValues: {
      searchContent: '',
    },
  });
  const getContentSearch = () => {
    if (!watch('searchContent')) return '';

    return watch('searchContent');
  };
  const {
    isLoading,
    error,
    data: pagedPolicies,
  } = useQuery(['getPolicy', refetch, page], async () => {
    const result = await getPoliciesPaged(selectedTenant.slug, page, 15, getContentSearch());
    if (result?.data.data.length > 0) {
      return result?.data;
    }
    return {
      data: [],
      page,
      totalPages: 0,
    };
  });

  const onDelete = item => {
    setItemToDelete(item);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      const result = await deletePolicy(itemToDelete?._id);
      if (result.status === 204) {
        onClose();
        setRefetch(!refetch);
        SwalReact.fire({
          title: 'Sucesso!',
          text: 'Politica removida com sucesso',
          icon: 'success',
        });
      } else {
        throw new Error('Ocorreu um erro ao tentar remover a Política!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };
  return (
    <Container title="Políticas" breadcrumb="Adequações / Políticas" showButtonAdd linkPage="/policies">
      {isLoading && <PageLoaderList></PageLoaderList>}
      {error && <div>Ocorreu um erro ... {error.message}</div>}
      {!isLoading && !error && pagedPolicies.data?.length === 0 && (
        <>
          <Card className="mb-2 mt-3">
            <Row>
              <Column xs={12} md={8}>
                <CustomTextField
                  {...register('searchContent')}
                  label="Buscar por nome"
                  sx={{
                    minHeight: '40px',
                    maxHeight: '40px',
                    margin: '0 auto',
                    width: '100%',
                    ' > div': {
                      minHeight: '40px',
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    reset();
                    setRefetch(!refetch);
                  }}
                >
                  Limpar
                </Button>
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  endIcon={<SearchOutlinedIcon />}
                  disabled={watch('searchContent') === ''}
                  onClick={() => {
                    setRefetch(!refetch);
                  }}
                >
                  Filtrar
                </Button>
              </Column>
            </Row>
          </Card>
          <NoData description={'Não há Políticas para exibir'} />
        </>
      )}
      {!isLoading && !error && pagedPolicies.data?.length > 0 && (
        <div className="container">
          <Card className="mt-3">
            <Row>
              <Column xs={12} md={8}>
                <CustomTextField
                  {...register('searchContent')}
                  label="Buscar por nome"
                  sx={{
                    minHeight: '40px',
                    maxHeight: '40px',
                    margin: '0 auto',
                    width: '100%',
                    ' > div': {
                      minHeight: '40px',
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    reset();
                    setRefetch(!refetch);
                  }}
                >
                  Limpar
                </Button>
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  endIcon={<SearchOutlinedIcon />}
                  disabled={watch('searchContent') === ''}
                  onClick={() => {
                    setRefetch(!refetch);
                  }}
                >
                  Filtrar
                </Button>
              </Column>
            </Row>
          </Card>
          <DefaultTable
            rows={tableHeader}
            cells={pagedPolicies.data}
            userId={user.userId}
            onClickDelete={onDelete}
            link="policies"
          />
          <Stack
            sx={{
              justifyContent: 'center',
              flexDirection: 'row',
              marginTop: '16px',
            }}
          >
            <Pagination
              count={pagedPolicies.totalPages}
              onChange={(_, selectedPage) => {
                setPage(selectedPage);
              }}
              color="primary"
              page={page}
              showFirstButton
              showLastButton
            />
          </Stack>
          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja excluir <strong>{` ${itemToDelete?.name} `}</strong> como ? de negócio?
              </>
            }
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
          />
        </div>
      )}
    </Container>
  );
};
