import { backendPrivate } from "services";

export const getControls = async (tenant) => {
  return await backendPrivate.get(`/control`);
};

export const getControlById = async (id) => {
  return await backendPrivate.get(`/control/${id}`);
};

export const addControl = async (data) => {
  return await backendPrivate.post("/control", data);
};

export const editControl = async (id, data) => {
  return await backendPrivate.put(`/control/${id}`, data);
};

export const deleteControl = async (id) => {
  return await backendPrivate.delete(`/control/${id}`);
};

export const getControlSecurityStandards = async (id) => {
  return await backendPrivate.get(`/control/${id}/standards`);
};
