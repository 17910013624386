const { TextField, Select, InputLabel, FormControl, MenuItem, FormHelperText } = require('@mui/material');
const { TitleForm, ButtonForm } = require('Components/Form/styles');
const { Row, Column } = require('Components/Grid');
const { Controller } = require('react-hook-form');
const { useQuery } = require('react-query');
const { useSelector } = require('react-redux');
const { getUsersNameEmailByCompany } = require('services/users-service');
const { useAuthTenant, useAuthUser } = require('store/auth');
const { getSelectedObject } = require('utils/select_utils');
const { default: PolicyEditor } = require('./Editor');
const { default: DotsLoader } = require('Components/DotsLoader');

export const VersionFormComponent = ({
  control,
  errors,
  getValues,
  handleBack,
  handleComplete,
  handleSubmit,
  id,
  isLoading,
  register,
}) => {
  const selectedTenant = useSelector(useAuthTenant);
  const user = useSelector(useAuthUser);

  const { data: users } = useQuery(['getUsers'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getUsersNameEmailByCompany(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  const getDefaultAuthor = (author, filterAuthor) => {
    if (!id) {
      const resultFilter = filterAuthor?.find(filter => filter?.email === author?.email);
      return !!resultFilter ? resultFilter : '';
    }
    return '';
  };

  const onSubmit = () => {
    handleComplete();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center">
        <Column xs={6} className="d-flex">
          <TextField
            {...register(`version`, {
              required: 'Versão é obrigatório',
            })}
            label="Versão"
            autoFocus
            error={errors?.version ? true : false}
            sx={{
              minHeight: '48px',
              margin: '0 auto 24px',
              width: '100%',
            }}
            helperText={errors?.version ? errors?.version.message : null}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
        <Column xs={6} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues(`author`) !== '';
              },
              required: 'Autor é obrigatório',
            }}
            name={`author`}
            render={({ field: { onChange, value }, fieldState }) => (
              <FormControl fullWidth sx={{ marginBottom: '24px' }} error={fieldState.error ? true : false}>
                <InputLabel id="autor" shrink={true}>
                  Autor
                </InputLabel>
                <Select
                  id="autor"
                  value={getSelectedObject(users, value) ?? ''}
                  defaultValue={getDefaultAuthor(user, users)}
                  notched
                  label="Autor"
                  onChange={onChange}
                >
                  <MenuItem value={''} key={`author--1`}>
                    Selecione um autor
                  </MenuItem>
                  {users?.map((item, i) => (
                    <MenuItem value={item} key={`author-${i}`}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={6} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues(`reviewer`) !== '';
              },
              required: 'revisor é obrigatório',
            }}
            name={`reviewer`}
            render={({ field: { onChange, value }, fieldState }) => (
              <FormControl fullWidth sx={{ marginBottom: '24px' }} error={fieldState.error ? true : false}>
                <InputLabel id="revisor" shrink={true}>
                  Revisor
                </InputLabel>
                <Select
                  id="revisor"
                  value={getSelectedObject(users, value) ?? ''}
                  notched
                  label="Revisor"
                  onChange={onChange}
                >
                  <MenuItem value="" key={`reviewer--1`}>
                    Selecione um revisor
                  </MenuItem>
                  {users?.map((item, i) => (
                    <MenuItem value={item} key={`reviewer-${i}`}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
        <Column xs={6} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues(`approver`) !== '';
              },
              required: 'Aprovador é obrigatório',
            }}
            name={`approver`}
            render={({ field: { onChange, value }, fieldState }) => (
              <FormControl fullWidth sx={{ marginBottom: '24px' }} error={fieldState.error ? true : false}>
                <InputLabel id="aprovador" shrink={true}>
                  Aprovador
                </InputLabel>
                <Select
                  id="aprovador"
                  value={getSelectedObject(users, value) ?? ''}
                  notched
                  label="Aprovador"
                  onChange={onChange}
                >
                  <MenuItem value="" key={`approver--1`}>
                    Selecione um aprovador
                  </MenuItem>
                  {users?.map((item, i) => (
                    <MenuItem value={item} key={`approver-${i}`}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={6} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues(`status`) !== '';
              },
              required: 'Status é obrigatório',
            }}
            name={`status`}
            render={({ field: { onChange, value }, fieldState }) => (
              <FormControl fullWidth sx={{ marginBottom: '24px' }} error={fieldState.error ? true : false}>
                <InputLabel id="status" shrink={true}>
                  Status
                </InputLabel>
                <Select id="status" value={value ?? ''} notched label="Status" onChange={onChange}>
                  <MenuItem value="" key={`status--1`}>
                    Selecione um status
                  </MenuItem>
                  {['Iniciado', 'Concluído'].map((item, i) => (
                    <MenuItem value={item} key={`status-${i}`}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues(`policyText`) !== '';
              },
              required: 'Texto da política é obrigatório',
            }}
            name={`policyText`}
            render={({ field: { onChange, value }, fieldState }) => (
              <FormControl
                fullWidth
                sx={{
                  margin: '0 auto px',
                  '& .ql-picker-label > svg': {
                    height: '18px',
                    width: '18px',
                  },
                  '& .ql-container.ql-snow': {
                    minHeight: '200px',
                  },
                }}
                error={fieldState.error ? true : false}
              >
                <Row>
                  <TitleForm>Texto da Versão</TitleForm>
                </Row>
                <PolicyEditor value={value} onChange={onChange} />

                <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center" className="mt-3">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="cancel" label="cancelar" onClick={handleBack} />
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label={isLoading ? <DotsLoader /> : 'Salvar'} variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
