import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ButtonForm, CustomTextField, LinkButton } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useQueries, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetsNames } from 'services/assets-service';
import { getDatas } from 'services/datas-service';
import {
  addProcessDataMapping,
  editProcessDataMapping,
  getProcessMappingByIdAndMappingId,
} from 'services/processes-service';
import { getSystemsNames } from 'services/systems-service';
import { useAuthTenant } from 'store/auth';
/** Third Lib Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { PageLoaderForm } from 'Components/PageLoader/Form';
import { useEffect, useState } from 'react';
import { Container } from 'Components/Container';
import { FormSuccess } from 'Components/Form/Success';
import { legalBases, treatments } from './helpers';
import { InputLoader } from 'Components/PageLoader/Input';

export const DataMapping = () => {
  const { id, mappingId } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [finished, setFinished] = useState(false);
  const selectedTenant = useSelector(useAuthTenant);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      category: '',
      dataSource: '',
      holderProfile: '',
      retentionPeriod: '',
      storage: '',
      dataProcessing: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dataProcessing',
  });

  const handleAddDataProcessing = () => {
    append({
      processing: '',
      shareType: '',
      legalBase: '',
      complementLegalBasis: '',
      finality: '',
      position: '',
      tool: '',
      observation: '',
      destiny: '',
    });
  };

  const formatForm = dados => {
    return {
      name: dados.name,
      category: dados.category,
      dataSource: dados.dataSource,
      holderProfile: dados.holderProfile,
      retentionPeriod: dados.retentionPeriod,
      storage: dados.storage,
      dataProcessing: dados.dataProcessing.length > 0 ? dados.dataProcessing : [],
    };
  };

  const onSubmit = async () => {
    try {
      const formattedForm = formatForm(getValues());
      const res = mappingId
        ? await editProcessDataMapping(id, mappingId, formattedForm)
        : await addProcessDataMapping(id, formattedForm);

      if (res.status === 200) {
        setFinished(true);
      } else {
        throw new Error('Ocorreu um erro ao tentar salvar/editar o Processo!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const handleReset = () => {
    reset();
    navigate(`/processes/show/${id}`);
  };

  const queries = useQueries([
    {
      queryKey: ['getSystemsNames'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getSystemsNames(selectedTenant.slug);
        return result?.data.length > 0 ? result?.data : [];
      },
    },
    {
      queryKey: ['getAssetsNames'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getAssetsNames(selectedTenant.slug);
        return result?.data.length > 0 ? result?.data : [];
      },
    },
    {
      queryKey: ['getDatas'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getDatas(selectedTenant.slug);
        return result?.data.length > 0 ? result?.data : [];
      },
    },
  ]);

  const [
    { data: systems, isLoading: systemLoading, isError: systemError },
    { data: assets, isLoading: assetLoading, isError: assetError },
    { data: datas, isLoading: dataLoading, isError: dataError },
  ] = queries;

  const {
    isLoading,
    error,
    data: dataMapping,
  } = useQuery(['getProcessMapping', id, mappingId], async () => {
    if (!id) return null;
    if (!mappingId) return null;

    const resProcessMapping = await getProcessMappingByIdAndMappingId(id, mappingId);

    if (!resProcessMapping.data) return null;

    return resProcessMapping.data;
  });

  useEffect(() => {
    if (!id) return;
    if (!mappingId) return;
    if (!dataMapping) return;

    Object.keys(dataMapping).forEach(key => {
      setValue(key, dataMapping[key]);
    });

    return () => {};
  }, [id, mappingId, dataMapping, setValue]);
  const processing = watch('dataProcessing');

  return (
    <Container
      title={`${mappingId ? 'Editar' : 'Adicionar'} Dado do Processo`}
      breadcrumb="Mapeamento / Processo / Dados"
      linkPage={`/processes/show/${id}`}
    >
      <div
        className="card-content"
        style={{
          width: '80%',
        }}
      >
        {!error && !isLoading && !finished && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Row>
                <Column className="mb-3 font-weight-bold">dado#{mappingId ? mappingId + 1 : 1}</Column>
              </Row>
              <Row justifyContent="center">
                <Column xs={12} sm={6} flex>
                  {!dataLoading && !dataError && (
                    <Controller
                      control={control}
                      rules={{
                        validate: () => {
                          return getValues(`name`) !== '';
                        },
                        required: 'Dado é obrigatório',
                      }}
                      name={`name`}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <FormControl fullWidth sx={{ marginBottom: '24px' }} error={fieldState.error ? true : false}>
                          <InputLabel id={`name-label`} shrink>
                            Dado
                          </InputLabel>
                          <Select
                            labelId={`name-label`}
                            id={`name`}
                            value={value}
                            label="Nome"
                            onChange={onChange}
                            notched
                          >
                            <MenuItem value="" key={-1}>
                              Selecione um dado
                            </MenuItem>
                            {datas?.map(item => (
                              <MenuItem value={item.name} key={item._id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  )}
                  {dataLoading && !dataError && (
                    <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                      <InputLabel id="name-label" shrink={true}>
                        Dado
                      </InputLabel>
                      <InputLoader />
                    </FormControl>
                  )}
                </Column>
                <Column xs={12} sm={6} flex>
                  <Controller
                    control={control}
                    name={`category`}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                        <InputLabel id={`category-label`} shrink>
                          Categoria
                        </InputLabel>
                        <Select
                          labelId={`category-label`}
                          id={`category`}
                          value={value}
                          label="Categoria"
                          onChange={onChange}
                          notched
                        >
                          <MenuItem value="" key={-1}>
                            Selecione uma categoria
                          </MenuItem>
                          {['Dados pessoais', 'Dados pessoais sensíveis'].map((item, index) => (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Column>
              </Row>
              <Row justifyContent="center">
                <Column xs={12} sm={6} flex>
                  <CustomTextField
                    {...register(`dataSource`, { required: 'Origem dos dados é obrigatório' })}
                    label="Origem dos dados"
                    autoFocus
                    error={errors?.dataSource ? true : false}
                    sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                    helperText={errors?.dataSource ? errors.dataSource.message : null}
                    InputLabelProps={{ shrink: true }}
                  />
                </Column>
                <Column xs={12} sm={6} flex>
                  <CustomTextField
                    {...register(`holderProfile`, { required: 'Perfil do titular é obrigatório' })}
                    label="Perfil do titular"
                    autoFocus
                    error={errors?.holderProfile ? true : false}
                    sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                    helperText={errors?.holderProfile ? errors.holderProfile.message : null}
                    InputLabelProps={{ shrink: true }}
                  />
                </Column>
              </Row>
              <Row justifyContent="center">
                <Column xs={12} sm={6} flex>
                  <CustomTextField
                    {...register(`retentionPeriod`)}
                    label="Período de retenção"
                    autoFocus
                    sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Column>
                <Column xs={12} sm={6} flex>
                  {!systemLoading && !systemError && !assetLoading && !assetError && (
                    <Controller
                      control={control}
                      rules={{
                        validate: () => {
                          return getValues(`storage`) !== '';
                        },
                        required: 'Armazenamento é obrigatório',
                      }}
                      name={`storage`}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <FormControl fullWidth sx={{ marginBottom: '24px' }} error={fieldState.error ? true : false}>
                          <InputLabel id={`storage-label`} shrink>
                            Armazenamento
                          </InputLabel>
                          <Select
                            labelId={`storage-label`}
                            id={`storage`}
                            value={value}
                            label="Armazenamento"
                            onChange={onChange}
                            notched
                          >
                            <MenuItem value="" key={-1}>
                              Selecione um armazenamento
                            </MenuItem>
                            {systems?.map(item => (
                              <MenuItem value={item.name} key={item._id}>
                                {item.name}
                              </MenuItem>
                            ))}
                            {assets?.map(item => (
                              <MenuItem value={item.name} key={item._id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  )}
                  {(systemLoading || assetLoading) && !systemError && !assetError && (
                    <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                      <InputLabel id="storage-label" shrink={true}>
                        Armazenamento
                      </InputLabel>
                      <InputLoader />
                    </FormControl>
                  )}
                </Column>
              </Row>
              <Row>
                <Column>
                  {fields?.map((itemProcess, indexProcess) => {
                    return (
                      <div key={`${itemProcess.processing}-${indexProcess}`}>
                        <Row>
                          <Column className="mb-2">
                            <hr />
                          </Column>
                        </Row>
                        <Row>
                          <Column xs={6} className="mb-3">
                            <LinkButton
                              onClick={() => {
                                remove(indexProcess);
                              }}
                            >
                              remover tratamento de dados
                            </LinkButton>
                          </Column>
                        </Row>
                        <Row>
                          <Column className="mb-3 font-weight-bold">Tratamento dado#{indexProcess + 1}</Column>
                        </Row>
                        <Row justifyContent="center">
                          <Column xs={12} sm={6} flex>
                            <Controller
                              control={control}
                              name={`dataProcessing.${indexProcess}.processing`}
                              render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                                  <InputLabel id={`processing${indexProcess}-label`} shrink>
                                    Tratamento
                                  </InputLabel>
                                  <Select
                                    labelId={`processing${indexProcess}-label`}
                                    id={`processing${indexProcess}`}
                                    value={value ?? ''}
                                    label="Tratamento"
                                    onChange={onChange}
                                    notched
                                  >
                                    <MenuItem value="" key={-1}>
                                      Selecione um Tratamento
                                    </MenuItem>
                                    {treatments.map(item => (
                                      <MenuItem value={item} key={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </Column>
                          <Column xs={12} sm={6} flex>
                            <Controller
                              control={control}
                              name={`dataProcessing.${indexProcess}.legalBase`}
                              render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                                  <InputLabel id={`legalBase${indexProcess}-label`} shrink>
                                    Base legal
                                  </InputLabel>
                                  <Select
                                    labelId={`legalBase${indexProcess}-label`}
                                    id={`legalBase${indexProcess}`}
                                    value={value ?? ''}
                                    label="Base legal"
                                    onChange={onChange}
                                    notched
                                  >
                                    <MenuItem value="" key={-1}>
                                      Selecione uma base legal
                                    </MenuItem>
                                    {legalBases.map((item, index) => (
                                      <MenuItem value={item} key={index}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </Column>
                        </Row>
                        {/* só exibir se o tratamentro for compartilhamento */}
                        {processing[indexProcess].processing === 'Compartilhar' && (
                          <Row justifyContent="center">
                            <Column xs={12} sm={6} flex>
                              <Controller
                                control={control}
                                name={`dataProcessing.${indexProcess}.shareType`}
                                render={({ field: { onChange, value } }) => (
                                  <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                                    <InputLabel id={`shareType${indexProcess}-label`} shrink>
                                      Tipo de compartilhamento
                                    </InputLabel>
                                    <Select
                                      labelId={`shareType${indexProcess}-label`}
                                      id={`shareType${indexProcess}`}
                                      value={value ?? ''}
                                      label="Tipo de compartilhamento"
                                      onChange={onChange}
                                      notched
                                    >
                                      <MenuItem value="" key={-1}>
                                        Selecione uma tipo de compartilhamento
                                      </MenuItem>
                                      {['Interno', 'Externo'].map((item, index) => (
                                        <MenuItem value={item} key={index}>
                                          {item}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                )}
                              />
                            </Column>
                            <Column xs={12} sm={6} flex>
                              <CustomTextField
                                {...register(`dataProcessing.${indexProcess}.destiny`)}
                                label="Destino"
                                autoFocus
                                sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </Column>
                          </Row>
                        )}
                        <Row justifyContent="center">
                          <Column xs={12} sm={12} flex>
                            <CustomTextField
                              {...register(`dataProcessing.${indexProcess}.complementLegalBasis`)}
                              label="Complemento base legal"
                              autoFocus
                              sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Column>
                        </Row>
                        <Row justifyContent="center">
                          <Column xs={12} sm={6} flex>
                            <CustomTextField
                              {...register(`dataProcessing.${indexProcess}.finality`)}
                              label="Finalidade"
                              sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Column>
                          <Column xs={12} sm={6} flex>
                            <Controller
                              control={control}
                              name={`dataProcessing.${indexProcess}.position`}
                              render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                                  <InputLabel id={`position${indexProcess}-label`} shrink>
                                    Posição
                                  </InputLabel>
                                  <Select
                                    labelId={`position${indexProcess}-label`}
                                    id={`position${indexProcess}`}
                                    value={value ?? ''}
                                    label="Posição"
                                    onChange={onChange}
                                    notched
                                  >
                                    <MenuItem value="" key={-1}>
                                      Selecione uma posição
                                    </MenuItem>
                                    {['Controlador', 'Operador'].map(item => (
                                      <MenuItem value={item} key={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </Column>
                        </Row>
                        <Row>
                          <Column xs={12} sm={12} flex>
                            {!systemLoading && !systemError && !assetLoading && !assetError && (
                              <Controller
                                control={control}
                                name={`dataProcessing.${indexProcess}.tool`}
                                render={({ field: { onChange, value } }) => (
                                  <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                                    <InputLabel id={`tool${indexProcess}-label`} shrink>
                                      Ferramenta
                                    </InputLabel>
                                    <Select
                                      labelId={`tool${indexProcess}-label`}
                                      id={`tool${indexProcess}`}
                                      value={value ?? ''}
                                      label="Ferramenta"
                                      onChange={onChange}
                                      notched
                                    >
                                      <MenuItem value="" key={-1}>
                                        Selecione uma ferramenta
                                      </MenuItem>
                                      {systems?.map(item => (
                                        <MenuItem value={item.name} key={item._id}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                      {assets?.map(item => (
                                        <MenuItem value={item.name} key={item._id}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                )}
                              />
                            )}
                            {(systemLoading || assetLoading) && !systemError && !assetError && (
                              <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                                <InputLabel id={`tool${indexProcess}-label`} shrink={true}>
                                  Ferramenta
                                </InputLabel>
                                <InputLoader />
                              </FormControl>
                            )}
                          </Column>
                        </Row>
                        <Row>
                          <Column>
                            <TextField
                              {...register(`dataProcessing.${indexProcess}.observation`)}
                              label="Observação"
                              multiline
                              rows={4}
                              sx={{ margin: '0 auto 24px', width: '100%' }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </Column>
                        </Row>
                      </div>
                    );
                  })}
                </Column>
              </Row>
              <Row>
                <Column className="mb-3">
                  <LinkButton onClick={handleAddDataProcessing}>Adicionar tratamento de dados</LinkButton>
                </Column>
              </Row>
              <Row>
                <Column className="mb-2">
                  <hr />
                </Column>
              </Row>
            </div>

            <Row justifyContent="end" alignItems="center" className="mt-2">
              <Column xs={3} justifyContent="center" className="d-flex">
                <ButtonForm secondary id="cancel" label="cancelar" onClick={handleReset}></ButtonForm>
              </Column>
              <Column xs={2} justifyContent="center" className="d-flex">
                <ButtonForm label="Salvar" variant="submit" />
              </Column>
            </Row>
          </form>
        )}
        {isLoading && <PageLoaderForm />}

        {finished && (
          <FormSuccess
            title="Dados do Processo cadastrado com sucesso!"
            labelStartAgain="Cadastrar novo Dado do Processo"
            handleStartAgain={() => handleReset()}
            labelShow="Ver detalhes"
            handleShow={() => {
              navigate(`/processes/show/${id}`);
            }}
          />
        )}
      </div>
    </Container>
  );
};
