import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

export const ModalConfirmDelete = ({description, open, onClose, onConfirm}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
        >
            <DialogTitle id="alert-dialog-title" style={{textAlign: "center"}}>
                <HighlightOffOutlinedIcon
                    id="icon"
                    style={{
                        fill: "#E35541",
                        height: '48px',
                        width: '48px',
                    }} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{textAlign: 'center'}}>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent: "center", marginBottom: "16px"}}>
                <Button variant="outlined" size="medium" onClick={onClose}>Cancelar</Button>
                <Button variant="contained" size="medium" color="primary" onClick={onConfirm} autoFocus>
                    Sim, Excluir
                </Button>
            </DialogActions>
        </Dialog>
    )
}