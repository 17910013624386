export const tableHeader = [
    {
        id: 'name',
        type: 'text',
        value: 'Nome',
    },
    {
        id: 'criticity',
        type: 'select',
        value: 'Criticidade',
    },
    {
        id: 'type',
        type: 'string',
        value: 'Tipo',
    },
    {
        id: 'pii',
        type: 'boolean',
        value: 'PII',
    },
    {
        id: 'location',
        type: 'text',
        value: 'Localização',
    },
    {
        id: 'user',
        type: 'text',
        value: 'Responsável',
    },
    {
        id: 'action',
        type: 'actions',
        value: '',
    },
]
