import { Container } from 'Components/Container';
import { TitleForm } from 'Components/Form/styles';
import { Row } from 'Components/Grid';
import { useNavigate, useParams } from 'react-router-dom';
import { Detalhes } from './Details';
import { FormSuccess } from 'Components/Form/Success';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { addOffice, editOffice, getOfficeById } from 'services/offices-service';
import { useEffect } from 'react';

export const OfficesForm = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const { id } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [idOffice, setIdOffice] = useState(null);
  const [finished, setFinished] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      address: '',
      phone: '',
      companyKey: selectedTenant.slug,
    },
  });

  const handleComplete = async () => {
    try {
      const res = id ? await editOffice(id, getValues()) : await addOffice(getValues());
      if (res.status === 200 || res.status === 201) {
        setIdOffice(res.data._id ?? id);
        setFinished(true);
      } else {
        throw new Error('Ocorreu um erro ao tentar salvar/editar o Escritório!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const handleReset = () => {
    reset();
    setFinished(false);
    setIdOffice(null);
    navigate('/offices/add');
  };

  const getOffice = useCallback(async () => {
    try {
      const resOffice = await getOfficeById(id);
      Object.keys(resOffice.data).forEach(key => {
        setValue(key, resOffice.data[key]);
      });
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  }, [id, setValue, SwalReact]);

  useEffect(() => {
    if (!id) return;

    getOffice();

    return () => {};
  }, [id, getOffice]);

  return (
    <Container
      title={`${id ? 'Editar' : 'Adicionar'} Escritório`}
      breadcrumb="Gerenciar / Escritórios"
      linkPage="/offices"
    >
      <div className="card-content">
        <Row>
          <TitleForm>Detalhes</TitleForm>
        </Row>
        {!finished && (
          <Detalhes
            control={control}
            register={register}
            handleComplete={handleComplete}
            handleSubmit={handleSubmit}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
          />
        )}
        {finished && (
          <FormSuccess
            title="Escritório cadastrado com sucesso!"
            labelStartAgain="Cadastrar novo Escritório"
            handleStartAgain={() => handleReset()}
            labelShow="Ver detalhes"
            handleShow={() => navigate('/offices/show/' + idOffice)}
          />
        )}
      </div>
    </Container>
  );
};
