import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Box, Button, FormControl, ListItemIcon, Menu, MenuItem, Select } from '@mui/material';
import { Container } from 'Components/Container';
import { PageLoaderShow } from 'Components/PageLoader/Show';
import { getExportFileRopa, getRopaById } from 'services/report-ropa-service';
import { ReportsRopaProcess } from './Process';
import { ReportsRopaArea } from './Area';
import { useReactToPrint } from 'react-to-print';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import { ReportsRopaCompany } from './Company';
import { Column, Row } from 'Components/Grid';
import { Controller, useForm } from 'react-hook-form';
import { green } from '@mui/material/colors';
import { ReportsRopaExecutiveSummary } from './ExecutiveSummary';

export const ReportsRopaShow = () => {
  const { id } = useParams();
  const componentRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [filterList, setFilterList] = useState([]);
  const { control, watch } = useForm({
    defaultValues: {
      filter: '',
      area: '',
      process: '',
      data: '',
      category: '',
      processing: '',
      legalBase: '',
      position: '',
    },
  });

  const {
    isLoading,
    error,
    data: ropa,
  } = useQuery('getReportRopaById', async () => {
    const dataRopa = await getRopaById(id);
    if (dataRopa.status === 200) {
      setFilterList(
        dataRopa.data.scope === 'Empresa'
          ? ['Área', 'Processo', 'Dados', 'Categoria', 'Tratamento', 'Base Legal', 'Posição']
          : dataRopa.data.scope === 'Área'
          ? ['Processo', 'Dados', 'Categoria', 'Tratamento', 'Base Legal', 'Posição']
          : ['Dados', 'Categoria', 'Tratamento', 'Base Legal', 'Posição'],
      );
      return dataRopa.data;
    }
    return null;
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { size: 10in 12in }',
  });

  const getContentFiltered = () => {
    const contentRopa = {
      name: ropa.name,
      scope: ropa.scope,
      company: {},
      area: {},
      process: {},
    };
    if (ropa.scope === 'Empresa') {
      contentRopa.company.name = ropa.company.name;
      contentRopa.company.description = ropa.company.description;
      contentRopa.company.areas = [];

      ropa.company.areas.forEach(itemArea => {
        const contentArea = {
          name: itemArea.name,
          description: itemArea.description,
          user: itemArea.user,
          processes: [],
        };
        itemArea.processes.forEach(itemProcess => {
          const contentProcess = {
            name: itemProcess.name,
            descriptionDataFlow: itemProcess.descriptionDataFlow,
            user: itemProcess.user,
            dataMapping: [],
          };
          itemProcess.dataMapping.forEach(itemDataMap => {
            const contentDataMap = {
              name: itemDataMap.name,
              category: itemDataMap.category,
              dataSource: itemDataMap.dataSource,
              holderProfile: itemDataMap.holderProfile,
              retentionPeriod: itemDataMap.retentionPeriod,
              storage: itemDataMap.storage,
              dataProcessing: [],
            };
            itemDataMap.dataProcessing.forEach(itemDataProcess => {
              const contentDataProcess = {
                complementLegalBasis: itemDataProcess.complementLegalBasis,
                destiny: itemDataProcess.destiny,
                finality: itemDataProcess.finality,
                legalBase: itemDataProcess.legalBase,
                observation: itemDataProcess.observation,
                position: itemDataProcess.position,
                processing: itemDataProcess.processing,
                shareType: itemDataProcess.shareType,
                tool: itemDataProcess.tool,
              };
              contentDataMap.dataProcessing.push(contentDataProcess);
            });
            contentProcess.dataMapping.push(contentDataMap);
          });
          contentArea.processes.push(contentProcess);
        });
        contentRopa.company.areas.push(contentArea);
      });

      contentRopa.company.areas = contentRopa.company.areas.filter(areaFilter => {
        if (watch('filter') === 'Área' && !!watch('area')) {
          return areaFilter.name === watch('area');
        }
        if (watch('filter') === 'Processo' && !!watch('process')) {
          return areaFilter.processes.some(itemProcess => itemProcess.name === watch('process'));
        }
        if (watch('filter') === 'Dados' && !!watch('data')) {
          return areaFilter.processes.some(itemProcess => {
            return itemProcess.dataMapping.some(dataMap => dataMap.name === watch('data'));
          });
        }
        if (watch('filter') === 'Categoria' && !!watch('category')) {
          return areaFilter.processes.some(itemProcess => {
            return itemProcess.dataMapping.some(dataMap => dataMap.category === watch('category'));
          });
        }
        if (watch('filter') === 'Tratamento' && !!watch('processing')) {
          return areaFilter.processes.some(itemProcess => {
            return itemProcess.dataMapping.some(dataMap => {
              return dataMap.dataProcessing.some(dataProcess => {
                return dataProcess.processing === watch('processing');
              });
            });
          });
        }
        if (watch('filter') === 'Legal Base' && !!watch('legalBase')) {
          return areaFilter.processes.some(itemProcess => {
            return itemProcess.dataMapping.some(dataMap => {
              return dataMap.dataProcessing.some(dataProcess => {
                return dataProcess.legalBase === watch('legalBase');
              });
            });
          });
        }
        if (watch('filter') === 'Posição' && !!watch('position')) {
          return areaFilter.processes.some(itemProcess => {
            return itemProcess.dataMapping.some(dataMap => {
              return dataMap.dataProcessing.some(dataProcess => {
                return dataProcess.position === watch('position');
              });
            });
          });
        }
        return true;
      });

      contentRopa.company.areas.forEach(areaFilter => {
        areaFilter.processes = areaFilter.processes.filter(processFilter => {
          if (watch('filter') === 'Processo' && !!watch('process')) {
            return processFilter.name === watch('process');
          }
          if (watch('filter') === 'Dados' && !!watch('data')) {
            return processFilter.dataMapping.some(dataMap => dataMap.name === watch('data'));
          }
          if (watch('filter') === 'Categoria' && !!watch('category')) {
            return processFilter.dataMapping.some(dataMap => dataMap.category === watch('category'));
          }
          if (watch('filter') === 'Tratamento' && !!watch('processing')) {
            return processFilter.dataMapping.some(dataMap => {
              return dataMap.dataProcessing.some(dataProcess => {
                return dataProcess.processing === watch('processing');
              });
            });
          }
          if (watch('filter') === 'Legal Base' && !!watch('legalBase')) {
            return processFilter.dataMapping.some(dataMap => {
              return dataMap.dataProcessing.some(dataProcess => {
                return dataProcess.legalBase === watch('legalBase');
              });
            });
          }
          if (watch('filter') === 'Posição' && !!watch('position')) {
            return processFilter.dataMapping.some(dataMap => {
              return dataMap.dataProcessing.some(dataProcess => {
                return dataProcess.position === watch('position');
              });
            });
          }
          return true;
        });

        areaFilter.processes.forEach(processFilter => {
          processFilter.dataMapping = processFilter.dataMapping.filter(dataMap => {
            if (watch('filter') === 'Dados' && !!watch('data')) {
              return dataMap.name === watch('data');
            }
            if (watch('filter') === 'Categoria' && !!watch('category')) {
              return dataMap.category === watch('category');
            }
            if (watch('filter') === 'Tratamento' && !!watch('processing')) {
              return dataMap.dataProcessing.some(itemDataProcess => {
                return itemDataProcess.processing === watch('processing');
              });
            }
            if (watch('filter') === 'Legal Base' && !!watch('legalBase')) {
              return dataMap.dataProcessing.some(itemDataProcess => {
                return itemDataProcess.legalBase === watch('legalBase');
              });
            }
            if (watch('filter') === 'Posição' && !!watch('position')) {
              return dataMap.dataProcessing.some(itemDataProcess => {
                return itemDataProcess.position === watch('position');
              });
            }
            return true;
          });

          processFilter.dataMapping.forEach(dataMap => {
            dataMap.dataProcessing = dataMap.dataProcessing.filter(dataProcess => {
              if (watch('filter') === 'Tratamento' && !!watch('processing')) {
                return dataProcess.processing === watch('processing');
              }
              if (watch('filter') === 'Legal Base' && !!watch('legalBase')) {
                return dataProcess.legalBase === watch('legalBase');
              }
              if (watch('filter') === 'Posição' && !!watch('position')) {
                return dataProcess.position === watch('position');
              }
              return true;
            });
          });
        });
      });
    } else if (ropa.scope === 'Área') {
      contentRopa.area.name = ropa.area.name;
      contentRopa.area.description = ropa.area.description;
      contentRopa.area.user = ropa.area.user;
      contentRopa.area.processes = [];

      ropa.area.processes.forEach(itemProcess => {
        const contentProcess = {
          name: itemProcess.name,
          descriptionDataFlow: itemProcess.descriptionDataFlow,
          user: itemProcess.user,
          dataMapping: [],
        };
        itemProcess.dataMapping.forEach(itemDataMap => {
          const contentDataMap = {
            name: itemDataMap.name,
            category: itemDataMap.category,
            dataSource: itemDataMap.dataSource,
            holderProfile: itemDataMap.holderProfile,
            retentionPeriod: itemDataMap.retentionPeriod,
            storage: itemDataMap.storage,
            dataProcessing: [],
          };
          itemDataMap.dataProcessing.forEach(itemDataProcess => {
            const contentDataProcess = {
              complementLegalBasis: itemDataProcess.complementLegalBasis,
              destiny: itemDataProcess.destiny,
              finality: itemDataProcess.finality,
              legalBase: itemDataProcess.legalBase,
              observation: itemDataProcess.observation,
              position: itemDataProcess.position,
              processing: itemDataProcess.processing,
              shareType: itemDataProcess.shareType,
              tool: itemDataProcess.tool,
            };
            contentDataMap.dataProcessing.push(contentDataProcess);
          });
          contentProcess.dataMapping.push(contentDataMap);
        });
        contentRopa.area.processes.push(contentProcess);
      });

      contentRopa.area.processes = contentRopa.area.processes
        .filter(processFilter => {
          if (watch('filter') === 'Processo' && !!watch('process')) {
            return processFilter.name === watch('process');
          }
          if (watch('filter') === 'Dados' && !!watch('data')) {
            return processFilter.dataMapping.some(dataMap => dataMap.name === watch('data'));
          }
          if (watch('filter') === 'Categoria' && !!watch('category')) {
            return processFilter.dataMapping.some(dataMap => dataMap.category === watch('category'));
          }
          if (watch('filter') === 'Tratamento' && !!watch('processing')) {
            return processFilter.dataMapping.some(dataMap => {
              return dataMap.dataProcessing.some(dataProcess => {
                return dataProcess.processing === watch('processing');
              });
            });
          }
          if (watch('filter') === 'Legal Base' && !!watch('legalBase')) {
            return processFilter.dataMapping.some(dataMap => {
              return dataMap.dataProcessing.some(dataProcess => {
                return dataProcess.legalBase === watch('legalBase');
              });
            });
          }
          if (watch('filter') === 'Posição' && !!watch('position')) {
            return processFilter.dataMapping.some(dataMap => {
              return dataMap.dataProcessing.some(dataProcess => {
                return dataProcess.position === watch('position');
              });
            });
          }
          return true;
        })
        .map(processFilter => {
          processFilter.dataMapping = processFilter.dataMapping
            .filter(dataMap => {
              if (watch('filter') === 'Dados' && !!watch('data')) {
                return dataMap.name === watch('data');
              }
              if (watch('filter') === 'Categoria' && !!watch('category')) {
                return dataMap.category === watch('category');
              }
              if (watch('filter') === 'Tratamento' && !!watch('processing')) {
                return dataMap.dataProcessing.some(itemDataProcess => {
                  return itemDataProcess.processing === watch('processing');
                });
              }
              if (watch('filter') === 'Legal Base' && !!watch('legalBase')) {
                return dataMap.dataProcessing.some(itemDataProcess => {
                  return itemDataProcess.legalBase === watch('legalBase');
                });
              }
              if (watch('filter') === 'Posição' && !!watch('position')) {
                return dataMap.dataProcessing.some(itemDataProcess => {
                  return itemDataProcess.position === watch('position');
                });
              }
              return true;
            })
            .map(dataMap => {
              dataMap.dataProcessing = dataMap.dataProcessing.filter(dataProcess => {
                if (watch('filter') === 'Tratamento' && !!watch('processing')) {
                  return dataProcess.processing === watch('processing');
                }
                if (watch('filter') === 'Legal Base' && !!watch('legalBase')) {
                  return dataProcess.legalBase === watch('legalBase');
                }
                if (watch('filter') === 'Posição' && !!watch('position')) {
                  return dataProcess.position === watch('position');
                }
                return true;
              });
              return dataMap;
            });
          return processFilter;
        });
    } else if (ropa.scope === 'Processo') {
      contentRopa.process.name = ropa.process.name;
      contentRopa.process.descriptionDataFlow = ropa.process.descriptionDataFlow;
      contentRopa.process.user = ropa.process.user;
      contentRopa.process.dataMapping = [];

      ropa.process.dataMapping.forEach(itemDataMap => {
        const contentDataMap = {
          name: itemDataMap.name,
          category: itemDataMap.category,
          dataSource: itemDataMap.dataSource,
          holderProfile: itemDataMap.holderProfile,
          retentionPeriod: itemDataMap.retentionPeriod,
          storage: itemDataMap.storage,
          dataProcessing: [],
        };
        itemDataMap.dataProcessing.forEach(itemDataProcess => {
          const contentDataProcess = {
            complementLegalBasis: itemDataProcess.complementLegalBasis,
            destiny: itemDataProcess.destiny,
            finality: itemDataProcess.finality,
            legalBase: itemDataProcess.legalBase,
            observation: itemDataProcess.observation,
            position: itemDataProcess.position,
            processing: itemDataProcess.processing,
            shareType: itemDataProcess.shareType,
            tool: itemDataProcess.tool,
          };
          contentDataMap.dataProcessing.push(contentDataProcess);
        });
        contentRopa.process.dataMapping.push(contentDataMap);
      });

      contentRopa.process.dataMapping = contentRopa.process.dataMapping
        .filter(dataMap => {
          if (watch('filter') === 'Dados' && !!watch('data')) {
            return dataMap.name === watch('data');
          }
          if (watch('filter') === 'Categoria' && !!watch('category')) {
            return dataMap.category === watch('category');
          }
          if (watch('filter') === 'Tratamento' && !!watch('processing')) {
            return dataMap.dataProcessing.some(itemDataProcess => {
              return itemDataProcess.processing === watch('processing');
            });
          }
          if (watch('filter') === 'Legal Base' && !!watch('legalBase')) {
            return dataMap.dataProcessing.some(itemDataProcess => {
              return itemDataProcess.legalBase === watch('legalBase');
            });
          }
          if (watch('filter') === 'Posição' && !!watch('position')) {
            return dataMap.dataProcessing.some(itemDataProcess => {
              return itemDataProcess.position === watch('position');
            });
          }
          return true;
        })
        .map(dataMap => {
          dataMap.dataProcessing = dataMap.dataProcessing.filter(dataProcess => {
            if (watch('filter') === 'Tratamento' && !!watch('processing')) {
              return dataProcess.processing === watch('processing');
            }
            if (watch('filter') === 'Legal Base' && !!watch('legalBase')) {
              return dataProcess.legalBase === watch('legalBase');
            }
            if (watch('filter') === 'Posição' && !!watch('position')) {
              return dataProcess.position === watch('position');
            }
            return true;
          });
          return dataMap;
        });
    }
    return contentRopa;
  };

  const exportExcel = async () => {
    const response = await getExportFileRopa(getContentFiltered());
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    let fileName = '';
    switch (ropa.scope) {
      case 'Empresa':
        fileName = 'relatorio_ropa_versao_empresa.xlsx';
        break;
      case 'Área':
        fileName = 'relatorio_ropa_versao_area.xlsx';
        break;
      case 'Processo':
        fileName = 'relatorio_ropa_versao_processo.xlsx';
        break;
      default:
        fileName = 'relatorio_ropa_versao_sumario_executivo.xlsx';
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getAreaFilter = scope => {
    if (scope === 'Empresa') {
      const set = new Set(ropa.company.areas);
      const areasFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="area"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select id="area" displayEmpty value={value} onChange={onChange} autoWidth size="small" className="mt-3">
                <MenuItem value="" key={'index-a-0'}>
                  Selecione uma Área
                </MenuItem>
                {areasFilter.map(item => (
                  <MenuItem value={item.name} key={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    }
    return <></>;
  };

  const getProcessFilter = scope => {
    if (scope === 'Empresa') {
      let processesFilter = [];
      ropa.company.areas.forEach(itemArea => {
        itemArea.processes.forEach(itemProcess => {
          processesFilter.push(itemProcess.name);
        });
      });
      const set = new Set(processesFilter);
      processesFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="process"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="process"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-p-0'}>
                  Selecione um Processo
                </MenuItem>
                {processesFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Área') {
      let processesFilter = [];
      ropa.area.processes.forEach(itemProcess => {
        processesFilter.push(itemProcess.name);
      });
      const set = new Set(processesFilter);
      processesFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="process"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="process"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-p-0'}>
                  Selecione um Processo
                </MenuItem>
                {processesFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    }
    return <></>;
  };

  const getMappingFilter = scope => {
    if (scope === 'Empresa') {
      let mappingFilter = [];
      ropa.company.areas.forEach(itemArea => {
        itemArea.processes.forEach(itemProcess => {
          itemProcess.dataMapping.forEach(itemDataMap => {
            mappingFilter.push(itemDataMap.name);
          });
        });
      });
      const set = new Set(mappingFilter);
      mappingFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="data"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select id="data" displayEmpty value={value} onChange={onChange} autoWidth size="small" className="mt-3">
                <MenuItem value="" key={'index-p-0'}>
                  Selecione um Dado de Mapeamento
                </MenuItem>
                {mappingFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Área') {
      let mappingFilter = [];
      ropa.area.processes.forEach(itemProcess => {
        itemProcess.dataMapping.forEach(itemDataMap => {
          mappingFilter.push(itemDataMap.name);
        });
      });
      const set = new Set(mappingFilter);
      mappingFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="data"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select id="data" displayEmpty value={value} onChange={onChange} autoWidth size="small" className="mt-3">
                <MenuItem value="" key={'index-p-0'}>
                  Selecione um Dado de Mapeamento
                </MenuItem>
                {mappingFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Processo') {
      let mappingFilter = [];
      ropa.process.dataMapping.forEach(itemDataMap => {
        mappingFilter.push(itemDataMap.name);
      });
      const set = new Set(mappingFilter);
      mappingFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="data"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select id="data" displayEmpty value={value} onChange={onChange} autoWidth size="small" className="mt-3">
                <MenuItem value="" key={'index-p-0'}>
                  Selecione um Dado de Mapeamento
                </MenuItem>
                {mappingFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    }
    return <></>;
  };

  const getCategoryFilter = scope => {
    if (scope === 'Empresa') {
      let categoryFilter = [];
      ropa.company.areas.forEach(itemArea => {
        itemArea.processes.forEach(itemProcess => {
          itemProcess.dataMapping.forEach(itemDataMap => {
            if (itemDataMap.category !== '') {
              categoryFilter.push(itemDataMap.category);
            }
          });
        });
      });
      const set = new Set(categoryFilter);
      categoryFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="category"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="category"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione uma Categoria
                </MenuItem>
                {categoryFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Área') {
      let categoryFilter = [];
      ropa.area.processes.forEach(itemProcess => {
        itemProcess.dataMapping.forEach(itemDataMap => {
          if (itemDataMap.category !== '') {
            categoryFilter.push(itemDataMap.category);
          }
        });
      });
      const set = new Set(categoryFilter);
      categoryFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="category"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="category"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione uma Categoria
                </MenuItem>
                {categoryFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Processo') {
      let categoryFilter = [];
      ropa.process.dataMapping.forEach(itemDataMap => {
        if (itemDataMap.category !== '') {
          categoryFilter.push(itemDataMap.category);
        }
      });
      const set = new Set(categoryFilter);
      categoryFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="category"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="category"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione uma Categoria
                </MenuItem>
                {categoryFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    }
    return <></>;
  };

  const getProcessingFilter = scope => {
    if (scope === 'Empresa') {
      let processingFilter = [];
      ropa.company.areas.forEach(itemArea => {
        itemArea.processes.forEach(itemProcess => {
          itemProcess.dataMapping.forEach(itemDataMap => {
            itemDataMap.dataProcessing.forEach(itemDataProcess => {
              processingFilter.push(itemDataProcess.processing);
            });
          });
        });
      });
      const set = new Set(processingFilter);
      processingFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="processing"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="processing"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione um Tratamento
                </MenuItem>
                {processingFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Área') {
      let processingFilter = [];
      ropa.area.processes.forEach(itemProcess => {
        itemProcess.dataMapping.forEach(itemDataMap => {
          itemDataMap.dataProcessing.forEach(itemDataProcess => {
            processingFilter.push(itemDataProcess.processing);
          });
        });
      });
      const set = new Set(processingFilter);
      processingFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="processing"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="processing"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione um Tratamento
                </MenuItem>
                {processingFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Processo') {
      let processingFilter = [];
      ropa.process.dataMapping.forEach(itemDataMap => {
        itemDataMap.dataProcessing.forEach(itemDataProcess => {
          processingFilter.push(itemDataProcess.processing);
        });
      });
      const set = new Set(processingFilter);
      processingFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="processing"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="processing"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione um Tratamento
                </MenuItem>
                {processingFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    }
    return <></>;
  };

  const getLegalBaseFilter = scope => {
    if (scope === 'Empresa') {
      let legalBaseFilter = [];
      ropa.company.areas.forEach(itemArea => {
        itemArea.processes.forEach(itemProcess => {
          itemProcess.dataMapping.forEach(itemDataMap => {
            itemDataMap.dataProcessing.forEach(itemDataProcess => {
              if (itemDataProcess.legalBase !== '') {
                legalBaseFilter.push(itemDataProcess.legalBase);
              }
            });
          });
        });
      });
      const set = new Set(legalBaseFilter);
      legalBaseFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="legalBase"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="legalBase"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione uma Base Legal
                </MenuItem>
                {legalBaseFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Área') {
      let legalBaseFilter = [];
      ropa.area.processes.forEach(itemProcess => {
        itemProcess.dataMapping.forEach(itemDataMap => {
          itemDataMap.dataProcessing.forEach(itemDataProcess => {
            if (itemDataProcess.legalBase !== '') {
              legalBaseFilter.push(itemDataProcess.legalBase);
            }
          });
        });
      });
      const set = new Set(legalBaseFilter);
      legalBaseFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="legalBase"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="legalBase"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione uma Base Legal
                </MenuItem>
                {legalBaseFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Processo') {
      let legalBaseFilter = [];
      ropa.process.dataMapping.forEach(itemDataMap => {
        itemDataMap.dataProcessing.forEach(itemDataProcess => {
          if (itemDataProcess.legalBase !== '') {
            legalBaseFilter.push(itemDataProcess.legalBase);
          }
        });
      });
      const set = new Set(legalBaseFilter);
      legalBaseFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="legalBase"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="legalBase"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione uma Base Legal
                </MenuItem>
                {legalBaseFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    }
    return <></>;
  };

  const getPositionFilter = scope => {
    if (scope === 'Empresa') {
      let positionFilter = [];
      ropa.company.areas.forEach(itemArea => {
        itemArea.processes.forEach(itemProcess => {
          itemProcess.dataMapping.forEach(itemDataMap => {
            itemDataMap.dataProcessing.forEach(itemDataProcess => {
              positionFilter.push(itemDataProcess.position);
            });
          });
        });
      });
      const set = new Set(positionFilter);
      positionFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="position"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="position"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione uma Posição
                </MenuItem>
                {positionFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Área') {
      let positionFilter = [];
      ropa.area.processes.forEach(itemProcess => {
        itemProcess.dataMapping.forEach(itemDataMap => {
          itemDataMap.dataProcessing.forEach(itemDataProcess => {
            positionFilter.push(itemDataProcess.position);
          });
        });
      });
      const set = new Set(positionFilter);
      positionFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="position"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="position"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione uma Posição
                </MenuItem>
                {positionFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    } else if (scope === 'Processo') {
      let positionFilter = [];
      ropa.process.dataMapping.forEach(itemDataMap => {
        itemDataMap.dataProcessing.forEach(itemDataProcess => {
          positionFilter.push(itemDataProcess.position);
        });
      });
      const set = new Set(positionFilter);
      positionFilter = Array.from(set);
      return (
        <Controller
          control={control}
          name="position"
          render={({ field: { onChange, value } }) => (
            <FormControl sx={{ marginBottom: '24px', marginInlineStart: '16px' }}>
              <Select
                id="position"
                displayEmpty
                value={value}
                onChange={onChange}
                autoWidth
                size="small"
                className="mt-3"
              >
                <MenuItem value="" key={'index-c-0'}>
                  Selecione uma Posição
                </MenuItem>
                {positionFilter.map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      {!isLoading && !error && (
        <Container title={`${ropa?.name}`} breadcrumb={`Relatórios / Ropa / ${ropa?.name}`} linkPage="/reports/ropa">
          <Box sx={{ width: '100%' }}>
            <Row className="mt-3">
              <Column xs={12} sm={6} md={6}>
                {ropa.scope !== 'Sumário Executivo' && (
                  <Controller
                    control={control}
                    name="filter"
                    render={({ field: { onChange, value } }) => (
                      <FormControl sx={{ marginBottom: '24px' }}>
                        <Select
                          id="filter"
                          displayEmpty
                          value={value}
                          onChange={onChange}
                          autoWidth
                          size="small"
                          className="mt-3"
                        >
                          <MenuItem value="" key={'index-0'}>
                            Filtrar por
                          </MenuItem>
                          {filterList.map(item => (
                            <MenuItem value={item} key={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                )}
                {watch('filter') === 'Área' && getAreaFilter(ropa.scope)}
                {watch('filter') === 'Processo' && getProcessFilter(ropa.scope)}
                {watch('filter') === 'Dados' && getMappingFilter(ropa.scope)}
                {watch('filter') === 'Categoria' && getCategoryFilter(ropa.scope)}
                {watch('filter') === 'Tratamento' && getProcessingFilter(ropa.scope)}
                {watch('filter') === 'Base Legal' && getLegalBaseFilter(ropa.scope)}
                {watch('filter') === 'Posição' && getPositionFilter(ropa.scope)}
              </Column>
              <Column xs={12} sm={6} md={6} flex justifyContent="end">
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  aria-controls={anchorEl ? 'user-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={anchorEl ? 'true' : undefined}
                  endIcon={<PrintOutlinedIcon />}
                  onClick={handleClick}
                >
                  Imprimir
                </Button>
              </Column>
            </Row>
            <Menu
              anchorEl={anchorEl}
              id="user-menu"
              open={anchorEl != null}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    minWidth: 110,
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                style={{
                  justifyContent: 'space-between',
                  paddingInline: '16px',
                }}
                onClick={handlePrint}
              >
                PDF
                <ListItemIcon style={{ justifyContent: 'center' }}>
                  <PictureAsPdfOutlinedIcon style={{ color: 'red' }} fontSize="small" />
                </ListItemIcon>
              </MenuItem>
              <MenuItem
                style={{
                  justifyContent: 'space-between',
                  paddingInline: '16px',
                }}
                onClick={exportExcel}
              >
                Excel
                <ListItemIcon style={{ justifyContent: 'center' }}>
                  <TableViewOutlinedIcon style={{ color: green[500] }} fontSize="small" />
                </ListItemIcon>
              </MenuItem>
            </Menu>
            <Box ref={componentRef}>
              {ropa.scope === 'Empresa' && (
                <ReportsRopaCompany
                  company={ropa.company}
                  filter={watch('filter')}
                  area={watch('area')}
                  process={watch('process')}
                  data={watch('data')}
                  category={watch('data')}
                  processing={watch('processing')}
                  legalBase={watch('legalBase')}
                  position={watch('position')}
                />
              )}
              {ropa.scope === 'Área' && (
                <ReportsRopaArea
                  area={ropa.area}
                  filter={watch('filter')}
                  process={watch('process')}
                  data={watch('data')}
                  category={watch('data')}
                  treatment={watch('treatment')}
                  legalBase={watch('legalBase')}
                  position={watch('position')}
                />
              )}
              {ropa.scope === 'Processo' && (
                <ReportsRopaProcess
                  process={ropa.process}
                  filter={watch('filter')}
                  data={watch('data')}
                  category={watch('category')}
                  treatment={watch('treatment')}
                  legalBase={watch('legalBase')}
                  position={watch('position')}
                />
              )}
              {ropa.scope === 'Sumário Executivo' && <ReportsRopaExecutiveSummary dados={ropa.executiveSummary} />}
            </Box>
          </Box>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  );
};
