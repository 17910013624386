import { Shimmer } from "../styles"

export const PageLoaderAcceptPolicy = () => {
    return (
        <div style={{display: 'flex', marginTop: '32px', flexDirection: 'column'}}>
            <div style={{height: '38px', width: '420px', display:'flex', marginInline: 'auto'}}>
                <Shimmer style={{height: '38px', width: '420px'}}></Shimmer>
            </div>
            <div style={{height: '38px', width: '100px', display:'flex', marginLeft: 'auto', marginRight: '16px'}}>
                <Shimmer style={{height: '38px', width: '200px'}}></Shimmer>
            </div>
            {Array(10).fill('').map((_, index) => (
                <div key={index} 
                    style={{height: '24px', width: '100%', display:'flex', paddingInline: '16px', marginTop: '16px'}}>
                    <Shimmer style={{height: '24px'}}></Shimmer>
                </div>
            ))}
            <div style={{height: '38px', width: '99px', display:'flex', marginLeft: '16px', marginTop: '16px'}}>
                <Shimmer style={{height: '38px', width: '99px'}}></Shimmer>
            </div>
        </div>
    )
}