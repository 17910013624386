import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const PolicyEditor = ({ onChange, value }) => {
  const editorRef = useRef(null);

  return (
    <Editor
      tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
      onInit={(_, editor) => (editorRef.current = editor)}
      value={value}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'preview',
          'help',
          'wordcount',
        ],
        toolbar:
          'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol' +
          'removeformat | image',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        images_file_types: 'jpg,svg,webp',
      }}
      onEditorChange={() => {
        onChange(editorRef.current.getContent());
      }}
    />
  );
};

export default PolicyEditor;
