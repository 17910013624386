import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { FormSuccess } from 'Components/Form/Success';
import { Container } from 'Components/Container';
import { Column, Row } from 'Components/Grid';
import { ButtonForm, CustomTextField, TitleForm } from 'Components/Form/styles';
import { addUser, editUser, getUserById } from 'services/users-service';
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
import { getUsersGroups } from 'services/users-groups-service';
import { useQuery } from 'react-query';
import { getCompanyRolesBySlug } from 'services/company-service';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { getSelectedContentByCompareFields, getSelectedObjectContentByCompareFields } from 'utils/select_utils';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import { PageLoaderForm } from 'Components/PageLoader/Form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name, personName, theme) => {
  return {
    fontWeight:
      personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

export const UsersForm = () => {
  const theme = useTheme();
  const { id } = useParams();
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [idUser, setIdUser] = useState(null);
  const [finished, setFinished] = useState(false);

  const { data: userGroups } = useQuery(['getUserGroups'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getUsersGroups(selectedTenant.slug);
    return !!result?.data && result?.data.length > 0 ? result?.data : [];
  });

  const { data: companyRoles } = useQuery(['getCompanyRoles'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getCompanyRolesBySlug(selectedTenant.slug);
    return (!!result?.data && result?.data.length) > 0 ? result?.data : [];
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      active: true,
      name: '',
      email: '',
      cellphone: '',
      groups: [],
      permissions: '',
    },
  });

  const handleReset = () => {
    reset();
    setFinished(false);
    setIdUser(null);
    navigate('/users/add');
  };

  const {
    data: user,
    isLoading,
    error,
  } = useQuery(['getUser', id], async () => {
    if (!id) return null;

    const result = await getUserById(id);

    if (!result?.data) return null;

    return result?.data;
  });

  const formatUser = values => {
    if (id) {
      const groupsUser =
        !!user.groups && user.groups.length > 0
          ? user.groups?.filter(group => group.companyKey !== selectedTenant.slug)
          : [];
      return {
        name: values.name,
        cellphone: values.cellphone,
        email: values.email,
        groups: [
          ...groupsUser,
          ...values.groups.map(group => {
            return {
              companyKey: selectedTenant.slug,
              name: group.name,
            };
          }),
        ],
        permissions: [
          ...user.permissions.filter(permission => permission.companyKey !== selectedTenant.slug),
          {
            companyKey: selectedTenant.slug,
            roleKey: values.permissions.roleKey,
          },
        ],
        active: user.active,
      };
    }
    return {
      name: values.name,
      cellphone: values.cellphone,
      email: values.email,
      active: true,
      groups: values.groups.map(group => {
        return {
          companyKey: selectedTenant.slug,
          name: group.name,
        };
      }),
      permissions: [
        {
          companyKey: selectedTenant.slug,
          roleKey: values.permissions.roleKey,
        },
      ],
    };
  };

  const onSubmit = async () => {
    try {
      const res = id
        ? await editUser(id, selectedTenant.slug, formatUser(getValues()))
        : await addUser(formatUser(getValues()));
      if (res.status === 200 || res.status === 201) {
        setIdUser(res.data._id ?? id);
        setFinished(true);
      } else {
        throw new Error('Ocorreu um erro ao tentar salvar/editar o Usuário!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        html: error.response?.data?.message ?? error.message,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    if (!id) return;
    if (!user) return;

    Object.keys(user).forEach(key => {
      if (key === 'permissions') {
        setValue(
          key,
          user[key].find(item => item.companyKey === selectedTenant.slug),
        );
      } else if (key === 'groups') {
        setValue(key, user[key] ?? []);
      } else {
        setValue(key, user[key]);
      }
    });

    return () => {};
  }, [id, user, selectedTenant, setValue]);

  const showForm = () => {
    if (id) return !isLoading && !error;
    return true;
  };

  return (
    <Container title={`${id ? 'Editar' : 'Adicionar'} Usuário`} breadcrumb="Gerenciar / Usuários" linkPage="/users">
      {isLoading && <PageLoaderForm />}
      {showForm() && (
        <div className="card-content">
          <Row>
            <TitleForm>Dados do usuário</TitleForm>
          </Row>
          {!finished && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row justifyContent="center">
                <Column xs={12} className="d-flex">
                  <CustomTextField
                    {...register('name', { required: 'Nome é obrigatório' })}
                    label="Nome"
                    autoFocus
                    error={errors?.name ? true : false}
                    sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                    helperText={errors?.name ? errors.name.message : null}
                    InputLabelProps={{ shrink: true }}
                  />
                </Column>
                <Column xs={12} className="d-flex">
                  <CustomTextField
                    {...register('email', { required: 'Email é obrigatório' })}
                    label="Email"
                    error={errors?.email ? true : false}
                    sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                    helperText={errors?.email ? errors.email.message : null}
                    InputLabelProps={{ shrink: true }}
                  />
                </Column>
                <Column xs={12} className="d-flex">
                  <CustomTextField
                    {...register('cellphone')}
                    label="Telefone"
                    sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Column>
                <Column xs={12} className="d-flex">
                  <Controller
                    control={control}
                    name="groups"
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                        <InputLabel id="user-group-label" shrink>
                          Grupo de usuário
                        </InputLabel>
                        <Select
                          labelId="user-group-label"
                          id="groups"
                          multiple
                          notched
                          value={getSelectedContentByCompareFields(userGroups, value, 'name', 'companyKey')}
                          onChange={onChange}
                          input={<OutlinedInput id="user-group-chip" label="Grupo de usuário" />}
                          renderValue={selected => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected?.map(selectedValue => (
                                <Chip
                                  key={`usergroup-chip-${selectedValue._id}`}
                                  label={selectedValue.name}
                                  variant="outlined"
                                  color="primary"
                                  sx={{
                                    width: 'fit-content',
                                  }}
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {userGroups?.map(item => (
                            <MenuItem key={item._id} value={item} style={getStyles(item._id, value._id, theme)}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Column>
                <Column xs={12} className="d-flex">
                  <Controller
                    control={control}
                    name="permissions"
                    rules={{
                      validate: () => {
                        return getValues('permissions') != null && getValues('permissions') !== '';
                      },
                      required: 'Responsável é obrigatório',
                    }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth sx={{ marginBottom: '24px' }}>
                        <InputLabel id="user-permission-label" shrink>
                          Permissão do usuário
                        </InputLabel>
                        <Select
                          labelId="user-permission-label"
                          id="permissions"
                          value={getSelectedObjectContentByCompareFields(companyRoles, value, 'roleKey', 'roleKey')}
                          label="Permissão do usuário"
                          onChange={onChange}
                          notched
                        >
                          <MenuItem value={''} key={-1}>
                            Selecione uma permissão
                          </MenuItem>
                          {companyRoles?.map((item, index) => (
                            <MenuItem value={item} key={`user-permission-${index}`}>
                              {item.roleName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Column>
              </Row>
              <Row justifyContent="end" alignItems="center">
                <Column xs={3} justifyContent="center" className="d-flex">
                  <ButtonForm secondary id="cancel" label="cancelar" onClick={() => navigate('/users')}></ButtonForm>
                </Column>
                <Column xs={2} justifyContent="center" className="d-flex">
                  <ButtonForm label="Salvar" variant="submit" />
                </Column>
              </Row>
            </form>
          )}
          {finished && (
            <FormSuccess
              title="Usuário cadastrado com sucesso!"
              labelStartAgain="Cadastrar novo usuário"
              handleStartAgain={() => handleReset()}
              labelShow="Ver detalhes"
              handleShow={() => navigate('/users/show/' + idUser)}
            />
          )}
        </div>
      )}
    </Container>
  );
};
