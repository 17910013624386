import styled from "styled-components";

export const Main = styled.div`

* {
  box-sizing: border-box;
}

box-shadow: 0px 6px 20px #EFF1F7;
border-radius: 8px;
display: flex;
flex-direction: column;
padding: 16px;
position: relative;
width: 198px;

h1 {
  color: #6B6664;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  margin-bottom: 8px;
}

h2 {
  color: #537DE4;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
  margin-bottom: 16px;
}

hr {
  background-color: #F7F9FB;
  border: none;
  height: 1px;
  margin: 0px;
  width: 100%;
}

div {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  
  p {
    color: #6B6664;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    margin: 0px;
  }

  span {
    color: #6B6664;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }
}

button {
  align-items: center;
  background-color: #0039C5;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 2px 10px #EFF1F7;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: -24px;
  top: 95px;
  width: 32px;
  z-index: 99;
}
`;
