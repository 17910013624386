import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import PieChart from "../Charts/PieChart";
import { IconButton } from "../styles";
const ChartCard = ({
  title,
  titleInsideCard,
  deployedCount,
  notDeployedCount,
  notApplicableCount,
  onSeeClick,
  onEditClick,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Stack spacing={2}>
      <Typography color="#0133AE" fontWeight={700} fontSize={"16px"}>
        {!titleInsideCard && title}
      </Typography>
      <Box
        bgcolor={"#fff"}
        p={3}
        borderRadius={"8px"}
        boxShadow={"0px 6px 20px #EFF1F7;"}
      >
        <Box display={"flex"}>
          <Box flex={1}>
            {titleInsideCard && (
              <Typography color="#0133AE" fontWeight={700} fontSize={"16px"}>
                {title}
              </Typography>
            )}
          </Box>
          {(onEditClick || onSeeClick) && (
            <>
              <IconButton
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                id="more-button"
              >
                <img src="/img/icons/dots.svg" alt="Icone 3 pontinhos" />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "more-button",
                }}
              >
                {onSeeClick && (
                  <MenuItem onClick={onSeeClick}>
                    <ListItemIcon>
                      <img src="/img/icons/see.svg" alt="Icone ver" />
                    </ListItemIcon>
                    <ListItemText>Ver</ListItemText>
                  </MenuItem>
                )}

                {onEditClick && (
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <img src="/img/icons/pencil.svg" alt="Icone editar" />
                    </ListItemIcon>
                    <ListItemText>Editar</ListItemText>
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </Box>
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <Box
            width={"50%"}
            minWidth={200}
            minHeight={200}
            position={"relative"}
          >
            <Box
              borderRadius={"50%"}
              bgcolor={"#fff"}
              padding={2}
              boxShadow={"0px 6px 20px #EFF1F7"}
              display={"flex"}
              justifyContent={"center"}
              allignItems={"center"}
            >
              <PieChart
                implemented={deployedCount}
                nonImplemented={notDeployedCount}
                nonApplicable={notApplicableCount}
              />
            </Box>
          </Box>
          <ChartLegend
            deployedCount={deployedCount}
            notApplicableCount={notApplicableCount}
            notDeployedCount={notDeployedCount}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default ChartCard;

const ChartLegend = ({
  deployedCount,
  notDeployedCount,
  notApplicableCount,
}) => (
  <Box
    display={"flex"}
    alignItems={"center"}
    justifyContent={"center"}
    fontSize={"12px"}
    color={"#6B6664"}
  >
    <Stack spacing={2}>
      <Stack direction={"row"}>
        <Square color={"#0039C5"} />
        <span>Controles implantados : {deployedCount}</span>
      </Stack>
      <Stack direction={"row"}>
        <Square color={"#537DE4"} />
        <span>Não implantados : {notDeployedCount} </span>
      </Stack>
      <Stack direction={"row"}>
        <Square color={"#04B2D9"} />
        <span>Não aplicável : {notApplicableCount} </span>
      </Stack>
    </Stack>
  </Box>
);

const Square = ({ color }) => (
  <Box minWidth={16} height={16} bgcolor={color} borderRadius={"6px"} mr={1} />
);
