import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import MovieIcon from "@mui/icons-material/Movie";
import { Close } from "@mui/icons-material";

const FileUpload = ({ onUpload, mt }) => {
  const [files, setFiles] = useState([
    { filename: "Arquivo 1", size: "30", progress: 50, type: "image/png" },
    { filename: "Arquivo 2", size: "45", progress: 30, type: "audio/mp3" },
    { filename: "Arquivo 3", size: "10", progress: 80, type: "video/mp4" },
    {
      filename: "Arquivo 3",
      size: "10",
      progress: 80,
      type: "application/pdf",
    },
  ]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Simulating upload process with a timeout function
      setTimeout(() => {
        onUpload(acceptedFiles);
        setFiles(acceptedFiles);
      }, 1500);
    },
    [onUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const fileIcons = {
    image: <ImageIcon sx={{ color: "#537DE4" }} />,
    audio: <AudiotrackIcon sx={{ color: "#537DE4" }} />,
    video: <MovieIcon sx={{ color: "#537DE4" }} />,
    application: <DescriptionIcon sx={{ color: "#537DE4" }} />,
  };

  const renderIcon = (file) => {
    return fileIcons[file.type.split("/")[0]] || <DescriptionIcon />;
  };

  return (
    <Box mt={mt}>
      <Box
        {...getRootProps()}
        border={"2px dashed  rgba(83, 125, 228, 1)"}
        p={4}
        borderRadius={2}
        style={{ cursor: "pointer" }}
      >
        <Stack alignItems={"center"}>
          <input {...getInputProps()} />
          <Box>
            <img src="/img/icons/upload.svg" alt="Icone upload" />
          </Box>
          <Box>
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>
                <span
                  style={{
                    fontWeight: 600,
                    textDecoration: "underline",
                    color: "#3C3938",
                  }}
                >
                  Clique para subir uma arquivo
                </span>
                , ou arraste e solte
              </p>
            )}
          </Box>
          <Typography color="#6B6664" fontWeight={500} fontSize={"14px"}>
            Tamanho máximo permitido: 50MB
          </Typography>
        </Stack>
      </Box>
      <Stack spacing={2} width={"50%"} mt={4} display={"none"}>
        {files.map((file, index) => (
          <Box
            key={index}
            display={"flex"}
            borderRadius="8px"
            border="1px solid rgba(189, 185, 183, 1)"
            p={2}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              padding={"10px 10px"}
              borderRadius={"8px"}
              border={"1px solid rgba(189, 185, 183, 1)"}
              height={"50px"}
              width={"50px"}
            >
              {renderIcon(file)}
            </Box>
            <Stack ml={4} flex={1} width={"100%"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography fontSize={14} fontWeight={700}>
                  {file.filename}
                </Typography>
                <Close sx={{ cursor: "pointer" }} />
              </Box>
              <Typography color="#6B6664" fontWeight={500} fontSize={"12px"}>
                {file.size} MB
              </Typography>
              <Stack
                direction={"row"}
                spacing={1}
                width={"100%"}
                alignItems={"center"}
              >
                <LinearProgress width={"100%"} />
                <Typography
                  color="#6B6664"
                  fontWeight={500}
                  fontSize={"12px"}
                  width={"100%"}
                  flex={1}
                >
                  {file.progress}%
                </Typography>
              </Stack>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default FileUpload;
