import { Container } from 'Components/Container';
import { TitleForm } from 'Components/Form/styles';
import { FormSuccess } from 'Components/Form/Success';
import { Row } from 'Components/Grid';
import { StepperForm } from 'Components/StepperForm';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { addCompany, editCompany, getCompanyById } from 'services/company-service';
import { Detalhes } from './Details';
import { Managers } from './Managers';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const steps = ['Detalhes', 'Coordenadores/Gerentes'];

export const CompaniesPartnerForm = () => {
  const { id } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [idCompany, setIdCompany] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [finished, setFinished] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      active: true,
      cnpj: '',
      corporateName: '',
      description: '',
      domains: [],
      income: '',
      industry: '',
      managers: [],
      name: '',
      partner: false,
      roles: [],
      size: '',
      slug: '',
      signedPlan: 'Free',
    },
  });

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() ? activeStep : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const getTitleStep = () => {
    if (finished) return <></>;

    if (activeStep === 0)
      return (
        <Row>
          <TitleForm>Detalhes do Cliente</TitleForm>
        </Row>
      );

    return (
      <Row>
        <TitleForm>Coordenadores/Gerentes</TitleForm>
      </Row>
    );
  };

  const handleReset = () => {
    reset();
    setActiveStep(0);
    setFinished(false);
    setIdCompany(null);
    navigate('/companies/add');
  };

  const transformData = data => {
    return {
      ...data,
      managers: [
        ...data.managers.map(manager => {
          return {
            _id: manager._id,
            name: manager.name,
          };
        }),
      ],
    };
  };

  const handleComplete = async () => {
    try {
      const res = id ? await editCompany(id, transformData(getValues())) : await addCompany(transformData(getValues()));
      if (res.status === 200 || res.status === 201) {
        setIdCompany(res.data._id ?? id);
        setFinished(true);
      } else {
        throw new Error('Ocorreu um erro ao tentar salvar/editar a Empresa!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const getCompany = useCallback(async () => {
    try {
      const resCompany = await getCompanyById(id);
      Object.keys(resCompany.data).forEach(key => {
        setValue(key, resCompany.data[key]);
      });
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  }, [id, setValue, SwalReact]);

  useEffect(() => {
    if (!id) return;

    getCompany();

    return () => {};
  }, [id, getCompany]);

  return (
    <Container
      title={`${id ? 'Editar' : 'Adicionar'} Clientes`}
      breadcrumb="Configurações Glogais / Clientes"
      linkPage="/companies"
    >
      <StepperForm activeStep={activeStep} steps={steps} />
      <div className="card-content">
        {getTitleStep()}
        {activeStep === 0 && (
          <Detalhes
            control={control}
            register={register}
            handleNext={handleNext}
            handleSubmit={handleSubmit}
            errors={errors}
            getValues={getValues}
          />
        )}

        {activeStep === 1 && !finished && (
          <Managers
            control={control}
            register={register}
            handleBack={handleBack}
            handleComplete={handleComplete}
            handleSubmit={handleSubmit}
            errors={errors}
            getValues={getValues}
          />
        )}
        {finished && (
          <FormSuccess
            title="Cliente cadastrado com sucesso!"
            labelStartAgain="Cadastrar nova cliente"
            handleStartAgain={() => handleReset()}
            labelShow="Ver detalhes"
            handleShow={() => navigate('/companies/show/' + idCompany)}
          />
        )}
      </div>
    </Container>
  );
};
