import { Box, Stack } from "@mui/material";
import React, { useCallback } from "react";
import Header from "./Header";
import { Button } from "./styles";
import { useNavigate } from "react-router-dom";
import {
  deleteGapAnalysisReport,
  listGapAnalysisReports,
} from "services/gap-analysis-report";
import { useAuthTenant } from "store/auth";
import { useSelector } from "react-redux";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { ModalConfirmDelete } from "Components/ModalConfirmDelete";

export default function ReportsGapAnalysis() {
  const selectedTenant = useSelector(useAuthTenant);
  const [reports, setReports] = React.useState([]);
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [selectedReport, setSelectedReport] = React.useState(null);

  const fetchReports = useCallback(async () => {
    const result = await listGapAnalysisReports(selectedTenant.slug);
    if (result?.data) {
      setReports(result?.data);
    }
  }, [selectedTenant.slug]);

  React.useEffect(() => {
    fetchReports();
  }, [fetchReports]);

  const onDelete = async () => {
    const result = await deleteGapAnalysisReport(selectedReport._id);
    if (result.status === 200) {
      setReports(reports.filter((item) => item._id !== selectedReport._id));
    }
    setDeleteModalOpen(false);
    selectedReport(null);
  };

  return (
    <Box padding={4}>
      <Header
        title={"Relatórios"}
        breadcrumb={"Relatórios / Gap analysis"}
        actions={
          <>
            <Button onClick={() => navigate("add")}>
              <img src="/img/icons/plus.svg" alt="Icone mais" /> Adicionar
            </Button>
          </>
        }
      />

      <Stack bgcolor={"#fff"} p={4} mt={4} gap={2}>
        <Box
          border={"1px solid #F1EFEE"}
          p={2}
          borderRadius={2}
          fontWeight={700}
          display={"grid"}
          gridTemplateColumns={"200px 1fr"}
          gap={2}
          color={"#3C3938"}
        >
          <Box>Padrão</Box>
          <Box>Data criação</Box>
        </Box>

        {reports.map((item) => (
          <Box
            border={"1px solid #F1EFEE"}
            p={2}
            borderRadius={2}
            display={"grid"}
            gridTemplateColumns={"200px 1fr 100px"}
            gap={2}
            color={"#3C3938"}
          >
            <Box>
              {item.securityStandard?.name} (Versão {item.version})
            </Box>
            <Box>{new Date(item.created_at).toLocaleDateString()}</Box>
            <Box display={"flex"} alignItems={"center"} gap={2}>
              <img
                width={"24px"}
                height={"24px"}
                src="/img/icons/see.svg"
                alt="Ícone de arquivo"
                onClick={() => navigate(`show/${item._id}`)}
                style={{ cursor: "pointer" }}
              />
              <img
                width={"24px"}
                height={"24px"}
                src="/img/icons/print.svg"
                alt="Ícone de impressora"
                style={{ cursor: "pointer" }}
              />
              <DeleteOutlineOutlined
                style={{ color: "#3f6ad3" }}
                onClick={() => {
                  setSelectedReport(item);
                  setDeleteModalOpen(true);
                }}
              />
            </Box>
          </Box>
        ))}
        <ModalConfirmDelete
          description={
            <>
              Tem certeza que deseja excluir o relatório{" "}
              {selectedReport?.securityStandard?.name} - Versão{" "}
              {selectedReport?.version}?
            </>
          }
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={onDelete}
        />
      </Stack>
    </Box>
  );
}
