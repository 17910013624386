import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Column, Row } from 'Components/Grid';
import LogoOutlineIcon from '../../../assets/logo_outline.png';
import HourGlassIcon from '../../../assets/hourglass.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation, useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { createCheckoutSession, getPricesByProductCheckout, getProductsCheckout } from 'services/checkout-service';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { formatMoney } from 'utils/number_utils';
import { getPresetPlan } from 'services/company-service';

export const OnboardChoosePlan = () => {
  const { slug } = useParams();
  const SwalReact = withReactContent(Swal);
  const [expandSelectPeriod, setExpandSelectPeriod] = useState(true);
  const [expandDetailPlan, setExpandDetailPlan] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPeriodPlan, setSelectedPeriodPlan] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data: presetPlan } = useQuery(['getPresetPlan', slug], async () => {
    if (!slug) return null;
    const result = await getPresetPlan(slug);
    return result?.data ? result?.data : null;
  });

  const {
    data: products,
    isLoading: productLoading,
    error: productError,
  } = useQuery(['getProducts'], async () => {
    const result = await getProductsCheckout();
    return result?.data.data.length > 0 ? result?.data.data : [];
  });

  const {
    data: prices,
    isLoading: priceLoading,
    error: priceError,
  } = useQuery(['getPricesByProduct', selectedPlan], async () => {
    if (!selectedPlan) return [];
    const result = await getPricesByProductCheckout(selectedPlan.id);
    return result?.data.data.length > 0 ? result?.data.data : [];
  });

  const mutation = useMutation(
    priceItem => {
      return createCheckoutSession(priceItem.id, slug);
    },
    {
      onError: error => {
        // An error happened!
        SwalReact.fire({
          title: 'Erro!',
          text: error.response.data.message ?? error.message,
          icon: 'error',
        });
      },
      onSuccess: data => {
        if (data.status === 200) {
          window.location.href = data.data.url;
        }
      },
    },
  );

  useEffect(() => {
    if (!selectedPlan && products?.length > 0 && !presetPlan) {
      setSelectedPlan(products[0]);
    } else if (!selectedPlan && products?.length > 0 && presetPlan) {
      setSelectedPlan(products.find(product => product.metadata.vo_key === presetPlan.plan));
    }
  }, [products, selectedPlan, presetPlan]);

  useEffect(() => {
    if (selectedPlan && !selectedPeriodPlan && prices?.length > 0) {
      setSelectedPeriodPlan(prices[0]);
    }
  }, [prices, selectedPlan, selectedPeriodPlan]);

  const goToLoginPage = () => {
    const urlSplit = window.location.origin.split('/');
    const baseUrl = urlSplit[2].substring(3);
    window.location.href = `https://${slug}.${baseUrl}/login`;
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        height: '100%',
        paddingInline: '24px',
        fontFamily:
          '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        backgroundColor: '#F7F9FB',
      }}
      className="container"
    >
      <Row alignItems="start">
        <Column sm={8}>
          <div className="mb-4 mt-5">
            <img
              alt="Logo com borda azulada e plano de fundo vazado"
              src={LogoOutlineIcon}
              style={{
                maxHeight: '240px',
                marginInline: '0',
                display: 'flex',
              }}
            />
          </div>
          <Accordion
            defaultExpanded={true}
            expanded={expandSelectPeriod}
            onChange={() => setExpandSelectPeriod(!expandSelectPeriod)}
            sx={{
              borderRadius: '8px',
              padding: '8px 16px 16px',
              '::before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Row>
                <Column sm={'auto'}>
                  <img
                    alt="Ampulheta"
                    src={HourGlassIcon}
                    style={{
                      margin: '0',
                      display: 'flex',
                    }}
                  />
                </Column>
                <Column sm={10} className="pl-0">
                  Selecione um periodo
                </Column>
                <Column sm={12}>
                  <Typography sx={{ color: '#BDB9B7', fontWeight: 400 }} fontSize={14}>
                    Escolha com qual frequência você gostaria de ser cobrado
                  </Typography>
                </Column>
              </Row>
            </AccordionSummary>
            <AccordionDetails>
              {!priceLoading && !priceError && prices.length > 0 && (
                <RadioGroup
                  aria-label="visualization-mode"
                  value={selectedPeriodPlan?.id || ''}
                  // onChange={(item) => }
                >
                  {prices.map((price, index) => (
                    <Row
                      key={price.id}
                      style={{
                        maxHeight: '58px',
                        border: '1px solid #F1EFEE',
                        borderRadius: '8px',
                        paddingBlock: '8px',
                      }}
                      className={index > 0 ? 'mt-3' : ''}
                    >
                      <Column sm={'auto'} className="px-1">
                        <Radio
                          value={price.id}
                          onClick={() => setSelectedPeriodPlan(price)}
                          sx={{
                            width: 'fit-content !important',
                          }}
                        />
                      </Column>
                      <Column sm={10} className="pl-2">
                        <Typography
                          sx={{
                            color: '#3C3938',
                            fontWeight: 700,
                          }}
                          fontSize={14}
                        >
                          {price.recurring.interval === 'month' ? 'Por mês' : 'Por ano'}
                        </Typography>
                        <Typography
                          sx={{
                            color: '#6B6664',
                            fontWeight: 400,
                          }}
                          fontSize={12}
                        >
                          {formatMoney(price.unit_amount * 0.01)}
                          {price.recurring.interval === 'month' ? '/mês' : '/ano'}
                        </Typography>
                      </Column>
                    </Row>
                  ))}
                </RadioGroup>
              )}
            </AccordionDetails>
          </Accordion>
        </Column>
        <Column sm={4}>
          <Card
            className="mt-5"
            sx={{
              padding: '16px 16px',
              borderRadius: '8px',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            <Row>
              <Column sm={7}>
                <Typography sx={{ color: '#3C3938', fontWeight: 700 }} fontSize={14}>
                  {!!selectedPlan ? selectedPlan?.description : 'Selecione um plano'}
                </Typography>
              </Column>
              <Column flex justifyContent="end">
                {!productLoading && !productError && (
                  <>
                    <Button
                      id="plan-button"
                      aria-controls={open ? 'plan-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      Mudar plano
                    </Button>
                    <Menu
                      id="plan-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'plan-button',
                      }}
                    >
                      {products?.map(product => (
                        <MenuItem
                          key={product.id}
                          onClick={() => {
                            setSelectedPlan(product);
                            setSelectedPeriodPlan(null);
                            handleClose();
                          }}
                        >
                          {product.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </Column>
            </Row>
            <Row>
              <Column>
                <Typography
                  sx={{
                    color: '#BDB9B7',
                    fontWeight: 400,
                  }}
                  fontSize={14}
                >
                  {selectedPlan?.description || ''}
                </Typography>
              </Column>
            </Row>
          </Card>

          <Accordion
            defaultExpanded={true}
            expanded={expandDetailPlan}
            onChange={() => setExpandDetailPlan(!expandDetailPlan)}
            sx={{
              borderRadius: '8px',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              padding: '8px 0px 16px',
              '::before': {
                display: 'none',
              },
              marginTop: '0!important',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                borderBottom: '1px solid #F1EFEE',
                maxHeight: '48px',
                minHeight: '48px!important',
              }}
            >
              <Row>
                <Column sm={12}>
                  <Typography sx={{ color: '#0039C5', fontWeight: 700 }} fontSize={14}>
                    Detalhes do plano
                  </Typography>
                </Column>
              </Row>
            </AccordionSummary>
            <AccordionDetails>
              <Row style={{ maxHeight: '18px' }}>
                <Column>
                  <Typography sx={{ color: '#BDB9B7', fontWeight: 700 }} fontSize={14}>
                    Subtotal
                  </Typography>
                </Column>
                <Column flex justifyContent="end">
                  <Typography sx={{ color: '#3C3938', fontWeight: 700 }} fontSize={14}>
                    {!!selectedPeriodPlan ? formatMoney(selectedPeriodPlan.unit_amount * 0.01) : 'R$0,00'}
                  </Typography>
                </Column>
              </Row>
              <Row style={{ maxHeight: '18px' }}>
                <Column>
                  <Typography sx={{ color: '#BDB9B7', fontWeight: 700 }} fontSize={14}>
                    Desconto
                  </Typography>
                </Column>
                <Column flex justifyContent="end">
                  <Typography sx={{ color: '#3C3938', fontWeight: 700 }} fontSize={14}>
                    R$0,00
                  </Typography>
                </Column>
              </Row>
              <Row style={{ maxHeight: '36px' }} className="mt-3">
                <Column>
                  <Typography sx={{ color: '#BDB9B7', fontWeight: 700 }} fontSize={14}>
                    Total
                  </Typography>
                </Column>
                <Column flex justifyContent="end">
                  <Typography sx={{ color: '#3C3938', fontWeight: 700 }} fontSize={19}>
                    {!!selectedPeriodPlan ? formatMoney(selectedPeriodPlan.unit_amount * 0.01) : 'R$0,00'}
                  </Typography>
                </Column>
              </Row>
            </AccordionDetails>
          </Accordion>

          <Row className="mt-4">
            <Column sm={6}>
              <Button variant={'outlined'} color={'primary'} onClick={() => goToLoginPage()}>
                Acessar agora
              </Button>
            </Column>
            <Column sm={6}>
              <Button variant={'contained'} color={'primary'} onClick={() => mutation.mutate(selectedPeriodPlan)}>
                Contratar agora
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
    </Box>
  );
};
