import { Card } from "Components/Card"
import { Row } from "Components/Grid"
import { TitleFooterProcess, TitleMainContentProcess, TitleProcess } from "./styles"
import { Box } from "@mui/material"

export const ReportsRopaProcess = ({process, filter, data, category, legalBase, processing, position}) => {
    return (
        <Card className="mt-4 p-report mtp-0 ptp-8 pbp-8">
            <Row className="mtp-0 page-break-auto">
                <TitleProcess xs={12} sm={12} md={12} className="page-break-auto">
                    Processo
                </TitleProcess>
                <TitleMainContentProcess xs={12} sm={6} md={6} className="page-break-auto">
                    <Row className="pl-3 font-weight-bold mb-2">Processo</Row>
                    <Row className="pl-3">{process.name}</Row>
                </TitleMainContentProcess>
                <TitleMainContentProcess xs={12} sm={6} md={6} className="page-break-auto">
                    <Row className="pl-3 font-weight-bold mb-2">Responsável</Row>
                    <Row className="pl-3">{process.user}</Row>
                </TitleMainContentProcess>
                <TitleFooterProcess xs={12} sm={12} md={12} className="page-break-auto">
                    <Row className="pl-3 font-weight-bold mb-2">Descrição de fluxo de dados</Row>
                    <Row className="pl-3 mb-2">{process.descriptionDataFlow}</Row>
                </TitleFooterProcess>
            </Row>
            {!!process.dataMapping && process.dataMapping.filter((dataMap) => {
                if (filter === 'Dados' && !!data) {
                    return (dataMap.name) === data
                }
                if (filter === 'Categoria' && !!category) {
                    return (dataMap.category) === category
                }
                if (filter === 'Tratamento' && !!processing) {
                    return dataMap.dataProcessing.some((itemDataProcess) => {
                        return (itemDataProcess.processing) === processing
                    })
                }
                if (filter === 'Legal Base' && !!legalBase) {
                    return dataMap.dataProcessing.some((itemDataProcess) => {
                        return (itemDataProcess.legalBase) === legalBase
                    })
                }
                if (filter === 'Posição' && !!position) {
                    return dataMap.dataProcessing.some((itemDataProcess) => {
                        return (itemDataProcess.position) === position
                    })
                }
                return true
            }).map((dataMap, index) => (
                <Box key={`${dataMap.name}${index}`}>
                    <Row className="mt-4 mtp-0 mbp-0 ptp-8 pbp-8 page-break-auto">
                        <TitleProcess xs={12} sm={12} md={12}className="page-break-auto">
                            Dados
                        </TitleProcess>
                        <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                            <Row className="pl-3 font-weight-bold mb-2">Dado</Row>
                            <Row className="pl-3">{dataMap.name}</Row>
                        </TitleMainContentProcess>
                        <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                            <Row className="pl-3 font-weight-bold mb-2">Origem</Row>
                            <Row className="pl-3">{dataMap.dataSource}</Row>
                        </TitleMainContentProcess>
                        <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                            <Row className="pl-3 font-weight-bold mb-2">Perfil do Titular</Row>
                            <Row className="pl-3">{dataMap.holderProfile}</Row>
                        </TitleMainContentProcess>
                        <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                            <Row className="pl-3 font-weight-bold mb-2">Retenção</Row>
                            <Row className="pl-3">{dataMap.retentionPeriod}</Row>
                        </TitleMainContentProcess>
                        <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                            <Row className="pl-3 font-weight-bold mb-2">Categoria</Row>
                            <Row className="pl-3">{dataMap.category}</Row>
                        </TitleMainContentProcess>
                        <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                            <Row className="pl-3 font-weight-bold mb-2">Armazenamento</Row>
                            <Row className="pl-3">{dataMap.storage}</Row>
                        </TitleMainContentProcess>
                    </Row>
                    {dataMap.dataProcessing && dataMap.dataProcessing.filter((dataProcess) => {
                        if (filter === 'Tratamento' && !!processing) {
                            return dataProcess.processing === processing
                        }
                        if (filter === 'Legal Base' && !!legalBase) {
                            return dataProcess.legalBase === legalBase
                        }
                        if (filter === 'Posição' && !!position) {
                            return dataProcess.position === position
                        }
                        return true
                    }).map((dataProcess) => (
                        <Row className="mtp-0 page-break-auto" key={dataProcess.processing}>
                            <TitleProcess className="no-radius page-break-auto" xs={12} sm={12} md={12}>
                                Tratamentos
                            </TitleProcess>
                            <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                                <Row className="pl-3 font-weight-bold mb-2">Tratamento</Row>
                                <Row className="pl-3">{dataProcess.processing}</Row>
                            </TitleMainContentProcess>
                            <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                                <Row className="pl-3 font-weight-bold mb-2">Finalidade</Row>
                                <Row className="pl-3">{dataProcess.finality}</Row>
                            </TitleMainContentProcess>
                            <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                                <Row className="pl-3 font-weight-bold mb-2">Posição</Row>
                                <Row className="pl-3">{dataProcess.position}</Row>
                            </TitleMainContentProcess>
                            <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                                <Row className="pl-3 font-weight-bold mb-2">Base Legal</Row>
                                <Row className="pl-3">{dataProcess.legalBase}</Row>
                            </TitleMainContentProcess>
                            <TitleMainContentProcess xs={12} sm={12} md={12}className="page-break-auto">
                                <Row className="pl-3 font-weight-bold mb-2">Complemento Base Legal</Row>
                                <Row className="pl-3">{dataProcess.complementLegalBasis}</Row>
                            </TitleMainContentProcess>
                            <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                                <Row className="pl-3 font-weight-bold mb-2">Ferramenta</Row>
                                <Row className="pl-3">{dataProcess.tool}</Row>
                            </TitleMainContentProcess>
                            <TitleMainContentProcess xs={12} sm={6} md={6}className="page-break-auto">
                                <Row className="pl-3 font-weight-bold mb-2">Destino</Row>
                                <Row className="pl-3">{dataProcess.destiny}</Row>
                            </TitleMainContentProcess>
                            <TitleFooterProcess xs={12} sm={12} md={12}className="page-break-auto">
                                <Row className="pl-3 font-weight-bold mb-2">Compartilhamento</Row>
                                <Row className="pl-3">{dataProcess.shareType}</Row>
                            </TitleFooterProcess>
                        </Row>
                    ))}
                </Box>
            ))}
        </Card>
    )
}