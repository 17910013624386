import { backendPrivate } from "services";

export const addReportGapAnalysis = async (data) => {
  return await backendPrivate.post("reports/gap-analysis", data);
};

export const listGapAnalysisReports = async (tenantId) => {
  return await backendPrivate.get(`/reports/gap-analysis?companyKey=${tenantId}`);
};

export const getGapAnalysisReport = async (id) => {
  return await backendPrivate.get(`/reports/gap-analysis/${id}`);
};

export const deleteGapAnalysisReport = async (id) => {
  return await backendPrivate.delete(`/reports/gap-analysis/${id}`);
};
