import { backendPrivate } from "services"

export const getProcesses = async (tenant) => {
    return await backendPrivate.get(`/process?companyKey=${tenant}`)
}

export const getProcessesPaged = async (tenant, page, perPage) => {
    return await backendPrivate.get(`/process/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getProcessesShort = async (tenant) => {
    return await backendPrivate.get(`/process/short-data?companyKey=${tenant}`)
}

export const getProcessesShortPaged = async (tenant, page, perPage, queryContent) => {
    return await backendPrivate.get(`/process/short-data-paged?companyKey=${tenant}&page=${page}&perPage=${perPage}${queryContent}`)
}

export const getProcessesNames = async (tenant) => {
    return await backendPrivate.get(`/process/names?companyKey=${tenant}`)
}

export const getProcessById = async (id) => {
    return await backendPrivate.get(`/process/${id}`)
}

export const getProcessMappingByIdAndMappingId = async (id, processMappingId) => {
    return await backendPrivate.get(`/process/${id}/mapping/${processMappingId}`)
}

export const getProcessBoundsById = async (id) => {
    return await backendPrivate.get(`/process/${id}/bounds`)
}

export const addProcess = async (process) => {
    return await backendPrivate.post('/process', process)
}

export const editProcess = async (id, process) => {
    return await backendPrivate.put(`/process/${id}`, process)
}

export const addProcessDataMapping = async (id, processDataMapping) => {
    return await backendPrivate.post(`/process/${id}/mapping`, processDataMapping)
}

export const editProcessDataMapping = async (id, dataMappingId, processDataMapping) => {
    return await backendPrivate.put(`/process/${id}/mapping/${dataMappingId}`, processDataMapping)
}

export const importProcessDataMapping = async (id, content) => {
  return await backendPrivate.post(`/process/${id}/import-mapping`, content)
}

export const deleteProcess = async (id) => {
    return await backendPrivate.delete(`/process/${id}`)
}

export const deleteProcessMapping = async (processId, processMappingId) => {
    return await backendPrivate.delete(`/process/${processId}/mapping/${processMappingId}`)
}