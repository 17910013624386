import React from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import styled from "styled-components";

const IconButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 10px;
  color: #537de4;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  min-width: 60;
  padding: 8px 16px;
  box-shadow: 0px 6px 20px #eff1f7;

  &:hover {
    background-color: #e2e2e2e2;
  }
`;

function MenuButton({ iconSrc, menuItems = [] }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        id="more-button"
      >
        <img src={iconSrc} alt="Icone mais opções" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "more-button",
        }}
      >
        {menuItems.map((item) => (
          <MenuItem onClick={item.onClick}>
            <ListItemIcon>
              <img src={item.iconSrc} alt="Icone ver" />
            </ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default MenuButton;
