import styled from "styled-components";

export const Title = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #0039c5;
`;

export const OutlinedButton = styled.button`
  align-items: center;
  background-color: white;
  border: 1px solid #537de4;
  border-radius: 12px;
  color: #537de4;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  padding: 8px 16px;

  img {
    margin-right: 8px;
  }
`;

export const OutlinedButtonSmall = styled.button`
  align-items: center;
  background-color: white;
  border: 1px solid #537de4;
  border-radius: 12px;
  color: #537de4;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  padding: 4px 16px;

  img {
    margin-right: 8px;
  }
`;

export const Button = styled.button`
  align-items: center;
  background-color: #0039c5;
  border: none;
  border-radius: 12px;
  color: white;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  padding: 8px 16px;

  img {
    margin-right: 8px;
  }
`;

export const Subtitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: gray;
  width: fit-content;
`;

export const IconButton = styled.button`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 10px;
  color: #537de4;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  min-width: 60;
  padding: 8px 16px;
  box-shadow: 0px 6px 20px #eff1f7;

  &:hover {
    background-color: #e2e2e2e2;
  }
`;

export const GridBox = styled.div`
  display: grid;
  gap: 32px;
  margin-top: 4px;
  grid-template-columns: repeat(auto-fill, minmax(415px, 1fr));

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
