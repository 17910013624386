import { Box, Chip, Tab, Tabs } from '@mui/material'
import { Container } from 'Components/Container'
import { Column } from 'Components/Grid'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ColumnEdit, LinkEdit, RowExhibition } from './styles'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { getAssetById } from 'services/assets-service'
import { PageLoaderShow } from 'Components/PageLoader/Show'

const TabPanel = (props) => {
  const { children, value, index, id, ariaLabel, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={id}
      aria-labelledby={ariaLabel}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export const AssetsShow = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [tabPosition, setTabPosition] = useState(0)
  const { isLoading, error, data: asset } = useQuery('getAssetById', async () => {
    const dataAsset = await getAssetById(id)
    return (dataAsset.status === 200)  ? dataAsset.data : null
  })
  const onClickEdit = () => {
    navigate('/assets/edit/'+id)
  }

  return (
    <>
      {!isLoading && !error && (
        <Container
          title={`${asset?.name}`}
          breadcrumb={`Mapeamento / Ativos / ${asset?.name}`}
          linkPage="/assets"
        >
          <Box sx={{ width: '100%' }} className="container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabPosition}
                onChange={(_, newValue) => setTabPosition(newValue)}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Detalhes"
                  id="tab-details"
                  aria-controls="tabpanel-details"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Vínculos"
                  id="tab-links"
                  aria-controls="tabpanel-links"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
              </Tabs>
            </Box>
            <TabPanel
              value={tabPosition}
              index={0}
              id="tabpanel-details"
              ariaLabel="tab-details"
            >
              <RowExhibition>
                <Column xs={3}>Nome</Column>
                <Column>{asset.name}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Criticidade</Column>
                <Column>{asset.criticity}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Responsável</Column>
                <Column>{asset.user}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>PII</Column>
                <Column>{asset.pii ? 'Sim' : 'Não'}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>TAGs</Column>
                <Column>{asset.tags?.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    variant="outlined"
                    color="primary"
                    sx={{
                      width: 'fit-content',
                    }}
                  />
                ))}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Descrição</Column>
                <Column>{asset.description}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Local</Column>
                <Column>{asset.location}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit  onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
            <TabPanel
              value={tabPosition}
              index={1}
              id="tabpanel-links"
              ariaLabel="tab-links"
            >
              <RowExhibition>
                <Column xs={3}>Áreas vinculadas</Column>
                <Column>
                  {asset?.areas?.map((area) => (
                    <Chip
                      key={area.name}
                      label={area.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Processos vinculados</Column>
                <Column>
                  {asset?.processes?.map((process) => (
                    <Chip
                      key={process.name}
                      label={process.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Sistemas vinculados</Column>
                <Column>
                  {asset?.systems?.map((system) =>(
                      <Chip
                        key={system.name}
                        label={system.name}
                        variant="outlined"
                        color="primary"
                        sx={{
                          width: 'fit-content',
                        }}
                      />))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
          </Box>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  )
}
