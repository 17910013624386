import * as dayjs from "dayjs"
import 'dayjs/locale/pt-br'
dayjs.locale('pt-br')

export const formatDateToDateBR = (dateToFormat) => {
    if (!dateToFormat) return ''
    return dayjs(dateToFormat).format('DD/MM/YYYY')
}
export const formatDateToDateBRPolish = (dateToFormat) => {
    if (!dateToFormat) return ''
    return dayjs(dateToFormat).format('DD [de] MMMM, YYYY')
}
export const formatDateTimeToDateTimeBR = (dateToFormat, withoutAs = false) => {
    if (!dateToFormat) return ''
    if (withoutAs === true) return dayjs(dateToFormat).format('DD/MM/YYYY HH:mm')
    return dayjs(dateToFormat).format('DD/MM/YYYY [às] HH:mm')
}