import { Card } from 'Components/Card';
import NoDataIcon from '../../assets/no_data.png';
import { Column, Row } from 'Components/Grid';

export const NoData = ({ description, xs = 8, md = 8, lg = 8 }) => {
  return (
    <Row>
      <Column xs={xs} md={md} lg={lg} className="mx-auto">
        <Card className="mt-3">
          <Row>
            <Column xs={12} md={12} lg={12}>
              <img
                alt="Caixa vazia"
                src={NoDataIcon}
                style={{
                  maxHeight: '240px',
                  margin: '0 auto',
                  display: 'flex',
                }}
              />
            </Column>
            <Column xs={12} md={12} lg={12} className="text-center font-weight-bold">
              {description}
            </Column>
          </Row>
        </Card>
      </Column>
    </Row>
  );
};
