import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  TimeScale,
  Tooltip,
  Title,
  Legend,
  PointElement,
} from "chart.js";
import "chartjs-adapter-date-fns";

Chart.register(
  CategoryScale,
  LinearScale,
  LineController,
  LineElement,
  TimeScale,
  Tooltip,
  Title,
  Legend,
  PointElement
);

const LineChart = ({ data, period, viewByControl }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const generateDeployedData = (data) => data.map((item) => item.deployed);
    const generateNotDeployedData = (data) =>
      data.map((item) => item.notDeployed);
    const generateNotApplicableData = (data) =>
      data.map((item) => item.notApplicable);

    const generatePartiallyDeployedData = (data) =>
      data.map((item) => item.partiallyDeployed);

    const generateLabels = (period) => {
      const currentDate = new Date();
      const labels = [];

      for (let i = period - 1; i >= 0; i--) {
        const date = new Date();
        date.setMonth(currentDate.getMonth() - i);
        const month = date.toLocaleString("default", { month: "short" });
        labels.push(month);
      }

      return labels;
    };

    const months = data[0]?.mapByMonth?.map((item) => item.month);
    const newData = months.map((month) => {
      const totalDeployedInMonth = data
        .map((item) => item.mapByMonth)
        .flat()
        .filter((item) => item.month === month)
        .reduce((sum, item) => sum + item.deployed, 0);

      const totalNotDeployedInMonth = data
        .map((item) => item.mapByMonth)
        .flat()
        .filter((item) => item.month === month)
        .reduce((sum, item) => sum + item.notDeployed, 0);

      const totalNotApplicableInMonth = data
        .map((item) => item.mapByMonth)
        .flat()
        .reduce((sum, item) => sum + item.notApplicable, 0);

      const totalPartiallyDeployedInMonth = data
        .map((item) => item.mapByMonth)
        .flat()
        .filter((item) => item.month === month)
        .reduce((sum, item) => sum + item.partiallyDeployed, 0);

      return {
        deployed: totalDeployedInMonth,
        month: month,
        notApplicable: totalNotApplicableInMonth,
        notDeployed: totalNotDeployedInMonth,
        partiallyDeployed: totalPartiallyDeployedInMonth,
      };
    });

    const labels = generateLabels(period);

    const deployedStandardsData = generateDeployedData(newData);
    const notDeployedData = generateNotDeployedData(newData);
    const notApplicableData = generateNotApplicableData(newData);
    const partiallyDeployedData = generatePartiallyDeployedData(newData);

    setChartData({
      labels,
      datasets: [
        {
          label: viewByControl ? "Implantados" : "Atende",
          data: deployedStandardsData,
          fill: false,
          backgroundColor: "#04B2D9",
          borderColor: "#04B2D9",
        },
        {
          label: viewByControl ? "Não implantados" : "Não atende",
          data: notDeployedData,
          fill: false,
          backgroundColor: "#DFC175",
          borderColor: "#DFC175",
        },
        {
          label: "Não aplicável",
          data: notApplicableData,
          fill: false,
          backgroundColor: "#e2e2e2",
          borderColor: "#e2e2e2",
        },
        {
          label: viewByControl
            ? "Parcialmente implantados"
            : "Atende parcialmente",
          data: partiallyDeployedData,
          fill: false,
          backgroundColor: "#c1eaf3",
          borderColor: "#c1eaf3",
        },
      ],
    });
  }, [data, period, viewByControl]);

  return (
    <div>
      <Line
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top",
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default LineChart;
