import DotsLoader from 'Components/DotsLoader';
import { ButtonForm } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { userAuth } from 'services/auth-service';
import { authenticatedFailure, authenticatedFinish, authenticatedSuccess, login, useAuthLoading } from 'store/auth';
import { getTenant } from 'utils/tenant_utils';
import { HasAccount, Observation, SubtitleLogin, TitleLogin, InputCode } from './styles';

export const VerifyCode = () => {
  const { email } = useParams();
  const loading = useSelector(useAuthLoading);
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
    },
  });
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const mutation = useMutation(
    ({ code, email }) => {
      dispatch(login());
      return userAuth(email, code, getTenant());
    },
    {
      onError: error => {
        // An error happened!
        dispatch(authenticatedFailure());
        setErrorMessage(error.response.data.message);
      },
      onSuccess: data => {
        setErrorMessage(null);
        dispatch(
          authenticatedSuccess({
            user: data.data.user,
            token: data.data.token,
            refreshToken: data.data.refreshToken,
          }),
        );
        setTimeout(() => {
          dispatch(authenticatedFinish());
          navigate(`/dashboard`);
        }, 4000);
      },
    },
  );

  const verif = async () => {
    const codes = getValues();
    const code = `${codes.code1}${codes.code2}${codes.code3}${codes.code4}${codes.code5}${codes.code6}`.substring(0, 6);

    mutation.mutate({ code, email });
  };
  const tabField = (value, nextField) => {
    if (!isNaN(value) && value !== undefined && value !== '') {
      document.getElementById(nextField).focus();
    }
  };
  const onPaste = value => {
    let contentPasted = value;
    if (value.length > 6) {
      contentPasted = value.substring(0, 6);
    }
    for (let i = 0; i < contentPasted.length; i++) {
      setValue(`code${i + 1}`, value.charAt(i));
    }
    document.getElementById(`code${contentPasted.length}`).focus();
  };

  const hasErrorsField = () => {
    return errors.code1 || errors.code2 || errors.code3 || errors.code4 || errors.code5 || errors.code6;
  };

  return (
    <form onSubmit={handleSubmit(verif)} className="pt-5">
      <Row justifyContent="center" className="mt-5">
        <Column xs={12} className="text-center mb-3">
          <TitleLogin>Virtual</TitleLogin>
          <TitleLogin className="bold"> Officer</TitleLogin>
        </Column>
        <Column xs={12} className="text-center mb-2">
          <SubtitleLogin>Verifique o Codigo Enviado por E-mail</SubtitleLogin>
        </Column>
        <Column xs={12} className="text-center">
          <Observation>
            Enviamos um código de validação para o email informado .<br />
            <span>nome@email-empresa.com.br</span>
          </Observation>
        </Column>
      </Row>
      <Row justifyContent="center">
        <InputCode
          onKeyUp={e => tabField(e.target.value, 'code2')}
          onPaste={e => {
            onPaste(e.clipboardData.getData('Text'));
          }}
          id="code1"
          {...register(`code1`, { required: true })}
          inputProps={{
            minLength: 1,
            maxLength: 1,
            pattern: '[0-9]*',
          }}
          tabIndex="1"
          error={errors?.code1 ? true : false}
          sx={{
            input: {
              padding: '16.5px 20px',
              width: '100%',
            },
            '.MuiOutlinedInput-root': {
              padding: 0,
            },
          }}
        />
        <InputCode
          onKeyUp={e => tabField(e.target.value, 'code3')}
          id="code2"
          {...register(`code2`, { required: true })}
          inputProps={{
            minLength: 1,
            maxLength: 1,
            pattern: '[0-9]*',
          }}
          tabIndex="2"
          error={errors?.code2 ? true : false}
          sx={{
            input: {
              padding: '16.5px 20px',
              width: '100%',
            },
            '.MuiOutlinedInput-root': {
              padding: 0,
            },
          }}
        />
        <InputCode
          onKeyUp={e => tabField(e.target.value, 'code4')}
          id="code3"
          {...register(`code3`, { required: true })}
          inputProps={{
            minLength: 1,
            maxLength: 1,
            pattern: '[0-9]*',
          }}
          tabIndex="3"
          error={errors?.code3 ? true : false}
          sx={{
            input: {
              padding: '16.5px 20px',
              width: '100%',
            },
            '.MuiOutlinedInput-root': {
              padding: 0,
            },
          }}
        />
        <InputCode
          onKeyUp={e => tabField(e.target.value, 'code5')}
          id="code4"
          {...register(`code4`, { required: true })}
          inputProps={{
            minLength: 1,
            maxLength: 1,
            pattern: '[0-9]*',
          }}
          tabIndex="4"
          error={errors?.code4 ? true : false}
          sx={{
            input: {
              padding: '16.5px 20px',
              width: '100%',
            },
            '.MuiOutlinedInput-root': {
              padding: 0,
            },
          }}
        />
        <InputCode
          onKeyUp={e => tabField(e.target.value, 'code6')}
          id="code5"
          {...register(`code5`, { required: true })}
          inputProps={{
            minLength: 1,
            maxLength: 1,
            pattern: '[0-9]*',
          }}
          tabIndex="5"
          error={errors?.code5 ? true : false}
          sx={{
            input: {
              padding: '16.5px 20px',
              width: '100%',
            },
            '.MuiOutlinedInput-root': {
              padding: 0,
            },
          }}
        />
        <InputCode
          id="code6"
          {...register(`code6`, { required: true })}
          inputProps={{
            minLength: 1,
            maxLength: 1,
            pattern: '[0-9]*',
          }}
          tabIndex="6"
          error={errors?.code6 ? true : false}
          sx={{
            input: {
              padding: '16.5px 20px',
              width: '100%',
            },
            '.MuiOutlinedInput-root': {
              padding: 0,
            },
          }}
        />
      </Row>

      <Row justifyContent="center">
        <Column xs={9} sm={8} className="mx-auto mb-2 mt-2 text-center">
          {errorMessage && errorMessage}
          {hasErrorsField() && 'Os campos de código de verificação são obrigatórios!'}
          {!errorMessage && !hasErrorsField() && <>&nbsp;</>}
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={8} sm={6} className="mx-auto mb-3 mt-1" justifyContent="center" flex>
          <ButtonForm
            className="full center"
            label={mutation.isLoading | loading ? <DotsLoader /> : 'Entrar'}
            variant="submit"
          />
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="text-center">
          <HasAccount>
            Não possui conta?{' '}
            <button type="button" onClick={() => navigate(`/create-account`)}>
              Cadastre-se
            </button>
            <br />
            <br />
            {/* <button>Não recebi instruções de confirmação</button> */}
          </HasAccount>
        </Column>
        <Column xs={12} className="text-center"></Column>
      </Row>
    </form>
  );
};
