import backend, { backendPrivate } from "services"

export const getCompanies = async () => {
    return await backendPrivate.get(`/company`)
}

export const getCompaniesPaged = async (page, perPage) => {
    return await backendPrivate.get(`/company/paged?page=${page}&per_page=${perPage}`)
}

export const getCompanyById = async (id) => {
    return await backendPrivate.get(`/company/${id}`)
}

export const getCompanyBySlug = async (slug) => {
    return await backend.get(`/company/by-slug/${slug}`)
}

export const getCompanyShortBySlug = async (slug) => {
    return await backend.get(`/company/short-data-by-slug/${slug}`)
}

export const getMapCompanyBySlug = async (slug) => {
    return await backendPrivate.get(`/company/map-by-slug/${slug}`)
}

export const getCompanyRolesBySlug = async (slug) => {
    return await backendPrivate.get(`/company/roles-by-slug/${slug}`)
}

export const addCompany = async (data) => {
    return await backendPrivate.post('/company', data)
}

export const editCompany = async (id, data) => {
    return await backendPrivate.put(`/company/${id}`, data)
}

export const deleteCompany = async (id) => {
    return await backendPrivate.delete(`/company/${id}`)
}

export const getCompaniesByPartner = async (tenantId) => {
    return await backendPrivate.get(`/company/by-partner/${tenantId}`)
}

export const getCompaniesByPartnerPaged = async (tenantId, page, perPage) => {
    return await backendPrivate.get(`/company/by-partner/${tenantId}?page=${page}&per-page=${perPage}`)
}

export const createCompany = async (data, idOnboard) => {
    return await backend.post(`/company/onboarding/${idOnboard}`, data)
}

export const editCompanyPlan = async (slug, data) => {
    return await backend.put(`/company/onboarding/${slug}`, data)
}

export const getPresetPlan = async (slug) => {
    return await backend.get(`/site/preset-plan/${slug}`,)
}