import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
/** Components */
import { Container } from 'Components/Container';
import { TitleForm } from 'Components/Form/styles';
import { Row } from 'Components/Grid';
import { StepperForm } from 'Components/StepperForm';
import { Details } from './Details';
/** Services */
import { addPolicy, editPolicy, getPolicyById } from 'services/policies-service';
/** State Managers */
import { useAuthTenant } from 'store/auth';
import { useSelector } from 'react-redux';
/** Third Lib Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useMutation } from 'react-query';

const steps = ['Detalhes', 'Versões'];

export const PoliciesForm = () => {
  const { id } = useParams();
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [activeStep] = useState(0);
  const [finished] = useState(false);
  const {
    register,
    formState: { errors },
    control,
    getValues,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: '',
      classification: '',
      userGroups: [],
      description: '',
      companyKey: selectedTenant.slug,
    },
  });

  const mutation = useMutation(
    () => {
      return id ? editPolicy(id, formatForm(getValues())) : addPolicy(formatForm(getValues()));
    },
    {
      onError: error => {
        // An error happened!
        SwalReact.fire({
          title: 'Erro!',
          html: error.response.data.message || error.message,
          icon: 'error',
        });
      },
      onSuccess: data => {
        if (data.status === 200) {
          navigate(`/policies/${id}/versions`);
        } else if (data.status === 201) {
          navigate(`/policies/${data.data.id}/versions`);
        }
      },
    },
  );

  const formatForm = data => {
    return {
      ...data,
      userGroups: data.userGroups.length
        ? data.userGroups.map(userGroup => {
            return {
              _id: userGroup._id,
              name: userGroup.name,
            };
          })
        : [],
    };
  };

  const handleComplete = async () => {
    mutation.mutate();
  };

  const handleContinue = () => {
    navigate(`/policies/${id}/versions`);
  };

  const getTitleStep = () => {
    if (finished) return <></>;

    return (
      <Row>
        <TitleForm>Detalhes</TitleForm>
      </Row>
    );
  };

  const getPolicy = useCallback(async () => {
    if (id) {
      try {
        const resPolicy = await getPolicyById(id);
        Object.keys(resPolicy.data).forEach(key => {
          setValue(key, resPolicy.data[key]);
        });
      } catch (error) {
        SwalReact.fire({
          title: 'Erro!',
          text: error.response.data.message ?? error.message,
          icon: 'error',
        });
      }
    }
  }, [id, setValue, SwalReact]);

  useEffect(() => {
    getPolicy();
  }, [getPolicy]);

  return (
    <Container title={`Políticas`} breadcrumb="Adequações / Políticas" linkPage="/policies">
      <StepperForm activeStep={activeStep} steps={steps} />
      <div className="card-content w-80">
        {getTitleStep()}
        <Details
          control={control}
          register={register}
          isLoading={mutation.isLoading}
          errors={errors}
          handleComplete={handleComplete}
          handleContinue={handleContinue}
          handleSubmit={handleSubmit}
          id={id}
        />
      </div>
    </Container>
  );
};
