import { Box, Chip, Tab, Tabs } from '@mui/material'
import { Container } from 'Components/Container'
import { Column } from 'Components/Grid'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ColumnEdit, LinkEdit, RowExhibition } from './styles'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { getAreaById } from 'services/areas-service'
import { PageLoaderShow } from 'Components/PageLoader/Show'

const TabPanel = (props) => {
  const { children, value, index, id, ariaLabel, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={id}
      aria-labelledby={ariaLabel}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

export const AreasShow = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [tabPosition, setTabPosition] = useState(0)
  const { isLoading, error, data: area } = useQuery('getAreaById', async () => {
    const dataArea = await getAreaById(id)
    return (dataArea.status === 200) ? dataArea.data : null
  })

  const onClickEdit = () => {
    navigate('/areas/edit/'+id)
  }

  return (
    <>
      {!isLoading && !error && (
        <Container
          title={`${area?.name}`}
          breadcrumb={`Mapeamento / Áreas / ${area?.name}`}
          linkPage="/areas"
        >
          <Box sx={{ width: '100%' }} className="container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabPosition}
                onChange={(data, newValue) => setTabPosition(newValue)}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Detalhes"
                  id="tab-details"
                  aria-controls="tabpanel-details"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Vínculos"
                  id="tab-links"
                  aria-controls="tabpanel-links"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
              </Tabs>
            </Box>
            <TabPanel
              value={tabPosition}
              index={0}
              id="tabpanel-details"
              ariaLabel="tab-details"
            >
              <RowExhibition>
                <Column xs={3}>Nome</Column>
                <Column>{area.name}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Criticidade</Column>
                <Column>{area.criticity}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Responsável</Column>
                <Column>{area.user}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>TAGs</Column>
                <Column>{area?.tags?.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    variant="outlined"
                    color="primary"
                    sx={{
                      width: 'fit-content',
                    }}
                  />
                ))}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Descrição</Column>
                <Column>{area.description}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
            <TabPanel
              value={tabPosition}
              index={1}
              id="tabpanel-links"
              ariaLabel="tab-links"
            >
              <RowExhibition>
                <Column xs={3}>Processos vinculados</Column>
                <Column>
                  {area?.processes?.map((process) => (
                    <Chip
                      key={process.name}
                      label={process.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Sistemas vinculados</Column>
                <Column>
                  {area?.systems?.map((system) =>(
                    <Chip
                      key={system.name}
                      label={system.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                      }}
                    />
                  ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Ativos</Column>
                <Column>
                  {area?.assets?.map((asset) => (
                    <Chip
                      key={asset.name}
                      label={asset.name}
                      variant="outlined"
                      color="primary"
                      sx={{
                        width: 'fit-content',
                      }}
                    />)
                  )}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
          </Box>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  )
}
