import React, { useEffect } from "react";
import { Chart, ArcElement } from "chart.js";
import { Pie } from "react-chartjs-2";
Chart.register(ArcElement);

export default function PieChart({
  implemented,
  nonImplemented,
  nonApplicable,
}) {
  const [chartData, setChartData] = React.useState({
    labels: ["Implantado", "N. implantado", "Não aplicavél"],
    datasets: [
      {
        data: [],
        backgroundColor: ["#0039C5", "#537DE4", "#04B2D9"],
        borderWidth: 0,
      },
    ],
  });

  useEffect(() => {
    // populate data
    if (implemented === 0 && nonImplemented === 0 && nonApplicable === 0) {
      setChartData((prevState) => ({
        ...prevState,
        labels: ["Não há dados"],
        datasets: [
          {
            ...prevState.datasets[0],
            data: [1],
            backgroundColor: ["#E5E5E5"],
          },
        ],
      }));
      return;
    }

    setChartData((prevState) => ({
      ...prevState,
      datasets: [
        {
          ...prevState.datasets[0],
          data: [implemented, nonImplemented, nonApplicable],
        },
      ],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Pie
      data={chartData}
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
    />
  );
}
