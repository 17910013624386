import DotsLoader from 'Components/DotsLoader';
import { ButtonForm, CustomTextField } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getCompanyBySlug } from 'services/company-service';
import { createUser } from 'services/users-service';
import { getTenant } from 'utils/tenant_utils';
import { HasAccount, SubtitleLogin, TitleLogin } from './styles';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { updateTenant } from 'store/auth';

export const CreateAccount = () => {
  const dispatch = useDispatch();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const { data: company } = useQuery(['getCompanyBySlug'], async () => {
    const response = await getCompanyBySlug(getTenant());
    return response?.data;
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
    },
  });

  const mutation = useMutation(
    newUser => {
      return createUser(newUser);
    },
    {
      onError: error => {
        // An error happened!
        SwalReact.fire({
          title: 'Erro!',
          text: error.response.data.message ?? error.message,
          icon: 'error',
        });
      },
      onSuccess: data => {
        if (data.status === 200 || data.status === 201) {
          dispatch(updateTenant({ tenant: company }));
          navigate(`/verify-code/${data.data.email}`);
        }
      },
    },
  );

  const onSubmit = () => {
    const userData = getValues();
    mutation.mutate({
      name: userData.name,
      email: userData.email,
      active: true,
      permissions: [
        {
          roleKey: 'user_default',
          companyKey: company.slug,
        },
      ],
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-5">
      <Row justifyContent="center" className="mt-5">
        <Column xs={12} className="text-center mb-3">
          <TitleLogin>Virtual</TitleLogin>
          <TitleLogin className="bold"> Officer</TitleLogin>
        </Column>
        <Column xs={12} className="text-center mb-4">
          <SubtitleLogin>Crie uma conta Virtual Office</SubtitleLogin>
        </Column>
      </Row>
      {!mutation.isSucess && (
        <>
          <Row justifyContent="center">
            <Column xs={8} sm={6} className="mx-auto mb-4" justifyContent="center" flex>
              <CustomTextField
                {...register('name', { required: 'Nome é obrigatório' })}
                label="Nome"
                name="name"
                error={errors?.name ? true : false}
                helperText={errors?.name ? errors.name.message : null}
                InputLabelProps={{ shrink: true }}
                sx={{
                  input: {
                    padding: '16.5px 10px',
                    width: '100%',
                  },
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  width: '100%',
                }}
              />
            </Column>
          </Row>
          <Row justifyContent="center">
            <Column xs={8} sm={6} className="mx-auto mb-4" justifyContent="center" flex>
              <CustomTextField
                {...register('email', { required: 'Email é obrigatório' })}
                label="Email"
                name="email"
                error={errors?.email ? true : false}
                helperText={errors?.email ? errors.email.message : null}
                InputLabelProps={{ shrink: true }}
                sx={{
                  input: {
                    padding: '16.5px 10px',
                    width: '100%',
                  },
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  width: '100%',
                }}
              />
            </Column>
          </Row>
          <Row justifyContent="center">
            <Column xs={8} sm={6} className="mx-auto mb-2" justifyContent="center" flex>
              <ButtonForm
                className="full center"
                label={mutation.isLoading ? <DotsLoader /> : 'Continuar'}
                variant="submit"
              />
            </Column>
          </Row>
        </>
      )}
      <Row justifyContent="center" className="mb-2">
        <Column xs={12} className="text-center">
          <HasAccount>
            Já possui conta?{' '}
            <button type="button" onClick={() => navigate(`/login`)}>
              Login
            </button>
            <br />
            <br />
            {/* <button>Não recebi instruções de confirmação</button> */}
          </HasAccount>
        </Column>
      </Row>
    </form>
  );
};
