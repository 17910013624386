import { Column } from "Components/Grid";
import styled from "styled-components";

export const Title = styled(Column)`
  border: 1px solid #EBECF8;
  border-radius: 8px 8px 0 0;
  font-weight: 700;
  font-size: 14px;
  color: #0133AE;
  height: 50px;
  text-align: center;
  padding: 16px;
  &.no-radius {
    border-radius: 0;
  }
`

export const TitleHighMainContent = styled(Column)`
  border: 1px solid #EBECF8;
  font-size: 14px;
  color: #3C3938;
  height: 76px;
  padding: 16px;
`

export const TitleFooter = styled(Column)`
  border: 1px solid #EBECF8;
  border-radius: 0 0 8px 8px;
  font-size: 14px;
  color: #3C3938;
  min-height: 76px;
  padding: 16px;
`

export const TitleMainContent = styled(Column)`
  border: 1px solid #EBECF8;
  font-size: 14px;
  font-weight: bold;
  color: #3C3938;
  height: 56px;
  padding: 16px;
`

export const MainContent = styled(Column)`
  border: 1px solid #EBECF8;
  font-size: 14px;
  color: #3C3938;
  height: 56px;
  padding: 16px;
  text-align: center;
`

export const CustomTableReport = styled.table`
  border-collapse: collapse;
  width: 100%;
  > thead > tr > th {
    border: 1px solid #EBECF8;
    padding: 16px;
    font-size: 14px;
    &.less-p {
      padding: 16px 10px;
    }
  }
  > tbody > tr > td {
    border: 1px solid #EBECF8;
    padding: 16px;
    font-size: 14px;
    vertical-align: top;
  }
`