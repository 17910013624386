import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Container } from 'Components/Container';
import Paper from '@mui/material/Paper';

import { getPoliciesUserByPolicyIdAndPolicyVersionId } from 'services/policies-service';
import { formatDateTimeToDateTimeBR } from 'utils/date_utils';
import { Column, Row } from 'Components/Grid';
import { formatDateToDateBRPolish } from 'utils/date_utils';
import { PageLoaderShow } from 'Components/PageLoader/Show';
import { NoData } from 'Components/NoData';
import { ButtonForm } from 'Components/Form/styles';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';

export const PoliciesShowAccepts = () => {
  const { id, idVersion } = useParams();
  const componentRef = useRef();

  const {
    data: policyVersions,
    isLoading,
    error,
  } = useQuery('getPoliciesUserByPolicyIdAndPolicyVersionId', async () => {
    const resPoliciesVersionUsers = await getPoliciesUserByPolicyIdAndPolicyVersionId(id, idVersion);

    if (resPoliciesVersionUsers.status === 200) {
      return resPoliciesVersionUsers.data.length > 0 ? resPoliciesVersionUsers.data : null;
    }

    return null;
  });

  const getNamePolicy = policies => {
    return policies ? policies[0]?.policyName : '';
  };

  const getClassificationPolicy = policies => {
    return policies ? policies[0]?.policy?.classification : '';
  };

  const getVersionPolicy = policies => {
    return policies ? policies[0]?.policyVersion : '';
  };

  const getAuthorName = item => {
    return item ? item?.policy?.policyVersions?.[idVersion]?.author?.name : '';
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { size: 10in 12in }',
  });

  return (
    <>
      {!isLoading && !error && !!policyVersions && (
        <Container
          title={`${getNamePolicy(policyVersions)} - Versão (${getVersionPolicy(policyVersions)})`}
          breadcrumb={`Adequações / Políticas / ${getNamePolicy(policyVersions)} / Versão / ${getVersionPolicy(
            policyVersions,
          )} / Aceites`}
          linkPage="/policies"
          actionButton={
            <ButtonForm secondary id="printPdf" label="Exportar PDF" onClick={() => handlePrint()}></ButtonForm>
          }
        >
          <Box
            ref={componentRef}
            sx={{
              width: '100%',
              fontFamily:
                '"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            }}
            className="container"
          >
            <Row className="mb-3">
              <Column xs={12}>
                <h3 className="m-0">{getNamePolicy(policyVersions)}</h3>
              </Column>
              <Column xs={12}>
                Versão: {getVersionPolicy(policyVersions)} | Classificação: {getClassificationPolicy(policyVersions)}
              </Column>
              <Column xs={12}>Publicação: {formatDateToDateBRPolish(policyVersions?.[0]?.policyPublishedDate)}</Column>
              <Column xs={12}>Autor: {getAuthorName(policyVersions?.[0])}</Column>
              <Column xs={12} className="mb-2"></Column>
              <Column xs={12}>
                <h4 className="m-0">Aceites</h4>
              </Column>
            </Row>
            <TableContainer component={Paper} style={{ marginBottom: '1rem' }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow key={'table-row-header'}>
                    <TableCell key={`thrc-stage`}>Nome</TableCell>
                    <TableCell key={`thrc-status`}>Data de envio</TableCell>
                    <TableCell key={`thrc-date`}>Data de leitura</TableCell>
                    <TableCell key={`thrc-sponsor`}>Data de aceite</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {policyVersions?.map(item => (
                    <TableRow key={'table-row-header-review'}>
                      <TableCell key={`thrc-stage-1`}>{item?.user?.name}</TableCell>
                      <TableCell key={`thrc-status-1`}>{formatDateTimeToDateTimeBR(item.sentDate, true)}</TableCell>
                      <TableCell key={`thrc-date-1`}>{formatDateTimeToDateTimeBR(item.readedDate, true)}</TableCell>
                      <TableCell key={`thrc-sponsor-1`}>
                        {formatDateTimeToDateTimeBR(item.acceptedDate, true)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
      {!isLoading && !error && !policyVersions && (
        <Container title={`Voltar`} linkPage={`/policies/show/${id}`}>
          <NoData description={'Não há Aceites para esta versão de Política para exibir'} />
        </Container>
      )}
    </>
  );
};
