import { FormSuccess } from 'Components/Form/Success';
import { StepperForm } from 'Components/StepperForm';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from '../../../Components/Container';
import { Detalhes } from './Details';
import { Vinculos } from './Bonds';
import { useEffect } from 'react';
import { Row } from 'Components/Grid';
import { TitleForm } from 'Components/Form/styles';
import { addArea, editArea, getAreaById } from 'services/areas-service';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useQuery } from 'react-query';
import { PageLoaderForm } from 'Components/PageLoader/Form';

const steps = ['Detalhes', 'Vínculos'];

export const AreasForm = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const { id } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [idArea, setIdArea] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [finished, setFinished] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      criticity: '',
      user: '',
      tags: [],
      decription: '',
      processes: [],
      systems: [],
      assets: [],
      companyKey: selectedTenant.slug,
    },
  });

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() ? activeStep : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const formatProcess = process => {
    return {
      _id: process._id,
      name: process.name,
      description: process.description,
      user: process.user,
      criticity: process.criticity,
    };
  };

  const formatAsset = asset => {
    return {
      _id: asset._id,
      name: asset.name,
      description: asset.description,
      user: asset.user,
      criticity: asset.criticity,
    };
  };

  const formatSystem = system => {
    return {
      _id: system._id,
      name: system.name,
      description: system.description,
      criticity: system.criticity,
      user: system.user,
    };
  };

  const formatForm = dados => {
    return {
      name: dados.name,
      criticity: dados.criticity,
      user: dados.user,
      tags: dados.tags.length > 0 ? dados.tags : [],
      description: dados.description,
      processes: dados.processes.length > 0 ? dados.processes.map(process => formatProcess(process)) : [],
      systems: dados.systems.length > 0 ? dados.systems.map(system => formatSystem(system)) : [],
      assets: dados.assets.length > 0 ? dados.assets.map(asset => formatAsset(asset)) : [],
      companyKey: dados.companyKey ?? selectedTenant.slug,
    };
  };

  const handleComplete = async () => {
    try {
      setIsLoadingForm(true);
      const formattedForm = formatForm(getValues());
      const res = id ? await editArea(id, formattedForm) : await addArea(formattedForm);
      if (res.status === 200 || res.status === 201) {
        setIdArea(res.data._id ?? id);
        setFinished(true);
        setIsLoadingForm(false);
      } else {
        throw new Error('Ocorreu um erro ao tentar salvar/editar a Área');
      }
    } catch (error) {
      setIsLoadingForm(false);
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const handleReset = () => {
    reset();
    setActiveStep(0);
    setFinished(false);
    setIdArea(null);
    navigate('/areas/add');
  };

  const getTitleStep = () => {
    if (finished) return <></>;

    if (activeStep === 0)
      return (
        <Row>
          <TitleForm>Detalhes da área de negócio</TitleForm>
        </Row>
      );

    return (
      <Row>
        <TitleForm>Vinculos da área de negócio</TitleForm>
      </Row>
    );
  };

  const {
    isLoading,
    error,
    data: area,
  } = useQuery(['getArea', id], async () => {
    if (!id) return null;

    const resArea = await getAreaById(id);

    if (!resArea.data) return null;

    return resArea.data;
  });

  useEffect(() => {
    if (!id) return;
    if (!area) return;

    Object.keys(area).forEach(key => {
      setValue(key, area[key]);
    });

    return () => {};
  }, [id, area, setValue]);

  const showForm = () => {
    if (id) return !isLoading && !error;
    return true;
  };

  return (
    <Container
      title={`${id ? 'Editar' : 'Adicionar'} Área de negócios`}
      breadcrumb="Mapeamento / Áreas de negócio"
      linkPage="/areas"
    >
      {isLoading && <PageLoaderForm />}
      {showForm() && (
        <>
          <StepperForm activeStep={activeStep} steps={steps} />
          <div className="card-content">
            {getTitleStep()}
            {activeStep === 0 && (
              <Detalhes
                control={control}
                register={register}
                handleNext={handleNext}
                handleSubmit={handleSubmit}
                errors={errors}
                getValues={getValues}
                id={id}
              />
            )}
            {activeStep === 1 && !finished && (
              <Vinculos
                control={control}
                register={register}
                handleBack={handleBack}
                handleComplete={handleComplete}
                handleSubmit={handleSubmit}
                loading={isLoadingForm}
              />
            )}
            {finished && (
              <FormSuccess
                title="Área de negócio cadastrada com sucesso!"
                labelStartAgain="Cadastrar nova área de negócio"
                handleStartAgain={() => handleReset()}
                labelShow="Ver detalhes"
                handleShow={() => navigate('/areas/show/' + idArea)}
              />
            )}
          </div>
        </>
      )}
    </Container>
  );
};
