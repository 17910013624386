import DotsLoader from 'Components/DotsLoader';
import { ButtonForm, CustomTextField } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { SubtitleLogin, TitleLogin } from './styles';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { createCompany, getCompanyShortBySlug } from 'services/company-service';
import { FormControl, TextField } from '@mui/material';
import { MuiChipsInput } from 'mui-chips-input';

export const OnboardCompany = () => {
  const { idOnboard } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const { data: company } = useQuery(['getCompanyBySlug'], async () => {
    const response = await getCompanyShortBySlug('vo');
    return response.data;
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      name: '',
      corporateName: '',
      slug: '',
      cnpj: '',
      size: '',
      income: '',
      industry: '',
      description: '',
      active: true,
      partner: false,
      managers: [],
      roles: [],
      domains: [],
      signedPlan: '',
    },
  });

  const mutation = useMutation(
    newCompany => {
      return createCompany(newCompany, idOnboard);
    },
    {
      onError: error => {
        // An error happened!
        SwalReact.fire({
          title: 'Erro!',
          text: error.response.data.message ?? error.message,
          icon: 'error',
        });
      },
      onSuccess: data => {
        if (data.status === 200) {
          const companyData = getValues();
          navigate(`/onboard-company-admin/${companyData.slug}`);
        }
      },
    },
  );

  const onSubmit = () => {
    const companyData = getValues();
    mutation.mutate({
      name: companyData.name,
      corporateName: companyData.corporateName,
      cnpj: companyData.cnpj,
      slug: companyData.slug,
      size: companyData.size,
      income: companyData.income,
      industry: companyData.industry,
      domains: companyData.domains,
      managers: [
        {
          _id: company._id,
          name: company.name,
        },
      ],
      partner: false,
      active: true,
      signedPlan: 'Free',
      roles: [],
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center" className="mt-5">
        <Column xs={12} className="text-center mb-3">
          <TitleLogin>Virtual</TitleLogin>
          <TitleLogin className="bold"> Officer</TitleLogin>
        </Column>
        <Column xs={12} className="text-center mb-4">
          <SubtitleLogin>Cadastre sua empresa</SubtitleLogin>
        </Column>
      </Row>
      {!mutation.isSucess && (
        <>
          <Row justifyContent="center">
            <Column xs={8} sm={8} className="mx-auto mb-4" justifyContent="center" flex>
              <CustomTextField
                {...register('name', { required: 'Nome é obrigatório' })}
                label="Nome"
                name="name"
                error={errors?.name ? true : false}
                helperText={errors?.name ? errors.name.message : null}
                InputLabelProps={{ shrink: true }}
                sx={{
                  input: {
                    padding: '16.5px 10px',
                    width: '100%',
                  },
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  width: '100%',
                }}
              />
            </Column>
          </Row>
          <Row justifyContent="center">
            <Column xs={8} sm={8} className="mx-auto mb-4" justifyContent="center" flex>
              <CustomTextField
                {...register('corporateName', { required: 'Nome Fantasia é obrigatório' })}
                label="Nome Fantasia"
                name="corporateName"
                error={errors?.corporateName ? true : false}
                helperText={errors?.corporateName ? errors.corporateName.message : null}
                InputLabelProps={{ shrink: true }}
                sx={{
                  input: {
                    padding: '16.5px 10px',
                    width: '100%',
                  },
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  width: '100%',
                }}
              />
            </Column>
          </Row>
          <Row justifyContent="center">
            <Column xs={8} sm={4} className="ml-auto mb-4 pr-2" justifyContent="center" flex>
              <CustomTextField
                {...register('slug', { required: 'Identificador é obrigatório' })}
                label="Identificador"
                name="slug"
                error={errors?.slug ? true : false}
                helperText={errors?.slug ? errors.slug.message : null}
                InputLabelProps={{ shrink: true }}
                sx={{
                  input: {
                    padding: '16.5px 10px',
                    width: '100%',
                  },
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  width: '100%',
                }}
              />
            </Column>
            <Column xs={8} sm={4} className="mr-auto mb-4 pl-2" justifyContent="center" flex>
              <CustomTextField
                {...register('cnpj', { required: 'CNPJ é obrigatório' })}
                label="CNPJ"
                name="cnpj"
                error={errors?.cnpj ? true : false}
                helperText={errors?.cnpj ? errors.cnpj.message : null}
                InputLabelProps={{ shrink: true }}
                sx={{
                  input: {
                    padding: '16.5px 10px',
                    width: '100%',
                  },
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  width: '100%',
                }}
              />
            </Column>
          </Row>
          <Row justifyContent="center">
            <Column xs={8} sm={4} className="ml-auto mb-4 pr-2" justifyContent="center" flex>
              <CustomTextField
                {...register('size', { required: 'Quantidade de funcionários é obrigatório' })}
                label="Quantidade de funcionários"
                name="size"
                error={errors?.size ? true : false}
                helperText={errors?.size ? errors.size.message : null}
                InputLabelProps={{ shrink: true }}
                sx={{
                  input: {
                    padding: '16.5px 10px',
                    width: '100%',
                  },
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  width: '100%',
                }}
              />
            </Column>
            <Column xs={8} sm={4} className="mr-auto mb-4 pl-2" justifyContent="center" flex>
              <CustomTextField
                {...register('income', { required: 'Receita é obrigatório' })}
                label="Receita"
                name="income"
                error={errors?.income ? true : false}
                helperText={errors?.income ? errors.income.message : null}
                InputLabelProps={{ shrink: true }}
                sx={{
                  input: {
                    padding: '16.5px 10px',
                    width: '100%',
                  },
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  width: '100%',
                }}
              />
            </Column>
          </Row>
          <Row justifyContent="center">
            <Column xs={8} sm={8} className="mx-auto mb-4" justifyContent="center" flex>
              <CustomTextField
                {...register('industry', { required: 'Indústria é obrigatório' })}
                label="Indústria"
                name="industry"
                error={errors?.industry ? true : false}
                helperText={errors?.industry ? errors.industry.message : null}
                InputLabelProps={{ shrink: true }}
                sx={{
                  input: {
                    padding: '16.5px 10px',
                    width: '100%',
                  },
                  '.MuiOutlinedInput-root': {
                    padding: 0,
                  },
                  width: '100%',
                }}
              />
            </Column>
          </Row>
          <Row justifyContent="center">
            <Column xs={8} sm={8} className="mx-auto mb-4">
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="domains"
                  render={({ field: { onChange, value } }) => (
                    <MuiChipsInput
                      label="Domínios"
                      value={value}
                      onChange={onChange}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Digite e aperte enter"
                      renderChip={(Component, props) => {
                        return (
                          <Component
                            {...props}
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: 'fit-content',
                            }}
                          />
                        );
                      }}
                      sx={{
                        '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled': {
                          width: 'fit-content',
                        },
                        '& .MuiButtonBase-root.MuiIconButton-root': {
                          width: 'fit-content',
                          marginTop: '0px',
                        },
                      }}
                    />
                  )}
                ></Controller>
              </FormControl>
            </Column>
          </Row>
          <Row>
            <Column xs={8} sm={8} className="mx-auto mb-4">
              <TextField
                {...register('description')}
                label="Descrição"
                multiline
                rows={4}
                sx={{ margin: '0 auto 24px', width: '100%' }}
                InputLabelProps={{ shrink: true }}
              />
            </Column>
          </Row>
          <Row justifyContent="center" className="mb-3">
            <Column xs={8} sm={6} className="mx-auto mb-2" justifyContent="center" flex>
              <ButtonForm
                className="full center"
                label={mutation.isLoading ? <DotsLoader /> : 'Continuar'}
                variant="submit"
              />
            </Column>
          </Row>
        </>
      )}
      {/* <Row justifyContent="center" className="mb-2">
                <Column xs={12} className="text-center">
                    <HasAccount>
                        Já possui conta? <button type="button" onClick={() => navigate(`/login`)} >Login</button><br/><br/>
                    </HasAccount>
                </Column>
            </Row> */}
    </form>
  );
};
