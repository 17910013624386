import { Column } from "Components/Grid";
import styled from "styled-components";

export const TitleCompany = styled(Column)`
    border: 1px solid #EBECF8;
    border-radius: 8px 8px 0 0;
    font-weight: 700;
    font-size: 14px;
    color: #0133AE;
    height: 50px;
    text-align: center;
    padding: 16px;
    &.no-radius {
        border-radius: 0;
    }
`

export const TitleMainContentCompany = styled(Column)`
    border: 1px solid #EBECF8;
    font-size: 14px;
    color: #3C3938;
    height: 76px;
    padding: 16px;
`

export const TitleFooterCompany = styled(Column)`
    border: 1px solid #EBECF8;
    border-radius: 0 0 8px 8px;
    font-size: 14px;
    color: #3C3938;
    min-height: 76px;
    padding: 16px;
`