import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  TimeScale,
  Tooltip,
  Title,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";

Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  TimeScale,
  Tooltip,
  Title,
  Legend
);

const BarChart = ({ data, period, viewByControl }) => {
  const labels = [];

  const [chartData, setChartData] = useState({
    labels,
    datasets: [],
  });

  useEffect(() => {
    console.log(data);
    const generateLabels = (period) => {
      const currentDate = new Date();
      const labels = [];

      for (let i = period - 1; i >= 0; i--) {
        const date = new Date();
        date.setMonth(currentDate.getMonth() - i);
        const month = date.toLocaleString("default", { month: "short" });
        labels.push(month);
      }

      return labels;
    };
    // Prepare data for the chart
    const labels = generateLabels(period);
    const datasets = [];
    console.log(datasets);

    data.forEach((standard, standardIndex) => {
      const generateDeployedData = (data) => {
        return data.map((item) => item.deployed);
      };
      const generateNotDeployedData = (data) => {
        return data.map((item) => item.notDeployed);
      };
      const generateNotApplicableData = (data) => {
        return data.map((item) => item.notApplicable);
      };
      const generatePartiallyDeployedData = (data) => {
        return data.map((item) => item.partiallyDeployed);
      };
      const deployedStandardsData = generateDeployedData(standard.mapByMonth);
      const notDeployedData = generateNotDeployedData(standard.mapByMonth);
      const notApplicableData = generateNotApplicableData(standard.mapByMonth);
      const partiallyDeployedData = generatePartiallyDeployedData(
        standard.mapByMonth
      );

      datasets.push({
        label:
          standard.securityStandard?.name +
          (viewByControl ? " - Não implantados" : " - Não atende"),
        data: notDeployedData,
        backgroundColor: "#DFC175",
        categoryPercentage: 1,
        stack: standardIndex,
      });
      datasets.push({
        label: standard.securityStandard?.name + " - Não aplicáveis",
        data: notApplicableData,
        backgroundColor: "#e2e2e2",
        categoryPercentage: 1,
        stack: standardIndex,
      });
      datasets.push({
        label:
          standard.securityStandard?.name +
          (viewByControl
            ? " - Parcialmente implantados"
            : "- Atende parcialmente"),
        data: partiallyDeployedData,
        backgroundColor: "#c1eaf3",
        categoryPercentage: 1,
        stack: standardIndex,
      });
      datasets.push({
        label:
          standard.securityStandard?.name +
          (viewByControl ? " - Implantados" : "- Atende"),
        data: deployedStandardsData,
        backgroundColor: "#04B2D9",
        categoryPercentage: 1,
        stack: standardIndex,
      });
    });

    setChartData({
      labels: labels,
      datasets: datasets,
    });
  }, [data, period, viewByControl]);

  return (
    <div>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          interaction: {
            intersect: false,
          },
          plugins: {
            legend: {
              position: "top",
              display: false,
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        }}
      />
    </div>
  );
};

export default BarChart;
