import { Box, Tab, Tabs } from '@mui/material'
import { Container } from 'Components/Container'
import { Column } from 'Components/Grid'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ColumnEdit, LinkEdit, RowExhibition } from './styles'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { getOfficeById } from 'services/offices-service'
import { PageLoaderShow } from 'Components/PageLoader/Show'

const TabPanel = (props) => {
    const { children, value, index, id, ariaLabel, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={id}
            aria-labelledby={ariaLabel}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

export const OfficesShow = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [tabPosition, setTabPosition] = useState(0)
    const { isLoading, error, data: office } = useQuery('getOfficeId', async () => {
        const dataOffice = await getOfficeById(id)
        return (dataOffice.status === 200) ? dataOffice.data : null
    })

    const onClickEdit = () => {
        navigate('/offices/edit/'+id)
    }

    return (
        <>
            {!isLoading && !error && (
                <Container
                    title={`${office?.name}`}
                    breadcrumb={`Gerenciar / Escritórios / ${office?.name}`}
                    linkPage="/offices"
                    >
                    <Box sx={{ width: '100%' }} className="container">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={tabPosition}
                                onChange={(data, newValue) => setTabPosition(newValue)}
                                aria-label="basic tabs example" >
                                <Tab
                                    label="Detalhes"
                                    id="tab-details"
                                    aria-controls="tabpanel-details"
                                    style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                                    />
                            </Tabs>
                        </Box>
                        <TabPanel
                            value={tabPosition}
                            index={0}
                            id="tabpanel-details"
                            ariaLabel="tab-details"
                            >
                            <RowExhibition>
                                <Column xs={3}>Nome</Column>
                                <Column>{office.name}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Endereço</Column>
                                <Column>{office.address}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Telefone</Column>
                                <Column>{office.phone}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                        </TabPanel>
                    </Box>
                </Container>
            )}
            {isLoading && <PageLoaderShow />}
            {error && <div>Erro</div>}
        </>
    )
}
