import { createSlice } from '@reduxjs/toolkit'
import {
    authenticatedFailureAction,
    authenticatedFinishAction,
    authenticatedSuccessAction,
    refreshTokenSucessAction,
    loginAction,
    logoffAction,
    updateTenantAction
} from './actions'

const INITIAL_STATE = {
    refreshToken: null,
    token: null,
    user: null,
    isLoggedIn: false,
    error: false,
    loading: false,
    selectedTenant: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        authenticatedFailure: authenticatedFailureAction,
        authenticatedFinish: authenticatedFinishAction,
        authenticatedSuccess: authenticatedSuccessAction,
        refreshTokenSucess: refreshTokenSucessAction,
        login: loginAction,
        logoff: logoffAction,
        updateTenant: updateTenantAction
    }
})

export const { authenticatedFinish, authenticatedSuccess, authenticatedFailure, refreshTokenSucess, login, logoff, updateTenant } = authSlice.actions

export default authSlice.reducer

export const useAuthTenant = state => state.selectedTenant

export const useAuthLoggedIn = state => state.isLoggedIn

export const useAuthUser = state => state.user

export const useAuthLoading = state => state.loading

export const useAuthError = state => state.error

export const useAuthToken = state => state.token

export const useAuthRefreshToken = state => state.refreshToken