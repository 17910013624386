import { Card } from "Components/Card"
import { ReportsRopaProcess } from "../Process"
import { Row } from "Components/Grid"
import { TitleArea, TitleFooterArea, TitleMainContentArea } from "./styles"

export const ReportsRopaArea = ({area, filter, process, data, category, legalBase, processing, position}) => {
    return (
        <>
            <Card className={`mt-4 p-report mtp-0 ptp-8 pbp-8 page-break-auto`}>
                <Row>
                    <TitleArea xs={12} sm={12} md={12}>
                        Informações da área
                    </TitleArea>
                    <TitleMainContentArea xs={12} sm={6} md={6}>
                        <Row className="pl-3 font-weight-bold mb-2">Área</Row>
                        <Row className="pl-3">{area.name}</Row>
                    </TitleMainContentArea>
                    <TitleMainContentArea xs={12} sm={6} md={6}>
                        <Row className="pl-3 font-weight-bold mb-2">Responsável</Row>
                        <Row className="pl-3">{area.user}</Row>
                    </TitleMainContentArea>
                    <TitleFooterArea xs={12} sm={12} md={12}>
                        <Row className="pl-3 font-weight-bold mb-2">Descrição</Row>
                        <Row className="pl-3 mb-2">{area.description}</Row>
                    </TitleFooterArea>
                </Row>
            </Card>
            {!!area.processes && area.processes.filter((processFilter) => {
                if (filter === "Processo" && !!process) {
                    return processFilter.name === process
                }
                if (filter === 'Dados' && !!data) {
                    return processFilter.dataMapping.some((dataMap) => dataMap.name === data)
                }
                if (filter === 'Categoria' && !!category) {
                    return processFilter.dataMapping.some((dataMap) => dataMap.category === category)
                }
                if (filter === 'Tratamento' && !!processing) {
                    return processFilter.dataMapping.some((dataMap) => {
                        return dataMap.dataProcessing.some((dataProcess) => {
                            return (dataProcess.processing) === processing
                        })
                    })
                }
                if (filter === 'Legal Base' && !!legalBase) {
                    return processFilter.dataMapping.some((dataMap) => {
                        return dataMap.dataProcessing.some((dataProcess) => {
                            return (dataProcess.legalBase) === legalBase
                        })
                    })
                }
                if (filter === 'Posição' && !!position) {
                    return processFilter.dataMapping.some((dataMap) => {
                        return dataMap.dataProcessing.some((dataProcess) => {
                            return (dataProcess.position) === position
                        })
                    })
                }
                return true
            }).map((itemProcess, index) => (
                <ReportsRopaProcess
                    process={itemProcess}
                    key={`${itemProcess._id}-${index}`}
                    filter={filter}
                    data={data}
                    category={category}
                    legalBase={legalBase}
                    processing={processing}
                    position={position} />
            ))}
        </>
    )
}