export const tableHeader = [
    {
        id: 'version',
        type: 'text',
        value: 'Versão',
    },
    {
        id: 'publishedDate',
        type: 'datetime',
        value: 'Publicação',
    },
    {
        id: 'reviewedDate',
        type: 'datetime',
        value: 'Revisão',
    },
    {
        id: 'approvedDate',
        type: 'datetime',
        value: 'Aprovação',
    },
    {
        id: 'action',
        type: 'actions-policy-version',
        value: '',
    },
]