import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material'
import { ButtonForm, CustomTextField } from 'Components/Form/styles'
import { Column, Row } from 'Components/Grid'
import { MuiChipsInput } from 'mui-chips-input'
import { Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const Detalhes = ({
    handleNext,
    register,
    control,
    handleSubmit,
    errors,
    getValues
}) => {
    const navigate = useNavigate()
    const onSubmit = () => {
        handleNext()
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row justifyContent="center">
                <Column xs={12} className="d-flex">
                    <CustomTextField
                        {...register('name', { required: 'Nome é obrigatório' })}
                        label="Nome"
                        autoFocus
                        error={errors?.name ? true : false}
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        helperText={errors?.name ? errors.name.message : null}
                        InputLabelProps={{ shrink: true }}
                    />
                </Column>
            </Row>
            <Row justifyContent="center">
                <Column xs={12} className="d-flex">
                    <CustomTextField
                        {...register('corporateName', { required: 'Razão social é obrigatório' })}
                        label="Razão social"
                        autoFocus
                        error={errors?.corporateName ? true : false}
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        helperText={errors?.corporateName ? errors.corporateName.message : null}
                        InputLabelProps={{ shrink: true }}
                    />
                </Column>
            </Row>
            <Row justifyContent="center">
                <Column xs={12} className="d-flex">
                    <CustomTextField
                        {...register('slug', { required: 'Identificador é obrigatório' })}
                        label="Identificador"
                        error={errors?.slug ? true : false}
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        helperText={errors?.slug ? errors.slug.message : null}
                        InputLabelProps={{ shrink: true }}
                    />
                </Column>
            </Row>
            <Row justifyContent="center">
                <Column xs={12} className="d-flex">
                    <CustomTextField
                        {...register('cnpj', { required: 'CNPJ é obrigatório' })}
                        label="CNPJ"
                        error={errors?.cnpj ? true : false}
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        helperText={errors?.cnpj ? errors.cnpj.message : null}
                        InputProps={{
                            minLength: 14
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Column>
            </Row>
            <Row justifyContent="center">
                <Column xs={12} className="d-flex">
                    <CustomTextField
                        {...register('size')}
                        label="Quantidade de funcionários"
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Column>
            </Row>
            <Row justifyContent="center">
                <Column xs={12} className="d-flex">
                    <CustomTextField
                        {...register('income')}
                        label="Receita"
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Column>
            </Row>
            <Row justifyContent="center">
                <Column xs={12} className="d-flex">
                    <CustomTextField
                        {...register('industry')}
                        label="Indústria"
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Column>
            </Row>
            <Row justifyContent="center">
                <Column xs={12} className="d-flex">
                    <FormControl
                        fullWidth
                        sx={{ margin: '0 auto 24px' }}
                    >
                        <Controller
                            control={control}
                            name="domains"
                            render={({ field: { onChange, value }}) => (
                                <MuiChipsInput
                                label="Domínios" 
                                value={value} 
                                onChange={onChange}
                                InputLabelProps={{ shrink: true }}
                                placeholder="Digite e aperte enter"
                                renderChip={(Component, props) => {
                                    return (
                                    <Component 
                                        {...props}
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                        width: 'fit-content',
                                        }}
                                    />
                                    )
                                }}
                                sx={{
                                    '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled': {
                                    width: 'fit-content'
                                    },
                                    '& .MuiButtonBase-root.MuiIconButton-root': {
                                    width: 'fit-content',
                                    marginTop: '0px'
                                    }
                                }}/>
                            )}
                        ></Controller>
                    </FormControl>
                </Column>
            </Row>
            <Row>
                <Column xs={12} className="d-flex">
                    <Controller
                        control={control}
                        name="active"
                        render={({ field: { onChange, value }}) => (
                            <FormControlLabel
                                value={value}
                                control={<Checkbox checked={value} onChange={onChange} name="active" />}
                                label="Ativo"
                                sx={{marginLeft: 0, marginBottom: '16px'}}
                            />
                        )}
                    />
                </Column>
            </Row>
            <Row>
                <Column xs={12} className="d-flex">
                    <Controller
                        control={control}
                        name="partner"
                        render={({ field: { onChange, value }}) => (
                            <FormControlLabel
                                value={value}
                                control={<Checkbox checked={value} onChange={onChange} name="partner" />}
                                label="Parceiro"
                                sx={{marginLeft: 0, marginBottom: '16px'}}
                            />
                        )}
                    />
                </Column>
            </Row>
            <Row>
                <Column xs={12} className="d-flex">
                    <Controller
                        control={control}
                        rules={{
                        validate: () => {
                            return getValues('signedPlan') !== ''
                        },
                        required: 'Plano de assinatua é obrigatório',
                        }}
                        name="signedPlan"
                        render={({ field: { onChange, value } }) => (
                        <FormControl
                            fullWidth
                            sx={{ marginBottom: '24px' }}
                            error={errors?.signedPlan ? true : false}
                        >
                            <InputLabel id="signed-plan-label" shrink={true}>Plano de assinatura</InputLabel>
                            <Select
                                labelId="signed-plan-label"
                                id="signedPlan"
                                value={value}
                                notched
                                label="Plano de assinatura"
                                onChange={onChange}
                            >
                                <MenuItem value="" key={-1}>
                                    Selecione um Plano de assinatura
                                </MenuItem>
                                {['Free', 'Starter', 'Premium'].map((item, index) => (
                                    <MenuItem value={item} key={index}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                {errors?.signedPlan ? errors.signedPlan.message : ''}
                            </FormHelperText>
                        </FormControl>
                        )}
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <TextField
                    {...register('description')}
                    label="Descrição"
                    multiline
                    rows={4}
                    sx={{ margin: '0 auto 24px', width: '100%' }}
                    InputLabelProps={{ shrink: true }}
                    />
                </Column>
            </Row>
            <Row justifyContent="end" alignItems="center">
                <Column xs={3} justifyContent="center" className="d-flex">
                    <ButtonForm
                    secondary
                    id="cancel"
                    label="cancelar"
                    onClick={() => navigate('/companies')}
                    ></ButtonForm>
                </Column>
                <Column xs={2} justifyContent="center" className="d-flex">
                    <ButtonForm label="Avançar" variant="submit" />
                </Column>
            </Row>
        </form>
    )
}