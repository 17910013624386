import { Row } from 'Components/Grid';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

let legalBases = [
  {
    name: 'Consentimento do titular',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
  {
    name: 'Obrigação legal ou regulatório pelo controlador',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
  {
    name: 'Tratamento e uso compartilhado de dados necessários à execução de políticas públicas',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
  {
    name: 'Realização de estudos por órgão de pesquisa',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
  {
    name: 'Execução de contrato ou de procedimentos preliminares',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
  {
    name: 'Exercício regular de direitos em processo judicial, administrativo ou arbitral',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
  {
    name: 'Proteção da vida ou da incolumidade física do titular ou de terceiro',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
  {
    name: 'Tutela da saúde em procedimentos realizados por profissionais da saúde',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
  {
    name: 'Legítimo interesse do controlador ou de terceiro',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
  {
    name: 'Proteção ao crédito',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
  {
    name: 'Garantia da prevenção à fraude e a segurança do titular',
    areaQuantity: 0,
    personalDataQuantity: 0,
    sensitivePersonalDataQuantity: 0,
    processQuantity: 0,
  },
];

const generateDataByFilter = data => {
  return legalBases.map(item => {
    const exists = data.some(subitem => subitem.name === item.name);
    if (exists) {
      const dataResult = data.find(subitem => subitem.name === item.name);
      item.areaQuantity = dataResult.areaQuantity;
      item.processQuantity = dataResult.processQuantity;
      item.personalDataQuantity = dataResult.personalDataQuantity;
      item.sensitivePersonalDataQuantity = dataResult.sensitivePersonalDataQuantity;
    }
    return item;
  });
};

export const GraphicLegalBaseVolumeData = ({ indicatorVolumeGraphicByLegalBase }) => {
  legalBases = generateDataByFilter(indicatorVolumeGraphicByLegalBase);
  return (
    <Row className="mt-4">
      <ResponsiveContainer width="100%" height="100%" minHeight={500} minWidth={600}>
        <BarChart
          layout="vertical"
          width={800}
          height={500}
          data={legalBases}
          margin={{
            top: 5,
            right: 10,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" width={350} fontSize={14} />
          <Tooltip />
          <Legend />
          <Bar name={'Processos'} stackId={'BarStacked'} dataKey="processQuantity" barSize={35} fill="#4472c5" />
          <Bar
            name={'Dados Pessoais'}
            stackId={'BarStacked'}
            dataKey="personalDataQuantity"
            barSize={35}
            fill="#ed7e30"
          />
          <Bar
            name={'Dados Pessoais Sensíveis'}
            stackId={'BarStacked'}
            dataKey="sensitivePersonalDataQuantity"
            barSize={35}
            fill="#a5a5a5"
          />
          <Bar name={'Áreas'} stackId={'BarStacked'} dataKey="areaQuantity" barSize={35} fill="#ffc100" />
        </BarChart>
      </ResponsiveContainer>
    </Row>
  );
};
