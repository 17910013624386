import React, { useState } from "react";
import { TabButton } from "./styles";

function TabList({ children }) {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  return (
    <div>
      <div className="tab-list">
        {children.map((child) => {
          const { label } = child.props;
          return (
            <TabButton
              onClick={() => setActiveTab(label)}
              isActive={activeTab === label}
            >
              {label}
            </TabButton>
          );
        })}
      </div>
      <div className="tab-content">
        {children.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
}

export default TabList;
