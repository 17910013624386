import { Box } from '@mui/material'
import { Container } from 'Components/Container'
import { Column } from 'Components/Grid'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ColumnEdit, LinkEdit, RowExhibition } from './styles'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { PageLoaderShow } from 'Components/PageLoader/Show'
import { getUsersGroupById } from 'services/users-groups-service'

export const UsersGroupsShow = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const { isLoading, error, data: userGroup } = useQuery('getUserGroupById', async () => {
        if (!id) return null
        const dataUserGroup = await getUsersGroupById(id)

        return (dataUserGroup.status === 200) ? dataUserGroup.data : null
    })

    const onClickEdit = () => {
        navigate('/users-groups/edit/'+id)
    }

    return (
        <>
            {!isLoading && !error && (
                <Container
                    title={`${userGroup?.name}`}
                    breadcrumb={`Gerenciar / Grupos de usuários / ${userGroup?.name}`}
                    linkPage="/users-groups"
                >
                    <Box sx={{ width: '100%' }} className="container">
                        <Box sx={{ p: 3 }}>
                            <RowExhibition>
                                <Column xs={3}>Nome</Column>
                                <Column>{userGroup.name}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Ativo</Column>
                                <Column>{userGroup.active ? 'Sim' : 'Não'}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                        </Box>
                    </Box>
                </Container>
            )}
            {isLoading && <PageLoaderShow />}
            {error && <div>Erro</div>}
        </>
    )
}
