import { Row } from 'Components/Grid';
import { MainContent, TitleMainContent } from '../styles';
import { capitalizeWords } from 'utils/string_utils';

const AreaData = ({ areaData }) => {
  return (
    <>
      <MainContent xs={3} sm={3} md={3}>
        <Row className="pl-3 font-weight-bold mb-2">{capitalizeWords(areaData.name)}</Row>
      </MainContent>
      <MainContent xs={3} sm={3} md={3}>
        <Row className="pl-3 font-weight-bold mb-2" justifyContent="center">
          {areaData.personalDataQuantity}
        </Row>
      </MainContent>
      <MainContent xs={3} sm={3} md={3}>
        <Row className="pl-3 font-weight-bold mb-2" justifyContent="center">
          {areaData.sensitivePersonalDataQuantity}
        </Row>
      </MainContent>
      <MainContent xs={3} sm={3} md={3}>
        <Row className="pl-3 font-weight-bold mb-2" justifyContent="center">
          {areaData.legalBaseQuantity}
        </Row>
      </MainContent>
    </>
  );
};

export const IndicatorAreaDatas = ({ indicatorAreaDatas }) => {
  return (
    <Row className="mt-4">
      <TitleMainContent xs={3} sm={3} md={3}>
        <Row className="pl-3 font-weight-bold mb-2">Nome da Área</Row>
      </TitleMainContent>
      <TitleMainContent xs={3} sm={3} md={3}>
        <Row className="pl-3 font-weight-bold mb-2">Dados Pessoais</Row>
      </TitleMainContent>
      <TitleMainContent xs={3} sm={3} md={3}>
        <Row className="pl-3 font-weight-bold mb-2">Dados Pessoais Sensíveis</Row>
      </TitleMainContent>
      <TitleMainContent xs={3} sm={3} md={3}>
        <Row className="pl-3 font-weight-bold mb-2">Bases Legais</Row>
      </TitleMainContent>
      {indicatorAreaDatas &&
        indicatorAreaDatas.map((item, key) => <AreaData key={`area-data-${key}`} areaData={item} />)}
    </Row>
  );
};
