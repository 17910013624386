import { Box, Collapse, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { StyledTable, TableCell, TableHeader, TableRow } from './styles';
import { getSecurityStandardControls } from 'services/security-standard-service';
import { useNavigate, useParams } from 'react-router-dom';
import { getReportByControls } from 'services/gap-analysis';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import { formatDateTimeToDateTimeBR } from 'utils/date_utils';
import { ControlTypes } from '../DashboardTab';
import { AccessTime, Check, LockClock } from '@mui/icons-material';
import MenuButton from 'Components/MenuButton';
import { OutlinedButtonSmall } from 'Pages/GapAnalisys/styles';

const ControlDeploymentStatus = {
  DEPLOYED: 'Implantado',
  NOT_DEPLOYED: 'N. Implantado',
  NOT_APPLICABLE: 'N. Aplicável',
  PARTIALLY_DEPLOYED: 'Parcialmente implantado',
};

export default function RequisitesTab({ standardId }) {
  const navigate = useNavigate();
  const [report, setReport] = React.useState([]);
  const selectedTenant = useSelector(useAuthTenant);
  const { id } = useParams();
  const [selectedControlId, setSelectedControlId] = useState();
  const [selectedRequisiteId, setSelectedRequisiteId] = useState();
  const [loading, setLoading] = useState(false);

  const fetchSecurityStandardControls = useCallback(async () => {
    if (standardId) {
      setLoading(true);
      const result = await getSecurityStandardControls(standardId);
      if (result?.data) {
        const distinctRequisites = [];
        result?.data
          .filter(item => item.requisite)
          .forEach(element => {
            if (distinctRequisites.map(item => item._id).includes(element.requisite?._id)) return;
            else distinctRequisites.push(element.requisite);
          });

        const requisites = distinctRequisites.map(req => ({
          ...req,
          controls: result?.data.filter(item => item.requisite?._id === req._id).map(item => item.control),
        }));
        const report = await fetchReport();
        const finalReport = requisites.map(requisite => {
          return {
            ...requisite,
            controls: requisite.controls.map(control => {
              const deployments = report.filter(item => item.controlId === control._id);
              const implantation = deployments.filter(d => d.deploymentStatus === 'DEPLOYED').length;

              const notApplicable = deployments.filter(d => d.deploymentStatus === 'NOT_APPLICABLE').length;

              const hasAnyDeployment = deployments.filter(d => d.deploymentStatus).length > 0;

              const status =
                deployments.length > 0 && notApplicable === deployments.length ? (
                  <Status status={ControlDeploymentStatus['NOT_APPLICABLE']} />
                ) : deployments.length - notApplicable === implantation ? (
                  <Status status={ControlDeploymentStatus['DEPLOYED']} />
                ) : implantation > 0 ? (
                  <Status status={ControlDeploymentStatus['PARTIALLY_DEPLOYED']} />
                ) : (
                  <Status status={ControlDeploymentStatus['NOT_DEPLOYED']} />
                );

              return {
                ...control,
                deployments: deployments,
                status: status,
                progress: `${implantation}/${hasAnyDeployment ? deployments.length : 0}`,
              };
            }),
          };
        });

        setReport(finalReport);
      }
      setLoading(false);
      return result?.data;
    }
    setLoading(false);
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standardId]);

  useEffect(() => {
    fetchSecurityStandardControls();
  }, [fetchSecurityStandardControls]);

  const fetchReport = useCallback(async () => {
    const result = await getReportByControls(selectedTenant.slug, id);
    const newData = [];
    if (result?.data) {
      result?.data.forEach(d => {
        const control = d.control;
        const deployments =
          d.controlDeployments.map(d => {
            return {
              _id: d._id,
              deploymentStatus: d.status,
              control: control.what,
              controlId: control._id,
              object: d.object,
              type: ControlTypes[d.type],
              status: <Status status={ControlDeploymentStatus[d.status]} />,
              implanter: (
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  {d.status === 'NOT_DEPLOYED' ? (
                    <Tooltip title={'Aguardando implantação'} placement={'top'} arrow>
                      <AccessTime sx={{ color: '#DFC175' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={'Implantado'} placement={'top'} arrow>
                      <Check sx={{ color: '#75DF8C' }} />
                    </Tooltip>
                  )}
                  {d.implanter}
                </Stack>
              ),
              implantation: formatDateTimeToDateTimeBR(d.updatedAt, true),
              validator: (
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  {d.status === 'NOT_DEPLOYED' ? (
                    <Tooltip title={'Aguardando implantação'} placement={'top'} arrow>
                      <LockClock sx={{ color: '#BDB9B7' }} />
                    </Tooltip>
                  ) : d.validated ? (
                    <Tooltip title={'Validado'} placement={'top'} arrow>
                      <Check sx={{ color: '#75DF8C' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={'Aguardado validação'} placement={'top'} arrow>
                      <AccessTime sx={{ color: '#DFC175' }} />
                    </Tooltip>
                  )}{' '}
                  {d.validator}
                </Stack>
              ),
              action: (
                <MenuButton
                  iconSrc="/img/icons/dots.svg"
                  menuItems={[
                    {
                      label: 'Ver',
                      onClick: () => navigate(`deployment-log/${d._id}`),
                      iconSrc: '/img/icons/see.svg',
                    },
                    {
                      label: 'Editar',
                      onClick: () => navigate(`control/${control._id}/deployment-log/${d._id}`),
                      iconSrc: '/img/icons/pencil.svg',
                    },
                  ]}
                />
              ),
            };
          }) ?? [];
        const result = deployments.length > 0 ? deployments : [{ control: control.what, controlId: control._id }];

        newData.push(...result);
      });

      newData.sort((a, b) => {
        if (a.control < b.control) {
          return -1;
        }
        if (a.control > b.control) {
          return 1;
        }
        return 0;
      });
      return newData;
    }
  }, [id, navigate, selectedTenant.slug]);

  if (loading) {
    return (
      <Stack spacing={4}>
        {[0, 1, 2].map(num => (
          <Box key={num} border={'1px solid lightgray'} borderRadius={'8px'}>
            {' '}
            <StyledTable>
              <tbody>
                {Array.from({ length: 4 }, (_, index) => (
                  <TableRow key={index}>
                    {Array.from({ length: 4 }, (_, cellIndex) => (
                      <TableCell key={cellIndex} align={'center'}>
                        <Skeleton style={{ minWidth: 100 }} height={30} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </tbody>
            </StyledTable>
          </Box>
        ))}
      </Stack>
    );
  }
  return (
    <Stack spacing={4}>
      {report.map(requisite => (
        <Box key={requisite._id} border={'1px solid lightgray'} borderRadius={'8px'}>
          <Box borderBottom={'1px solid lightgray'} p={2} fontWeight={700}>
            {requisite.name}
          </Box>
          <StyledTable>
            <thead>
              <TableRow>
                <TableHeader>Controle</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Implantação</TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
            </thead>
            <tbody>
              {requisite.controls.map(control => {
                const open = selectedControlId === control._id && selectedRequisiteId === requisite._id;
                return (
                  <>
                    <TableRow key={control._id}>
                      <TableCell>{control.what}</TableCell>
                      <TableCell>{control.status}</TableCell>
                      <TableCell>{control.progress}</TableCell>
                      <TableCell style={{ width: '80px' }}>
                        <Box
                          bgcolor={'white'}
                          borderRadius={'8px'}
                          boxShadow={'0px 6px 20px 0px #EFF1F7'}
                          p={'8px'}
                          width={'32px'}
                          height={'32px'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setSelectedControlId(open ? undefined : control._id);
                            setSelectedRequisiteId(open ? undefined : requisite._id);
                          }}
                        >
                          <img
                            src="/img/icons/arrowDown.svg"
                            alt=""
                            style={{
                              transform: open ? 'rotate(180deg)' : 'unset',
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={4} style={{ margin: 0, padding: 0 }}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <ExpansionPanel controls={control.deployments} controlId={control._id} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </tbody>
          </StyledTable>
        </Box>
      ))}
    </Stack>
  );
}

const ExpansionPanel = ({ controls, controlId }) => {
  const navigate = useNavigate();
  return (
    <Box p={'2px'}>
      <StyledTable>
        <thead>
          <TableRow style={{ background: '#EAEAEA' }}>
            <TableHeader>Tipo</TableHeader>
            <TableHeader>Objeto</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Implantador</TableHeader>
            <TableHeader>Implantação</TableHeader>
            <TableHeader>Validador</TableHeader>
            <TableHeader>
              {' '}
              <OutlinedButtonSmall
                style={{ background: 'transparent' }}
                variant="outlined"
                onClick={() => navigate(`control/${controlId}/deployment-log`)}
              >
                <img src="/img/icons/plus-blue.svg" alt="Icone gráfico" />
                Adicionar
              </OutlinedButtonSmall>
            </TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {controls.map(control => (
            <TableRow style={{ background: '#F8F8F8' }}>
              <TableCell>{control.type}</TableCell>
              <TableCell>{control.object}</TableCell>
              <TableCell>{control.status}</TableCell>
              <TableCell>{control.implanter}</TableCell>
              <TableCell>{control.implantation}</TableCell>
              <TableCell>{control.validator}</TableCell>
              <TableCell>{control.action}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </Box>
  );
};

const Status = ({ status }) => (
  <Stack direction={'row'} alignItems={'center'} spacing={1}>
    <StatusBullet status={status} />
    <Typography color="#6B6664" fontWeight={500} fontSize={'14px'}>
      {status}
    </Typography>
  </Stack>
);

const StatusBullet = ({ status }) => {
  const implantedColor = '#75DF8C';
  const notImplantedColor = '#DFC175';
  const notApplicableColor = '#BDB9B7';
  const partiallyDeployedColor = '#c1eaf3';

  const getStatusColor = status => {
    switch (status) {
      case 'Implantado':
        return implantedColor;
      case 'N. Implantado':
        return notImplantedColor;
      case 'N. aplicável':
        return notApplicableColor;
      case 'Parcialmente implantado':
        return partiallyDeployedColor;
      default:
        return notApplicableColor;
    }
  };

  return <Box width={10} height={10} borderRadius={10} bgcolor={getStatusColor(status)} />;
};
