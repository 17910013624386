import { Box, Chip, Tab, Tabs } from '@mui/material'
import { Container } from 'Components/Container'
import { Column } from 'Components/Grid'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ColumnEdit, LinkEdit, RowExhibition } from './styles'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { getCompanyById } from 'services/company-service'
import { PageLoaderShow } from 'Components/PageLoader/Show'

const TabPanel = (props) => {
    const { children, value, index, id, ariaLabel, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={id}
            aria-labelledby={ariaLabel}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}

export const CompaniesShow = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [tabPosition, setTabPosition] = useState(0)
    const { isLoading, error, data: company } = useQuery('getCompanyById', async () => {
        const dataCompany = await getCompanyById(id)
        return (dataCompany.status === 200) ? dataCompany.data : null
    })

    const onClickEdit = () => {
        navigate('/companies/edit/'+id)
    }

    return (
        <>
            {!isLoading && !error && (
                <Container
                    title={`${company?.name}`}
                    breadcrumb={`Configurações Globais / Clientes / ${company?.name}`}
                    linkPage="/companies"
                    >
                    <Box sx={{ width: '100%' }} className="container">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={tabPosition}
                                onChange={(data, newValue) => setTabPosition(newValue)}
                                aria-label="basic tabs example" >
                                <Tab
                                    label="Detalhes"
                                    id="tab-details"
                                    aria-controls="tabpanel-details"
                                    style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                                    />
                                <Tab
                                    label="Empresa Coordenadora"
                                    id="tab-managers"
                                    aria-controls="tabpanel-managers"
                                    style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                                    />
                            </Tabs>
                        </Box>
                        <TabPanel
                            value={tabPosition}
                            index={0}
                            id="tabpanel-details"
                            ariaLabel="tab-details"
                            >
                            <RowExhibition>
                                <Column xs={3}>Nome</Column>
                                <Column>{company.name}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Razão Social</Column>
                                <Column>{company?.corporateName}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Identificador</Column>
                                <Column>{company.slug}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>CNPJ</Column>
                                <Column>{company.cnpj}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Quantidade de funcionários</Column>
                                <Column>{company?.size}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Receita</Column>
                                <Column>{company.income}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Indústria</Column>
                                <Column>{company.industry}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Domínios</Column>
                                <Column>
                                    {company.domains?.map((domain) => (
                                        <Chip
                                            key={`company-domain-${domain}`}
                                            label={domain}
                                            variant="outlined"
                                            color="primary"
                                            sx={{
                                                width: 'fit-content',
                                                marginInlineEnd: '6px'
                                            }}
                                            />
                                    ))}
                                </Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Ativo</Column>
                                <Column>{company.active ? 'Sim': 'Não'}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Parceiro</Column>
                                <Column>{company.partner ? 'Sim': 'Não'}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Plano assinado</Column>
                                <Column>{company?.signedPlan}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                            <RowExhibition>
                                <Column xs={3}>Resumo</Column>
                                <Column>{company.description}</Column>
                                <ColumnEdit xs={2}>
                                <LinkEdit onClick={onClickEdit}>
                                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                    Editar
                                </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                        </TabPanel>
                        <TabPanel
                            value={tabPosition}
                            index={1}
                            id="tabpanel-managers"
                            ariaLabel="tab-managers"
                            >
                            <RowExhibition>
                                <Column>
                                    {company.managers?.map((manager) => (
                                        <Chip
                                            key={`company-chip-${manager._id}`}
                                            label={manager.name}
                                            variant="outlined"
                                            color="primary"
                                            sx={{
                                            width: 'fit-content',
                                            }}
                                            />
                                    ))}
                                </Column>
                                <ColumnEdit xs={2}>
                                    <LinkEdit onClick={onClickEdit}>
                                        <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                                        Editar
                                    </LinkEdit>
                                </ColumnEdit>
                            </RowExhibition>
                        </TabPanel>
                    </Box>
                </Container>
            )}
            {isLoading && <PageLoaderShow />}
            {error && <div>Erro</div>}
        </>
    )
}
