import { Column } from 'Components/Grid'
import styled from 'styled-components'

export const ContainerSuccess = styled.div`
  display: flex;
  flex-direction: column;
`
export const TitleContent = styled(Column)`
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  font-feature-settings: 'liga' off;
  color: #3c3938;
  margin: 0 auto 48px;
  text-align: center;
`
