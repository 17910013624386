import React from 'react';
import { Main } from './styles';


export const Footer = () => {

  return (
    <Main>
      <span>© 2022 Virtual Office -</span>
      <p>Todos Direitos Reservados</p>
    </Main>
  );
};
