import styled from "styled-components";

export const TabButton = styled.button`
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => (props.isActive ? "#0039C5" : "gray")};
  border-bottom: ${(props) => (props.isActive ? "3px solid #0039C5" : "none")};
`;
