export const tableHeader = [
  {
    id: 'name',
    type: 'text',
    value: 'Nome',
  },
  {
    id: 'storage',
    type: 'text',
    value: 'Armazenamento',
  },
  {
    id: 'category',
    type: 'text',
    value: 'Categoria',
  },
  {
    id: 'dataSource',
    type: 'text',
    value: 'Origem',
  },
  {
    id: 'holderProfile',
    type: 'text',
    value: 'Titular',
  },
  {
    id: 'retentionPeriod',
    type: 'text',
    value: 'Retenção',
  },
  {
    id: 'action',
    type: 'actions-datamapping',
    value: '',
  },
];