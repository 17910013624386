import { SideBar } from 'Components/SideBar';
import { Header } from 'Components/Header';
import { Main, SecondColumn } from '../styles';
import { Footer } from 'Components/Footer';

export const LayoutAuthenticated = ({children}) => {
    return (
        <Main>
            <SideBar/>
            <SecondColumn>
                <Header/>
                <main style={{marginBottom: '40px', position: 'relative'}}>
                    {children}
                </main>
                <Footer/>
            </SecondColumn>
        </Main>
    )
}