import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { deleteCookie, getCookiesShortPaged } from 'services/cookies-service';
import { tableHeader } from './table-header.js';
import { useAuthTenant } from 'store/auth';

import { DefaultTable } from 'Components/DefaultTable';
import { Container } from '../../Components/Container';
import { Information } from 'Components/Information';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { PageLoaderList } from 'Components/PageLoader/List';
import { NoData } from 'Components/NoData';
import { Pagination, Stack } from '@mui/material';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export const Cookies = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const [open, setOpen] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const {
    isLoading,
    error,
    data: pagedCompanySites,
  } = useQuery(['getCookies', reFetch, page], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getCookiesShortPaged(selectedTenant.slug, page, 15);
    if (result?.data.data.length > 0) {
      return result?.data;
    }
    return {
      data: [],
      page,
      totalPages: 0,
    };
  });

  const onDelete = item => {
    setItemToDelete(item);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      const result = await deleteCookie(itemToDelete?.companySiteId);
      if (result.status === 200 || result.status === 204) {
        onClose();
        setRefetch(!reFetch);
        SwalReact.fire({
          title: 'Sucesso!',
          text: 'Cookie removido com sucesso',
          icon: 'success',
        });
      } else {
        throw new Error('Ocorreu um erro ao tentar remover o cookie!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  return (
    <Container
      title="Gestão de Cookies e Consentimento"
      breadcrumb="Adequações / Gestão de Cookies e Consentimento"
      showButtonAdd
      linkPage="/cookies"
    >
      <Information
        title="Gestão de Cookies e Consentimento"
        description={
          <>
            O Cookie serve para cadastrar nos sites a exibição de uma coleta de dados com requisição de permissão para
            isso
          </>
        }
        style={{
          marginTop: '16px',
        }}
      />
      {isLoading && <PageLoaderList></PageLoaderList>}
      {error && <div>Ocorreu um erro ...</div>}
      {!isLoading && !error && pagedCompanySites.data.length === 0 && (
        <NoData description={'Não há cookies para exibir'} />
      )}
      {!isLoading && !error && pagedCompanySites.data?.length > 0 && (
        <div className="container">
          <DefaultTable
            rows={tableHeader}
            cells={pagedCompanySites.data}
            onClickDelete={onDelete}
            link="cookies"
            onClickEdit={item => {
              navigate(`/cookies/edit/${item.companySiteId}`);
            }}
            onClickShow={item => {
              navigate(`/cookies/show/${item.companySiteId}`);
            }}
            onClick={() => {}}
          />
          <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginTop: '16px' }}>
            <Pagination
              count={pagedCompanySites.totalPages}
              onChange={(_, selectedPage) => {
                setPage(selectedPage);
              }}
              color="primary"
              page={page}
              showFirstButton
              showLastButton
            />
          </Stack>
          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja excluir <strong>{` ${itemToDelete?.url} `}</strong> do vinculo de cookies?
              </>
            }
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
          />
        </div>
      )}
    </Container>
  );
};
