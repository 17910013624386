import { DefaultButton } from 'Components/DefaultButton'
import styled from 'styled-components'

export const Subtitle = styled.h2`
  width: fit-content;
`
export const ButtonAdd = styled(DefaultButton)`
  &.no-margin {
    margin: 0 !important;
    height: 38px;
    width: 171px;
  }
`
export const ContainerContent = styled.div`
  .card-content {
    box-shadow: 0px 6px 20px #eff1f7;
    border-radius: 8px;
    background-color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 24px;
    width: 70%;
    margin: 32px auto 0;
  }
`
