import { ButtonForm, CustomTextField } from 'Components/Form/styles'
import { Column, Row } from 'Components/Grid'
import { useNavigate } from 'react-router-dom'

export const Detalhes = ({
    handleComplete,
    register,
    handleSubmit,
    errors,
}) => {
    // const theme = useTheme()
    const navigate = useNavigate()
    const onSubmit = () => {
        handleComplete()
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row justifyContent="center">
                <Column xs={12} sm={12} md={12} className="d-flex">
                    <CustomTextField
                        {...register('name', { required: 'Nome é obrigatório' })}
                        label="Nome"
                        autoFocus
                        error={errors?.name ? true : false}
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        helperText={errors?.name ? errors.name.message : null}
                        InputLabelProps={{ shrink: true }}
                        />
                </Column>
            </Row>
            <Row justifyContent="center">
                <Column xs={12} sm={12} md={12} className="d-flex">
                    <CustomTextField
                        {...register('address')}
                        label="Endereço"
                        // error={errors?.name ? true : false}
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        // helperText={errors?.name ? errors.name.message : null}
                        InputLabelProps={{ shrink: true }}
                        />
                </Column>
            </Row>
            <Row justifyContent="center">
                <Column xs={12} sm={12} md={12} className="d-flex">
                    <CustomTextField
                        {...register('phone')}
                        label="Telefone"
                        // error={errors?.name ? true : false}
                        sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
                        // helperText={errors?.name ? errors.name.message : null}
                        InputLabelProps={{ shrink: true }}
                        />
                </Column>
            </Row>
            <Row justifyContent="end" alignItems="center">
                <Column xs={3} justifyContent="center" className="d-flex">
                    <ButtonForm
                    secondary
                    id="cancel"
                    label="cancelar"
                    onClick={() => navigate('/offices')}
                    ></ButtonForm>
                </Column>
                <Column xs={2} justifyContent="center" className="d-flex">
                    <ButtonForm label="Salvar" variant="submit" />
                </Column>
            </Row>
        </form>
    )
}
