import { useState } from 'react';
import { ButtonClose, CardInformation, DescriptionInformation, TitleInformation } from './styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export const Information = ({ title, description, style }) => {
  const [closed, setClosed] = useState(false);

  return (
    <CardInformation className={closed ? 'closed' : ''} style={style}>
      <ButtonClose onClick={() => setClosed(true)} startIcon={<ClearOutlinedIcon />}></ButtonClose>
      <TitleInformation>{title}</TitleInformation>
      <DescriptionInformation>{description}</DescriptionInformation>
    </CardInformation>
  );
};
