import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from '@mui/material';
import { Column, Row } from 'Components/Grid';
import { Controller } from 'react-hook-form';
import { ButtonForm } from 'Components/Form/styles';
import { useQuery } from 'react-query';
import { getSelectedContent } from 'utils/select_utils';
import { getCompanies } from 'services/company-service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name, personName, theme) => {
  return {
    fontWeight:
      personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

export const Managers = ({ handleBack, handleComplete, control, handleSubmit, errors, getValues, idCompany }) => {
  const theme = useTheme();

  const onSubmit = () => {
    handleComplete();
  };

  const { data: companies } = useQuery(['getCompanies'], async () => {
    const result = await getCompanies();
    return result?.data.length > 0
      ? idCompany
        ? result?.data.filter(company => company._id !== idCompany)
        : result?.data
      : [];
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="managers"
            rules={{
              validate: () => {
                return getValues('managers').length > 0;
              },
              required: 'Coordenadores/Gerentes é obrigatório',
            }}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ margin: '0 auto 24px' }} error={errors?.managers ? true : false}>
                <InputLabel id="managers-label" shrink>
                  Coordenadores/Gerentes
                </InputLabel>
                <Select
                  labelId="managers-label"
                  id="managers"
                  multiple
                  notched
                  value={getSelectedContent(companies, value)}
                  onChange={onChange}
                  input={<OutlinedInput id="companies-chip" label="Coordenadores/Gerentes" />}
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected?.map(selectedValue => (
                        <Chip
                          key={`company-chip-${selectedValue._id}`}
                          label={selectedValue.name}
                          variant="outlined"
                          color="primary"
                          sx={{
                            width: 'fit-content',
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {companies?.map(item => (
                    <MenuItem key={item._id} value={item} style={getStyles(item._id, value._id, theme)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.managers ? errors.managers.message : ''}</FormHelperText>
              </FormControl>
            )}
          ></Controller>
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="back" label="Voltar" onClick={handleBack}></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label="Salvar" variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
