import { Box, Checkbox } from "@mui/material";
export const ControlDetailsItem = ({ name, value, isArray, objKeys }) => {
  return (
    <Box
      display={"flex"}
      borderBottom={"1px solid lightGray"}
      pt={2}
      pb={2}
      width={"100%"}
    >
      <Box sx={{ width: "30%", fontWeight: "bold" }}>
        <span>{name}</span>
      </Box>
      {!isArray && (
        <Box maxWidth={"70%"}>
          <span>{value}</span>
        </Box>
      )}
      {value && isArray && (
        <Box display={"flex"} maxWidth={"70%"} gap={1}>
          {value.map((i) => (
            <Box
              key={i}
              display={"flex"}
              alignItems={"center"}
              whiteSpace={"nowrap"}
              justifyContent={"start"}
              ml={"-10px"}
            >
              <Checkbox checked disabled />
              <span>{objKeys[i]}</span>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
