import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useRef } from "react";
import Header from "../Header";
import { useParams } from "react-router-dom";
import { getGapAnalysisReport } from "services/gap-analysis-report";
import { useSelector } from "react-redux";
import { useAuthTenant } from "store/auth";
import { getCompanyBySlug } from "services/company-service";
import { OutlinedButton } from "../styles";
import { useReactToPrint } from "react-to-print";

export default function GapAnalysisReportDetails() {
  const [open, setOpen] = React.useState(null);
  const [gapAnalysisReport, setGapAnalysisReport] = React.useState(null);
  const { id } = useParams();
  const [company, setCompany] = React.useState(null);
  const selectedTenant = useSelector(useAuthTenant);
  const [isLoadingCompany, setIsLoadingCompany] = React.useState(false);
  const [isLoadingReport, setIsLoadingReport] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState("ALL");
  const componentRef = useRef();
  const [isPrinting, setIsPrinting] = React.useState(false);

  const fetchGapAnalysisReport = useCallback(async () => {
    setIsLoadingReport(true);
    const result = await getGapAnalysisReport(id);
    if (result?.data) {
      setGapAnalysisReport(result?.data);
    }
    setIsLoadingReport(false);
  }, [id]);

  React.useEffect(() => {
    fetchGapAnalysisReport();
  }, [fetchGapAnalysisReport]);

  const fetchCompany = useCallback(async () => {
    setIsLoadingCompany(true);
    const result = await getCompanyBySlug(selectedTenant.slug);
    if (result?.data) {
      setCompany(result?.data);
    }
    setIsLoadingCompany(false);
  }, [selectedTenant]);

  React.useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const getStatusLabel = (status) => {
    switch (status) {
      case "DEPLOYED":
        return "Implantado";
      case "NOT_DEPLOYED":
        return "Não Implantado";
      case "PARTIALLY_DEPLOYED":
        return "Parcialmente Implantado";
      case "NOT_APPLICABLE":
        return "Não Aplicável";
      default:
        return "Não Aplicável";
    }
  };

  const getRequisiteStatusLabel = (status) => {
    switch (status) {
      case "DEPLOYED":
        return "Atende";
      case "NOT_DEPLOYED":
        return "Não Atende";
      case "PARTIALLY_DEPLOYED":
        return "Atende Parcialmente";
      case "NOT_APPLICABLE":
        return "Não Aplicável";
      default:
        return "Não Aplicável";
    }
  };

  const filteredBySelectedStatus = gapAnalysisReport?.requisites?.filter(
    (requisite) => {
      if (selectedStatus === "ALL") {
        return true;
      }
      return requisite.status === selectedStatus;
    }
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@page { size: 10in 12in }",
  });
  return (
    <Box padding={4} ref={componentRef}>
      <Header
        title={"Relatórios"}
        breadcrumb={
          "Relatórios / Gap analysis / Detalhes / " +
          gapAnalysisReport?.securityStandard?.name
        }
        actions={<></>}
      />

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt={4}
      >
        <FormControl>
          <InputLabel id="standard-select-label">Filtrar por status</InputLabel>
          <Select
            labelId="standard-select-label"
            id="standard-select"
            label="Filtrar por status"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            style={{ minWidth: "200px" }}
          >
            <MenuItem value={"ALL"}>Todos</MenuItem>
            <MenuItem value={"DEPLOYED"}>Atende</MenuItem>
            <MenuItem value={"NOT_DEPLOYED"}>Não Atende</MenuItem>
            <MenuItem value={"PARTIALLY_DEPLOYED"}>
              Atende Parcialmente
            </MenuItem>
            <MenuItem value={"NOT_APPLICABLE"}>Não Aplicável</MenuItem>
          </Select>
        </FormControl>
        <OutlinedButton
          style={{ background: "transparent" }}
          onClick={() => {
            setIsPrinting(true);
            setTimeout(() => {
              handlePrint();
            }, 1000);

            setTimeout(() => {
              setIsPrinting(false);
            }, 5000);
          }}
        >
          <img src={"/img/icons/print.svg"} alt={"Imprimir"} />
          Imprimir
        </OutlinedButton>
      </Box>

      <Box padding={2} bgcolor={"#fff"} fontSize={14} mt={4}>
        {!isLoadingCompany && (
          <Box border={"1px solid #F1EFEE"} borderRadius={2}>
            <Box
              padding={2}
              textAlign={"center"}
              fontWeight={700}
              color={"#0133AE"}
              borderBottom={"1px solid #F1EFEE"}
            >
              Dados da empresa
            </Box>
            <Box padding={2} borderBottom={"1px solid #F1EFEE"}>
              <Box fontWeight={700} color={"#3C3938"}>
                Nome da Empresa
              </Box>
              <Box>{company?.name}</Box>
            </Box>
            <Box padding={2}>
              <Box fontWeight={700} color={"#3C3938"}>
                Resumo da Empresa
              </Box>
              <Box>{company?.description}</Box>
            </Box>
          </Box>
        )}
        {isLoadingCompany && (
          <Skeleton loading={isLoadingCompany} height={250} />
        )}
      </Box>

      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        mt={4}
      >
        <Box fontSize={24} fontWeight={700} color={"#537DE4"}>
          Padrão: {gapAnalysisReport?.securityStandard?.name}
        </Box>
        <Box fontSize={14} fontWeight={700} color={"#537DE4"}>
          Versão {gapAnalysisReport?.version}
        </Box>
      </Box>

      <Box display={"flex"} mt={4} gap={2} justifyContent={"space-between"}>
        <DashboardCard
          icon={"pizza-chart"}
          title={"Conformidade"}
          value={`${gapAnalysisReport?.percentageOfCompliance || 0}`}
          dark
          isLoading={isLoadingReport}
          percentage
        />
        <DashboardCard
          icon={"check-circle"}
          title={"Atende"}
          value={gapAnalysisReport?.meetsRequirementCount || 0}
          isLoading={isLoadingReport}
        />
        <DashboardCard
          icon={"alert-triangule"}
          title={"Atende parcialmente"}
          value={gapAnalysisReport?.meetsRequirementPartiallyCount || 0}
          isLoading={isLoadingReport}
        />
        <DashboardCard
          icon={"close-circle"}
          title={"Não atende"}
          value={gapAnalysisReport?.doesNotMeetRequirementCount || 0}
          isLoading={isLoadingReport}
        />
        <DashboardCard
          icon={"remove"}
          title={"Não Aplicável"}
          value={gapAnalysisReport?.notApplicableCount}
          isLoading={isLoadingReport}
        />
      </Box>

      {isLoadingReport && <Skeleton height={600} />}

      {!isLoadingReport && (
        <Stack bgcolor={"#fff"} p={4} mt={4} gap={2}>
          {filteredBySelectedStatus?.map((item) => (
            <Box
              border={"1px solid #F1EFEE"}
              borderRadius={2}
              style={{ cursor: "pointer" }}
              onClick={() => setOpen(open === item ? null : item)}
            >
              <Box
                p={2}
                display={"grid"}
                gridTemplateColumns={"1fr 200px  30px"}
                gap={2}
                color={"#3C3938"}
              >
                <Box fontWeight={600} fontSize={14}>
                  {item.name}
                </Box>
                <Box fontSize={14}>
                  {" "}
                  <Status status={getRequisiteStatusLabel(item.status)} />
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={2}
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(open === item ? null : item)}
                >
                  <img
                    src="/img/icons/arrowDown.svg"
                    alt=""
                    style={{
                      transform: open === "1" ? "rotate(180deg)" : "unset",
                    }}
                  />
                </Box>
              </Box>
              {(open === item || isPrinting) && (
                <Box display={"flex"} flexDirection={"column"}>
                  <Box
                    fontWeight={600}
                    fontSize={14}
                    display={"grid"}
                    gridTemplateColumns={"300px 1fr"}
                    bgcolor={"#EAEAEA"}
                    p={2}
                  >
                    <Box>Controle</Box>
                    <Box>Status</Box>
                  </Box>
                  {item.controls.map((control) => (
                    <Box
                      fontWeight={600}
                      fontSize={14}
                      display={"grid"}
                      gridTemplateColumns={"300px 1fr"}
                      bgcolor={"#F8F8F8"}
                      p={2}
                    >
                      <Box>{control.name}</Box>
                      <Box>
                        <Status status={getStatusLabel(control.status)} />
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
}

const DashboardCard = ({
  title,
  value,
  color,
  dark,
  icon,
  isLoading,
  percentage,
}) => {
  const numValue = parseFloat(value);
  const roundedValue = Math.round(numValue * 100) / 100;
  return isLoading ? (
    <Box
      borderRadius={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      gap={1}
      flexBasis={"100%"}
    >
      <Skeleton height={250} width={"100%"} />
    </Box>
  ) : (
    <Box
      bgcolor={dark ? "#537DE4" : "#fff"}
      borderRadius={2}
      p={2}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      gap={1}
      flexBasis={"100%"}
    >
      <Box display={"flex"}>
        <Box
          bgcolor={dark ? "#fff" : "#537DE4"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          p={1}
          borderRadius={"12px"}
        >
          <img
            width={"24px"}
            height={"24px"}
            src={`/img/icons/${icon}.svg`}
            alt="Ícone de dashboard"
          />
        </Box>
      </Box>

      <Box fontSize={"40px"} fontWeight={700} color={dark ? "#fff" : "#537DE4"}>
        {roundedValue} {percentage ? "%" : ""}
      </Box>
      <Box fontSize={16} fontWeight={400} color={dark ? "#fff" : "#3C3938"}>
        {title}
      </Box>
    </Box>
  );
};

const Status = ({ status }) => {
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      <StatusBullet status={status} />
      <Typography color="#6B6664" fontWeight={500} fontSize={"14px"}>
        {status}
      </Typography>
    </Stack>
  );
};

const StatusBullet = ({ status }) => {
  const implantedColor = "#75DF8C";
  const partiallyImplantedColor = "#c1eaf3";
  const notImplantedColor = "#DFC175";
  const notApplicableColor = "#BDB9B7";

  const getStatusColor = (status) => {
    console.log(status);
    switch (status) {
      case "Implantado":
        return implantedColor;
      case "Não Implantado":
        return notImplantedColor;
      case "Parcialmente Implantado":
        return partiallyImplantedColor;
      case "Não aplicável":
        return notApplicableColor;
      case "Atende":
        return implantedColor;
      case "Não Atende":
        return notImplantedColor;
      case "Atende Parcialmente":
        return partiallyImplantedColor;
      default:
        return notApplicableColor;
    }
  };

  return (
    <Box
      width={10}
      height={10}
      borderRadius={10}
      bgcolor={getStatusColor(status)}
    />
  );
};
