import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAuthLoggedIn } from "store/auth";
import { LayoutAuthenticated } from "./Authenticated";
import { LayoutUnauthenticated } from "./Unauthenticated";

export const Layout = ({ children }) => {
  const location = useLocation();
  const isLoggedIn = useSelector(useAuthLoggedIn);

  const isUnauthenticatedRoute = () => {
    const paths = location.pathname.split("/");
    const path = paths.length > 1 ? paths[1] : paths[0];
    switch (path) {
      case "login":
      case "create-account":
      case "onboard-company":
      case "onboard-company-admin":
      case "confirmation-instructions":
      case "recovery-password":
      case "verify-code":
        return true;
      default:
        return false;
    }
  };

  const isUncommonRoute = () => {
    const paths = location.pathname.split("/");
    const path = paths.length > 4 ? paths[paths.length - 1] : paths.length > 1 ? paths[1] : paths[0];
    switch (path) {
      case "accept-policy-version":
      case "onboard-choose-plan":
      case "onboard-cancel":
      case "onboard-success":
        return true;
      default:
        return false;
    }
  };

  const getLayout = children => {
    if (isUncommonRoute()) {
      return children;
    }
    if (isUnauthenticatedRoute() || !isLoggedIn) {
      return <LayoutUnauthenticated>{children}</LayoutUnauthenticated>;
    }
    return <LayoutAuthenticated>{children}</LayoutAuthenticated>;
  };
  return getLayout(children);
};
