import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useTheme,
} from '@mui/material';
import DotsLoader from 'Components/DotsLoader';
import { ButtonForm } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUsersGroups } from 'services/users-groups-service';
import { useAuthTenant } from 'store/auth';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (name, personName, theme) => {
  return {
    fontWeight:
      personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
};

export const Details = ({ register, control, isLoading, errors, handleComplete, handleContinue, id, handleSubmit }) => {
  const selectedTenant = useSelector(useAuthTenant);
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: usersGroups } = useQuery(['getUsersGroups'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const result = await getUsersGroups(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  return (
    <form onSubmit={handleSubmit(handleComplete)}>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <TextField
            {...register('name', { required: 'Nome é obrigatório' })}
            label="Nome"
            autoFocus
            error={errors?.name ? true : false}
            sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
            helperText={errors?.name ? errors.name.message : null}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12} className="d-flex">
          <TextField
            {...register('classification', { required: 'Classificação é obrigatório' })}
            label="Classificação"
            error={errors?.classification ? true : false}
            sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
            helperText={errors?.classification ? errors.classification.message : null}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            name="userGroups"
            render={({ field: { onChange, value }, fieldState }) => (
              <FormControl fullWidth sx={{ margin: '0 auto 24px' }} error={fieldState.error ? true : false}>
                <InputLabel id="user-groups-label" shrink={true}>
                  Grupo de usuários
                </InputLabel>
                <Select
                  labelId="user-groups-label"
                  id="userGroups"
                  multiple
                  value={value}
                  onChange={onChange}
                  notched
                  input={<OutlinedInput id="user-groups-chip" label="Grupo de usuarios" />}
                  renderValue={selected => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected
                        ?.filter(item => item != null)
                        .map(value => (
                          <Chip
                            key={value?._id}
                            label={value?.name}
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: 'fit-content',
                            }}
                          />
                        ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {usersGroups?.map(item => (
                    <MenuItem
                      key={`${item._id}-${item.name}`}
                      value={item}
                      style={getStyles(item._id, value._id, theme)}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
              </FormControl>
            )}
          ></Controller>
        </Column>
      </Row>
      <Row>
        <Column>
          <TextField
            {...register('description', {
              required: 'Descrição é obrigatório',
            })}
            label="Descrição"
            multiline
            rows={4}
            error={errors?.description ? true : false}
            helperText={errors?.description ? errors.description.message : null}
            sx={{ margin: '0 auto 24px', width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="cancel" label="cancelar" onClick={() => navigate('/policies')}></ButtonForm>
        </Column>
        {!!id && (
          <Column xs={3} justifyContent="end" className="d-flex">
            <ButtonForm label={'Continuar'} variant="button" onClick={() => handleContinue()} />
          </Column>
        )}
        <Column xs={3} justifyContent="end" className="d-flex">
          <ButtonForm label={isLoading ? <DotsLoader /> : 'Salvar'} variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
