import { Component } from 'react'

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, contentError: null};
    }

    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
        return { hasError: true , contentError: error};
    }

    componentDidCatch(error, info) {
      // Example "componentStack":
      //   in ComponentThatThrows (created by App)
      //   in ErrorBoundary (created by App)
      //   in div (created by App)
      //   in App
        // logErrorToMyService(error, info.componentStack);
        // console.error(error, info.componentStack)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.fallback(new Error(this.state.contentError));
        }

        return this.props.children;
    }
}

export default ErrorBoundary