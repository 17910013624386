import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useQueries } from 'react-query';
import { useAuthTenant } from 'store/auth';
import { getSelectedContent } from 'utils/select_utils';

import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from '@mui/material';
import { Column, Row } from 'Components/Grid';
import { ButtonForm } from 'Components/Form/styles';
import DotsLoader from 'Components/DotsLoader';

import { getAreasShort } from 'services/areas-service';
import { getProcessesShort } from 'services/processes-service';
import { getSystemsShort } from 'services/systems-service';
import { getStyles, MenuProps } from './helpers';
import { InputLoader } from 'Components/PageLoader/Input';

export const Vinculos = ({ handleBack, handleComplete, control, handleSubmit, isLoading }) => {
  const selectedTenant = useSelector(useAuthTenant);
  const theme = useTheme();

  const onSubmit = () => {
    handleComplete();
  };

  const queries = useQueries([
    {
      queryKey: ['getAreas'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getAreasShort(selectedTenant.slug);
        return result?.data.length > 0 ? result?.data : [];
      },
    },
    {
      queryKey: ['getProcesses'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getProcessesShort(selectedTenant.slug);
        return result?.data.length > 0 ? result?.data : [];
      },
    },
    {
      queryKey: ['getSystems'],
      queryFn: async () => {
        if (selectedTenant.slug === undefined) return [];
        const result = await getSystemsShort(selectedTenant.slug);
        return result?.data.length > 0 ? result?.data : [];
      },
    },
  ]);

  const [
    { data: areas, isLoading: areaLoading, isError: areaError },
    { data: processes, isLoading: processLoading, isError: processError },
    { data: systems, isLoading: systemLoading, isError: systemError },
  ] = queries;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          {!areaLoading && !areaError && (
            <Controller
              control={control}
              name="areas"
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
                  <InputLabel id="areas-label" shrink>
                    Áreas vinculadas
                  </InputLabel>
                  <Select
                    labelId="areas-label"
                    id="areas"
                    multiple
                    notched
                    value={getSelectedContent(areas, value)}
                    onChange={onChange}
                    input={<OutlinedInput id="areas-chip" label="Áreas vinculadas" />}
                    renderValue={selectedAreas => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selectedAreas?.map(selectedValue => (
                          <Chip
                            key={`area-chip-${selectedValue._id}`}
                            label={selectedValue.name}
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: 'fit-content',
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {areas?.map(area => (
                      <MenuItem key={area._id} value={area} style={getStyles(area._id, value._id, theme)}>
                        {area.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            ></Controller>
          )}
          {areaLoading && !areaError && (
            <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
              <InputLabel id="areas-label" shrink={true}>
                Áreas vinculadas
              </InputLabel>
              <InputLoader />
            </FormControl>
          )}
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          {!processLoading && !processError && (
            <Controller
              control={control}
              name="processes"
              render={({ field: { onChange, value }, fieldState }) => (
                <FormControl fullWidth sx={{ margin: '0 auto 24px' }} error={fieldState.error ? true : false}>
                  <InputLabel id="processes-label" shrink={true}>
                    Processos vinculados
                  </InputLabel>
                  <Select
                    labelId="processes-label"
                    id="processes"
                    multiple
                    notched
                    value={getSelectedContent(processes, value)}
                    onChange={onChange}
                    input={<OutlinedInput id="processes-chip" label="Processos vinculados" />}
                    renderValue={selectedProcesses => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selectedProcesses?.map(selectedValue => (
                          <Chip
                            key={`process-chip-${selectedValue._id}`}
                            label={selectedValue.name}
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: 'fit-content',
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {processes?.map(item => (
                      <MenuItem key={`process-${item._id}`} value={item} style={getStyles(item._id, value._id, theme)}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
                </FormControl>
              )}
            ></Controller>
          )}
          {processLoading && !processError && (
            <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
              <InputLabel id="processes-label" shrink={true}>
                Processos vinculados
              </InputLabel>
              <InputLoader />
            </FormControl>
          )}
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          {!systemLoading && !systemError && (
            <Controller
              control={control}
              name="systems"
              render={({ field: { onChange, value }, fieldState }) => (
                <FormControl fullWidth sx={{ margin: '0 auto 24px' }} error={fieldState.error ? true : false}>
                  <InputLabel id="systems-label" shrink={true}>
                    Sistemas vinculados
                  </InputLabel>
                  <Select
                    labelId="systems-label"
                    id="systems"
                    multiple
                    notched
                    value={getSelectedContent(systems, value)}
                    onChange={onChange}
                    input={<OutlinedInput id="systems-chip" label="Sistemas vinculados" />}
                    renderValue={selectedSystems => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selectedSystems?.map(value => (
                          <Chip
                            key={value._id}
                            label={value.name}
                            variant="outlined"
                            color="primary"
                            sx={{
                              width: 'fit-content',
                            }}
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {systems?.map(item => (
                      <MenuItem key={item._id} value={item} style={getStyles(item._id, value._id, theme)}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{fieldState.error ? fieldState.error.message : ''}</FormHelperText>
                </FormControl>
              )}
            ></Controller>
          )}
          {systemLoading && !systemError && (
            <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
              <InputLabel id="systems-label" shrink={true}>
                Sistemas vinculados
              </InputLabel>
              <InputLoader />
            </FormControl>
          )}
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="cancel" label="cancelar" onClick={handleBack}></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label={isLoading ? <DotsLoader /> : 'Salvar'} variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
