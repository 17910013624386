import React from 'react'
import PropTypes from 'prop-types'
import { Main } from './styles'

export const DefaultButton = (props) => {
  const { label, secondary, id, onClick, variant, className } = props
  return (
    <Main
      id={id}
      type={variant || 'button'}
      secondary={secondary}
      onClick={onClick}
      className={className}
    >
      {label}
    </Main>
  )
}

DefaultButton.propTypes = {
  label: PropTypes.any,
  secondary: PropTypes.bool,
}

DefaultButton.defaultProps = {
  secondary: false,
}
