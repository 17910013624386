export const tableHeaderCategory = [
    {
        id: 'name',
        type: 'text',
        value: 'COOKIE',
    },
    {
        id: 'domain',
        type: 'text',
        value: 'HOSTNAME',
    },
    {
        id: 'category',
        type: 'category-cookie',
        value: 'CLASSIFICAÇÃO',
    },
    {
        id: 'expires',
        type: 'time-expires',
        value: 'EXPIRAÇÃO',
    }
]

export const tableHeaderConsent = [
    {
        id: 'id',
        type: 'text',
        value: 'NÚMERO DE CONSENTIMENTO',
    },
    {
        id: 'status',
        type: 'consent-status',
        value: 'STATUS',
    },
    {
        id: 'categories',
        type: 'consent-categories',
        value: 'CATEGORIAS',
    },
    {
        id: 'country',
        type: 'text',
        value: 'PAIS',
    },
    {
        id: 'anonymizedIp',
        type: 'text',
        value: 'ANONIMIZAÇÃO IP',
    },
    {
        id: 'createdAt',
        type: 'datetime',
        value: 'DADO EM',
    }
]