import { Card } from "@mui/material";
import styled from "styled-components";

export const CardCompany = styled(Card)`
    min-height: 106px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
`
export const TitleCompany = styled.h3`
    font-family: 'Oxygen';
    font-weight: 400;
    font-size: 24px;
    color: #0039C5;
    margin-block-start: 25px;
    margin-block-end: 9px;
`

export const CardCounter = styled(Card)`
    width: 198px;
    height: 180px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
    padding: 16px;
`
export const LogoCardCounter = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
`
export const ContainerLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #537DE4;
    box-shadow: 0px 6px 20px #EFF1F7;
    border-radius: 12px;
`
export const ContentCardCounter = styled.div`
    height: 54px;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    // line-height: 54px;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
    color: #0039C5;
`
export const TitleCardCounter = styled.div`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    font-feature-settings: 'liga' off;
    color: #6B6664;
    margin-block-start: 10px;
    height: 20px;
`

export const TitleContent = styled.h4`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #0039C5;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
`
export const CardPendingPolicies = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 10px;
    height: 338px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
`
export const CardStatusControls = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    height: 338px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
`

export const ItemPendingPolicy = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    &:hover {
        cursor: pointer;
    }
`


