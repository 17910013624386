import { Shimmer } from "../../styles"

export const PageLoaderDashboardPolicyCard = () => {
    return (
        <div style={{display: 'flex'}}>
            <div style={{height: '338px', width: '488px', display:'flex'}}>
                <Shimmer style={{height: '338px', width: '488px'}}></Shimmer>
            </div>
        </div>
    )
}