import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { FormSuccess } from 'Components/Form/Success';
import { Container } from '../../../Components/Container';
import { Row } from 'Components/Grid';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid';

import { ButtonForm, TitleForm } from 'Components/Form/styles';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TextField,
  Typography,
  FormGroup,
  Divider,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  addControl,
  editControl,
  getControlById,
  getControlSecurityStandards,
  getControls,
} from 'services/controls-service';

import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AreaOptions,
  CyberSecConceptOptions,
  DomainOptions,
  DomainValue,
  FunctionOptions,
  ISPropertiesOptions,
  TypeOptions,
  getKeyByValue,
} from '../options';
import { StepperForm } from 'Components/StepperForm';
import {
  createSecurityStandardRequisite,
  getSecurityStandardRequisites,
  getSecurityStandards,
} from 'services/security-standard-service';

const toEmptyArray = value => {
  return value === false ? [] : value;
};
const toNullIfEmpty = value => {
  return value === '' ? null : value === undefined ? null : value;
};

const schema = z.object({
  what: z.string().nonempty(),
  why: z.string(),
  how: z.string(),
  where: z.string(),
  function: z.any().transform(toEmptyArray),
  isProperties: z.any().transform(toEmptyArray),
  cyberSecConcept: z.any().transform(toEmptyArray),
  type: z.string().default(''),
  prerequisite: z.string().default('').transform(toNullIfEmpty),
  actionPlan: z.string(),
  area: z.string().default(''),
  domain: z.string().default(''),
});
const steps = ['Detalhes', 'Padrão'];

export const ControlsForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [allControls, setAllControls] = useState([]);
  const [allSecurityStandards, setAllSecurityStandards] = useState([]);
  const [selectedSecurityStandard, setSelectedSecurityStandard] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [idControl, setIdControl] = useState(null);
  const [finished, setFinished] = useState(false);
  const [requisites, setRequisites] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState('');
  const [selectedRequisites, setSelectedRequisites] = useState([]);
  const [isCreatingNewRequisite, setIsCreatingNewRequisite] = useState(false);
  const [newRequisite, setNewRequisite] = useState('');
  const [openedStandard, setOpenedStandard] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
    reset,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const handleReset = () => {
    navigate('/controls/add');
    setActiveStep(0);
    setFinished(false);
    setIdControl(null);
    setSelectedSecurityStandard([]);
    reset();
  };

  const getRequisitesByStandard = async standardId => {
    const result = await getSecurityStandardRequisites(standardId);
    setRequisites(result?.data);
  };

  const fetchControls = useCallback(async () => {
    if (id) {
      const result = await getControlById(id);
      if (result?.data) {
        setValue('what', result?.data.what);
        setValue('why', result?.data.why);
        setValue('how', result?.data.how);
        setValue('where', result?.data.where);
        setValue('function', result?.data.function);
        setValue('isProperties', result?.data.isProperties);
        setValue('cyberSecConcept', result?.data.cyberSecConcept);
        setValue('type', result?.data.type);
        setValue('prerequisite', result?.data.prerequisite?._id ?? '');
        setValue('actionPlan', result?.data.actionPlan);
        setValue('area', result?.data.area);
        setValue('domain', result?.data.domain);
      }
      return result?.data;
    }
    return null;
  }, [id, setValue]);

  useEffect(() => {
    fetchControls();
  }, [fetchControls]);

  const onSubmit = async data => {
    const controlSecurityStandards = [];
    selectedSecurityStandard.forEach(item => {
      item.requisites.forEach(requisite => {
        controlSecurityStandards.push({
          standard: item.standard,
          requisite: requisite._id,
        });
      });
    });
    const request = {
      ...data,
      securityStandards: controlSecurityStandards,
    };
    try {
      const res = id ? await editControl(id, request) : await addControl(request);
      if (res.status === 200) {
        setIdControl(res.data._id ?? id);
        setFinished(true);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() ? activeStep : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handlePrevStep = () => {
    if (activeStep === 0) {
      navigate('/controls');
    } else {
      handleBack();
    }
  };

  const handleNextStep = data => {
    if (activeStep === 0) {
      handleNext();
    } else {
      onSubmit(data);
    }
  };

  const fetchAllControls = useCallback(async () => {
    const result = await getControls();
    if (result?.data) {
      setAllControls(result?.data);
    }
  }, []);

  useEffect(() => {
    fetchAllControls();
  }, [fetchAllControls]);

  const fetchAllSecurityStandards = useCallback(async () => {
    const result = await getSecurityStandards();
    if (result?.data) {
      setAllSecurityStandards(result?.data);
    }
  }, []);

  useEffect(() => {
    fetchAllSecurityStandards();
  }, [fetchAllSecurityStandards]);

  const fetchControlSecurityStandards = useCallback(async () => {
    if (!id) return;
    const result = await getControlSecurityStandards(id);
    if (result?.data) {
      const distinctStandards = [];
      result?.data.forEach(item => {
        if (distinctStandards.map(item => item._id).includes(item.securityStandard._id)) return;
        else distinctStandards.push(item.securityStandard);
      });
      setSelectedSecurityStandard(
        distinctStandards.map(standard => ({
          standard: standard,
          requisites: result?.data
            .filter(item => item.securityStandard._id === standard._id)
            .map(item => item.requisite),
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idControl]);

  useEffect(() => {
    fetchControlSecurityStandards();
  }, [fetchControlSecurityStandards]);

  const filteredSecurityStandards = useMemo(() => {
    if (!selectedSecurityStandard.length) return allSecurityStandards;
    return allSecurityStandards.filter(securityStandard => {
      return !selectedSecurityStandard.some(selected => selected.standard._id === securityStandard._id);
    });
  }, [allSecurityStandards, selectedSecurityStandard]);

  const deleteStandard = item => {
    setSelectedSecurityStandard(
      selectedSecurityStandard.filter(selected => selected.standard._id !== item.standard._id),
    );
  };

  const handleSelectStandard = event => {
    const { value } = event.target;
    setSelectedStandard(value);
    getRequisitesByStandard(value._id);
    setIsCreatingNewRequisite(false);
  };

  const handleAddStandard = () => {
    if (!selectedSecurityStandard || selectedRequisites.length === 0) return;
    setSelectedSecurityStandard([
      ...selectedSecurityStandard,
      { standard: selectedStandard, requisites: selectedRequisites },
    ]);
    setRequisites([]);
    setSelectedRequisites([]);
    setSelectedStandard('');
  };

  const cleanStandardAndRequisites = () => {
    setSelectedRequisites([]);
    setSelectedStandard('');
  };

  const handleCreateRequisite = async () => {
    if (newRequisite === '') return;

    const request = {
      _id: uuidv4(),
      name: newRequisite,
      securityStandard: selectedStandard._id,
    };
    const result = await createSecurityStandardRequisite(request);

    if (result.status === 201) {
      setRequisites([...requisites, request]);
      setIsCreatingNewRequisite(false);
      setNewRequisite('');
    }
  };

  return (
    <Container
      title={`${id ? 'Editar' : 'Adicionar'} Controle`}
      breadcrumb="Configurações globais / Controles"
      linkPage="/controls"
    >
      {!finished && <StepperForm activeStep={activeStep} steps={steps} />}
      <div className="card-content">
        <Row>
          <TitleForm>{activeStep === 0 ? 'Detalhes sobre o controle' : 'Vincular Padrão'}</TitleForm>
        </Row>

        <form onSubmit={handleSubmit(handleNextStep)}>
          <Stack spacing={4}>
            {!finished && activeStep === 0 && (
              <>
                <Box display={'flex'} gap={4} justifyContent={'space-between'}>
                  <TextField
                    label="O que"
                    variant="outlined"
                    error={errors.what}
                    helperText={errors.what?.message}
                    fullWidth
                    {...register('what')}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Onde"
                    variant="outlined"
                    fullWidth
                    multiline
                    {...register('where')}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <TextField
                  label="Porque"
                  multiline
                  rows={4}
                  fullWidth
                  {...register('why')}
                  error={errors.why}
                  helperText={errors.why?.message}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Como"
                  multiline
                  rows={4}
                  fullWidth
                  {...register('how')}
                  InputLabelProps={{ shrink: true }}
                />
                <Box>
                  <span style={{ color: '#537DE4', fontSize: 12 }}>Função</span>

                  <Box display="flex" mt={1}>
                    {Object.keys(FunctionOptions).map(key => (
                      <Controller
                        key={key}
                        name="function"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="function"
                                value={key}
                                onChange={e => {
                                  if (e.target.checked) {
                                    onChange([...value, e.target.value]);
                                  } else {
                                    onChange(value.filter(v => v !== e.target.value));
                                  }
                                }}
                                checked={value.includes(key)}
                              />
                            }
                            label={FunctionOptions[key]}
                          />
                        )}
                      />
                    ))}
                  </Box>
                </Box>

                <Box>
                  <span style={{ color: '#537DE4', fontSize: 12 }}>Is Properties</span>
                  <Box display="flex" mt={1}>
                    {Object.keys(ISPropertiesOptions).map(key => (
                      <Controller
                        key={key}
                        name="isProperties"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isProperties"
                                value={key}
                                onChange={e => {
                                  if (e.target.checked) {
                                    onChange([...value, e.target.value]);
                                  } else {
                                    onChange(value.filter(v => v !== e.target.value));
                                  }
                                }}
                                checked={value.includes(key)}
                              />
                            }
                            label={ISPropertiesOptions[key]}
                          />
                        )}
                      />
                    ))}
                  </Box>
                </Box>

                <Box>
                  <span style={{ color: '#537DE4', fontSize: 12 }}>CiberSec</span>
                  <Box display="flex" mt={1}>
                    {Object.keys(CyberSecConceptOptions).map(key => (
                      <Controller
                        key={key}
                        name="cyberSecConcept"
                        control={control}
                        defaultValue={[]}
                        render={({ field: { onChange, value } }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="cyberSecConcept"
                                value={key}
                                onChange={e => {
                                  if (e.target.checked) {
                                    onChange([...value, e.target.value]);
                                  } else {
                                    onChange(value.filter(v => v !== e.target.value));
                                  }
                                }}
                                checked={value.includes(key)}
                              />
                            }
                            label={CyberSecConceptOptions[key]}
                          />
                        )}
                      />
                    ))}
                  </Box>
                </Box>

                <Box display={'flex'} gap={4} justifyContent={'space-between'}>
                  <FormControl fullWidth>
                    <InputLabel id="type">Tipo</InputLabel>
                    <Select labelId="type" id="type" label="Tipo" {...register('type')} value={watch('type') ?? ''}>
                      {Object.keys(TypeOptions).map(key => (
                        <MenuItem key={key} value={key}>
                          {TypeOptions[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="prerequisite">Pré requisito</InputLabel>
                    <Select
                      labelId="prerequisite"
                      id="prerequisite"
                      label="Pré requisito"
                      {...register('prerequisite')}
                      value={watch('prerequisite') ?? ''}
                    >
                      {allControls.map(control => (
                        <MenuItem key={control._id} value={control._id}>
                          {control.what}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <TextField
                  label="Plano de ação"
                  variant="outlined"
                  fullWidth
                  {...register('actionPlan')}
                  InputLabelProps={{ shrink: true }}
                />
                <Box display={'flex'} gap={4} justifyContent={'space-between'}>
                  <FormControl style={{ width: '48%' }}>
                    <InputLabel id="area">Área</InputLabel>
                    <Select
                      labelId="area"
                      label="Área"
                      {...register('area')}
                      value={watch('area') ?? ''}
                      error={!!errors.area}
                      helperText={errors.area?.message}
                    >
                      {Object.keys(AreaOptions).map((key, index) => (
                        <MenuItem key={key} value={key}>
                          {AreaOptions[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl style={{ width: '48%' }}>
                    <InputLabel id="area">Domínio</InputLabel>
                    <Select
                      labelId="dominio"
                      label="Domínio"
                      {...register('domain')}
                      value={watch('domain') ?? ''}
                      error={!!errors.domain}
                      helperText={errors.domain?.message}
                    >
                      {DomainOptions.filter(d => d.area === AreaOptions[watch('area')]).map((item, index) => (
                        <MenuItem key={index} value={getKeyByValue(DomainValue, item.name)}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </>
            )}
            {activeStep === 1 && !finished && (
              <>
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel id="securityStandard">Padrão</InputLabel>
                    <Select
                      labelId="securityStandard"
                      id="securityStandard"
                      label="Padrão"
                      onChange={handleSelectStandard}
                      value={selectedStandard}
                    >
                      {filteredSecurityStandards.length === 0 && (
                        <MenuItem disabled value={''}>
                          Nenhum padrão encontrado
                        </MenuItem>
                      )}

                      {filteredSecurityStandards.map(standard => (
                        <MenuItem key={standard._id} value={standard}>
                          {standard.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedStandard && (
                    <>
                      <Typography>Selecione os requsitos</Typography>
                      <Box padding={2} border={'1px solid lightgray'} borderRadius={'8px'}>
                        <FormGroup>
                          {requisites.map(requisite => (
                            <FormControlLabel
                              key={requisite._id}
                              control={
                                <Checkbox
                                  sx={{
                                    width: 'fit-content',
                                  }}
                                  value={requisite}
                                  onChange={e => {
                                    const { checked } = e.target;
                                    if (checked) setSelectedRequisites([...selectedRequisites, requisite]);
                                    else setSelectedRequisites(selectedRequisites.filter(r => r._id !== requisite._id));
                                  }}
                                />
                              }
                              label={requisite.name}
                            />
                          ))}
                        </FormGroup>
                        <Box
                          display={isCreatingNewRequisite ? 'none' : 'flex'}
                          justifyContent={'center'}
                          gap={1}
                          onClick={() => setIsCreatingNewRequisite(true)}
                          style={{ cursor: 'pointer' }}
                        >
                          <img src="/img/icons/plus-blue.svg" alt="Icone adicionar" />
                          <Typography color={'#537DE4'} fontWeight={500}>
                            Cadastrar novo requisito
                          </Typography>
                        </Box>

                        <Box display={isCreatingNewRequisite ? 'flex' : 'none'} flexDirection={'column'} gap={2}>
                          <TextField
                            label="Requisito"
                            variant="outlined"
                            fullWidth
                            onChange={e => setNewRequisite(e.target.value)}
                            value={newRequisite}
                            rows={2}
                            multiline
                          />
                          <Box display={'flex'} gap={2}>
                            <ButtonForm
                              disabled
                              secondary
                              label={'Cancelar'}
                              onClick={() => setIsCreatingNewRequisite(false)}
                              style={{ minWidth: 'fit-content !important' }}
                              className="full"
                            ></ButtonForm>
                            <ButtonForm
                              disabled
                              primary
                              label={'Incluir requisito'}
                              onClick={handleCreateRequisite}
                              style={{ minWidth: 'fit-content !important' }}
                              className="full"
                            ></ButtonForm>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        display={isCreatingNewRequisite || selectedRequisites.length === 0 ? 'none' : 'flex'}
                        justifyContent={'center'}
                        gap={2}
                      >
                        <ButtonForm
                          secondary
                          label={'Limpar'}
                          onClick={cleanStandardAndRequisites}
                          style={{ minWidth: '100% !important' }}
                          className="full"
                        ></ButtonForm>
                        <ButtonForm
                          submit
                          label={'Adicionar'}
                          onClick={handleAddStandard}
                          style={{ minWidth: '100% !important' }}
                          className="full"
                        ></ButtonForm>
                      </Box>
                    </>
                  )}
                </Stack>

                <Stack spacing={2}>
                  {selectedSecurityStandard.map((item, index) => (
                    <Box key={index} borderRadius={'4px'} border={'1px solid #BDB9B7 '}>
                      <Box
                        display={'flex'}
                        borderBottom={openedStandard === item.standard._id ? ' 1px solid #BDB9B7 ' : 'none'}
                        alignItems={'center'}
                        p={2}
                      >
                        <Typography width={'30%'} color={'#3C3938'} fontWeight={700} flex={1}>
                          Padrão: {item.standard.name}
                        </Typography>

                        <Box style={{ cursor: 'pointer' }} mr={2}>
                          <img src="/img/icons/cross.svg" alt="Icone excluir" onClick={() => deleteStandard(item)} />
                        </Box>
                        <Box
                          bgcolor={'white'}
                          borderRadius={'8px'}
                          boxShadow={'0px 6px 20px 0px #EFF1F7'}
                          p={'8px'}
                          width={'32px'}
                          height={'32px'}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            if (openedStandard === item.standard._id) {
                              setOpenedStandard(null);
                            } else {
                              setOpenedStandard(item.standard._id);
                            }
                          }}
                        >
                          <img
                            src="/img/icons/arrowDown.svg"
                            alt=""
                            style={{
                              transform: openedStandard === item.standard._id ? 'rotate(180deg)' : 'unset',
                            }}
                          />
                        </Box>
                      </Box>

                      {openedStandard === item.standard._id &&
                        item.requisites.map(requisite => {
                          return (
                            <>
                              <Box display={'flex'} p={'8px 16px'}>
                                <Typography flex={1}>{requisite?.name}</Typography>
                              </Box>
                              <Divider />
                            </>
                          );
                        })}
                    </Box>
                  ))}
                </Stack>
              </>
            )}
            {!finished && selectedRequisites?.length === 0 && (
              <Box display={'flex'} gap={2} justifyContent={'space-between'}>
                <ButtonForm
                  secondary
                  id="cancel"
                  label={activeStep === 0 ? 'Cancelar' : 'Voltar'}
                  onClick={handlePrevStep}
                ></ButtonForm>
                <ButtonForm
                  label={activeStep === 1 ? 'Salvar' : 'Próximo'}
                  variant="submit"
                  style={{ width: '80px !important' }}
                />
              </Box>
            )}
          </Stack>
        </form>

        {finished && (
          <FormSuccess
            title={`Controle ${idControl ? 'editado' : 'cadastrado'} com sucesso!`}
            labelStartAgain="Cadastrar novo controle"
            handleStartAgain={() => handleReset()}
            labelShow="Ver detalhes"
            handleShow={() => navigate('/controls/show/' + idControl)}
          />
        )}
      </div>
    </Container>
  );
};
