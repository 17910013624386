import { Column, Row } from 'Components/Grid';
import { useQuery } from 'react-query';
import { getMapCompanyBySlug } from 'services/company-service';
import {
  CardCompany,
  CardCounter,
  CardPendingPolicies,
  CardStatusControls,
  ContainerLogo,
  ContentCardCounter,
  ItemPendingPolicy,
  LogoCardCounter,
  TitleCardCounter,
  TitleCompany,
  TitleContent,
} from './styles';
import { useSelector } from 'react-redux';
import { useAuthTenant, useAuthUser } from 'store/auth';
import { formatDateToDateBRPolish } from 'utils/date_utils';
import { PageLoaderDashboardCards } from 'Components/PageLoader/Dashboard/Cards';
import { PageLoaderDashboardPolicyCard } from 'Components/PageLoader/Dashboard/PolicyCard';
import { getPendingPolicies } from 'services/policies-service';

export const Dashboard = () => {
  const user = useSelector(useAuthUser);
  const selectedTenant = useSelector(useAuthTenant);
  const companyMap = useQuery(['getMapCompanyBySlug'], async () => {
    if (selectedTenant.slug === undefined) return null;
    const response = await getMapCompanyBySlug(selectedTenant.slug);
    return response?.data || null;
  });

  const pendingPolicies = useQuery(['getPendingPolicies'], async () => {
    if (selectedTenant.slug === undefined) return [];
    const response = await getPendingPolicies(selectedTenant.slug, user.userId);
    return response?.data || null;
  });

  return (
    <div className="mt-3 px-3">
      <Row>
        <Column xs={12} className="mx-auto">
          {selectedTenant && (
            <CardCompany className="px-3">
              <TitleCompany>{selectedTenant?.corporateName || selectedTenant.name}</TitleCompany>
              <div>{formatDateToDateBRPolish(new Date())}</div>
            </CardCompany>
          )}
        </Column>
      </Row>
      {companyMap.isLoading && <PageLoaderDashboardCards />}
      {!companyMap.error && !companyMap.isLoading && (
        <Row justifyContent="around" alignItems="center" className="px-3 mt-3">
          <CardCounter>
            <LogoCardCounter>
              <ContainerLogo>
                <img src="img/icons/flag.png" alt="logo de uma bandeira" />
              </ContainerLogo>
            </LogoCardCounter>
            <ContentCardCounter>{companyMap?.data?.areas}</ContentCardCounter>
            <TitleCardCounter>Áreas mapeadas</TitleCardCounter>
          </CardCounter>
          <CardCounter>
            <LogoCardCounter>
              <ContainerLogo>
                <img src="img/icons/grid.png" alt="logo de um grid" />
              </ContainerLogo>
            </LogoCardCounter>
            <ContentCardCounter>{companyMap?.data?.assets}</ContentCardCounter>
            <TitleCardCounter>Ativos mapeados</TitleCardCounter>
          </CardCounter>
          <CardCounter>
            <LogoCardCounter>
              <ContainerLogo>
                <img src="img/icons/drawer.png" alt="logo de uma caixa de processos" />
              </ContainerLogo>
            </LogoCardCounter>
            <ContentCardCounter>{companyMap?.data?.processes}</ContentCardCounter>
            <TitleCardCounter>Processos mapeados</TitleCardCounter>
          </CardCounter>
          <CardCounter>
            <LogoCardCounter>
              <ContainerLogo>
                <img src="img/icons/computer.png" alt="logo de um computador" />
              </ContainerLogo>
            </LogoCardCounter>
            <ContentCardCounter>{companyMap?.data?.systems}</ContentCardCounter>
            <TitleCardCounter>Sistemas mapeados</TitleCardCounter>
          </CardCounter>
          <CardCounter>
            <LogoCardCounter>
              <ContainerLogo>
                <img src="img/icons/database.png" alt="logo de um banco de dados" />
              </ContainerLogo>
            </LogoCardCounter>
            <ContentCardCounter>{companyMap?.data?.datas}</ContentCardCounter>
            <TitleCardCounter>Dados mapeados</TitleCardCounter>
          </CardCounter>
        </Row>
      )}
      <Row className="px-2 mt-3 mb-3">
        <Column xs={6}>
          <TitleContent>Políticas pendentes</TitleContent>
          {pendingPolicies.isLoading && <PageLoaderDashboardPolicyCard />}
          {pendingPolicies.isSuccess && !pendingPolicies.isLoading && (
            <CardPendingPolicies>
              {pendingPolicies.data?.length === 0 && <>Sem políticas pendentes</>}
              {pendingPolicies.data?.map(pendingPolicy => (
                <ItemPendingPolicy
                  onClick={() => {
                    window.open(
                      `https://${selectedTenant.slug}.virtualofficer.io/accept-policy-version/${pendingPolicy._id}`,
                      '_blank',
                    );
                  }}
                >
                  {pendingPolicy.policyName} - {pendingPolicy.policyVersion}
                </ItemPendingPolicy>
              ))}
            </CardPendingPolicies>
          )}
        </Column>
        <Column xs={6}>
          <TitleContent>Status de controles</TitleContent>
          {companyMap.isLoading && <PageLoaderDashboardPolicyCard />}
          {companyMap.isSuccess && !companyMap.isLoading && (
            <CardStatusControls>Em breve gráfico de status de controles e métricas</CardStatusControls>
          )}
        </Column>
      </Row>
    </div>
  );
};
