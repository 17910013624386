import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import Header from '../Header';
import BarChart from '../Charts/BarChart';
import { getChartEvolution, getChartEvolutionByRequisites, getGapAnalysisByCompany } from 'services/gap-analysis';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import LineChart from '../Charts/LineChart';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export default function Evolution() {
  const [lastMonthsNumber, setLastMonthsNumber] = React.useState(6);
  const [gapAnalysis, setGapAnalysis] = React.useState([]);
  const [selectedGapAnalysis, setSelectedGapAnalysis] = React.useState('all');
  const selectedTenant = useSelector(useAuthTenant);
  const [chartData, setChartData] = React.useState([]);
  const [implanted, setImplanted] = React.useState(true);
  const [notImplanted, setNotImplanted] = React.useState(true);
  const [notApplicable, setNotApplicable] = React.useState(true);
  const [partiallyImplanted, setPartiallyImplanted] = React.useState(true);
  const [chartType, setChartType] = React.useState('bar');
  const [mode, setMode] = React.useState('controls');

  const fetchChartData = async (startDate, selectedGapAnalysis) => {
    const gapId = selectedGapAnalysis === 'all' ? undefined : selectedGapAnalysis;
    const result =
      mode === 'controls'
        ? await getChartEvolution(
            selectedTenant.slug,
            startDate.toISOString().slice(0, 10),
            new Date().toISOString().slice(0, 10),
            gapId,
          )
        : await getChartEvolutionByRequisites(
            selectedTenant.slug,
            startDate.toISOString().slice(0, 10),
            new Date().toISOString().slice(0, 10),
            gapId,
          );
    if (result?.data) {
      setChartData(result?.data);
    }
  };

  const fetchGapAnalysis = useCallback(async () => {
    const result = await getGapAnalysisByCompany(selectedTenant.slug);
    if (result?.data) {
      setGapAnalysis(result?.data);
    }
  }, [selectedTenant.slug]);

  useEffect(() => {
    fetchGapAnalysis();
  }, [fetchGapAnalysis]);

  useEffect(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - lastMonthsNumber);
    fetchChartData(date, selectedGapAnalysis);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const handleChangePeriod = async event => {
    setLastMonthsNumber(event.target.value);
    const date = new Date();
    date.setMonth(date.getMonth() - event.target.value);
    await fetchChartData(date, selectedGapAnalysis);
  };

  const handleChangeGapAnalysis = async event => {
    setSelectedGapAnalysis(event.target.value);
    const date = new Date();
    date.setMonth(date.getMonth() - lastMonthsNumber);
    await fetchChartData(date, event.target.value);
  };

  useEffect(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 6);
    fetchChartData(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheck = event => {
    const { name, checked } = event.target;
    switch (name) {
      case 'implanted':
        setImplanted(checked);
        break;
      case 'notImplanted':
        setNotImplanted(checked);
        break;
      case 'notApplicable':
        setNotApplicable(checked);
        break;
      case 'partiallyImplanted':
        setPartiallyImplanted(checked);
        break;
      default:
        break;
    }
  };

  const filteredChartData = chartData
    .map(gap => {
      const newMapByMonth = gap.mapByMonth.map(month => {
        const newItem = { ...month };
        if (!implanted) {
          newItem.deployed = 0;
        }
        if (!notImplanted) {
          newItem.notDeployed = 0;
        }
        if (!notApplicable) {
          newItem.notApplicable = 0;
        }
        if (!partiallyImplanted) {
          newItem.partiallyDeployed = 0;
        }
        return newItem;
      });
      return {
        ...gap,
        mapByMonth: newMapByMonth,
        securityStandard: gapAnalysis.find(item => item._id === gap.gapAnalysis._id)?.securityStandard,
      };
    })
    .flat();

  const handleChangeMode = async event => {
    setMode(event.target.value);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} p={4} gap={4}>
      <Header title={'Gap Analysis'} breadcrumb={'Gap Analysis / Evolução'} />

      <Box
        bgcolor={'#fff'}
        style={{ filter: 'drop-shadow(0px 6px 20px #EFF1F7)' }}
        p={4}
        display={'flex'}
        flexDirection={'column'}
        gap={4}
      >
        <Box display={'flex'} justifyContent={'flex-end'}>
          <FormControl style={{ minWidth: 300 }}>
            <InputLabel id="standard-label">Tipo de gráfico</InputLabel>
            <Select
              labelId="standard-label"
              id="standard"
              value={chartType}
              label="Tipo de gráfico"
              onChange={e => setChartType(e.target.value)}
            >
              <MenuItem value={'bar'}>Colunas</MenuItem>
              <MenuItem value={'line'}>Linhas</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box display={'flex'}>
          {filteredChartData.length === 0 && (
            <Box flex={1} marginTop={'-100px'}>
              <Skeleton style={{ minWidth: '100%' }} height={500} />
            </Box>
          )}
          {filteredChartData.length > 0 && chartType === 'bar' && (
            <Box flex={1}>
              <BarChart data={filteredChartData} period={lastMonthsNumber} viewByControl={mode === 'controls'} />
            </Box>
          )}

          {filteredChartData.length > 0 && chartType === 'line' && (
            <Box flex={1}>
              <LineChart data={filteredChartData} period={lastMonthsNumber} viewByControl={mode === 'controls'} />
            </Box>
          )}

          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-end'}
            p={4}
            width={250}
            boxShadow={'0px 6px 20px #EFF1F7'}
          >
            <Stack width={'100%'}>
              <Typography fontSize={14} fontWeight={600} color={'gray'}>
                Ver relatório por
              </Typography>
              <RadioGroup aria-label="visualization-mode" value={mode} onChange={handleChangeMode}>
                <Stack direction="row" alignItems="center" ml={'-8px'}>
                  <Radio value={'controls'} sx={{ width: 'fit-content' }} />
                  <Typography fontSize={14}>Controles</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" ml={'-8px'}>
                  <Radio value={'requisites'} sx={{ width: 'fit-content' }} />
                  <Typography fontSize={14}>Requisitos</Typography>
                </Stack>
              </RadioGroup>
              <Divider mt={1} />
            </Stack>

            <ChartLabel
              label={mode === 'controls' ? 'Implantado' : 'Atende'}
              color={'#04B2D9'}
              checked={implanted}
              onChange={onCheck}
              name={'implanted'}
            />
            <ChartLabel
              label={mode === 'controls' ? 'Parc. Implantado' : 'Atende Parcialmente'}
              color={'#c1eaf3'}
              checked={partiallyImplanted}
              onChange={onCheck}
              name={'partiallyImplanted'}
            />

            <ChartLabel
              label={mode === 'controls' ? 'N. Implantado' : 'Não atende'}
              color={'#DFC175'}
              checked={notImplanted}
              onChange={onCheck}
              name={'notImplanted'}
            />
            <ChartLabel
              label={'Não Aplicável'}
              color={'#C4C4C4'}
              checked={notApplicable}
              onChange={onCheck}
              name={'notApplicable'}
            />

            <Stack spacing={3} width={'100%'} mt={4}>
              <FormControl fullWidth>
                <InputLabel id="standard-label">Padrão</InputLabel>
                <Select
                  labelId="standard-label"
                  id="standard"
                  value={selectedGapAnalysis}
                  label="Padrão"
                  onChange={handleChangeGapAnalysis}
                >
                  <MenuItem value={'all'}>Todos</MenuItem>
                  {gapAnalysis.map(item => (
                    <MenuItem value={item._id}>{item.securityStandard.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="period-select-label">Período</InputLabel>
                <Select
                  labelId="period-select-label"
                  id="period-select"
                  value={lastMonthsNumber}
                  label="Período"
                  onChange={handleChangePeriod}
                >
                  <MenuItem value={3}>Últimos 3 meses</MenuItem>
                  <MenuItem value={6}>Últimos 6 meses</MenuItem>
                  <MenuItem value={12}>Últimos 12 meses</MenuItem>
                  <MenuItem value={18}>Últimos 18 meses</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const ChartLabel = ({ label, color, checked, onChange, name }) => (
  <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}>
    <Box bgcolor={color} minWidth={15} height={15} borderRadius={12}>
      <span>&nbsp;&nbsp;</span>
    </Box>
    <Typography fontSize={14} flex={1}>
      {label}
    </Typography>
    <Box>
      <Checkbox checked={checked} name={name} onChange={e => onChange(e)} />
    </Box>
  </Box>
);
