import { Container } from "Components/Container"
import { Column, Row } from "Components/Grid"

export const NoPermission = () => {
    return (
        <Container>
            <Row>
                <Column xs={12}>
                    <h2>Você não tem permissão para acessar esta tela!</h2>
                </Column>
            </Row>
        </Container>
    )
}