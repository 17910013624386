import styled from 'styled-components'

export const Main = styled.button`
  align-items: center;
  background-color: ${(props) => (props.secondary ? 'white' : '#0039C5')};
  border:${(props) => (props.secondary ? '1px solid #537DE4' : 'none')};
  border-radius: 12px;
  color:${(props) => (props.secondary ? '#537DE4' : 'white')}; 
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  height: 48px;
  line-height: 20px;
  cursor: pointer;
  margin: 0 0 0 40em;

  img {
    margin-right: 8px;
  }
`
