import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { ButtonForm, CustomTextField } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';
import { InputLoader } from 'Components/PageLoader/Input';
import { MuiChipsInput } from 'mui-chips-input';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUsersName } from 'services/users-service';
import { useAuthTenant } from 'store/auth';

export const Detalhes = ({ handleNext, register, control, handleSubmit, errors, getValues, id }) => {
  const selectedTenant = useSelector(useAuthTenant);
  const navigate = useNavigate();
  const onSubmit = () => {
    handleNext();
  };

  const { data: users, isLoading: userLoading } = useQuery(['getUsers'], async () => {
    const result = await getUsersName(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <CustomTextField
            {...register('name', { required: 'Nome é obrigatório' })}
            label="Nome"
            autoFocus
            error={errors?.name ? true : false}
            sx={{ minHeight: '48px', margin: '0 auto 24px', width: '100%' }}
            helperText={errors?.name ? errors.name.message : null}
            InputLabelProps={{ shrink: true }}
            disabled={!!id}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12} className="d-flex">
          <Controller
            control={control}
            rules={{
              validate: () => {
                return getValues('criticity') !== '';
              },
              required: 'Criticidade é obrigatório',
            }}
            name="criticity"
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ marginBottom: '24px' }} error={errors?.criticity ? true : false}>
                <InputLabel id="criticity-label" shrink>
                  Criticidade
                </InputLabel>
                <Select
                  labelId="criticity-label"
                  id="criticity"
                  value={value}
                  label="Criticidade"
                  onChange={onChange}
                  notched
                >
                  <MenuItem value="" key={-1}>
                    {' '}
                    Selecione uma criticidade{' '}
                  </MenuItem>
                  {['1', '2', '3', '4', '5'].map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {' '}
                      {item}{' '}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors?.criticity ? errors.criticity.message : ''}</FormHelperText>
              </FormControl>
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12} className="d-flex">
          {!userLoading && (
            <Controller
              control={control}
              rules={{
                validate: () => {
                  return getValues('user') !== '';
                },
                required: 'Responsável é obrigatório',
              }}
              name="user"
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth sx={{ marginBottom: '24px' }} error={errors?.user ? true : false}>
                  <InputLabel id="user-label" shrink>
                    Responsável
                  </InputLabel>
                  <Select labelId="user-label" id="user" value={value} label="Responsável" onChange={onChange} notched>
                    <MenuItem value="" key={-1}>
                      Selecione um responsável
                    </MenuItem>
                    {users
                      ?.map(user => user.name)
                      .map((item, index) => (
                        <MenuItem value={item} key={`user-${index}`}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{errors?.user ? errors.user.message : ''}</FormHelperText>
                </FormControl>
              )}
            />
          )}
          {userLoading && (
            <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
              <InputLabel id="user-label" shrink={true}>
                Responsável
              </InputLabel>
              <InputLoader />
            </FormControl>
          )}
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column xs={12} className="d-flex">
          <FormControl fullWidth sx={{ margin: '0 auto 24px' }}>
            <Controller
              control={control}
              name="tags"
              render={({ field: { onChange, value } }) => (
                <MuiChipsInput
                  label="Tags"
                  value={value}
                  onChange={onChange}
                  InputLabelProps={{ shrink: true }}
                  placeholder="Digite e aperte enter"
                  renderChip={(Component, props) => {
                    return (
                      <Component
                        {...props}
                        variant="outlined"
                        color="primary"
                        sx={{
                          width: 'fit-content',
                        }}
                      />
                    );
                  }}
                  sx={{
                    '& .MuiButtonBase-root.MuiChip-root.MuiChip-filled': {
                      width: 'fit-content',
                    },
                    '& .MuiButtonBase-root.MuiIconButton-root': {
                      width: 'fit-content',
                      marginTop: '0px',
                    },
                  }}
                />
              )}
            ></Controller>
          </FormControl>
        </Column>
      </Row>
      <Row>
        <Column>
          <TextField
            {...register('description', {
              required: 'Descrição é obrigatório',
            })}
            label="Descrição"
            multiline
            rows={4}
            error={errors?.description ? true : false}
            helperText={errors?.description ? errors.description.message : null}
            sx={{ margin: '0 auto 24px', width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <TextField
            {...register('descriptionDataFlow', {
              required: 'Descrição do fluxo de dados é obrigatório',
            })}
            label="Descrição do fluxo de dados"
            multiline
            rows={4}
            error={errors?.descriptionDataFlow ? true : false}
            helperText={errors?.descriptionDataFlow ? errors.descriptionDataFlow.message : null}
            sx={{ margin: '0 auto 8px', width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <Controller
            control={control}
            name="dpia"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                value={value}
                control={<Checkbox checked={value} onChange={onChange} name="dpia" />}
                label="DPIA"
                sx={{ marginLeft: 0, marginBottom: 0 }}
              />
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <Controller
            control={control}
            name="lia"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                value={value}
                control={<Checkbox checked={value} onChange={onChange} name="lia" />}
                label="LIA"
                sx={{
                  marginLeft: 0,
                  marginBottom: 0,
                  '& .MuiTypography-root': {
                    whiteSpace: 'nowrap',
                  },
                }}
              />
            )}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <Controller
            control={control}
            name="dataTreatmentFromChildrenTeenagerElderly"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                value={value}
                control={
                  <Checkbox checked={value} onChange={onChange} name="dataTreatmentFromChildrenTeenagerElderly" />
                }
                label="Tratamento de dados de crianças, adolescentes, vulneráveis e idosos"
                sx={{
                  marginLeft: 0,
                  marginBottom: '8px',
                  '& .MuiTypography-root': {
                    whiteSpace: 'nowrap',
                  },
                }}
              />
            )}
          />
        </Column>
      </Row>
      <Row justifyContent="end" alignItems="center">
        <Column xs={3} justifyContent="center" className="d-flex">
          <ButtonForm secondary id="cancel" label="cancelar" onClick={() => navigate('/processes')}></ButtonForm>
        </Column>
        <Column xs={2} justifyContent="center" className="d-flex">
          <ButtonForm label="Avançar" variant="submit" />
        </Column>
      </Row>
    </form>
  );
};
