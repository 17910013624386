import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

export const Row = (props) => {
  const {
    noGutters,
    flexWrap,
    alignItems,
    justifyContent,
    className,
    children,
    sx,
    style,
  } = props
  const rowClassName = 'row'
    .concat(' align-items-'.concat(alignItems))
    .concat(' justify-content-'.concat(justifyContent))
    .concat(' flex-'.concat(flexWrap || 'wrap'))
    .concat(noGutters ? ' no-gutters' : '')
    .concat(className ? ` ${className}` : '')
  return (
    <div className={rowClassName} sx={sx} style={style}>
      {children}
    </div>
  )
}

Row.propTypes = {
  alignItems: PropTypes.oneOf(['start', 'center', 'end', 'baseline']),
  children: PropTypes.node,
  className: PropTypes.string,
  flexWrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse']),
  justifyContent: PropTypes.oneOf([
    'start',
    'center',
    'end',
    'around',
    'between',
  ]),
  noGutters: PropTypes.bool,
  sx: PropTypes.object,
  style: PropTypes.object,
}

Row.defaultProps = {
  noGutters: false,
  justifyContent: 'start',
  alignItems: 'center',
  className: '',
}

const Col = styled.div`
  position: relative;
`
export const Column = (props) => {
  const {
    xs,
    sm,
    md,
    lg,
    xl,
    flex,
    alignSelf,
    justifyContent,
    className,
    children,
    sx,
    style,
  } = props
  const colClassName = ''
    .concat(xs ? 'col-'.concat(xs.toString()) : 'col')
    .concat(sm ? ' col-sm-'.concat(sm.toString()) : '')
    .concat(md ? ' col-md-'.concat(md.toString()) : '')
    .concat(lg ? ' col-lg-'.concat(lg.toString()) : '')
    .concat(xl ? ' col-xl-'.concat(xl.toString()) : '')
    .concat(flex ? ' d-flex' : '')
    .concat(alignSelf ? ' align-self-'.concat(alignSelf) : '')
    .concat(justifyContent ? ' justify-content-'.concat(justifyContent) : '')
    .concat(className ? ` ${className}` : '')
  return (
    <Col className={colClassName} sx={sx} style={style}>
      {children}
    </Col>
  )
}

Column.propTypes = {
  children: PropTypes.node,
  xs: PropTypes.any,
  sm: PropTypes.any,
  md: PropTypes.any,
  lg: PropTypes.any,
  xl: PropTypes.any,
  flex: PropTypes.bool,
  justifyContent: PropTypes.oneOf([
    'start',
    'center',
    'end',
    'around',
    'between',
  ]),
  alignSelf: PropTypes.oneOf(['start', 'center', 'end']),
  className: PropTypes.string,
  sx: PropTypes.object,
  style: PropTypes.object,
}

Column.defaultProps = {
  flex: false,
  className: '',
}
