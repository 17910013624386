import { Row } from 'Components/Grid';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { addPolicyVersion, editPolicyVersion, getPolicyVersionByPolicyIdAndVersion } from 'services/policies-service';
import { useNavigate, useParams } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useCallback, useEffect, useState } from 'react';
import { VersionFormComponent } from './Components/Form';
import { StepperForm } from 'Components/StepperForm';
import { Container } from 'Components/Container';
import { TitleForm } from 'Components/Form/styles';
import { FormSuccess } from 'Components/Form/Success';

const steps = ['Detalhes', 'Versões'];

export const PolicyVersionForm = () => {
  const { id, idVersion } = useParams();
  const SwalReact = withReactContent(Swal);
  const navigate = useNavigate();
  const [activeStep] = useState(1);
  const [finished, setFinished] = useState(false);
  const [idPolicyVersion, setIdPolicyVersion] = useState(null);
  const {
    register,
    formState: { errors },
    control,
    getValues,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      approvedDate: null,
      approver: null,
      author: null,
      policyText: '',
      policyVersionCommentaries: [],
      publishedDate: null,
      reviewedDate: null,
      reviewer: null,
      status: null,
      version: null,
    },
  });

  const formatVersion = content => {
    return {
      approvedDate: content?.approvedDate || null,
      approver: content?.approver || null,
      author: content?.author || null,
      policyText: content?.policyText || '',
      policyVersionCommentaries: content?.policyVersionCommentaries || [],
      publishedDate: content?.publishedDate || null,
      reviewedDate: content?.reviewedDate || null,
      reviewer: content?.reviewer || null,
      status: content?.status || null,
      version: content?.version || null,
    };
  };

  const mutation = useMutation(
    () => {
      return idVersion
        ? editPolicyVersion(id, idVersion, formatVersion(getValues()))
        : addPolicyVersion(id, formatVersion(getValues()));
    },
    {
      onError: error => {
        // An error happened!
        SwalReact.fire({
          title: 'Erro!',
          html: error.response.data.message || error.message,
          icon: 'error',
        });
      },
      onSuccess: data => {
        if (data.status === 200) {
          setIdPolicyVersion(idVersion);
        } else if (data.status === 201) {
          setIdPolicyVersion(data.data.policyVersionId);
        }
        setFinished(true);
      },
    },
  );

  const handleComplete = async () => {
    mutation.mutate();
  };

  const handleBack = () => {
    navigate(`/policies/${id}/versions`);
  };

  const getTitleStep = () => {
    if (finished) return <></>;

    return (
      <Row>
        <TitleForm>Versão</TitleForm>
      </Row>
    );
  };

  const getPolicyVersion = useCallback(async () => {
    if (id && idVersion) {
      try {
        const resPolicy = await getPolicyVersionByPolicyIdAndVersion(id, idVersion);
        Object.keys(resPolicy.data).forEach(key => {
          setValue(key, resPolicy.data[key]);
        });
      } catch (error) {
        SwalReact.fire({
          title: 'Erro!',
          text: error.response.data.message ?? error.message,
          icon: 'error',
        });
      }
    }
  }, [id, idVersion, setValue, SwalReact]);

  useEffect(() => {
    getPolicyVersion();
  }, [getPolicyVersion]);

  return (
    <Container title="Políticas" breadcrumb="Adequações / Políticas / Versões" linkPage={`/policies/${id}/versions`}>
      <StepperForm activeStep={activeStep} steps={steps} />
      <div className="card-content w-100">
        {getTitleStep()}
        {!finished && (
          <VersionFormComponent
            control={control}
            errors={errors}
            getValues={getValues}
            handleBack={handleBack}
            handleComplete={handleComplete}
            handleSubmit={handleSubmit}
            id={id}
            isLoading={mutation.isLoading}
            register={register}
          />
        )}
        {mutation.isSuccess && finished && (
          <FormSuccess
            title="Versão da Política cadastrada com sucesso!"
            labelStartAgain="Ver Detalhes"
            handleStartAgain={() => navigate(`/policies/show/${id}/versions/${idPolicyVersion}`)}
            showButtonSeeDetail={false}
          />
        )}
      </div>
    </Container>
  );
};
