import backend, { backendPrivate } from "services"

export const getPolicies = async (tenant) => {
    return await backendPrivate.get(`/policy?companyKey=${tenant}`)
}

export const getPoliciesPaged = async (tenant, page, perPage, searchContent) => {
    return await backendPrivate.get(`/policy/paged?companyKey=${tenant}&page=${page}&perPage=${perPage}&searchContent=${searchContent}`)
}

export const getPolicyById = async (id) => {
    return await backendPrivate.get(`/policy/${id}`)
}

export const getPolicyVersionsByPolicyId = async (id) => {
    return await backendPrivate.get(`/policy/${id}/version`)
}

export const getPolicyVersionByPolicyIdAndVersion = async (id, idVersion) => {
    return await backendPrivate.get(`/policy/${id}/version/${idVersion}`)
}

export const getPolicyPublicById = async (id) => {
    return await backend.get(`/policy/${id}/public`)
}

export const addPolicy = async (policy) => {
    return await backendPrivate.post('/policy', policy)
}

export const editPolicy = async (id, policy) => {
    return await backendPrivate.put(`/policy/${id}`, policy)
}

export const addPolicyVersion = async (id, policyVersion) => {
    return await backendPrivate.post(`/policy/${id}/version`, policyVersion)
}

export const editPolicyVersion = async (id, position, policyVersion) => {
    return await backendPrivate.put(`/policy/${id}/version/${position}`, policyVersion)
}

export const editPolicyVersionStatus = async (id, position, status) => {
    return await backendPrivate.put(`/policy/${id}/version/${position}/status`, {status})
}

export const getPendingPolicies = async (tenant, userId) => {
    return await backendPrivate.get(`/policy/user/${userId}/pending?companyKey=${tenant}`)
}

export const deletePolicy = async (id) => {
    return await backendPrivate.delete(`/policy/${id}`)
}

export const deletePolicyVersion = async (id, idVersion) => {
    return await backendPrivate.delete(`/policy/${id}/version/${idVersion}`)
}

export const getPolicyVersionUserById = async (id) => {
    return await backend.get(`/policy/version-user-accept/${id}`)
}

export const markPolicyVersionAsRead = async (id) => {
    return await backend.put(`/policy/version-user-accept/${id}/read`)
}

export const acceptPolicyVersion = async (id) => {
    return await backend.put(`/policy/version-user-accept/${id}/accepted`)
}

export const getPoliciesUserByPolicyIdAndPolicyVersionId = async (id, idVersion) => {
    return await backendPrivate.get(`/policy/${id}/version/${idVersion}/users-accept`)
}

export const getTotalPoliciesAcceptsByPolicyIdAndPolicyVersionId = async (id, idVersion) => {
    return await backendPrivate.get(`/policy/${id}/version/${idVersion}/users-accept/count`)
}

export const sendPolicyUserAcceptRequest = async (id, idVersion) => {
    return await backendPrivate.post(`/policy/${id}/version/${idVersion}/send-users-accept`)
}