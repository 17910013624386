export function textEllipsis(content, maxSize = 60) {
    if (typeof content === 'object') return content
    if (!content) return ''
    if (content.length > maxSize) {
        return `${String(content).substring(0, maxSize)}...`
    }
    return content
}

export function capitalizeWords(str) {
  return str.replace(/\b\w/g, function(char) {
    return char.toUpperCase();
  }).replace(/\B\w/g, function(char) {
    return char.toLowerCase();
  });
}