import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ButtonForm } from 'Components/Form/styles';
import { Column, Row } from 'Components/Grid';

/** Third Lib Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useQuery } from 'react-query';
import { deletePolicyVersion, getPolicyVersionsByPolicyId } from 'services/policies-service';
import { Container } from 'Components/Container';
import { StepperForm } from 'Components/StepperForm';
import { PageLoaderList } from 'Components/PageLoader/List';
import { NoData } from 'Components/NoData';
import { DefaultTable } from 'Components/DefaultTable';
import { tableHeader } from './table-header';
import { useAuthUser } from 'store/auth';
import { useSelector } from 'react-redux';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';

const steps = ['Detalhes', 'Versões'];

export const PolicyVersions = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const SwalReact = withReactContent(Swal);
  const user = useSelector(useAuthUser);
  const [open, setOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [activeStep] = useState(1);

  const {
    isLoading,
    error,
    data: policyVersions,
  } = useQuery(['getPolicyVersionsByPolicyId', refetch], async () => {
    const result = await getPolicyVersionsByPolicyId(id);
    if (!!result?.data) {
      return result?.data;
    }
    return [];
  });
  const [policyVersionPosition, setPolicyVersionPosition] = useState(null);

  const onDelete = (item, position) => {
    setItemToDelete(item);
    setPolicyVersionPosition(position);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setPolicyVersionPosition(null);
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      const result = await deletePolicyVersion(id, policyVersionPosition);
      if (result.status === 204) {
        onClose();
        setRefetch(!refetch);
        SwalReact.fire({
          title: 'Sucesso!',
          text: 'Versão da Politica removida com sucesso',
          icon: 'success',
        });
      } else {
        throw new Error('Ocorreu um erro ao tentar remover a Versão da Política!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const showAddButton = policyVersions => {
    if (policyVersions.length === 0) return true;
    if (policyVersions[policyVersions.length - 1].status === 'Publicado') return true;
    return false;
  };

  return (
    <Container title="Políticas" breadcrumb="Adequações / Políticas / Versões" linkPage={`/policies`}>
      <StepperForm activeStep={activeStep} steps={steps} />
      {isLoading && <PageLoaderList></PageLoaderList>}
      {error && <div>Ocorreu um erro ... {error.message}</div>}
      {!isLoading && !error && (
        <>
          <div className="card-content w-100">
            <Row justifyContent="center">
              <Column xs={6} className="d-flex">
                <ButtonForm
                  secondary
                  id="back"
                  label="Voltar"
                  variant="button"
                  onClick={() => navigate(`/policies/edit/${id}`)}
                />
              </Column>
              <Column xs={6} justifyContent="end" className="d-flex">
                {showAddButton(policyVersions) && (
                  <ButtonForm
                    label="+ Adicionar nova versão"
                    variant="button"
                    onClick={() => navigate(`/policies/${id}/versions/add`)}
                  />
                )}
              </Column>
            </Row>
          </div>
          {policyVersions?.length === 0 && (
            <NoData xs={12} md={12} lg={12} description={'Não há Versões da Política para exibir'} />
          )}
          {policyVersions?.length > 0 && (
            <Row>
              <Column xs={12} className="px-2 mt-3">
                <DefaultTable
                  rows={tableHeader}
                  cells={policyVersions}
                  onClickDelete={onDelete}
                  userId={user.userId}
                  id={id}
                  link="policies"
                  onClickEdit={position => {
                    navigate(`/policies/${id}/versions/edit/${position}`);
                  }}
                />
                <ModalConfirmDelete
                  description={
                    <>
                      Tem certeza que deseja excluir <strong>{` ${itemToDelete?.version} `}</strong> Versão da Política?
                    </>
                  }
                  open={open}
                  onClose={onClose}
                  onConfirm={onConfirm}
                />
              </Column>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};
