export const ISPropertiesOptions = {
  CONFIDENTIALITY: "Confidentiality",
  INTEGRITY: "Integrity",
  AVAILABILITY: "Availability",
};

export const FunctionOptions = {
  PREVENTION: "Prevention",
  CORRECTION: "Correction",
  DETECTION: "Detection",
};

export const CyberSecConceptOptions = {
  IDENTIFY: "Identify",
  PROTECT: "Protect",
  DETECT: "Detect",
  RESPOND: "Respond",
  RECOVER: "Recover",
};

export const TypeOptions = {
  TECHNICAL: "Technical",
  PHYSICAL: "Physical",
  GOVERNANCE: "Governance",
};

export const AreaOptions = {
  THIRD_PARTY: "Third Party",
  APPLICATION_SECURITY: "Application Security",
  DATA_PRIVACY_SECURITY: "Data Privacy and Security",
  LEGAL_AND_COMPLIANCE: "Legal and Compliance",
  SECURITY_OPERATIONS: "Security Operations",
  SECURITY_ORGANIZATIONS: "Security Organizations",
  SECURITY_STRATEGY: "Security Strategy",
  SYSTEM_AND_NETWORK_SECURITY: "System and Network Security",
};

export const DomainValue = {
  VENDOR_MANAGEMENT: "Vendor Management",
  SECURE_DEVELOPMENT_LIFECYCLE: "Secure Development LifeCycle",
  APP_SECURITY_ASSESSMENT: "App Security Assessment",
  SECURE_DESIGN: "Secure Design",
  DATABASE_SECURITY: "Database Security",
  BACKUP_AND_RECOVERY: "Backup and Recovery",
  PRIVACY_RIGHTS: "Privacy Rights",
  MEDIA_PROTECTION: "Media Protection",
  INTERNAL_AUDIT: "Internal Audit",
  INCIDENT_MANAGEMENT_AND_RESPONSE: "Incident Management and Response",
  LOGGING_MONITORING_AND_REPORTING: "Logging Monitoring and Reporting",
  OFFENSIVE_SECURITY: "Offensive Security",
  BUSINESS_CONTINUITY: "Business Continuity",
  VULNERABILITY_MANAGEMENT: "Vulnerability Management",
  AWARENESS_AND_TRAINING: "Awareness and Training",
  COMMITMENT: "Commitment",
  ASSETS_MANAGEMENT: "Assets Management",
  TEAM_ROLES_AND_RESPONSIBILITIES: "Team Roles and Responsibilities",
  PERSONNEL_SECURITY: "Personnel Security",
  RISK_MANAGEMENT: "Risk Management",
  FRAUD_PREVENTION: "Fraud Prevention",
  BRAND_PROTECTION: "Brand Protection",
  PHYSICAL_SECURITY: "Physical Security",
  GOVERNANCE: "Governance",
  KPIS_KRIS: "KPI's/KRI's",
  DASHBOARD_REPORT: "Dashboard/Report",
  SCORECARD: "Scorecard",
  DOCUMENTATION: "Documentation",
  GOALS_AND_OBJECTIVES: "Goals and Objectives",
  STRATEGIC_PLANNING: "Strategic Planning",
  ACCESS_CONTROL: "Access Control",
  CLOUD_SECURITY: "Cloud Security",
  NETWORK_DESIGN: "Network Design",
  CRYPTOGRAPHY: "Cryptography",
  SECURITY_ENGINEERING: "Security Engineering",
  SECURE_SYSTEMS: "Secure Systems",
  THREAT_MANAGEMENT: "Threat Management",
  MALICIOUS_CODE_PROTECTION: "Malicious Code Protection",
  MOBILITY_AND_WIRELESS: "Mobility and Wireless",
  PERIMETER_SECURITY: "Perimeter Security",
  SECURE_COMMUNICATIONS: "Secure Communications",
  IDS_IPS: "IDS/IPS",
};

export const DomainOptions = [
  {
    name: DomainValue.VENDOR_MANAGEMENT,
    value: "Vendor Management",
    area: AreaOptions.THIRD_PARTY,
  },
  {
    name: DomainValue.SECURE_DEVELOPMENT_LIFECYCLE,
    value: "Secure Development LifeCycle",
    area: AreaOptions.APPLICATION_SECURITY,
  },
  {
    name: DomainValue.APP_SECURITY_ASSESSMENT,
    value: "App Security Assessment",
    area: AreaOptions.APPLICATION_SECURITY,
  },
  {
    name: DomainValue.SECURE_DESIGN,
    value: "Secure Design",
    area: AreaOptions.APPLICATION_SECURITY,
  },
  {
    name: DomainValue.DATABASE_SECURITY,
    value: "Database Security",
    area: AreaOptions.DATA_PRIVACY_SECURITY,
  },
  {
    name: DomainValue.BACKUP_AND_RECOVERY,
    value: "Backup and Recovery",
    area: AreaOptions.DATA_PRIVACY_SECURITY,
  },
  {
    name: DomainValue.PRIVACY_RIGHTS,
    value: "Privacy Rights",
    area: AreaOptions.DATA_PRIVACY_SECURITY,
  },
  {
    name: DomainValue.MEDIA_PROTECTION,
    value: "Media Protection",
    area: AreaOptions.DATA_PRIVACY_SECURITY,
  },
  {
    name: DomainValue.INTERNAL_AUDIT,
    value: "Internal Audit",
    area: AreaOptions.LEGAL_AND_COMPLIANCE,
  },
  {
    name: DomainValue.INCIDENT_MANAGEMENT_AND_RESPONSE,
    value: "Incident Management and Response",
    area: AreaOptions.SECURITY_OPERATIONS,
  },
  {
    name: DomainValue.LOGGING_MONITORING_AND_REPORTING,
    value: "Logging Monitoring and Reporting",
    area: AreaOptions.SECURITY_OPERATIONS,
  },
  {
    name: DomainValue.OFFENSIVE_SECURITY,
    value: "Offensive Security",
    area: AreaOptions.SECURITY_OPERATIONS,
  },
  {
    name: DomainValue.BUSINESS_CONTINUITY,
    value: "Business Continuity",
    area: AreaOptions.SECURITY_OPERATIONS,
  },
  {
    name: DomainValue.VULNERABILITY_MANAGEMENT,
    value: "Vulnerability Management",
    area: AreaOptions.SECURITY_OPERATIONS,
  },
  {
    name: DomainValue.AWARENESS_AND_TRAINING,
    value: "Awareness and Training",
    area: AreaOptions.SECURITY_ORGANIZATIONS,
  },
  {
    name: DomainValue.COMMITMENT,
    value: "Commitment",
    area: AreaOptions.SECURITY_ORGANIZATIONS,
  },
  {
    name: DomainValue.ASSETS_MANAGEMENT,
    value: "Assets Management",
    area: AreaOptions.SECURITY_ORGANIZATIONS,
  },
  {
    name: DomainValue.TEAM_ROLES_AND_RESPONSIBILITIES,
    value: "Team Roles and Responsibilities",
    area: AreaOptions.SECURITY_ORGANIZATIONS,
  },
  {
    name: DomainValue.PERSONNEL_SECURITY,
    value: "Personnel Security",
    area: AreaOptions.SECURITY_ORGANIZATIONS,
  },
  {
    name: DomainValue.RISK_MANAGEMENT,
    value: "Risk Management",
    area: AreaOptions.SECURITY_STRATEGY,
  },
  {
    name: DomainValue.FRAUD_PREVENTION,
    value: "Fraud Prevention",
    area: AreaOptions.SECURITY_STRATEGY,
  },
  {
    name: DomainValue.BRAND_PROTECTION,
    value: "Brand Protection",
    area: AreaOptions.SECURITY_STRATEGY,
  },
  {
    name: DomainValue.PHYSICAL_SECURITY,
    value: "Physical Security",
    area: AreaOptions.SECURITY_STRATEGY,
  },
  {
    name: DomainValue.GOVERNANCE,
    value: "Governance",
    area: AreaOptions.SECURITY_STRATEGY,
  },
  {
    name: DomainValue.KPIS_KRIS,
    value: "KPI's/KRI's",
    area: AreaOptions.SECURITY_STRATEGY,
  },

  {
    name: DomainValue.DASHBOARD_REPORT,
    value: "Dashboard/Report",
    area: AreaOptions.SECURITY_STRATEGY,
  },
  {
    name: DomainValue.SCORECARD,
    value: "Scorecard",
    area: AreaOptions.SECURITY_STRATEGY,
  },
  {
    name: DomainValue.DOCUMENTATION,
    value: "Documentation",
    area: AreaOptions.SECURITY_STRATEGY,
  },
  {
    name: DomainValue.GOALS_AND_OBJECTIVES,
    value: "Goals and Objectives",
    area: AreaOptions.SECURITY_STRATEGY,
  },
  {
    name: DomainValue.STRATEGIC_PLANNING,
    value: "Strategic Planning",
    area: AreaOptions.SECURITY_STRATEGY,
  },
  {
    name: DomainValue.ACCESS_CONTROL,
    value: "Access Control",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.CLOUD_SECURITY,
    value: "Cloud Security",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.NETWORK_DESIGN,
    value: "Network Design",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.CRYPTOGRAPHY,
    value: "Cryptography",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.SECURITY_ENGINEERING,
    value: "Security Engineering",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.SECURE_SYSTEMS,
    value: "Secure Systems",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.THREAT_MANAGEMENT,
    value: "Threat Management",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.MALICIOUS_CODE_PROTECTION,
    value: "Malicious Code Protection",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.MOBILITY_AND_WIRELESS,
    value: "Mobility and Wireless",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.PERIMETER_SECURITY,
    value: "Perimeter Security",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.SECURE_COMMUNICATIONS,
    value: "Secure Communications",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
  {
    name: DomainValue.IDS_IPS,
    value: "IDS/IPS",
    area: AreaOptions.SYSTEM_AND_NETWORK_SECURITY,
  },
];

export function getKeyByValue(object, value) {
  const result = Object.keys(object).find((key) => object[key] === value);
  return result;
}
