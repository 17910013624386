import { Row } from 'Components/Grid';
import { MainContent, TitleMainContent } from '../styles';

export const IndicatorResume = ({ indicatorResume }) => {
  return (
    <Row className="mt-4">
      <TitleMainContent xs={8} sm={4} md={4}>
        <Row className="pl-3 font-weight-bold mb-2">Áreas de Negócio</Row>
      </TitleMainContent>
      <MainContent xs={4} sm={2} md={2}>
        <Row className="pl-3" justifyContent="center">
          {indicatorResume.areaQuantity}
        </Row>
      </MainContent>
      <TitleMainContent xs={8} sm={4} md={4}>
        <Row className="pl-3 font-weight-bold mb-2">Áreas que tratam dados pessoais</Row>
      </TitleMainContent>
      <MainContent xs={4} sm={2} md={2}>
        <Row className="pl-3" justifyContent="center">
          {indicatorResume.areaPersonalDataQuantity}
        </Row>
      </MainContent>
      <TitleMainContent xs={8} sm={4} md={4}>
        <Row className="pl-3 font-weight-bold mb-2">Processos</Row>
      </TitleMainContent>
      <MainContent xs={4} sm={2} md={2}>
        <Row className="pl-3" justifyContent="center">
          {indicatorResume.processQuantity}
        </Row>
      </MainContent>
      <TitleMainContent xs={8} sm={4} md={4}>
        <Row className="pl-3 font-weight-bold mb-2">Áreas que tratam dados pessoais sensíveis</Row>
      </TitleMainContent>
      <MainContent xs={4} sm={2} md={2}>
        <Row className="pl-3" justifyContent="center">
          {indicatorResume.areaSensitivePersonalDataQuantity}
        </Row>
      </MainContent>
      <TitleMainContent xs={8} sm={4} md={4}>
        <Row className="pl-3 font-weight-bold mb-2">Sistemas e Ativos</Row>
      </TitleMainContent>
      <MainContent xs={4} sm={2} md={2}>
        <Row className="pl-3" justifyContent="center">
          {indicatorResume.assetAndSystemQuantity}
        </Row>
      </MainContent>
      <TitleMainContent xs={8} sm={4} md={4}>
        <Row className="pl-3 font-weight-bold mb-2">Processos que precisam DPIA</Row>
      </TitleMainContent>
      <MainContent xs={4} sm={2} md={2}>
        <Row className="pl-3" justifyContent="center">
          {indicatorResume.processDPIAQuantity}
        </Row>
      </MainContent>
      <TitleMainContent xs={8} sm={4} md={4}>
        <Row className="pl-3 font-weight-bold mb-2">Bases legais utilizadas</Row>
      </TitleMainContent>
      <MainContent xs={4} sm={2} md={2}>
        <Row className="pl-3" justifyContent="center">
          {indicatorResume.legalBaseQuantity}
        </Row>
      </MainContent>
      <TitleMainContent xs={8} sm={4} md={4}>
        <Row className="pl-3 font-weight-bold mb-2">Processos que precisam LIA</Row>
      </TitleMainContent>
      <MainContent xs={4} sm={2} md={2}>
        <Row className="pl-3" justifyContent="center">
          {indicatorResume.processLIAQuantity}
        </Row>
      </MainContent>
    </Row>
  );
};
