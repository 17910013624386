import React from 'react';
import { Layout } from './Components/Layout';
import GlobalCSS from './globalStyles.css';
import Routing from './routing';

function App() {
  return (
    <>
    <Layout>
      <Routing/>
      <GlobalCSS/>  
    </Layout>
    </>
  );
}

export default App;
