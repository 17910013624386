export const tableHeader = [
  {
    id: 'companySiteId',
    type: 'text',
    value: 'Id',
  },
  {
    id: 'url',
    type: 'text',
    value: 'Site',
  },
  {
    id: 'action',
    type: 'actions',
    value: '',
  },
]
