import { useState } from 'react';
import { useQuery } from 'react-query';
/** Components */
import { DefaultTable } from 'Components/DefaultTable';
import { Container } from '../../Components/Container';
import { Information } from 'Components/Information';
import { tableHeader } from './table-header.js';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { PageLoaderList } from 'Components/PageLoader/List';
/** Services */
import { deleteAsset, getAssetsShortPaged } from 'services/assets-service';
/** State Managers */
import { useAuthTenant } from 'store/auth';
import { useSelector } from 'react-redux';
/** Third Lib Modal */
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { NoData } from 'Components/NoData';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from '@mui/material';
import { getUsersName } from 'services/users-service';
import { Controller, useForm } from 'react-hook-form';
import { Card } from 'Components/Card';
import { Column, Row } from 'Components/Grid';
import { CustomTextField } from 'Components/Form/styles';
import { InputLoader } from 'Components/PageLoader/Input';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export const Assets = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const [open, setOpen] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(1);

  const { register, control, reset, watch, getValues } = useForm({
    defaultValues: {
      name: '',
      criticity: '',
      location: '',
      type: '',
      user: '',
      archived: false,
      pii: 'Todos',
    },
  });

  const getContentSearch = () => {
    const dados = getValues();
    let queryString = `&archived=${dados.archived}&pii=${dados.pii}`;
    if (dados.name !== '') {
      queryString += `&name=${dados.name}`;
    }
    if (dados.criticity !== '') {
      queryString += `&criticity=${dados.criticity}`;
    }
    if (dados.location !== '') {
      queryString += `&location=${dados.location}`;
    }
    if (dados.type !== '') {
      queryString += `&type=${dados.type}`;
    }
    if (dados.user !== '') {
      queryString += `&user=${dados.user}`;
    }

    return queryString;
  };

  const { isLoading: userLoading, data: users } = useQuery(['getUsers'], async () => {
    if (selectedTenant.slug === undefined || selectedTenant.slug === null) return [];
    const result = await getUsersName(selectedTenant.slug);
    return result?.data.length > 0 ? result?.data : [];
  });

  const {
    isLoading,
    error,
    data: pagedAssets,
  } = useQuery(['getAssets', reFetch, page], async () => {
    if (selectedTenant.slug === undefined || selectedTenant.slug === null) return [];

    const queryContent = getContentSearch();
    const result = await getAssetsShortPaged(selectedTenant.slug, page, 15, queryContent);
    if (result?.data.data.length > 0) {
      return result?.data;
    }
    return {
      data: [],
      page,
      totalPages: 0,
    };
  });

  const onDelete = item => {
    setItemToDelete(item);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      const result = await deleteAsset(itemToDelete?._id);
      if (result.status === 204) {
        onClose();
        setRefetch(!reFetch);
        SwalReact.fire({
          title: 'Sucesso!',
          text: 'Átivo removido com sucesso',
          icon: 'success',
        });
      } else {
        throw new Error('Ocorreu um erro ao tentar remover o Ativo!');
      }
    } catch (error) {
      onClose();
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  return (
    <Container title="Ativos" breadcrumb="Mapeamento / Ativos" showButtonAdd linkPage="/assets">
      <Information
        title={'Ativos'}
        description={
          <p className="m-0">
            Os ativos ficam nos “bastidores”, suportando toda a nossa operação. Eles podem ser de três tipos:
            <br />
            <br />
            &emsp;• Pessoas: times, gestores, colaboradores, etc
            <br />
            &emsp;• Tecnologia: computadores, tablets, smartphones, servidores, bancos de dados, etc
            <br />
            &emsp;• Ambiente: datacenters, ambientes de nuvem (AWS, Google, Azure, etc), escritórios, etc
          </p>
        }
        style={{
          marginTop: '24px',
        }}
      />
      {!error && (
        <>
          <Card className="mb-2 mt-3">
            <Row className="mb-3" md={'3'}>
              <Column xs={12} md={5}>
                <CustomTextField
                  {...register('name')}
                  label="Nome"
                  sx={{
                    minHeight: '40px',
                    maxHeight: '40px',
                    margin: '0 auto',
                    width: '100%',
                    ' > div': {
                      minHeight: '40px',
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Column>
              <Column xs={12} className="d-flex" md={3}>
                {!userLoading && (
                  <Controller
                    control={control}
                    name="user"
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth>
                        <InputLabel id="user-label" shrink>
                          Responsável
                        </InputLabel>
                        <Select
                          labelId="user-label"
                          id="user"
                          value={value}
                          label="Responsável"
                          onChange={onChange}
                          notched
                          sx={{
                            minHeight: '40px',
                            maxHeight: '40px',
                            margin: '0 auto',
                            width: '100%',
                          }}
                        >
                          <MenuItem value="" key={-1}>
                            Selecione um responsável
                          </MenuItem>
                          {users
                            ?.map(user => user.name)
                            .map((item, index) => (
                              <MenuItem value={item} key={`user-${index}`}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                )}
                {userLoading && (
                  <FormControl fullWidth sx={{ margin: '0 auto' }}>
                    <InputLabel id="user-label" shrink={true}>
                      Responsável
                    </InputLabel>
                    <InputLoader height={'40px'} />
                  </FormControl>
                )}
              </Column>
              <Column xs={12} md={3}>
                <Controller
                  control={control}
                  name="archived"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      value={value}
                      control={<Checkbox checked={value} onChange={onChange} name="archived" />}
                      label="Arquivado"
                      sx={{ marginLeft: 0 }}
                    />
                  )}
                />
              </Column>
            </Row>
            <Row className="mb-3">
              <Column xs={12} className="d-flex" md={2}>
                <Controller
                  control={control}
                  name="criticity"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <InputLabel id="criticity-label" shrink>
                        Criticidade
                      </InputLabel>
                      <Select
                        labelId="criticity-label"
                        id="criticity"
                        value={value}
                        label="Criticidade"
                        onChange={onChange}
                        notched
                        sx={{
                          minHeight: '40px',
                          maxHeight: '40px',
                          margin: '0 auto',
                          width: '100%',
                        }}
                      >
                        <MenuItem value="" key={-1}>
                          {' '}
                          Selecione uma criticidade{' '}
                        </MenuItem>
                        {['1', '2', '3', '4', '5'].map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {' '}
                            {item}{' '}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Column>
              <Column xs={12} className="d-flex" md={3}>
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <InputLabel id="type-label" shrink>
                        Tipo
                      </InputLabel>
                      <Select
                        labelId="type-label"
                        id="type"
                        value={value}
                        label="Tipo"
                        onChange={onChange}
                        notched
                        sx={{
                          minHeight: '40px',
                          maxHeight: '40px',
                          margin: '0 auto',
                          width: '100%',
                        }}
                      >
                        <MenuItem value="" key={-1}>
                          Selecione um tipo
                        </MenuItem>
                        {['Pessoa', 'Tecnologia', 'Ambiente'].map((item, index) => (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Column>
              <Column xs={12} md={3}>
                <Controller
                  control={control}
                  name="pii"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth>
                      <InputLabel id="pii-label" shrink>
                        PII
                      </InputLabel>
                      <Select
                        labelId="pii-label"
                        id="pii"
                        value={value}
                        label="PII"
                        onChange={onChange}
                        notched
                        sx={{
                          minHeight: '40px',
                          maxHeight: '40px',
                          margin: '0 auto',
                          width: '100%',
                        }}
                      >
                        {['Todos', 'Sim', 'Não'].map(item => (
                          <MenuItem value={item} key={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    reset();
                    setRefetch(!reFetch);
                  }}
                >
                  Limpar
                </Button>
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  endIcon={<SearchOutlinedIcon />}
                  disabled={watch('typeFilter') === ''}
                  onClick={() => {
                    setRefetch(!reFetch);
                  }}
                >
                  Filtrar
                </Button>
              </Column>
            </Row>
          </Card>
        </>
      )}
      {isLoading && <PageLoaderList></PageLoaderList>}
      {error && <div>Ocorreu um erro ...</div>}
      {!isLoading && !error && pagedAssets.data?.length === 0 && <NoData description={'Não há ativos para exibir'} />}
      {!isLoading && !error && pagedAssets.data?.length > 0 && (
        <div className="container">
          <DefaultTable rows={tableHeader} cells={pagedAssets.data} onClickDelete={onDelete} link="assets" />
          <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginTop: '16px' }}>
            <Pagination
              count={pagedAssets.totalPages}
              onChange={(_, selectedPage) => {
                setPage(selectedPage);
              }}
              color="primary"
              page={page}
              showFirstButton
              showLastButton
            />
          </Stack>
          <ModalConfirmDelete
            description={
              <>
                Tem certeza que deseja excluir <strong>{` ${itemToDelete?.name} `}</strong> como ativo?
              </>
            }
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
          />
        </div>
      )}
    </Container>
  );
};
