import { Box, Chip, Tab, Tabs } from '@mui/material';
import { Container } from 'Components/Container';
import { Column, Row } from 'Components/Grid';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { ColumnEdit, LinkEdit, RowExhibition } from './styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { getPolicyById } from 'services/policies-service';
import { DefaultTable } from 'Components/DefaultTable';
import { tableHeader } from './table-header';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuthUser } from 'store/auth';
import { PageLoaderShow } from 'Components/PageLoader/Show';

const TabPanel = props => {
  const { children, value, index, id, ariaLabel, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={id} aria-labelledby={ariaLabel} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const PoliciesShow = () => {
  const { id } = useParams();
  const user = useSelector(useAuthUser);
  const navigate = useNavigate();
  const [tabPosition, setTabPosition] = useState(0);
  const {
    isLoading,
    error,
    data: policy,
  } = useQuery('getPolicyById', async () => {
    const resPolicy = await getPolicyById(id);

    if (resPolicy.status === 200) return resPolicy.data;

    return null;
  });

  const onClickEdit = () => {
    navigate('/policies/edit/' + id);
  };

  return (
    <>
      {!isLoading && !error && (
        <Container
          title={`${policy?.name}`}
          breadcrumb={`Adequações / Políticas / ${policy?.name}`}
          linkPage="/policies"
        >
          <Box sx={{ width: '100%' }} className="container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabPosition}
                onChange={(_, newValue) => setTabPosition(newValue)}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Detalhes"
                  id="tab-details"
                  aria-controls="tabpanel-details"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Versões"
                  id="tab-versions"
                  aria-controls="tabpanel-versions"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
              </Tabs>
            </Box>
            <TabPanel value={tabPosition} index={0} id="tabpanel-details" ariaLabel="tab-details">
              <RowExhibition>
                <Column xs={3}>Nome</Column>
                <Column>{policy.name}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Classificação</Column>
                <Column>{policy.classification}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Grupos de usuário</Column>
                <Column>
                  {policy.userGroups
                    ?.filter(item => item != null)
                    .map(userGroup => (
                      <Chip
                        key={userGroup?._id}
                        label={userGroup?.name}
                        variant="outlined"
                        color="primary"
                        sx={{
                          width: 'fit-content',
                          marginRight: '6px',
                        }}
                      />
                    ))}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Descrição</Column>
                <Column>{policy.description}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
            <TabPanel value={tabPosition} index={1} id="tabpanel-versions" ariaLabel="tab-versions">
              <Row justifyContent="center">
                <Column xs={12} className="d-flex px-0">
                  <DefaultTable
                    rows={tableHeader}
                    cells={policy?.policyVersions ?? []}
                    onClickDelete={() => {}}
                    hideDelete
                    id={id}
                    userId={user.userId}
                    onClickEdit={position => {
                      navigate(`/policies/${id}/versions/edit/${position}`);
                    }}
                    link="policies"
                  />
                </Column>
              </Row>
            </TabPanel>
          </Box>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  );
};
