import { backendPrivate } from "services"

export const getCookiesShortPaged = async (tenant, page, perPage) => {
    return await backendPrivate.get(`/illow/company-site?companyKey=${tenant}&page=${page}&perPage=${perPage}`)
}

export const getCookieById = async (id) => {
    return await backendPrivate.get(`/illow/company-site/${id}`)
}

export const deleteCookie = async (id) => {
    return await backendPrivate.delete(`/illow/company-site/${id}`)
}

export const addCookie = async (cookie) => {
    return await backendPrivate.post(`/illow/company-site`, cookie)
}

export const editCookie = async (id, cookie) => {
    return await backendPrivate.put(`/illow/company-site/${id}`, cookie)
}

export const getCookieCategoryById = async (id) => {
    return await backendPrivate.get(`/illow/cookie/${id}`)
}

export const getCookieConsentById = async (id) => {
    return await backendPrivate.get(`/illow/consent-log/${id}`)
}