export const tableHeader = [
    {
      id: 'name',
      type: 'text',
      value: 'Nome',
    },
    {
      id: 'lastVersion',
      type: 'text',
      value: 'Versão',
    },
    {
      id: 'policyVersions.publishedDate',
      type: 'array-object-datetime',
      value: 'Publicação',
    },
    {
      id: 'lastPolicyVersionStatus',
      type: 'text',
      value: 'Status',
    },
    {
      id: 'action',
      type: 'actions-policy',
      value: '',
    },
  ]
  