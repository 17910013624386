import { Shimmer } from "../../styles"

export const PageLoaderDashboardCards = () => {
    return (
        <div style={{display: 'flex', marginBlock: '16px'}}>
            <div style={{height: '180px', width: '198px', display:'flex', marginRight: '8px'}}>
                <Shimmer style={{height: '180px', width: '198px'}}></Shimmer>
            </div>
            <div style={{height: '180px', width: '198px', display:'flex', marginRight: '8px'}}>
                <Shimmer style={{height: '180px', width: '198px'}}></Shimmer>
            </div>
            <div style={{height: '180px', width: '198px', display:'flex', marginRight: '8px'}}>
                <Shimmer style={{height: '180px', width: '198px'}}></Shimmer>
            </div>
            <div style={{height: '180px', width: '198px', display:'flex', marginRight: '8px'}}>
                <Shimmer style={{height: '180px', width: '198px'}}></Shimmer>
            </div>
            <div style={{height: '180px', width: '198px', display:'flex'}}>
                <Shimmer style={{height: '180px', width: '198px'}}></Shimmer>
            </div>
        </div>
    )
}