export const tableHeader = [
    {
        id: 'name',
        type: 'text',
        value: 'Nome',
    },
    {
        id: 'criticity',
        type: 'select',
        value: 'Criticidade',
    },
    {
        id: 'saas',
        type: 'boolean',
        value: 'SaaS',
    },
    {
        id: 'developedInHouse',
        type: 'boolean',
        value: 'Desenvolvido internamente',
    },
    {
        id: 'user',
        type: 'text',
        value: 'Responsável',
    },
    {
        id: 'action',
        type: 'actions',
        value: '',
    },
]
