import { Box, Button, Typography } from "@mui/material"
import { Column, Row } from "Components/Grid"
import { TitleLogin } from "Pages/Login/styles"
import { ReactComponent as SuccessIcon } from '../../../assets/success.svg'
import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { editCompanyPlan } from "services/company-service"

export const OnboardSuccess = () => {
    const { slug, plan } = useParams()

    useQuery(['editCompanyPlan', slug, plan], async () => {
        if (!slug) return
        if (!plan) return
        await editCompanyPlan(slug, {plan})
    })
    const redirect = () => {
        const urlSplit = window.location.origin.split('/')
        const baseUrl = urlSplit[2].substring(3)
        window.location.href = `https://${slug}.${baseUrl}/login`
    }
    return (
        <Box>
            <Row>
                <Column xs={12} className="text-center mb-3 mt-5">
                    <TitleLogin>Virtual</TitleLogin>
                    <TitleLogin className="bold"> Officer</TitleLogin>
                </Column>
                <Column xs={12} className="text-center mb-3">
                    <Typography
                        sx={{
                            color:"#0039C5",
                            fontWeight: 700
                        }}
                        fontSize={24}>
                        Pagamento aprovado!
                    </Typography>
                </Column>
                <Column xs={12} className="text-center mb-3">
                    <SuccessIcon
                        width="80px"
                        height="80px"
                        style={{ margin: '0 auto 24px', display: 'flex' }}
                        />
                </Column>
                <Column xs={12} className="text-center mb-3">
                    <Typography
                        sx={{
                            color:"#3C3938",
                            fontWeight: 700
                        }}
                        fontSize={16}>
                        Sua conta foi criada com sucesso
                    </Typography>
                </Column>
                <Column xs={12} className="text-center mb-3">
                    <Typography
                        sx={{
                            color:"#3C3938",
                            fontWeight: 400
                        }}
                        fontSize={14}>
                        Aguarde o email com as instruções para a confirmação do seu<br/>
                        usuário e instruções para realizar o login.
                    </Typography>
                </Column>
                <Column xs={'auto'} flex justifyContent="end" className="mb-3 ml-auto pr-0">
                    <Typography
                        sx={{
                            color:"#6B6664",
                            fontWeight: 400,
                            width: 'fit-content'
                        }}
                        fontSize={14}>
                        Entre agora mesmo!
                    </Typography>
                </Column>
                <Column xs={'auto'} className="mb-3 mr-auto pl-0">
                    <Button onClick={() => redirect()}
                        sx={{
                            color: '#0039C5',
                            textTransform: 'none',
                            fontWeight: '700'
                        }}>
                        Login
                    </Button>
                </Column>
            </Row>
        </Box>
    )
}