import { useState } from 'react';
import { useQuery } from 'react-query';
import { DefaultTable } from 'Components/DefaultTable';
import { Container } from '../../Components/Container';
import { Information } from 'Components/Information';
import { tableHeader } from './table-header.js';
import { ModalConfirmDelete } from 'Components/ModalConfirmDelete';
import { deleteUser, enableUser, getUsers } from 'services/users-service';
import { PageLoaderList } from 'Components/PageLoader/List';
import { useSelector } from 'react-redux';
import { useAuthTenant } from 'store/auth';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from '@mui/material';
import { NoData } from 'Components/NoData';
import { Column, Row } from 'Components/Grid';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Controller, useForm } from 'react-hook-form';
import { CustomTextField } from 'Components/Form/styles';
import { Card } from 'Components/Card';

export const Users = () => {
  const selectedTenant = useSelector(useAuthTenant);
  const SwalReact = withReactContent(Swal);
  const [open, setOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(1);

  const { register, control, reset, watch } = useForm({
    defaultValues: {
      typeFilter: '',
      searchContent: '',
      active: true,
    },
  });

  const getContentSearch = () => {
    if (watch('typeFilter') === '') return '';
    if (!watch('searchContent')) return '';

    return watch('searchContent');
  };

  const {
    isLoading,
    error,
    data: pagedUsers,
  } = useQuery(['getUsers', refetch, page], async () => {
    const search = getContentSearch();
    const result = await getUsers(selectedTenant.slug, page, 15, watch('typeFilter'), search, watch('active'));
    if (result?.data.data.length > 0) {
      return result?.data;
    }
    return {
      data: [],
      page,
      totalPages: 0,
    };
  });

  const onDelete = item => {
    setItemToDelete(item);
    setOpen(true);
  };

  const onClose = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const onConfirm = async () => {
    try {
      const result = await deleteUser(itemToDelete?._id);
      if (result.status === 204) {
        onClose();
        setRefetch(!refetch);
        SwalReact.fire({
          title: 'Sucesso!',
          text: 'Usuário desabilitado com sucesso',
          icon: 'success',
        });
      } else {
        throw new Error('Ocorreu um erro ao tentar inativar o Usuário!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  const onChangeStatus = async item => {
    try {
      const result = await enableUser(item?._id);
      if (result.status === 200) {
        onClose();
        setRefetch(!refetch);
      } else {
        throw new Error('Ocorreu um erro ao tentar habilitar o Usuário!');
      }
    } catch (error) {
      SwalReact.fire({
        title: 'Erro!',
        text: error.response.data.message ?? error.message,
        icon: 'error',
      });
    }
  };

  return (
    <Container title="Usuários" breadcrumb="Gerenciar / Usuários" showButtonAdd linkPage="/users">
      <Information
        title="Usuários"
        description={`Colaboradores e demais usuários com acesso ao sistema.`}
        style={{
          marginTop: '24px',
        }}
      />
      {error && <div>Ocorreu um erro ...</div>}
      {!error && (
        <>
          <Card className="mb-2 mt-3">
            <Row className="mb-2" md={'3'}>
              <Column>
                <Controller
                  control={control}
                  name="active"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      value={value}
                      control={<Checkbox checked={value} onChange={onChange} name="active" />}
                      label="Ativo"
                      sx={{ marginLeft: 0, marginBottom: '16px' }}
                    />
                  )}
                />
              </Column>
            </Row>
            <Row>
              <Column xs={12} md={'3'}>
                <Controller
                  control={control}
                  name="typeFilter"
                  render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth sx={{ margin: '0 auto' }}>
                      <InputLabel id="type-filter-label" shrink>
                        Filtrar por
                      </InputLabel>
                      <Select
                        displayEmpty
                        labelId="type-filter-label"
                        id="typeFilter"
                        notched
                        label="Filtrar por"
                        value={value}
                        onChange={onChange}
                        size="small"
                        style={{ marginBottom: '16px !important' }}
                        sx={{
                          '& > .MuiSelect-select.MuiSelect-outlined': {
                            paddingLeft: '0 !important',
                          },
                        }}
                      >
                        <MenuItem value={''} key={-1}>
                          Selecione o campo
                        </MenuItem>
                        {['Nome', 'Email', 'Grupo']?.map((item, index) => (
                          <MenuItem value={item} key={`type-filter-${index}`}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Column>
              <Column xs={12} md={5}>
                <CustomTextField
                  {...register('searchContent')}
                  label="Buscar"
                  sx={{
                    minHeight: '40px',
                    maxHeight: '40px',
                    margin: '0 auto',
                    width: '100%',
                    ' > div': {
                      minHeight: '40px',
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    reset();
                    setRefetch(!refetch);
                  }}
                >
                  Limpar
                </Button>
              </Column>
              <Column xs={12} md={'auto'}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    width: 'fit-content!important',
                    borderRadius: '12px',
                    padding: '10px 24px 10px 24px',
                    textTransform: 'none',
                  }}
                  endIcon={<SearchOutlinedIcon />}
                  disabled={watch('typeFilter') === ''}
                  onClick={() => {
                    setRefetch(!refetch);
                  }}
                >
                  Filtrar
                </Button>
              </Column>
            </Row>
          </Card>
          {isLoading && <PageLoaderList></PageLoaderList>}
          {!isLoading && pagedUsers.data.length === 0 && <NoData description={'Não há usuários para exibir'} />}
          {!isLoading && pagedUsers.data?.length > 0 && (
            <div className="container mt-3">
              <DefaultTable
                rows={tableHeader}
                cells={pagedUsers.data}
                onClickDelete={onDelete}
                onClickStatus={onChangeStatus}
                link="users"
              />
              <Stack sx={{ justifyContent: 'center', flexDirection: 'row', marginTop: '16px' }}>
                <Pagination
                  count={pagedUsers.totalPages}
                  onChange={(_, selectedPage) => {
                    setPage(selectedPage);
                  }}
                  color="primary"
                  page={page}
                  showFirstButton
                  showLastButton
                />
              </Stack>
              <ModalConfirmDelete
                description={
                  <>
                    Tem certeza que deseja excluir <strong>{` ${itemToDelete?.name} `}</strong> como Usuário?
                  </>
                }
                open={open}
                onClose={onClose}
                onConfirm={onConfirm}
              />
            </div>
          )}
        </>
      )}
    </Container>
  );
};
