const { default: styled } = require("styled-components");

export const Card = styled.div`
    box-shadow: 0px 6px 20px #eff1f7;
    border-radius: 8px;
    background-color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    &.p-report {
        padding: 24px 32px;
    }
`