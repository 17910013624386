import styled from 'styled-components';

export const Main = styled.footer`
  align-items: center;
  background-color: #EFF0F1;
  display: flex;
  height: 40px;
  padding-left: 30px;
  width: calc( 100vw - 311px);
  max-width: calc( 100vw - 311px);
  bottom: 0;
  position: absolute;

  span {
    color: #0039C5;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  }

  p {
    color: #3c3938;
    font-size: 12px;
    line-height: 16px;
    margin:0px;
    margin-left: 4px;
  }
`;