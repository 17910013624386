export const tableHeader = [
  {
    id: 'name',
    type: 'text',
    value: 'Nome',
  },
  {
    id: 'email',
    type: 'text',
    value: 'Email',
  },
  {
    id: 'active',
    type: 'boolean',
    value: 'Ativo',
  },
  {
    id: 'action',
    type: 'actions-status',
    value: '',
  },
]
