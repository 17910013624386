export const getSelectedContent = (preValue, contentValue) => {
    if (!preValue && !contentValue) return []
    if (!preValue && contentValue) return contentValue
    if (preValue.length > 0 && contentValue.length > 0) {
        return preValue.filter((preContent) => contentValue.filter((content) => content._id === preContent._id).length > 0 ?  true : false)
    }
    return []
}

export const getSelectedObjectContentByCompareFields = (preValue, contentValue, fieldA, fieldB) => {
    if (!preValue && !contentValue) return ''
    if (!preValue && contentValue) return contentValue
    if (preValue.length > 0 && contentValue) {
        return preValue.find((preContent) => contentValue[fieldB] === preContent[fieldA])
    }
    return ''
}

export const getSelectedContentObjectByCompareFields = (preValue, contentValue, fieldA, fieldB) => {
    if (!preValue && !contentValue) return ''
    if (!preValue && contentValue) return contentValue
    if (preValue.length > 0 && contentValue.length > 0) {
        return preValue.find((preContent) => contentValue.filter((content) => content[fieldA] === preContent[fieldA] && content[fieldB] === preContent[fieldB]).length > 0 ?  true : false)
    }
    return ''
}

export const getSelectedContentByCompareFields = (preValue, contentValue, fieldA, fieldB) => {
    if (!preValue && !contentValue) return []
    if (!preValue && contentValue) return contentValue
    if (preValue.length > 0 && contentValue.length > 0) {
        return preValue.filter((preContent) => contentValue.filter((content) => content[fieldA] === preContent[fieldA] && content[fieldB] === preContent[fieldB]).length > 0 ?  true : false)
    }
    return []
}

export const getSelectedObject = (preValue, contentValue) => {
    if (!preValue && !contentValue) return ''
    if (!preValue && contentValue) return contentValue
    if (preValue.length > 0 && contentValue) {
        const resultFilter = preValue.find((preContent) => contentValue._id.toString() === preContent._id.toString())
        return !!resultFilter ? resultFilter : ''
    }
    return ''
}