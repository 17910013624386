import { Alert, Box, Button, Card, Snackbar, Tab, Tabs } from '@mui/material';
// Chi
import { Container } from 'Components/Container';
import { Column, Row } from 'Components/Grid';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnEdit, ContainerIcon, LinkEdit, RowExhibition } from './styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { PageLoaderShow } from 'Components/PageLoader/Show';
import { getCookieById, getCookieCategoryById, getCookieConsentById } from 'services/cookies-service';
import { DefaultTable } from 'Components/DefaultTable';
import { tableHeaderCategory, tableHeaderConsent } from './table-header';
import { ReactComponent as CopyIcon } from '../../../assets/copy_icon.svg';
import { ReactComponent as SecurityIcon } from '../../../assets/security.svg';
import { ReactComponent as OpenLinkIcon } from '../../../assets/open_link.svg';

const TabPanel = props => {
  const { children, value, index, id, ariaLabel, boxPadding, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={id} aria-labelledby={ariaLabel} {...other}>
      {value === index && <Box sx={{ p: boxPadding ?? 3 }}>{children}</Box>}
    </div>
  );
};

export const CookiesShow = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tabPosition, setTabPosition] = useState(0);
  const [showCopyCode, setShowCopyCode] = useState(false);
  const [showGtm, setShowGtm] = useState(false);
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const {
    isLoading,
    error,
    data: cookie,
  } = useQuery('getCookieById', async () => {
    const dataCookie = await getCookieById(id);
    return dataCookie.status === 200 ? dataCookie.data : null;
  });

  const { data: cookieCategories } = useQuery('getCookieCategoryById', async () => {
    const dataCookie = await getCookieCategoryById(id);
    return dataCookie.status === 200 ? dataCookie.data : null;
  });

  const { data: cookieConsent } = useQuery('getCookieConsentById', async () => {
    const dataCookieConsent = await getCookieConsentById(id);
    return dataCookieConsent.status === 200 ? dataCookieConsent.data : null;
  });

  const onClickEdit = () => {
    navigate('/cookies/edit/' + id);
  };

  const getPositionBanner = position => {
    switch (position) {
      case 'top':
        return 'Topo';
      case 'bottom':
        return 'Rodapé';
      case 'squaredLeft':
        return 'Canto Inferior Esquerdo';
      case 'squaredRight':
        return 'Canto Inferior Direito';
      default:
        return 'Centralizado';
    }
  };

  const copyCode = async () => {
    try {
      await navigator.clipboard.writeText(cookie.code);
      setShowCopySuccess(true);
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      {!isLoading && !error && (
        <Container title={`${cookie?.url}`} breadcrumb={`Adequações / ${cookie?.url}`} linkPage="/cookies">
          <Box sx={{ width: '100%' }} className="container">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabPosition}
                onChange={(data, newValue) => setTabPosition(newValue)}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Dashboard"
                  id="tab-dashboard"
                  aria-controls="tabpanel-integration"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Posicinamento e Formato"
                  id="tab-details"
                  aria-controls="tabpanel-details"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Configuração Avançada"
                  id="tab-advance"
                  aria-controls="tabpanel-advance"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Categorização"
                  id="tab-category"
                  aria-controls="tabpanel-category"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
                <Tab
                  label="Consentimento"
                  id="tab-consent"
                  aria-controls="tabpanel-consent"
                  style={{ marginLeft: 0, marginTop: 0, width: 'fit-content' }}
                />
              </Tabs>
            </Box>
            <TabPanel value={tabPosition} index={0} id="tabpanel-dashboard" ariaLabel="tab-dashboard">
              <Row>
                <Column xs={12} md={6}>
                  <Card sx={{ padding: '8px', textAlign: 'center', boxShadow: 'none', border: '1px solid #ccc' }}>
                    <span style={{ fontSize: '14px', color: '#6b7280' }}>Status Integração</span>
                    <br />
                    <div
                      style={{
                        height: '35px',
                        width: '35px',
                        borderRadius: '50%',
                        backgroundColor: cookie.customization.integrated ? 'green' : 'red',
                        marginInline: 'auto',
                        marginBlockStart: '8px',
                      }}
                    >
                      &nbsp;
                    </div>
                  </Card>
                </Column>
                <Column xs={12} md={6}>
                  <Card sx={{ padding: '8px', textAlign: 'center', boxShadow: 'none', border: '1px solid #ccc' }}>
                    <span style={{ fontSize: '14px', color: '#6b7280' }}>Total de Visitas</span>
                    <br />
                    <span style={{ fontSize: '32px', fontWeight: 'bold', marginBlockStart: '8px' }}>
                      {cookie.customization.pageViews}
                    </span>
                  </Card>
                </Column>
              </Row>
              <Row>
                <Column xs={12}>
                  <Card sx={{ padding: '16px', marginBlockStart: '16px', boxShadow: 'none', border: '1px solid #ccc' }}>
                    <Row>
                      <Column>
                        <span style={{ fontSize: '20px', color: '#4b90fe' }}>Código do banner de cookies</span>
                      </Column>
                    </Row>
                    <Row>
                      <Column>
                        <Card
                          sx={{
                            padding: '16px',
                            marginBlockStart: '16px',
                            backgroundColor: '#fffeFe',
                            boxShadow: 'none',
                            border: '1px solid #ccc',
                          }}
                        >
                          <Row className="mb-3">
                            <Column xs={9} style={{ fontSize: '20px', fontWeight: '500' }}>
                              Copiar código
                            </Column>
                            <Column style={{ display: 'flex', justifyContent: 'end' }}>
                              <ContainerIcon
                                onClick={() => {
                                  setShowGtm(false);
                                  setShowCopyCode(!showCopyCode);
                                }}
                                active={showCopyCode}
                              >
                                <CopyIcon width="44px" height="44px" style={{ padding: '8px' }} />
                              </ContainerIcon>
                            </Column>
                          </Row>
                          <Row className="mb-3">
                            <Column xs={12}>
                              <p className="mt-0 mb-4">
                                Você só tem que colar o código no inicio da tag {'<HEAD/>'}
                                do conteúdo HTML do seu site.
                              </p>
                            </Column>
                          </Row>
                        </Card>
                      </Column>
                      <Column>
                        <Card
                          sx={{
                            padding: '16px',
                            marginBlockStart: '16px',
                            backgroundColor: '#fffeFe',
                            boxShadow: 'none',
                            border: '1px solid #ccc',
                          }}
                        >
                          <Row>
                            <Column xs={9}>
                              <span style={{ fontSize: '20px', fontWeight: '500' }}>Google Tag Manager</span>
                              <br />
                              <span style={{ fontSize: '16px', fontWeight: '400', color: '#34d399' }}>
                                (Recomendado)
                              </span>
                            </Column>
                            <Column style={{ display: 'flex', justifyContent: 'end' }}>
                              <ContainerIcon
                                onClick={() => {
                                  setShowCopyCode(false);
                                  setShowGtm(!showGtm);
                                }}
                                active={showGtm}
                              >
                                <SecurityIcon width="44px" height="44px" style={{ padding: '8px' }} />
                              </ContainerIcon>
                            </Column>
                          </Row>
                          <Row>
                            <Column xs={12}>
                              <p>
                                Gerenciamos seus cookies, mas o GTM pode chegar primeiro, com esta opção basta instalar
                                nosso template.
                              </p>
                            </Column>
                          </Row>
                        </Card>
                      </Column>
                    </Row>
                    {showCopyCode && (
                      <Row>
                        <Column xs={12} flex style={{ alignItems: 'center', marginBlock: '16px' }}>
                          <div
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#d1d5db',
                              border: '1px solid #e5e7eb',
                            }}
                          ></div>
                          <span
                            style={{ fontSize: '14px', fontWeight: '400', marginInline: '12px', textWrap: 'nowrap' }}
                          >
                            Copiar código
                          </span>
                          <div
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#d1d5db',
                              border: '1px solid #e5e7eb',
                            }}
                          ></div>
                        </Column>
                      </Row>
                    )}
                    {showCopyCode && (
                      <Row>
                        <Column xs={12}>
                          <Card
                            sx={{
                              backgroundColor: '#f9fafb',
                              padding: '12px 24px',
                              boxShadow: 'none',
                              border: '1px solid #ccc',
                            }}
                          >
                            <Row>
                              <Column xs={10}>
                                <span id="scriptToCopy" style={{ fontSize: '14px' }}>
                                  {cookie?.code}
                                </span>
                              </Column>
                              <Column xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button
                                  variant="contained"
                                  startIcon={<CopyIcon width="22px" height="24px" style={{ padding: '2px' }} />}
                                  size="small"
                                  onClick={() => {
                                    copyCode();
                                  }}
                                >
                                  Copiar
                                </Button>
                              </Column>
                            </Row>
                          </Card>
                        </Column>
                      </Row>
                    )}
                    {showGtm && (
                      <Row>
                        <Column xs={12} flex style={{ alignItems: 'center', marginBlock: '16px' }}>
                          <div
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#d1d5db',
                              border: '1px solid #e5e7eb',
                            }}
                          ></div>
                          <span
                            style={{ fontSize: '14px', fontWeight: '400', marginInline: '12px', textWrap: 'nowrap' }}
                          >
                            Google Tag Manager
                          </span>
                          <div
                            style={{
                              width: '100%',
                              height: '1px',
                              backgroundColor: '#d1d5db',
                              border: '1px solid #e5e7eb',
                            }}
                          ></div>
                        </Column>
                      </Row>
                    )}
                    {showGtm && (
                      <Row>
                        <Column xs={12}>
                          <Card
                            sx={{
                              backgroundColor: '#f9fafb',
                              padding: '12px 24px',
                              boxShadow: 'none',
                              border: '1px solid #ccc',
                            }}
                          >
                            <Row>
                              <Column xs={11}>
                                <span style={{ fontSize: '14px' }}>
                                  Siga o passo a passo incluso na página do&nbsp;
                                  <a
                                    style={{ textDecoration: 'none', color: '#1976d2' }}
                                    href="https://support.vofficer.io/hc/pt-br/articles/23322149914260-Integração-do-Banner-de-Cookies-com-o-Google-Tag-Manager"
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    link a seguir
                                  </a>
                                  .
                                </span>
                              </Column>
                              <Column xs={1} className="pr-0" style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button
                                  variant="link"
                                  startIcon={<OpenLinkIcon width="22px" height="24px" style={{ padding: '2px' }} />}
                                  size="small"
                                  sx={{ padding: '6px', minWidth: '32px' }}
                                  href="https://support.vofficer.io/hc/pt-br/articles/23322149914260-Integração-do-Banner-de-Cookies-com-o-Google-Tag-Manager"
                                  rel="noreferrer"
                                  target="_blank"
                                ></Button>
                              </Column>
                            </Row>
                          </Card>
                        </Column>
                      </Row>
                    )}
                  </Card>
                </Column>
              </Row>
            </TabPanel>
            <TabPanel value={tabPosition} index={1} id="tabpanel-details" ariaLabel="tab-details">
              <RowExhibition>
                <Column xs={3}>Site</Column>
                <Column>{cookie.url}</Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Cor Fundo</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.customization.colors?.background}
                  <div
                    style={{
                      backgroundColor: cookie.customization.colors?.background,
                      width: '30px',
                      height: '15px',
                      display: 'inline-flex',
                      marginInlineStart: '8px',
                      border: '1px solid #ccc',
                    }}
                  >
                    &nbsp;
                  </div>
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Cor Princial</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.customization.colors?.buttonBackground}
                  <div
                    style={{
                      backgroundColor: cookie.customization.colors?.buttonBackground,
                      width: '30px',
                      height: '15px',
                      display: 'inline-flex',
                      marginInlineStart: '8px',
                      border: '1px solid #ccc',
                    }}
                  >
                    &nbsp;
                  </div>
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Cor Texto</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.customization.colors?.font}
                  <div
                    style={{
                      backgroundColor: cookie.customization.colors?.font,
                      width: '30px',
                      height: '15px',
                      display: 'inline-flex',
                      marginInlineStart: '8px',
                      border: '1px solid #ccc',
                    }}
                  >
                    &nbsp;
                  </div>
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Multilingua</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.customization.settings.activeMultilanguage ? 'Sim' : 'Não'}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Língua padrão</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.customization.settings.fixedLang}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Posição do Banner</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {getPositionBanner(cookie.customization.settings.positioning)}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={3}>Desfocar Fundo</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.customization.settings.showBlurBackground ? 'Sim' : 'Não'}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
            <TabPanel value={tabPosition} index={2} id="tabpanel-advance" ariaLabel="tab-advance">
              <RowExhibition>
                <Column xs={6}>Cookies bloqueados até o consentimento do usuário</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {!cookie.customization.advancedSettings?.gdprBanner?.permissive ? 'Sim' : 'Não'}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
              <RowExhibition>
                <Column xs={6}>Bloquear recursos até o consentimento do usuário</Column>
                <Column flex style={{ alignItems: 'center' }}>
                  {cookie.customization.advancedSettings?.gdprBanner?.resourceBlocker ? 'Sim' : 'Não'}
                </Column>
                <ColumnEdit xs={2}>
                  <LinkEdit onClick={onClickEdit}>
                    <EditOutlinedIcon style={{ fill: '#0039C5' }} />
                    Editar
                  </LinkEdit>
                </ColumnEdit>
              </RowExhibition>
            </TabPanel>
            <TabPanel value={tabPosition} index={3} id="tabpanel-category" ariaLabel="tab-category" boxPadding={1}>
              {!!cookieCategories && (
                <DefaultTable
                  rows={tableHeaderCategory}
                  cells={cookieCategories.cookies ?? []}
                  hideDelete
                  hideShow
                  hideEdit
                  link="cookies"
                />
              )}
            </TabPanel>
            <TabPanel value={tabPosition} index={4} id="tabpanel-consent" ariaLabel="tab-consent" boxPadding={1}>
              {!!cookieConsent && (
                <DefaultTable
                  rows={tableHeaderConsent}
                  cells={cookieConsent.consents ?? []}
                  hideDelete
                  hideShow
                  hideEdit
                  link="cookies"
                />
              )}
            </TabPanel>
          </Box>
          <Snackbar
            open={showCopySuccess}
            onClose={() => {
              setShowCopySuccess(false);
            }}
          >
            <Alert
              onClose={() => {
                setShowCopySuccess(false);
              }}
              severity="success"
              sx={{ width: '100%' }}
            >
              Código copiado para área de transferência!
            </Alert>
          </Snackbar>
        </Container>
      )}
      {isLoading && <PageLoaderShow />}
      {error && <div>Erro</div>}
    </>
  );
};
